import { Col, Container, Row } from 'react-bootstrap';
import { Dispatch, SetStateAction } from 'react';

import Feature from '@/common/Feature';
import { SortDirectionEnum } from '@/models/gen/graphql';
import SummaryHistoryFilters from '@/features/SummaryHistory/SummaryHistoryFilters';
import SummaryHistoryTable from '@/features/SummaryHistory/SummaryHistoryTable';
import { sleep } from '@/utils';
import { useProvider } from '@/common/Provider';

const SummaryHistory = (): JSX.Element => {
  return (
    <Feature name="SummaryHistory" context={initSummaryHistoryState} methods={summaryHistoryMethodConstructor}>
      <SummaryHistoryFilters />
      <Container>
        <Row>
          <Col>
            <SummaryHistoryTable />
          </Col>
        </Row>
      </Container>
    </Feature>
  );
};

export type SummaryHistoryFilters = {};
export type SummaryHistoryRow = {};
export type SummaryHistoryContext = {
  filters: SummaryHistoryFilters;
  summaries: Map<string, SummaryHistoryRow>;
  totalSummaries: number;
  selected: Map<string, SummaryHistoryRow>;
  loading: boolean;
  sortBy: string;
  sortDirection: SortDirectionEnum;
};
export type SummaryHistoryMethods = {
  resetState: (overrides?: Partial<SummaryHistoryContext>) => void;
  submitFilters: () => Promise<void>;
};
export const initSummaryHistoryState: SummaryHistoryContext = {
  filters: {},
  summaries: new Map(),
  totalSummaries: 0,
  selected: new Map(),
  sortBy: '',
  sortDirection: SortDirectionEnum.Asc,
  loading: false,
};
export const summaryHistoryMethodConstructor = (
  _state: SummaryHistoryContext,
  setState: Dispatch<SetStateAction<SummaryHistoryContext>>
): SummaryHistoryMethods => ({
  resetState: (overrides?: Partial<SummaryHistoryContext>): void => setState({ ...initSummaryHistoryState, ...(overrides || {}) }),
  submitFilters: async (): Promise<void> => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      // TODO: Add full query logic once the service has been created.
      await sleep(3000);
    } catch (err) {
      console.error('Error submitting filters:', err);
    } finally {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  },
});
export function useSummaryHistory<T = SummaryHistoryContext>(
  selector?: (state: SummaryHistoryContext) => T
): [T, Dispatch<SetStateAction<SummaryHistoryContext>>, SummaryHistoryMethods] {
  return useProvider<SummaryHistoryContext, T, SummaryHistoryMethods>(selector);
}

export default SummaryHistory;
