import './styles.scss';

import { Col, Container, Row } from 'react-bootstrap';
import { Dispatch, SetStateAction } from 'react';

import AccidentHistoryFilters from '@/features/AccidentHistory/AccidentHistoryFilters';
import AccidentHistoryTable from '@/features/AccidentHistory/AccidentHistoryTable';
import Feature from '@/common/Feature';
import { SortDirectionEnum } from '@/models/gen/graphql';
import { sleep } from '@/utils';
import { useProvider } from '@/common/Provider';

const AccidentHistory = (): JSX.Element => {
  return (
    <Feature name="AccidentHistory" context={initAccidentHistoryState} methods={incidentHistoryMethodConstructor}>
      <AccidentHistoryFilters />
      <Container>
        <Row>
          <Col>
            <AccidentHistoryTable />
          </Col>
        </Row>
      </Container>
    </Feature>
  );
};

export type AccidentHistoryFilters = {};
export type AccidentHistoryRow = {
  id: string;
  airport: string;
  datetime: string;
  airline: string;
  flightNumber: string;
  scheduledTime: string;
  van: string;
  employee: string;
  idNumber: string;
  manager: string;
  injury: boolean;
  crewInvolved: boolean;
};
export type AccidentHistoryContext = {
  filters: AccidentHistoryFilters;
  accidents: Map<string, AccidentHistoryRow>;
  totalAccidents: number;
  selected: Map<string, AccidentHistoryRow>;
  loading: boolean;
  sortBy: string;
  sortDirection: SortDirectionEnum;
};
export type AccidentHistoryMethods = {
  resetState: (overrides?: Partial<AccidentHistoryContext>) => void;
  submitFilters: () => Promise<void>;
};

// START MOCK DATA
const mockAccidents = new Map();
// END MOCK DATA

export const initAccidentHistoryState: AccidentHistoryContext = {
  filters: {},
  accidents: mockAccidents, // SET MOCK DATA
  totalAccidents: mockAccidents.size + 142,
  selected: new Map(),
  loading: false,
  sortBy: undefined,
  sortDirection: SortDirectionEnum.Asc,
};
export const incidentHistoryMethodConstructor = (
  _state: AccidentHistoryContext,
  setState: Dispatch<SetStateAction<AccidentHistoryContext>>
): AccidentHistoryMethods => ({
  resetState: (overrides?: Partial<AccidentHistoryContext>): void => setState({ ...initAccidentHistoryState, ...(overrides || {}) }),
  submitFilters: async (): Promise<void> => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      // TODO: Add full query logic once the service has been created.
      await sleep(3000);
    } catch (err) {
      console.error('Error submitting filters:', err);
    } finally {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  },
});
export function useAccidentHistory<T = AccidentHistoryContext>(
  selector?: (state: AccidentHistoryContext) => T
): [T, Dispatch<SetStateAction<AccidentHistoryContext>>, AccidentHistoryMethods] {
  return useProvider<AccidentHistoryContext, T, AccidentHistoryMethods>(selector);
}

export default AccidentHistory;

// APPEND MOCK DATA
Object.entries({
  '1': {
    id: '1',
    airport: 'JFK',
    datetime: '2023-01-01 12:00',
    airline: 'AA',
    flightNumber: '1234',
    scheduledTime: '2023-01-01 12:30',
    van: '654',
    employee: 'John Doe',
    idNumber: '12345',
    manager: 'Jane Smith',
    injury: true,
    crewInvolved: false,
  },
  '2': {
    id: '2',
    airport: 'LAX',
    datetime: '2023-01-02 14:00',
    airline: 'DL',
    flightNumber: '5678',
    scheduledTime: '2023-01-02 14:30',
    van: '098',
    employee: 'Alice Johnson',
    idNumber: '67890',
    manager: 'Bob Brown',
    injury: false,
    crewInvolved: true,
  },
  '3': {
    id: '3',
    airport: 'ORD',
    datetime: '2023-01-03 16:00',
    airline: 'UA',
    flightNumber: '9101',
    scheduledTime: '2023-01-03 16:30',
    van: '321',
    employee: 'Charlie White',
    idNumber: '11223',
    manager: 'Diana Green',
    injury: true,
    crewInvolved: true,
  },
  '4': {
    id: '4',
    airport: 'DFW',
    datetime: '2023-01-04 18:00',
    airline: 'SW',
    flightNumber: '1415',
    scheduledTime: '2023-01-04 18:30',
    van: '456',
    employee: 'Eve Black',
    idNumber: '44556',
    manager: 'Frank Blue',
    injury: false,
    crewInvolved: false,
  },
  '5': {
    id: '5',
    airport: 'MIA',
    datetime: '2023-01-05 20:00',
    airline: 'AA',
    flightNumber: '1617',
    scheduledTime: '2023-01-05 20:30',
    van: '789',
    employee: 'Grace Yellow',
    idNumber: '77889',
    manager: 'Hank Purple',
    injury: true,
    crewInvolved: false,
  },
  '6': {
    id: '6',
    airport: 'SEA',
    datetime: '2023-01-06 22:00',
    airline: 'DL',
    flightNumber: '1819',
    scheduledTime: '2023-01-06 22:30',
    van: '012',
    employee: 'Ivy Orange',
    idNumber: '99000',
    manager: 'Jack Pink',
    injury: false,
    crewInvolved: true,
  },
  '7': {
    id: '7',
    airport: 'BOS',
    datetime: '2023-01-07 23:00',
    airline: 'UA',
    flightNumber: '2021',
    scheduledTime: '2023-01-07 23:30',
    van: '345',
    employee: 'Kevin Gray',
    idNumber: '33445',
    manager: 'Lily Cyan',
    injury: true,
    crewInvolved: true,
  },
  '8': {
    id: '8',
    airport: 'PHX',
    datetime: '2023-01-08 01:00',
    airline: 'SW',
    flightNumber: '2223',
    scheduledTime: '2023-01-08 01:30',
    van: '678',
    employee: 'Mia Brown',
    idNumber: '55667',
    manager: 'Nina Magenta',
    injury: false,
    crewInvolved: false,
  },
  '9': {
    id: '9',
    airport: 'ATL',
    datetime: '2023-01-09 03:00',
    airline: 'AA',
    flightNumber: '2425',
    scheduledTime: '2023-01-09 03:30',
    van: '901',
    employee: 'Noah White',
    idNumber: '66778',
    manager: 'Olivia Black',
    injury: true,
    crewInvolved: false,
  },
  '10': {
    id: '10',
    airport: 'DEN',
    datetime: '2023-01-10 05:00',
    airline: 'DL',
    flightNumber: '2627',
    scheduledTime: '2023-01-10 05:30',
    van: '234',
    employee: 'Liam Green',
    idNumber: '88990',
    manager: 'Emma Blue',
    injury: false,
    crewInvolved: true,
  },
  '11': {
    id: '11',
    airport: 'SFO',
    datetime: '2023-01-11 07:00',
    airline: 'UA',
    flightNumber: '2829',
    scheduledTime: '2023-01-11 07:30',
    van: '567',
    employee: 'Sophia Red',
    idNumber: '11234',
    manager: 'Ava Yellow',
    injury: true,
    crewInvolved: true,
  },
  '12': {
    id: '12',
    airport: 'LAS',
    datetime: '2023-01-12 09:00',
    airline: 'SW',
    flightNumber: '3031',
    scheduledTime: '2023-01-12 09:30',
    van: '890',
    employee: 'Mason Brown',
    idNumber: '33456',
    manager: 'Isabella Purple',
    injury: false,
    crewInvolved: false,
  },
  '13': {
    id: '13',
    airport: 'MCO',
    datetime: '2023-01-13 11:00',
    airline: 'AA',
    flightNumber: '3233',
    scheduledTime: '2023-01-13 11:30',
    van: '123',
    employee: 'James Orange',
    idNumber: '55678',
    manager: 'Mia Pink',
    injury: true,
    crewInvolved: false,
  },
  '14': {
    id: '14',
    airport: 'CLT',
    datetime: '2023-01-14 13:00',
    airline: 'DL',
    flightNumber: '3435',
    scheduledTime: '2023-01-14 13:30',
    van: '456',
    employee: 'Benjamin Gray',
    idNumber: '77890',
    manager: 'Amelia Cyan',
    injury: false,
    crewInvolved: true,
  },
  '15': {
    id: '15',
    airport: 'PHL',
    datetime: '2023-01-15 15:00',
    airline: 'UA',
    flightNumber: '3637',
    scheduledTime: '2023-01-15 15:30',
    van: '789',
    employee: 'Elijah Magenta',
    idNumber: '99012',
    manager: 'Harper Violet',
    injury: true,
    crewInvolved: true,
  },
  '16': {
    id: '16',
    airport: 'IAH',
    datetime: '2023-01-16 17:00',
    airline: 'SW',
    flightNumber: '3839',
    scheduledTime: '2023-01-16 17:30',
    van: '012',
    employee: 'Lucas Indigo',
    idNumber: '11234',
    manager: 'Evelyn Teal',
    injury: false,
    crewInvolved: false,
  },
  '17': {
    id: '17',
    airport: 'MSP',
    datetime: '2023-01-17 19:00',
    airline: 'AA',
    flightNumber: '4041',
    scheduledTime: '2023-01-17 19:30',
    van: '345',
    employee: 'Henry White',
    idNumber: '33456',
    manager: 'Abigail Black',
    injury: true,
    crewInvolved: false,
  },
  '18': {
    id: '18',
    airport: 'DTW',
    datetime: '2023-01-18 21:00',
    airline: 'DL',
    flightNumber: '4243',
    scheduledTime: '2023-01-18 21:30',
    van: '678',
    employee: 'Alexander Green',
    idNumber: '55678',
    manager: 'Ella Blue',
    injury: false,
    crewInvolved: true,
  },
  '19': {
    id: '19',
    airport: 'BWI',
    datetime: '2023-01-19 23:00',
    airline: 'UA',
    flightNumber: '4445',
    scheduledTime: '2023-01-19 23:30',
    van: '901',
    employee: 'Sebastian Red',
    idNumber: '77890',
    manager: 'Scarlett Yellow',
    injury: true,
    crewInvolved: true,
  },
  '20': {
    id: '20',
    airport: 'FLL',
    datetime: '2023-01-20 01:00',
    airline: 'SW',
    flightNumber: '4647',
    scheduledTime: '2023-01-20 01:30',
    van: '234',
    employee: 'Jack Brown',
    idNumber: '99012',
    manager: 'Victoria Purple',
    injury: false,
    crewInvolved: false,
  },
  '21': {
    id: '21',
    airport: 'SAN',
    datetime: '2023-01-21 03:00',
    airline: 'AA',
    flightNumber: '4849',
    scheduledTime: '2023-01-21 03:30',
    van: '567',
    employee: 'Daniel Orange',
    idNumber: '11234',
    manager: 'Grace Pink',
    injury: true,
    crewInvolved: false,
  },
  '22': {
    id: '22',
    airport: 'TPA',
    datetime: '2023-01-22 05:00',
    airline: 'DL',
    flightNumber: '5051',
    scheduledTime: '2023-01-22 05:30',
    van: '890',
    employee: 'Matthew Gray',
    idNumber: '33456',
    manager: 'Chloe Cyan',
    injury: false,
    crewInvolved: true,
  },
  '23': {
    id: '23',
    airport: 'PDX',
    datetime: '2023-01-23 07:00',
    airline: 'UA',
    flightNumber: '5253',
    scheduledTime: '2023-01-23 07:30',
    van: '123',
    employee: 'Joseph Magenta',
    idNumber: '55678',
    manager: 'Zoe Violet',
    injury: true,
    crewInvolved: true,
  },
  '24': {
    id: '24',
    airport: 'SLC',
    datetime: '2023-01-24 09:00',
    airline: 'SW',
    flightNumber: '5455',
    scheduledTime: '2023-01-24 09:30',
    van: '456',
    employee: 'Samuel Indigo',
    idNumber: '77890',
    manager: 'Nora Teal',
    injury: false,
    crewInvolved: false,
  },
  '25': {
    id: '25',
    airport: 'DCA',
    datetime: '2023-01-25 11:00',
    airline: 'AA',
    flightNumber: '5657',
    scheduledTime: '2023-01-25 11:30',
    van: '789',
    employee: 'David White',
    idNumber: '99012',
    manager: 'Lily Black',
    injury: true,
    crewInvolved: false,
  },
  '26': {
    id: '26',
    airport: 'MDW',
    datetime: '2023-01-26 13:00',
    airline: 'DL',
    flightNumber: '5859',
    scheduledTime: '2023-01-26 13:30',
    van: '012',
    employee: 'Carter Green',
    idNumber: '11234',
    manager: 'Hannah Blue',
    injury: false,
    crewInvolved: true,
  },
  '27': {
    id: '27',
    airport: 'HNL',
    datetime: '2023-01-27 15:00',
    airline: 'UA',
    flightNumber: '6061',
    scheduledTime: '2023-01-27 15:30',
    van: '345',
    employee: 'Owen Red',
    idNumber: '33456',
    manager: 'Addison Yellow',
    injury: true,
    crewInvolved: true,
  },
  '28': {
    id: '28',
    airport: 'AUS',
    datetime: '2023-01-28 17:00',
    airline: 'SW',
    flightNumber: '6263',
    scheduledTime: '2023-01-28 17:30',
    van: '678',
    employee: 'Wyatt Brown',
    idNumber: '55678',
    manager: 'Aubrey Purple',
    injury: false,
    crewInvolved: false,
  },
  '29': {
    id: '29',
    airport: 'DAL',
    datetime: '2023-01-29 19:00',
    airline: 'AA',
    flightNumber: '6465',
    scheduledTime: '2023-01-29 19:30',
    van: '901',
    employee: 'John Orange',
    idNumber: '77890',
    manager: 'Eleanor Pink',
    injury: true,
    crewInvolved: false,
  },
  '30': {
    id: '30',
    airport: 'STL',
    datetime: '2023-01-30 21:00',
    airline: 'DL',
    flightNumber: '6667',
    scheduledTime: '2023-01-30 21:30',
    van: '234',
    employee: 'Jack Gray',
    idNumber: '99012',
    manager: 'Penelope Cyan',
    injury: false,
    crewInvolved: true,
  },
  '31': {
    id: '31',
    airport: 'BNA',
    datetime: '2023-01-31 23:00',
    airline: 'UA',
    flightNumber: '6869',
    scheduledTime: '2023-01-31 23:30',
    van: '567',
    employee: 'Levi Magenta',
    idNumber: '11234',
    manager: 'Aria Violet',
    injury: true,
    crewInvolved: true,
  },
  '32': {
    id: '32',
    airport: 'RDU',
    datetime: '2023-02-01 01:00',
    airline: 'SW',
    flightNumber: '7071',
    scheduledTime: '2023-02-01 01:30',
    van: '890',
    employee: 'Isaac Indigo',
    idNumber: '33456',
    manager: 'Layla Teal',
    injury: false,
    crewInvolved: false,
  },
  '33': {
    id: '33',
    airport: 'MCI',
    datetime: '2023-02-02 03:00',
    airline: 'AA',
    flightNumber: '7273',
    scheduledTime: '2023-02-02 03:30',
    van: '123',
    employee: 'Gabriel White',
    idNumber: '55678',
    manager: 'Lillian Black',
    injury: true,
    crewInvolved: false,
  },
  '34': {
    id: '34',
    airport: 'CLE',
    datetime: '2023-02-03 05:00',
    airline: 'DL',
    flightNumber: '7475',
    scheduledTime: '2023-02-03 05:30',
    van: '456',
    employee: 'Julian Green',
    idNumber: '77890',
    manager: 'Natalie Blue',
    injury: false,
    crewInvolved: true,
  },
  '35': {
    id: '35',
    airport: 'SMF',
    datetime: '2023-02-04 07:00',
    airline: 'UA',
    flightNumber: '7677',
    scheduledTime: '2023-02-04 07:30',
    van: '789',
    employee: 'Anthony Red',
    idNumber: '99012',
    manager: 'Camila Yellow',
    injury: true,
    crewInvolved: true,
  },
  '36': {
    id: '36',
    airport: 'SJC',
    datetime: '2023-02-05 09:00',
    airline: 'SW',
    flightNumber: '7879',
    scheduledTime: '2023-02-05 09:30',
    van: '012',
    employee: 'Dylan Brown',
    idNumber: '11234',
    manager: 'Aurora Purple',
    injury: false,
    crewInvolved: false,
  },
  '37': {
    id: '37',
    airport: 'SAT',
    datetime: '2023-02-06 11:00',
    airline: 'AA',
    flightNumber: '8081',
    scheduledTime: '2023-02-06 11:30',
    van: '345',
    employee: 'Lincoln Orange',
    idNumber: '33456',
    manager: 'Savannah Pink',
    injury: true,
    crewInvolved: false,
  },
  '38': {
    id: '38',
    airport: 'PIT',
    datetime: '2023-02-07 13:00',
    airline: 'DL',
    flightNumber: '8283',
    scheduledTime: '2023-02-07 13:30',
    van: '678',
    employee: 'Jaxon Gray',
    idNumber: '55678',
    manager: 'Brooklyn Cyan',
    injury: false,
    crewInvolved: true,
  },
  '39': {
    id: '39',
    airport: 'MKE',
    datetime: '2023-02-08 15:00',
    airline: 'UA',
    flightNumber: '8485',
    scheduledTime: '2023-02-08 15:30',
    van: '901',
    employee: 'Grayson Magenta',
    idNumber: '77890',
    manager: 'Bella Violet',
    injury: true,
    crewInvolved: true,
  },
  '40': {
    id: '40',
    airport: 'RSW',
    datetime: '2023-02-09 17:00',
    airline: 'SW',
    flightNumber: '8687',
    scheduledTime: '2023-02-09 17:30',
    van: '234',
    employee: 'Isaiah Indigo',
    idNumber: '99012',
    manager: 'Claire Teal',
    injury: false,
    crewInvolved: false,
  },
}).forEach(([key, value]) => mockAccidents.set(key, value));
