import { initIssueHistoryState, useIssueHistory } from '@/features/IssueHistory';

import { Button } from 'react-bootstrap';
import IssueHistoryFiltersInfo from '@/features/IssueHistory/IssueHistoryFilters/IssueHistoryFiltersInfo';
import QueryFilters from '@/components/QueryFilters';
import { useCallback } from 'react';

// TODO: Add appropriate filters to this component based on the design when it is complete.
const IssueHistoryFilters = (): JSX.Element => {
  const [{ loading }, setState, { submitFilters }] = useIssueHistory(({ loading }) => ({ loading }));
  const resetFilters = useCallback(() => setState((current) => ({ ...current, filters: initIssueHistoryState.filters })), [setState]);

  return (
    <>
      <QueryFilters>
        <QueryFilters.Control>
          <Button className="border-white" name="SUBMIT" variant="success" onClick={submitFilters} disabled={loading}>
            {loading && <i className="fa fa-spinner fa-pulse" />}
            {!loading && <span>Go</span>}
          </Button>
          <Button name="RESET" variant="outline-secondary-subtle" onClick={resetFilters}>
            Reset
          </Button>
        </QueryFilters.Control>
      </QueryFilters>
      <IssueHistoryFiltersInfo />
    </>
  );
};

export default IssueHistoryFilters;
