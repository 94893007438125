import EnumDropdown, { getItemsFromEnum } from '@/components/EnumDropdown';
import { initIncidentHistoryState, useIncidentHistory } from '@/features/IncidentHistory';

import AirlineIataDropdown from '@/components/AirlineDropdown';
import AirportDropdown from '@/components/AirportDropdown';
import { Button } from 'react-bootstrap';
import DateRangePicker from '@/components/DateRangePicker';
import Dropdown from '@/components/Dropdown';
import IncidentHistoryFiltersInfo from '@/features/IncidentHistory/IncidentHistoryFilters/IncidentHistoryFiltersInfo';
import QueryFilters from '@/components/QueryFilters';
import { useCallback } from 'react';

const IncidentHistoryFilters = (): JSX.Element => {
  const [{ filters, loading }, setState, { submitFilters }] = useIncidentHistory(({ filters, loading }) => ({ filters, loading }));
  const { startDatetime, endDatetime, airports, airlines, injuries } = filters;

  const resetFilters = useCallback((): void => {
    setState((current) => ({ ...current, filters: initIncidentHistoryState.filters }));
  }, [setState]);

  const onChange = useCallback(
    (name: string): ((value) => void) =>
      (value): void => {
        switch (name) {
          case 'datetime':
            const [from, to] = value?.target?.value?.split?.(' - ') || [null, null];
            return setState((current) => ({ ...current, filters: { ...current.filters, startDatetime: from, endDatetime: to } }));
          default:
            return setState((current) => ({ ...current, filters: { ...current.filters, [name]: value } }));
        }
      },
    []
  );

  return (
    <>
      <QueryFilters>
        <QueryFilters.Control>
          <DateRangePicker value={[startDatetime, endDatetime]} onChange={onChange('datetime')} />
        </QueryFilters.Control>
        <QueryFilters.Input>
          <AirportDropdown.Multi value={airports} onChange={onChange('airports')} options={{ locale: { 'Select...': 'Airports' } }} />
        </QueryFilters.Input>
        <QueryFilters.Input>
          <AirlineIataDropdown.Multi value={airlines} onChange={onChange('airlines')} options={{ locale: { 'Select...': 'Airlines' } }} />
        </QueryFilters.Input>
        <QueryFilters.Input>
          <Dropdown
            value={injuries}
            onChange={onChange('injuries')}
            items={[
              { label: 'Yes', value: '1' },
              { label: 'No', value: '0' },
            ]}
            options={{ locale: { 'Select...': 'Injuries?' } }}
          />
        </QueryFilters.Input>
        <QueryFilters.Control>
          <Button className="border-white" name="SUBMIT" variant="success" onClick={submitFilters} disabled={loading}>
            {loading && <i className="fa fa-spinner fa-pulse" />}
            {!loading && <span>Go</span>}
          </Button>
          <Button name="RESET" variant="outline-secondary-subtle" onClick={resetFilters}>
            Reset
          </Button>
        </QueryFilters.Control>
      </QueryFilters>
      <IncidentHistoryFiltersInfo />
    </>
  );
};

export default IncidentHistoryFilters;
