import './styles.scss';

import { Modal as BootstrapModal, ModalProps as BootstrapModalProps } from 'react-bootstrap';

import CloseButton from '@/common/CloseButton';
import ModalBody from '@/common/Modal/ModalBody';
import ModalFooter from '@/common/Modal/ModalFooter';
import ModalHeader from '@/common/Modal/ModalHeader';
import { getClasses } from '@/utils';

export type ModalProps = { title?: string; icon?: JSX.Element; header?: () => JSX.Element; footer?: () => JSX.Element } & Omit<
  BootstrapModalProps,
  'title'
>;

const Modal = ({ title, icon, header: Header, footer: Footer, children, ...bootstrapModalProps }: ModalProps): JSX.Element => {
  return (
    <BootstrapModal
      {...bootstrapModalProps}
      className={getClasses('Modal', bootstrapModalProps.className)}
      backdropClassName={getClasses('Modal-Backdrop', bootstrapModalProps.backdropClassName)}
      dialogClassName={getClasses('Modal-Dialog', bootstrapModalProps.dialogClassName)}
      contentClassName={getClasses('Modal-Content', bootstrapModalProps.contentClassName)}
    >
      <CloseButton className="Modal-Close" onClick={bootstrapModalProps.onHide} />
      <ModalHeader title={title} icon={icon}>
        {Header && <Header />}
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>{Footer && <Footer />}</ModalFooter>
    </BootstrapModal>
  );
};

export default Modal;
