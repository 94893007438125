/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AbortTripInput = {
  tripIds: Array<Scalars['String']['input']>;
};

export type AbortTripResponse = {
  __typename?: 'AbortTripResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type AcceptInputOption = {
  __typename?: 'AcceptInputOption';
  acceptInput: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type AcceptResponse = {
  __typename?: 'AcceptResponse';
  accepted: Scalars['Boolean']['output'];
  error?: Maybe<Error>;
  id: Scalars['String']['output'];
};

export type Activity = {
  __typename?: 'Activity';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdAtInLocation?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  formerObject?: Maybe<ActivityObject>;
  formerObjectId?: Maybe<Scalars['String']['output']>;
  formerValue?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  object?: Maybe<ActivityObject>;
  objectId?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<ActivitySubject>;
  subjectId: Scalars['String']['output'];
  tripId: Scalars['String']['output'];
  type: ActivityTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedAtInLocation?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  via?: Maybe<Scalars['String']['output']>;
};

export type ActivityConnection = {
  __typename?: 'ActivityConnection';
  edges?: Maybe<Array<Maybe<ActivityEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ActivityConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<ActivityMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Array<ActivitySearch>>;
};

export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Activity>;
};

export type ActivityMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  field?: InputMaybe<Scalars['Boolean']['input']>;
  formerObjectId?: InputMaybe<Scalars['Boolean']['input']>;
  formerValue?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['Boolean']['input']>;
  objectId?: InputMaybe<Scalars['Boolean']['input']>;
  subjectId?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['Boolean']['input']>;
  via?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityObject = {
  __typename?: 'ActivityObject';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ActivityObjectConnection = {
  __typename?: 'ActivityObjectConnection';
  edges?: Maybe<Array<Maybe<ActivityObjectEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ActivityObjectConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<ActivityObjectMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<ActivityObjectSearch>>;
};

export type ActivityObjectEdge = {
  __typename?: 'ActivityObjectEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ActivityObject>;
};

export type ActivityObjectMaskInput = {
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityObjectSearch = {
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<StringSearch>>;
  url?: InputMaybe<Array<StringSearch>>;
};

export type ActivitySearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  field?: InputMaybe<Array<StringSearch>>;
  formerObjectId?: InputMaybe<Array<StringSearch>>;
  formerValue?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  message?: InputMaybe<Array<StringSearch>>;
  objectId?: InputMaybe<Array<StringSearch>>;
  subjectId?: InputMaybe<Array<StringSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<ActivityTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  value?: InputMaybe<Array<StringSearch>>;
  via?: InputMaybe<Array<StringSearch>>;
};

export type ActivitySubject = {
  __typename?: 'ActivitySubject';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export enum ActivityTypeEnum {
  Client = 'CLIENT',
  Combine = 'COMBINE',
  Completion = 'COMPLETION',
  Import = 'IMPORT',
  Location = 'LOCATION',
  Rate = 'RATE',
  Trip = 'TRIP',
  User = 'USER',
  Vehicle = 'VEHICLE'
}

export type ActivityTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ActivityTypeEnum>;
};

export type AddLocationAliasesInput = {
  locations: Array<AliasInput>;
};

export type AddLocationAliasesResponse = {
  __typename?: 'AddLocationAliasesResponse';
  airportCodes?: Maybe<Array<AirportHasLocation>>;
  aliases?: Maybe<Array<Maybe<Alias>>>;
};

export enum AirlineTripsTableColumnsEnum {
  Activity = 'ACTIVITY',
  Actual = 'ACTUAL',
  AirportCode = 'AIRPORT_CODE',
  Attendant = 'ATTENDANT',
  Client = 'CLIENT',
  Combine = 'COMBINE',
  Completion = 'COMPLETION',
  DoLocation = 'DO_LOCATION',
  Driver = 'DRIVER',
  Eta = 'ETA',
  Fcr = 'FCR',
  Flags = 'FLAGS',
  FlightNumber = 'FLIGHT_NUMBER',
  IataCode = 'IATA_CODE',
  Pilot = 'PILOT',
  PuLocation = 'PU_LOCATION',
  Rate = 'RATE',
  ScheduledDate = 'SCHEDULED_DATE',
  ScheduledTime = 'SCHEDULED_TIME',
  Type = 'TYPE',
  Vehicle = 'VEHICLE'
}

export type Airport = {
  __typename?: 'Airport';
  cityCode: Scalars['String']['output'];
  cityName: Scalars['String']['output'];
  code: Scalars['String']['output'];
  country: Scalars['String']['output'];
  countryId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  elevation: Scalars['Int']['output'];
  icao: Scalars['String']['output'];
  location: Point;
  name: Scalars['String']['output'];
  stateCode: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type AirportConnection = {
  __typename?: 'AirportConnection';
  edges?: Maybe<Array<Maybe<AirportEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AirportConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AirportMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AirportSearch>>;
};

export type AirportEdge = {
  __typename?: 'AirportEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Airport>;
};

export type AirportGroup = {
  __typename?: 'AirportGroup';
  companyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type AirportGroupConnection = {
  __typename?: 'AirportGroupConnection';
  edges?: Maybe<Array<Maybe<AirportGroupEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AirportGroupConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AirportGroupMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AirportGroupSearch>>;
};

export type AirportGroupEdge = {
  __typename?: 'AirportGroupEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AirportGroup>;
};

export type AirportGroupMaskInput = {
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirportGroupSearch = {
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type AirportHasCompany = {
  __typename?: 'AirportHasCompany';
  airportCode: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
};

export type AirportHasCompanyConnection = {
  __typename?: 'AirportHasCompanyConnection';
  edges?: Maybe<Array<Maybe<AirportHasCompanyEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AirportHasCompanyConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AirportHasCompanyMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AirportHasCompanySearch>>;
};

export type AirportHasCompanyEdge = {
  __typename?: 'AirportHasCompanyEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AirportHasCompany>;
};

export type AirportHasCompanyMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirportHasCompanySearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
};

export type AirportHasGroup = {
  __typename?: 'AirportHasGroup';
  airportCode: Scalars['String']['output'];
  groupId: Scalars['String']['output'];
};

export type AirportHasGroupConnection = {
  __typename?: 'AirportHasGroupConnection';
  edges?: Maybe<Array<Maybe<AirportHasGroupEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AirportHasGroupConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AirportHasGroupMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AirportHasGroupSearch>>;
};

export type AirportHasGroupEdge = {
  __typename?: 'AirportHasGroupEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AirportHasGroup>;
};

export type AirportHasGroupMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  groupId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirportHasGroupSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  groupId?: InputMaybe<Array<StringSearch>>;
};

export type AirportHasLocation = {
  __typename?: 'AirportHasLocation';
  airportCode: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  locationId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type AirportHasLocationConnection = {
  __typename?: 'AirportHasLocationConnection';
  edges?: Maybe<Array<Maybe<AirportHasLocationEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AirportHasLocationConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AirportHasLocationMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AirportHasLocationSearch>>;
};

export type AirportHasLocationEdge = {
  __typename?: 'AirportHasLocationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AirportHasLocation>;
};

export type AirportHasLocationMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirportHasLocationSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  locationId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type AirportHasRate = {
  __typename?: 'AirportHasRate';
  airportCode: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
};

export type AirportHasRateConnection = {
  __typename?: 'AirportHasRateConnection';
  edges?: Maybe<Array<Maybe<AirportHasRateEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AirportHasRateConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AirportHasRateMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AirportHasRateSearch>>;
};

export type AirportHasRateEdge = {
  __typename?: 'AirportHasRateEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AirportHasRate>;
};

export type AirportHasRateMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  rate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirportHasRateSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  rate?: InputMaybe<Array<FloatSearch>>;
};

export type AirportHasUser = {
  __typename?: 'AirportHasUser';
  airportCode: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type AirportHasVehicle = {
  __typename?: 'AirportHasVehicle';
  airportCode: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<Vehicle>;
  vehicleId: Scalars['String']['output'];
};

export type AirportHasVehicleConnection = {
  __typename?: 'AirportHasVehicleConnection';
  edges?: Maybe<Array<Maybe<AirportHasVehicleEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AirportHasVehicleConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AirportHasVehicleMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AirportHasVehicleSearch>>;
};

export type AirportHasVehicleEdge = {
  __typename?: 'AirportHasVehicleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AirportHasVehicle>;
};

export type AirportHasVehicleMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirportHasVehicleSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
};

export type AirportMaskInput = {
  cityCode?: InputMaybe<Scalars['Boolean']['input']>;
  cityName?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['Boolean']['input']>;
  countryId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  elevation?: InputMaybe<Scalars['Boolean']['input']>;
  icao?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  stateCode?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirportSearch = {
  cityCode?: InputMaybe<Array<StringSearch>>;
  cityName?: InputMaybe<Array<StringSearch>>;
  code?: InputMaybe<Array<StringSearch>>;
  country?: InputMaybe<Array<StringSearch>>;
  countryId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  elevation?: InputMaybe<Array<IntSearch>>;
  icao?: InputMaybe<Array<StringSearch>>;
  location?: InputMaybe<Array<PointSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  stateCode?: InputMaybe<Array<StringSearch>>;
  timezone?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  url?: InputMaybe<Array<StringSearch>>;
};

export type AirportWithCompany = {
  __typename?: 'AirportWithCompany';
  cityCode: Scalars['String']['output'];
  cityName: Scalars['String']['output'];
  code: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  country: Scalars['String']['output'];
  countryId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  elevation: Scalars['Int']['output'];
  icao: Scalars['String']['output'];
  location: Point;
  name: Scalars['String']['output'];
  stateCode: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type AirportWithCompanyConnection = {
  __typename?: 'AirportWithCompanyConnection';
  edges?: Maybe<Array<Maybe<AirportWithCompanyEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AirportWithCompanyConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AirportWithCompanyMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AirportWithCompanySearch>>;
};

export type AirportWithCompanyEdge = {
  __typename?: 'AirportWithCompanyEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AirportWithCompany>;
};

export type AirportWithCompanyMaskInput = {
  cityCode?: InputMaybe<Scalars['Boolean']['input']>;
  cityName?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['Boolean']['input']>;
  countryId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  elevation?: InputMaybe<Scalars['Boolean']['input']>;
  icao?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  stateCode?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirportWithCompanySearch = {
  cityCode?: InputMaybe<Array<StringSearch>>;
  cityName?: InputMaybe<Array<StringSearch>>;
  code?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  country?: InputMaybe<Array<StringSearch>>;
  countryId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  elevation?: InputMaybe<Array<IntSearch>>;
  icao?: InputMaybe<Array<StringSearch>>;
  location?: InputMaybe<Array<PointSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  stateCode?: InputMaybe<Array<StringSearch>>;
  timezone?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  url?: InputMaybe<Array<StringSearch>>;
};

export type AirportWithGroup = {
  __typename?: 'AirportWithGroup';
  airportCode: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  groupId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type AirportWithGroupConnection = {
  __typename?: 'AirportWithGroupConnection';
  edges?: Maybe<Array<Maybe<AirportWithGroupEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AirportWithGroupConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AirportWithGroupMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AirportWithGroupSearch>>;
};

export type AirportWithGroupEdge = {
  __typename?: 'AirportWithGroupEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AirportWithGroup>;
};

export type AirportWithGroupMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  groupId?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirportWithGroupSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  groupId?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type AirportWithLocation = {
  __typename?: 'AirportWithLocation';
  active: Scalars['Int']['output'];
  address: Scalars['String']['output'];
  airportCode: Scalars['String']['output'];
  cityName?: Maybe<Scalars['String']['output']>;
  coordinates: Point;
  countryCode: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legacyId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  primaryAirportCode?: Maybe<Scalars['String']['output']>;
  radius: Scalars['Int']['output'];
  stateCode?: Maybe<Scalars['String']['output']>;
  traceId?: Maybe<Scalars['String']['output']>;
  type: AirportWithLocationTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type AirportWithLocationConnection = {
  __typename?: 'AirportWithLocationConnection';
  edges?: Maybe<Array<Maybe<AirportWithLocationEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AirportWithLocationConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AirportWithLocationMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AirportWithLocationSearch>>;
};

export type AirportWithLocationEdge = {
  __typename?: 'AirportWithLocationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AirportWithLocation>;
};

export type AirportWithLocationMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['Boolean']['input']>;
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  cityName?: InputMaybe<Scalars['Boolean']['input']>;
  coordinates?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  primaryAirportCode?: InputMaybe<Scalars['Boolean']['input']>;
  radius?: InputMaybe<Scalars['Boolean']['input']>;
  stateCode?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirportWithLocationSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  address?: InputMaybe<Array<StringSearch>>;
  airportCode?: InputMaybe<Array<StringSearch>>;
  cityName?: InputMaybe<Array<StringSearch>>;
  coordinates?: InputMaybe<Array<PointSearch>>;
  countryCode?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  legacyId?: InputMaybe<Array<IntSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  phoneNumber?: InputMaybe<Array<StringSearch>>;
  primaryAirportCode?: InputMaybe<Array<StringSearch>>;
  radius?: InputMaybe<Array<IntSearch>>;
  stateCode?: InputMaybe<Array<StringSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<AirportWithLocationTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  zipCode?: InputMaybe<Array<StringSearch>>;
};

export enum AirportWithLocationTypeEnum {
  Airport = 'AIRPORT',
  Exchange = 'EXCHANGE',
  Hotel = 'HOTEL'
}

export type AirportWithLocationTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<AirportWithLocationTypeEnum>;
};

export type AirportWithVehicle = {
  __typename?: 'AirportWithVehicle';
  active: Scalars['Int']['output'];
  airportCode: Scalars['String']['output'];
  airportPermitExpirationDate?: Maybe<Scalars['String']['output']>;
  capacity: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  emissionPermitExpirationDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  licensePlate?: Maybe<Scalars['String']['output']>;
  locationId: Scalars['String']['output'];
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  registrationExpirationDate?: Maybe<Scalars['String']['output']>;
  stateRegistered?: Maybe<Scalars['String']['output']>;
  tollTag?: Maybe<Scalars['String']['output']>;
  tracker: AirportWithVehicleTrackerEnum;
  trackingId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vin: Scalars['String']['output'];
  year?: Maybe<Scalars['Int']['output']>;
};

export type AirportWithVehicleConnection = {
  __typename?: 'AirportWithVehicleConnection';
  edges?: Maybe<Array<Maybe<AirportWithVehicleEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AirportWithVehicleConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AirportWithVehicleMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AirportWithVehicleSearch>>;
};

export type AirportWithVehicleEdge = {
  __typename?: 'AirportWithVehicleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AirportWithVehicle>;
};

export type AirportWithVehicleMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  airportPermitExpirationDate?: InputMaybe<Scalars['Boolean']['input']>;
  capacity?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  emissionPermitExpirationDate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  licensePlate?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['Boolean']['input']>;
  make?: InputMaybe<Scalars['Boolean']['input']>;
  model?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  registrationExpirationDate?: InputMaybe<Scalars['Boolean']['input']>;
  stateRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  tollTag?: InputMaybe<Scalars['Boolean']['input']>;
  tracker?: InputMaybe<Scalars['Boolean']['input']>;
  trackingId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  vin?: InputMaybe<Scalars['Boolean']['input']>;
  year?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirportWithVehicleSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  airportCode?: InputMaybe<Array<StringSearch>>;
  airportPermitExpirationDate?: InputMaybe<Array<StringSearch>>;
  capacity?: InputMaybe<Array<IntSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  emissionPermitExpirationDate?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  licensePlate?: InputMaybe<Array<StringSearch>>;
  locationId?: InputMaybe<Array<StringSearch>>;
  make?: InputMaybe<Array<StringSearch>>;
  model?: InputMaybe<Array<StringSearch>>;
  phoneNumber?: InputMaybe<Array<StringSearch>>;
  registrationExpirationDate?: InputMaybe<Array<StringSearch>>;
  stateRegistered?: InputMaybe<Array<StringSearch>>;
  tollTag?: InputMaybe<Array<StringSearch>>;
  tracker?: InputMaybe<Array<AirportWithVehicleTrackerEnumSearch>>;
  trackingId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  vin?: InputMaybe<Array<StringSearch>>;
  year?: InputMaybe<Array<IntSearch>>;
};

export enum AirportWithVehicleTrackerEnum {
  PhoneGps = 'PHONE_GPS',
  VanTracking = 'VAN_TRACKING'
}

export type AirportWithVehicleTrackerEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<AirportWithVehicleTrackerEnum>;
};

export type Alias = {
  __typename?: 'Alias';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  locationId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type AliasConnection = {
  __typename?: 'AliasConnection';
  edges?: Maybe<Array<Maybe<AliasEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AliasConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AliasMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AliasSearch>>;
};

export type AliasEdge = {
  __typename?: 'AliasEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Alias>;
};

export type AliasInput = {
  airportCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  aliases: Array<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
};

export type AliasMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AliasSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  locationId?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type ApproveUserAvailabilityDriverAppInput = {
  approved: Scalars['Int']['input'];
  ids: Array<Scalars['String']['input']>;
};

export type ApproveUserAvailabilityDriverAppResponse = {
  __typename?: 'ApproveUserAvailabilityDriverAppResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type ApproveUserAvailabilityInput = {
  approved: Scalars['Int']['input'];
  ids: Array<Scalars['String']['input']>;
};

export type ApproveUserAvailabilityResponse = {
  __typename?: 'ApproveUserAvailabilityResponse';
  updated: Scalars['Int']['output'];
};

export type AssignDriverInput = {
  driverId: Scalars['String']['input'];
  scheduled?: InputMaybe<Scalars['String']['input']>;
  tripId: Scalars['String']['input'];
  vehicleId?: InputMaybe<Scalars['String']['input']>;
};

export type AssignDriverResponse = {
  __typename?: 'AssignDriverResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type AssignVehicleInput = {
  tripId: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type AssignVehicleResponse = {
  __typename?: 'AssignVehicleResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type AssignVehicleToDriverInput = {
  scheduled?: InputMaybe<Scalars['String']['input']>;
  tripId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type AssignVehicleToDriverResponse = {
  __typename?: 'AssignVehicleToDriverResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type AuditSnapshotWithName = {
  __typename?: 'AuditSnapshotWithName';
  companyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AuditSnapshotWithNameConnection = {
  __typename?: 'AuditSnapshotWithNameConnection';
  edges?: Maybe<Array<Maybe<AuditSnapshotWithNameEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AuditSnapshotWithNameConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AuditSnapshotWithNameMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AuditSnapshotWithNameSearch>>;
};

export type AuditSnapshotWithNameEdge = {
  __typename?: 'AuditSnapshotWithNameEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AuditSnapshotWithName>;
};

export type AuditSnapshotWithNameMaskInput = {
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AuditSnapshotWithNameSearch = {
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
};

export type Auth = {
  __typename?: 'Auth';
  allowApproveUserAvailability: Scalars['Boolean']['output'];
  allowCreateUserAvailability: Scalars['Boolean']['output'];
  allowDeleteUserAvailability: Scalars['Boolean']['output'];
  allowGetLeaderboardReport: Scalars['Boolean']['output'];
  allowGetMyPerformanceReport: Scalars['Boolean']['output'];
  allowGetPerformanceReport: Scalars['Boolean']['output'];
  allowRatingsAndReviewsReport: Scalars['Boolean']['output'];
  allowSearchUserAvailability: Scalars['Boolean']['output'];
  allowSubscribeToPushNotifications: Scalars['Boolean']['output'];
  allowTripAssignDriver: Scalars['Boolean']['output'];
  allowUpdateUserAvailability: Scalars['Boolean']['output'];
};

export type AutoCombineRule = {
  __typename?: 'AutoCombineRule';
  combineStrategy: AutoCombineRuleCombineStrategyEnum;
  considerVehicle: Scalars['Int']['output'];
  contractId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  kind: AutoCombineRuleKindEnum;
  maxCombine: Scalars['Int']['output'];
  pickupWindow: Scalars['Int']['output'];
  updateScheduled: AutoCombineRuleUpdateScheduledEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export enum AutoCombineRuleCombineStrategyEnum {
  AggregateTrips = 'AGGREGATE_TRIPS',
  LinkTrips = 'LINK_TRIPS'
}

export type AutoCombineRuleCombineStrategyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<AutoCombineRuleCombineStrategyEnum>;
};

export type AutoCombineRuleConnection = {
  __typename?: 'AutoCombineRuleConnection';
  edges?: Maybe<Array<Maybe<AutoCombineRuleEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AutoCombineRuleConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<AutoCombineRuleMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<AutoCombineRuleSearch>>;
};

export type AutoCombineRuleEdge = {
  __typename?: 'AutoCombineRuleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AutoCombineRule>;
};

export enum AutoCombineRuleKindEnum {
  Arr = 'ARR',
  Dep = 'DEP'
}

export type AutoCombineRuleKindEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<AutoCombineRuleKindEnum>;
};

export type AutoCombineRuleMaskInput = {
  combineStrategy?: InputMaybe<Scalars['Boolean']['input']>;
  considerVehicle?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['Boolean']['input']>;
  maxCombine?: InputMaybe<Scalars['Boolean']['input']>;
  pickupWindow?: InputMaybe<Scalars['Boolean']['input']>;
  updateScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AutoCombineRuleSearch = {
  combineStrategy?: InputMaybe<Array<AutoCombineRuleCombineStrategyEnumSearch>>;
  considerVehicle?: InputMaybe<Array<IntSearch>>;
  contractId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  kind?: InputMaybe<Array<AutoCombineRuleKindEnumSearch>>;
  maxCombine?: InputMaybe<Array<IntSearch>>;
  pickupWindow?: InputMaybe<Array<IntSearch>>;
  updateScheduled?: InputMaybe<Array<AutoCombineRuleUpdateScheduledEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum AutoCombineRuleUpdateScheduledEnum {
  Earliest = 'EARLIEST',
  Latest = 'LATEST',
  None = 'NONE'
}

export type AutoCombineRuleUpdateScheduledEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<AutoCombineRuleUpdateScheduledEnum>;
};

export type AvailabilityType = {
  __typename?: 'AvailabilityType';
  approved?: Maybe<Scalars['Int']['output']>;
  color: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  legendDisplayName?: Maybe<Scalars['String']['output']>;
  repeat?: Maybe<Scalars['Int']['output']>;
  type: UserAvailabilityTypeEnum;
};

export enum AvailabilityTypeEnum {
  ApprovedOff = 'APPROVED_OFF',
  ApprovedOn = 'APPROVED_ON',
  RequestOff = 'REQUEST_OFF',
  RequestOn = 'REQUEST_ON'
}

export type BooleanSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<Scalars['Boolean']['input']>;
};

export type BufferTime = {
  __typename?: 'BufferTime';
  active: Scalars['Int']['output'];
  airportCode: Scalars['String']['output'];
  bufferMinutes: Scalars['Int']['output'];
  contractId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endLocation?: Maybe<Location>;
  endLocationDisplayName: Scalars['String']['output'];
  endLocationId?: Maybe<Scalars['String']['output']>;
  endTime: Scalars['String']['output'];
  id: Scalars['String']['output'];
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['String']['output']>;
  startLocation?: Maybe<Location>;
  startLocationDisplayName: Scalars['String']['output'];
  startLocationId: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type BufferTimeConnection = {
  __typename?: 'BufferTimeConnection';
  edges?: Maybe<Array<Maybe<BufferTimeEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BufferTimeConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<BufferTimeMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<BufferTimeSearch>>;
};

export type BufferTimeEdge = {
  __typename?: 'BufferTimeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<BufferTime>;
};

export type BufferTimeInput = {
  buffer: Scalars['Int']['input'];
  contractId: Scalars['String']['input'];
  dropoffLocationId?: InputMaybe<Scalars['String']['input']>;
  endTime: Scalars['String']['input'];
  pickupLocationId: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
};

export type BufferTimeMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  bufferMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  endLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  startLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BufferTimeResponse = {
  __typename?: 'BufferTimeResponse';
  bufferTime?: Maybe<BufferTime>;
  error?: Maybe<Scalars['String']['output']>;
};

export type BufferTimeSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  airportCode?: InputMaybe<Array<StringSearch>>;
  bufferMinutes?: InputMaybe<Array<IntSearch>>;
  contractId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  endLocationId?: InputMaybe<Array<StringSearch>>;
  endTime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  startLocationId?: InputMaybe<Array<StringSearch>>;
  startTime?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type BufferTimeWithContract = {
  __typename?: 'BufferTimeWithContract';
  active: Scalars['Int']['output'];
  airportCode: Scalars['String']['output'];
  bufferMinutes: Scalars['Int']['output'];
  companyId: Scalars['String']['output'];
  contractId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endLocationId?: Maybe<Scalars['String']['output']>;
  endTime: Scalars['String']['output'];
  id: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  startLocationId: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type BufferTimeWithContractConnection = {
  __typename?: 'BufferTimeWithContractConnection';
  edges?: Maybe<Array<Maybe<BufferTimeWithContractEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BufferTimeWithContractConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<BufferTimeWithContractMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<BufferTimeWithContractSearch>>;
};

export type BufferTimeWithContractEdge = {
  __typename?: 'BufferTimeWithContractEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<BufferTimeWithContract>;
};

export type BufferTimeWithContractMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  bufferMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  endLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  startLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BufferTimeWithContractSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  airportCode?: InputMaybe<Array<StringSearch>>;
  bufferMinutes?: InputMaybe<Array<IntSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  contractId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  endLocationId?: InputMaybe<Array<StringSearch>>;
  endTime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  startLocationId?: InputMaybe<Array<StringSearch>>;
  startTime?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type CancelTripInput = {
  tripIds: Array<Scalars['String']['input']>;
};

export type CancelTripResponse = {
  __typename?: 'CancelTripResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type CancellationRule = {
  __typename?: 'CancellationRule';
  airportCode: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endLocationId: Scalars['String']['output'];
  from: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  percentage: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  startLocationId: Scalars['String']['output'];
  to: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type CancellationRuleConnection = {
  __typename?: 'CancellationRuleConnection';
  edges?: Maybe<Array<Maybe<CancellationRuleEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CancellationRuleConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<CancellationRuleMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<CancellationRuleSearch>>;
};

export type CancellationRuleEdge = {
  __typename?: 'CancellationRuleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CancellationRule>;
};

export type CancellationRuleMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  endLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  percentage?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  startLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  to?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CancellationRuleSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  endLocationId?: InputMaybe<Array<StringSearch>>;
  from?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  percentage?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  startLocationId?: InputMaybe<Array<StringSearch>>;
  to?: InputMaybe<Array<IntSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type CheckinEvent = {
  __typename?: 'CheckinEvent';
  app: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  datetime: Scalars['String']['output'];
  id: Scalars['String']['output'];
  ipAddress: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
  tripId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userAgent: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type CheckinEventConnection = {
  __typename?: 'CheckinEventConnection';
  edges?: Maybe<Array<Maybe<CheckinEventEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CheckinEventConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<CheckinEventMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<CheckinEventSearch>>;
};

export type CheckinEventEdge = {
  __typename?: 'CheckinEventEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CheckinEvent>;
};

export type CheckinEventMaskInput = {
  app?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  datetime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  ipAddress?: InputMaybe<Scalars['Boolean']['input']>;
  platform?: InputMaybe<Scalars['Boolean']['input']>;
  sessionId?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  userAgent?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CheckinEventSearch = {
  app?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  datetime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  ipAddress?: InputMaybe<Array<StringSearch>>;
  platform?: InputMaybe<Array<StringSearch>>;
  sessionId?: InputMaybe<Array<StringSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  userAgent?: InputMaybe<Array<StringSearch>>;
  userId?: InputMaybe<Array<StringSearch>>;
};

export type ClosestRateItem = {
  __typename?: 'ClosestRateItem';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currency: ClosestRateItemCurrencyEnum;
  endDatetime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  rateGroupId: Scalars['String']['output'];
  rn: Scalars['String']['output'];
  startDatetime: Scalars['String']['output'];
  type: ClosestRateItemTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ClosestRateItemConnection = {
  __typename?: 'ClosestRateItemConnection';
  edges?: Maybe<Array<Maybe<ClosestRateItemEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ClosestRateItemConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<ClosestRateItemMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<ClosestRateItemSearch>>;
};

export enum ClosestRateItemCurrencyEnum {
  Usd = 'USD'
}

export type ClosestRateItemCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ClosestRateItemCurrencyEnum>;
};

export type ClosestRateItemEdge = {
  __typename?: 'ClosestRateItemEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ClosestRateItem>;
};

export type ClosestRateItemMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['Boolean']['input']>;
  endDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  rateGroupId?: InputMaybe<Scalars['Boolean']['input']>;
  rn?: InputMaybe<Scalars['Boolean']['input']>;
  startDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClosestRateItemSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  currency?: InputMaybe<Array<ClosestRateItemCurrencyEnumSearch>>;
  endDatetime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  rateGroupId?: InputMaybe<Array<StringSearch>>;
  rn?: InputMaybe<Array<StringSearch>>;
  startDatetime?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<ClosestRateItemTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum ClosestRateItemTypeEnum {
  Contract = 'CONTRACT',
  NonContract = 'NON_CONTRACT'
}

export type ClosestRateItemTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ClosestRateItemTypeEnum>;
};

export type ColumnObject = {
  key: Scalars['String']['input'];
};

export type ColumnObjectFilter = {
  __typename?: 'ColumnObjectFilter';
  key: Scalars['String']['output'];
};

export type CombinableTripEdge = {
  __typename?: 'CombinableTripEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Trip>;
};

export type CombineRule = {
  __typename?: 'CombineRule';
  contractId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<User>;
  discount?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  pickupWindow: Scalars['Int']['output'];
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['String']['output']>;
  turnaroundThreshold: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type CombineRuleConnection = {
  __typename?: 'CombineRuleConnection';
  edges?: Maybe<Array<Maybe<CombineRuleEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CombineRuleConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<CombineRuleMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<CombineRuleSearch>>;
};

export type CombineRuleEdge = {
  __typename?: 'CombineRuleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CombineRule>;
};

export type CombineRuleMaskInput = {
  contractId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  discount?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  pickupWindow?: InputMaybe<Scalars['Boolean']['input']>;
  turnaroundThreshold?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CombineRuleSearch = {
  contractId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  discount?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  pickupWindow?: InputMaybe<Array<IntSearch>>;
  turnaroundThreshold?: InputMaybe<Array<IntSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type CombineRuleWithContract = {
  __typename?: 'CombineRuleWithContract';
  companyId: Scalars['String']['output'];
  contractId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  pickupWindow: Scalars['Int']['output'];
  providerId: Scalars['String']['output'];
  turnaroundThreshold: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type CombineRuleWithContractConnection = {
  __typename?: 'CombineRuleWithContractConnection';
  edges?: Maybe<Array<Maybe<CombineRuleWithContractEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CombineRuleWithContractConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<CombineRuleWithContractMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<CombineRuleWithContractSearch>>;
};

export type CombineRuleWithContractEdge = {
  __typename?: 'CombineRuleWithContractEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CombineRuleWithContract>;
};

export type CombineRuleWithContractMaskInput = {
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  discount?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  pickupWindow?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  turnaroundThreshold?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CombineRuleWithContractSearch = {
  companyId?: InputMaybe<Array<StringSearch>>;
  contractId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  discount?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  pickupWindow?: InputMaybe<Array<IntSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  turnaroundThreshold?: InputMaybe<Array<IntSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type CombineTripInput = {
  combineType: TripCombineTypeEnum;
  tripIds: Array<Scalars['String']['input']>;
};

export type Communication = Node & {
  __typename?: 'Communication';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<User>;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  tripId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type CommunicationConnection = {
  __typename?: 'CommunicationConnection';
  edges?: Maybe<Array<Maybe<CommunicationEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CommunicationConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<CommunicationMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<CommunicationSearch>>;
};

export type CommunicationEdge = {
  __typename?: 'CommunicationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Communication>;
};

export type CommunicationMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommunicationSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  message?: InputMaybe<Array<StringSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Scalars['String']['output']>;
  adminEmail?: Maybe<Scalars['String']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  dispatchPhone: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoiceEmail?: Maybe<Scalars['String']['output']>;
  invoiceFax?: Maybe<Scalars['String']['output']>;
  invoicePhone?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  settingsId: Scalars['String']['output'];
  stateCode?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges?: Maybe<Array<Maybe<CompanyEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CompanyConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<CompanyMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<CompanySearch>>;
};

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Company>;
};

export type CompanyMaskInput = {
  address?: InputMaybe<Scalars['Boolean']['input']>;
  adminEmail?: InputMaybe<Scalars['Boolean']['input']>;
  cityName?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  dispatchPhone?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceEmail?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceFax?: InputMaybe<Scalars['Boolean']['input']>;
  invoicePhone?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  settingsId?: InputMaybe<Scalars['Boolean']['input']>;
  stateCode?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  website?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanySearch = {
  address?: InputMaybe<Array<StringSearch>>;
  adminEmail?: InputMaybe<Array<StringSearch>>;
  cityName?: InputMaybe<Array<StringSearch>>;
  countryCode?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  dispatchPhone?: InputMaybe<Array<StringSearch>>;
  displayName?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  invoiceEmail?: InputMaybe<Array<StringSearch>>;
  invoiceFax?: InputMaybe<Array<StringSearch>>;
  invoicePhone?: InputMaybe<Array<StringSearch>>;
  logo?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  settingsId?: InputMaybe<Array<StringSearch>>;
  stateCode?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  website?: InputMaybe<Array<StringSearch>>;
  zipCode?: InputMaybe<Array<StringSearch>>;
};

export type Completion = {
  __typename?: 'Completion';
  comment: Scalars['String']['output'];
  completionTypeId: Scalars['String']['output'];
  cost: Scalars['Float']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currency: CompletionCurrencyEnum;
  datetime: Scalars['String']['output'];
  distanceMeters: Scalars['Int']['output'];
  efficiency: Scalars['Float']['output'];
  equivalency: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type CompletionConnection = {
  __typename?: 'CompletionConnection';
  edges?: Maybe<Array<Maybe<CompletionEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CompletionConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<CompletionMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<CompletionSearch>>;
};

export enum CompletionCurrencyEnum {
  Usd = 'USD'
}

export type CompletionCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<CompletionCurrencyEnum>;
};

export type CompletionEdge = {
  __typename?: 'CompletionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Completion>;
};

export type CompletionMaskInput = {
  comment?: InputMaybe<Scalars['Boolean']['input']>;
  completionTypeId?: InputMaybe<Scalars['Boolean']['input']>;
  cost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['Boolean']['input']>;
  datetime?: InputMaybe<Scalars['Boolean']['input']>;
  distanceMeters?: InputMaybe<Scalars['Boolean']['input']>;
  efficiency?: InputMaybe<Scalars['Boolean']['input']>;
  equivalency?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompletionSearch = {
  comment?: InputMaybe<Array<StringSearch>>;
  completionTypeId?: InputMaybe<Array<StringSearch>>;
  cost?: InputMaybe<Array<FloatSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  currency?: InputMaybe<Array<CompletionCurrencyEnumSearch>>;
  datetime?: InputMaybe<Array<StringSearch>>;
  distanceMeters?: InputMaybe<Array<IntSearch>>;
  efficiency?: InputMaybe<Array<FloatSearch>>;
  equivalency?: InputMaybe<Array<FloatSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum CompletionStatusEnum {
  Isnotnull = 'ISNOTNULL',
  Isnull = 'ISNULL',
  Or = 'OR'
}

export type CompletionType = {
  __typename?: 'CompletionType';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type CompletionTypeConnection = {
  __typename?: 'CompletionTypeConnection';
  edges?: Maybe<Array<Maybe<CompletionTypeEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CompletionTypeConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<CompletionTypeMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<CompletionTypeSearch>>;
};

export type CompletionTypeEdge = {
  __typename?: 'CompletionTypeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CompletionType>;
};

export type CompletionTypeMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompletionTypeSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type CompletionWithType = {
  __typename?: 'CompletionWithType';
  comment: Scalars['String']['output'];
  completionTypeId: Scalars['String']['output'];
  cost: Scalars['Float']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currency: CompletionWithTypeCurrencyEnum;
  datetime: Scalars['String']['output'];
  distanceMeters: Scalars['Int']['output'];
  efficiency: Scalars['Float']['output'];
  equivalency: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type CompletionWithTypeConnection = {
  __typename?: 'CompletionWithTypeConnection';
  edges?: Maybe<Array<Maybe<CompletionWithTypeEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CompletionWithTypeConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<CompletionWithTypeMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<CompletionWithTypeSearch>>;
};

export enum CompletionWithTypeCurrencyEnum {
  Usd = 'USD'
}

export type CompletionWithTypeCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<CompletionWithTypeCurrencyEnum>;
};

export type CompletionWithTypeEdge = {
  __typename?: 'CompletionWithTypeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CompletionWithType>;
};

export type CompletionWithTypeMaskInput = {
  comment?: InputMaybe<Scalars['Boolean']['input']>;
  completionTypeId?: InputMaybe<Scalars['Boolean']['input']>;
  cost?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['Boolean']['input']>;
  datetime?: InputMaybe<Scalars['Boolean']['input']>;
  distanceMeters?: InputMaybe<Scalars['Boolean']['input']>;
  efficiency?: InputMaybe<Scalars['Boolean']['input']>;
  equivalency?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompletionWithTypeSearch = {
  comment?: InputMaybe<Array<StringSearch>>;
  completionTypeId?: InputMaybe<Array<StringSearch>>;
  cost?: InputMaybe<Array<FloatSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  currency?: InputMaybe<Array<CompletionWithTypeCurrencyEnumSearch>>;
  datetime?: InputMaybe<Array<StringSearch>>;
  distanceMeters?: InputMaybe<Array<IntSearch>>;
  efficiency?: InputMaybe<Array<FloatSearch>>;
  equivalency?: InputMaybe<Array<FloatSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type Contract = {
  __typename?: 'Contract';
  active: Scalars['Int']['output'];
  allowCombines: Scalars['Int']['output'];
  allowCrewCreateTrip: Scalars['Int']['output'];
  automateAddons: Scalars['Int']['output'];
  companyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  enableBufferTime: Scalars['Int']['output'];
  enableSocialDistancing: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  invoiceTermId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ContractConnection = {
  __typename?: 'ContractConnection';
  edges?: Maybe<Array<Maybe<ContractEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ContractConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<ContractMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<ContractSearch>>;
};

export type ContractEdge = {
  __typename?: 'ContractEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Contract>;
};

export type ContractEmail = {
  __typename?: 'ContractEmail';
  contractId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  type: ContractEmailTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ContractEmailConnection = {
  __typename?: 'ContractEmailConnection';
  edges?: Maybe<Array<Maybe<ContractEmailEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ContractEmailConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<ContractEmailMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<ContractEmailSearch>>;
};

export type ContractEmailEdge = {
  __typename?: 'ContractEmailEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ContractEmail>;
};

export type ContractEmailMaskInput = {
  contractId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContractEmailSearch = {
  contractId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  email?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<ContractEmailTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum ContractEmailTypeEnum {
  Other = 'OTHER'
}

export type ContractEmailTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ContractEmailTypeEnum>;
};

export type ContractMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowCombines?: InputMaybe<Scalars['Boolean']['input']>;
  allowCrewCreateTrip?: InputMaybe<Scalars['Boolean']['input']>;
  automateAddons?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  enableBufferTime?: InputMaybe<Scalars['Boolean']['input']>;
  enableSocialDistancing?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceTermId?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContractNumber = {
  __typename?: 'ContractNumber';
  contractId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  type: ContractNumberTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ContractNumberConnection = {
  __typename?: 'ContractNumberConnection';
  edges?: Maybe<Array<Maybe<ContractNumberEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ContractNumberConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<ContractNumberMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<ContractNumberSearch>>;
};

export type ContractNumberEdge = {
  __typename?: 'ContractNumberEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ContractNumber>;
};

export type ContractNumberMaskInput = {
  contractId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContractNumberSearch = {
  contractId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  email?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<ContractNumberTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum ContractNumberTypeEnum {
  Other = 'OTHER'
}

export type ContractNumberTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ContractNumberTypeEnum>;
};

export type ContractSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  allowCombines?: InputMaybe<Array<IntSearch>>;
  allowCrewCreateTrip?: InputMaybe<Array<IntSearch>>;
  automateAddons?: InputMaybe<Array<IntSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  enableBufferTime?: InputMaybe<Array<IntSearch>>;
  enableSocialDistancing?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  invoiceTermId?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type CountryRegion = {
  __typename?: 'CountryRegion';
  active: Scalars['Int']['output'];
  country: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  stateAbbrev?: Maybe<Scalars['String']['output']>;
  stateProvince: Scalars['String']['output'];
};

export type CountryRegionConnection = {
  __typename?: 'CountryRegionConnection';
  edges?: Maybe<Array<Maybe<CountryRegionEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CountryRegionConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<CountryRegionMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<CountryRegionSearch>>;
};

export type CountryRegionEdge = {
  __typename?: 'CountryRegionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CountryRegion>;
};

export type CountryRegionMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  stateAbbrev?: InputMaybe<Scalars['Boolean']['input']>;
  stateProvince?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CountryRegionSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  country?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  stateAbbrev?: InputMaybe<Array<StringSearch>>;
  stateProvince?: InputMaybe<Array<StringSearch>>;
};

export type CreateActivityBulkInput = {
  values: Array<CreateActivityInput>;
};

export type CreateActivityBulkResponse = {
  __typename?: 'CreateActivityBulkResponse';
  output: Array<CreateActivityResponse>;
};

export type CreateActivityInput = {
  field?: InputMaybe<Scalars['String']['input']>;
  formerObjectId?: InputMaybe<Scalars['String']['input']>;
  formerValue?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  objectId?: InputMaybe<Scalars['String']['input']>;
  subjectId: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
  type: ActivityTypeEnum;
  value?: InputMaybe<Scalars['String']['input']>;
  via?: InputMaybe<Scalars['String']['input']>;
};

export type CreateActivityObjectBulkInput = {
  values: Array<CreateActivityObjectInput>;
};

export type CreateActivityObjectBulkResponse = {
  __typename?: 'CreateActivityObjectBulkResponse';
  output: Array<CreateActivityObjectResponse>;
};

export type CreateActivityObjectInput = {
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type CreateActivityObjectResponse = {
  __typename?: 'CreateActivityObjectResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: ActivityObject;
};

export type CreateActivityResponse = {
  __typename?: 'CreateActivityResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Activity;
};

export type CreateAirportBulkInput = {
  values: Array<CreateAirportInput>;
};

export type CreateAirportBulkResponse = {
  __typename?: 'CreateAirportBulkResponse';
  output: Array<CreateAirportResponse>;
};

export type CreateAirportGroupBulkInput = {
  values: Array<CreateAirportGroupInput>;
};

export type CreateAirportGroupBulkResponse = {
  __typename?: 'CreateAirportGroupBulkResponse';
  output: Array<CreateAirportGroupResponse>;
};

export type CreateAirportGroupInput = {
  companyId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateAirportGroupResponse = {
  __typename?: 'CreateAirportGroupResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: AirportGroup;
};

export type CreateAirportHasCompanyBulkInput = {
  values: Array<CreateAirportHasCompanyInput>;
};

export type CreateAirportHasCompanyBulkResponse = {
  __typename?: 'CreateAirportHasCompanyBulkResponse';
  output: Array<CreateAirportHasCompanyResponse>;
};

export type CreateAirportHasCompanyInput = {
  airportCode: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
};

export type CreateAirportHasCompanyResponse = {
  __typename?: 'CreateAirportHasCompanyResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: AirportHasCompany;
};

export type CreateAirportHasGroupBulkInput = {
  values: Array<CreateAirportHasGroupInput>;
};

export type CreateAirportHasGroupBulkResponse = {
  __typename?: 'CreateAirportHasGroupBulkResponse';
  output: Array<CreateAirportHasGroupResponse>;
};

export type CreateAirportHasGroupInput = {
  airportCode: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
};

export type CreateAirportHasGroupResponse = {
  __typename?: 'CreateAirportHasGroupResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: AirportHasGroup;
};

export type CreateAirportHasLocationBulkInput = {
  values: Array<CreateAirportHasLocationInput>;
};

export type CreateAirportHasLocationBulkResponse = {
  __typename?: 'CreateAirportHasLocationBulkResponse';
  output: Array<CreateAirportHasLocationResponse>;
};

export type CreateAirportHasLocationInput = {
  airportCode: Scalars['String']['input'];
  locationId: Scalars['String']['input'];
};

export type CreateAirportHasLocationResponse = {
  __typename?: 'CreateAirportHasLocationResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: AirportHasLocation;
};

export type CreateAirportHasRateBulkInput = {
  values: Array<CreateAirportHasRateInput>;
};

export type CreateAirportHasRateBulkResponse = {
  __typename?: 'CreateAirportHasRateBulkResponse';
  output: Array<CreateAirportHasRateResponse>;
};

export type CreateAirportHasRateInput = {
  airportCode: Scalars['String']['input'];
  rate: Scalars['Float']['input'];
};

export type CreateAirportHasRateResponse = {
  __typename?: 'CreateAirportHasRateResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: AirportHasRate;
};

export type CreateAirportHasVehicleBulkInput = {
  values: Array<CreateAirportHasVehicleInput>;
};

export type CreateAirportHasVehicleBulkResponse = {
  __typename?: 'CreateAirportHasVehicleBulkResponse';
  output: Array<CreateAirportHasVehicleResponse>;
};

export type CreateAirportHasVehicleInput = {
  airportCode: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type CreateAirportHasVehicleResponse = {
  __typename?: 'CreateAirportHasVehicleResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: AirportHasVehicle;
};

export type CreateAirportInput = {
  cityCode: Scalars['String']['input'];
  cityName: Scalars['String']['input'];
  code: Scalars['String']['input'];
  country: Scalars['String']['input'];
  countryId: Scalars['String']['input'];
  elevation: Scalars['Int']['input'];
  icao: Scalars['String']['input'];
  location: PointInput;
  name: Scalars['String']['input'];
  stateCode: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type CreateAirportResponse = {
  __typename?: 'CreateAirportResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Airport;
};

export type CreateAliasBulkInput = {
  values: Array<CreateAliasInput>;
};

export type CreateAliasBulkResponse = {
  __typename?: 'CreateAliasBulkResponse';
  output: Array<CreateAliasResponse>;
};

export type CreateAliasInput = {
  locationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateAliasResponse = {
  __typename?: 'CreateAliasResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Alias;
};

export type CreateAutoCombineRuleBulkInput = {
  values: Array<CreateAutoCombineRuleInput>;
};

export type CreateAutoCombineRuleBulkResponse = {
  __typename?: 'CreateAutoCombineRuleBulkResponse';
  output: Array<CreateAutoCombineRuleResponse>;
};

export type CreateAutoCombineRuleInput = {
  combineStrategy: AutoCombineRuleCombineStrategyEnum;
  considerVehicle: Scalars['Int']['input'];
  contractId: Scalars['String']['input'];
  kind: AutoCombineRuleKindEnum;
  maxCombine: Scalars['Int']['input'];
  pickupWindow: Scalars['Int']['input'];
  updateScheduled: AutoCombineRuleUpdateScheduledEnum;
};

export type CreateAutoCombineRuleResponse = {
  __typename?: 'CreateAutoCombineRuleResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: AutoCombineRule;
};

export type CreateBufferTimeBulkInput = {
  values: Array<CreateBufferTimeInput>;
};

export type CreateBufferTimeBulkResponse = {
  __typename?: 'CreateBufferTimeBulkResponse';
  output: Array<CreateBufferTimeResponse>;
};

export type CreateBufferTimeInput = {
  active: Scalars['Int']['input'];
  airportCode: Scalars['String']['input'];
  bufferMinutes: Scalars['Int']['input'];
  contractId: Scalars['String']['input'];
  endLocationId?: InputMaybe<Scalars['String']['input']>;
  endTime: Scalars['String']['input'];
  startLocationId: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
};

export type CreateBufferTimeResponse = {
  __typename?: 'CreateBufferTimeResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: BufferTime;
};

export type CreateBufferTimesInput = {
  bufferTimes: Array<BufferTimeInput>;
};

export type CreateBufferTimesResponse = {
  __typename?: 'CreateBufferTimesResponse';
  bufferTimes?: Maybe<Array<Maybe<BufferTimeResponse>>>;
};

export type CreateCancellationRuleBulkInput = {
  values: Array<CreateCancellationRuleInput>;
};

export type CreateCancellationRuleBulkResponse = {
  __typename?: 'CreateCancellationRuleBulkResponse';
  output: Array<CreateCancellationRuleResponse>;
};

export type CreateCancellationRuleInput = {
  airportCode: Scalars['String']['input'];
  endLocationId: Scalars['String']['input'];
  from: Scalars['Int']['input'];
  percentage: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
  startLocationId: Scalars['String']['input'];
  to: Scalars['Int']['input'];
};

export type CreateCancellationRuleResponse = {
  __typename?: 'CreateCancellationRuleResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: CancellationRule;
};

export type CreateCheckinEventBulkInput = {
  values: Array<CreateCheckinEventInput>;
};

export type CreateCheckinEventBulkResponse = {
  __typename?: 'CreateCheckinEventBulkResponse';
  output: Array<CreateCheckinEventResponse>;
};

export type CreateCheckinEventInput = {
  app: Scalars['String']['input'];
  datetime: Scalars['String']['input'];
  ipAddress: Scalars['String']['input'];
  platform: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
  userAgent: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreateCheckinEventResponse = {
  __typename?: 'CreateCheckinEventResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: CheckinEvent;
};

export type CreateCombineRuleBulkInput = {
  values: Array<CreateCombineRuleInput>;
};

export type CreateCombineRuleBulkResponse = {
  __typename?: 'CreateCombineRuleBulkResponse';
  output: Array<CreateCombineRuleResponse>;
};

export type CreateCombineRuleInput = {
  contractId: Scalars['String']['input'];
  discount?: InputMaybe<Scalars['String']['input']>;
  pickupWindow: Scalars['Int']['input'];
  turnaroundThreshold: Scalars['Int']['input'];
};

export type CreateCombineRuleResponse = {
  __typename?: 'CreateCombineRuleResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: CombineRule;
};

export type CreateCombineTripInput = {
  tripIds: Array<Scalars['String']['input']>;
  values?: InputMaybe<UpdateTripValuesInput>;
};

export type CreateCombineTripResponse = {
  __typename?: 'CreateCombineTripResponse';
  error?: Maybe<Scalars['String']['output']>;
  updated: Scalars['Boolean']['output'];
};

export type CreateCommunicationBulkInput = {
  values: Array<CreateCommunicationInput>;
};

export type CreateCommunicationBulkResponse = {
  __typename?: 'CreateCommunicationBulkResponse';
  output: Array<CreateCommunicationResponse>;
};

export type CreateCommunicationInput = {
  message: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
};

export type CreateCommunicationResponse = {
  __typename?: 'CreateCommunicationResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Communication;
};

export type CreateCompanyBulkInput = {
  values: Array<CreateCompanyInput>;
};

export type CreateCompanyBulkResponse = {
  __typename?: 'CreateCompanyBulkResponse';
  output: Array<CreateCompanyResponse>;
};

export type CreateCompanyInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  adminEmail?: InputMaybe<Scalars['String']['input']>;
  cityName?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  dispatchPhone: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  invoiceEmail?: InputMaybe<Scalars['String']['input']>;
  invoiceFax?: InputMaybe<Scalars['String']['input']>;
  invoicePhone?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  settingsId: Scalars['String']['input'];
  stateCode?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyResponse = {
  __typename?: 'CreateCompanyResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Company;
};

export type CreateCompletionBulkInput = {
  values: Array<CreateCompletionInput>;
};

export type CreateCompletionBulkResponse = {
  __typename?: 'CreateCompletionBulkResponse';
  output: Array<CreateCompletionResponse>;
};

export type CreateCompletionInput = {
  comment: Scalars['String']['input'];
  completionTypeId: Scalars['String']['input'];
  cost: Scalars['Float']['input'];
  currency: CompletionCurrencyEnum;
  datetime: Scalars['String']['input'];
  distanceMeters: Scalars['Int']['input'];
  efficiency: Scalars['Float']['input'];
  equivalency: Scalars['Float']['input'];
};

export type CreateCompletionResponse = {
  __typename?: 'CreateCompletionResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Completion;
};

export type CreateCompletionTypeBulkInput = {
  values: Array<CreateCompletionTypeInput>;
};

export type CreateCompletionTypeBulkResponse = {
  __typename?: 'CreateCompletionTypeBulkResponse';
  output: Array<CreateCompletionTypeResponse>;
};

export type CreateCompletionTypeInput = {
  name: Scalars['String']['input'];
};

export type CreateCompletionTypeResponse = {
  __typename?: 'CreateCompletionTypeResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: CompletionType;
};

export type CreateContractBulkInput = {
  values: Array<CreateContractInput>;
};

export type CreateContractBulkResponse = {
  __typename?: 'CreateContractBulkResponse';
  output: Array<CreateContractResponse>;
};

export type CreateContractEmailBulkInput = {
  values: Array<CreateContractEmailInput>;
};

export type CreateContractEmailBulkResponse = {
  __typename?: 'CreateContractEmailBulkResponse';
  output: Array<CreateContractEmailResponse>;
};

export type CreateContractEmailInput = {
  contractId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  type: ContractEmailTypeEnum;
};

export type CreateContractEmailResponse = {
  __typename?: 'CreateContractEmailResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: ContractEmail;
};

export type CreateContractInput = {
  active: Scalars['Int']['input'];
  allowCombines: Scalars['Int']['input'];
  allowCrewCreateTrip: Scalars['Int']['input'];
  automateAddons: Scalars['Int']['input'];
  companyId: Scalars['String']['input'];
  enableBufferTime: Scalars['Int']['input'];
  enableSocialDistancing: Scalars['Int']['input'];
  invoiceTermId: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type CreateContractNumberBulkInput = {
  values: Array<CreateContractNumberInput>;
};

export type CreateContractNumberBulkResponse = {
  __typename?: 'CreateContractNumberBulkResponse';
  output: Array<CreateContractNumberResponse>;
};

export type CreateContractNumberInput = {
  contractId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  type: ContractNumberTypeEnum;
};

export type CreateContractNumberResponse = {
  __typename?: 'CreateContractNumberResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: ContractNumber;
};

export type CreateContractResponse = {
  __typename?: 'CreateContractResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Contract;
};

export type CreateCountryRegionBulkInput = {
  values: Array<CreateCountryRegionInput>;
};

export type CreateCountryRegionBulkResponse = {
  __typename?: 'CreateCountryRegionBulkResponse';
  output: Array<CreateCountryRegionResponse>;
};

export type CreateCountryRegionInput = {
  active: Scalars['Int']['input'];
  country: Scalars['String']['input'];
  stateAbbrev?: InputMaybe<Scalars['String']['input']>;
  stateProvince: Scalars['String']['input'];
};

export type CreateCountryRegionResponse = {
  __typename?: 'CreateCountryRegionResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: CountryRegion;
};

export type CreateCrewTripInput = {
  input: CreateTripInput;
};

export type CreateCrewTripResponse = {
  __typename?: 'CreateCrewTripResponse';
  response: TripResponse;
};

export type CreateDeviceBulkInput = {
  values: Array<CreateDeviceInput>;
};

export type CreateDeviceBulkResponse = {
  __typename?: 'CreateDeviceBulkResponse';
  output: Array<CreateDeviceResponse>;
};

export type CreateDeviceInput = {
  active: Scalars['Int']['input'];
  appName?: InputMaybe<Scalars['String']['input']>;
  lastSeenAt?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreateDeviceResponse = {
  __typename?: 'CreateDeviceResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Device;
};

export type CreateDistanceBulkInput = {
  values: Array<CreateDistanceInput>;
};

export type CreateDistanceBulkResponse = {
  __typename?: 'CreateDistanceBulkResponse';
  output: Array<CreateDistanceResponse>;
};

export type CreateDistanceInput = {
  cachedAt: Scalars['String']['input'];
  distanceText: Scalars['String']['input'];
  distanceValue: Scalars['Int']['input'];
  durationInTrafficText: Scalars['String']['input'];
  durationInTrafficValue: Scalars['Int']['input'];
  durationText: Scalars['String']['input'];
  durationValue: Scalars['Int']['input'];
  fromLocationId: Scalars['String']['input'];
  toLocationId: Scalars['String']['input'];
};

export type CreateDistanceResponse = {
  __typename?: 'CreateDistanceResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Distance;
};

export type CreateDownloadImportUrLsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type CreateDownloadImportUrLsResponse = {
  __typename?: 'CreateDownloadImportURLsResponse';
  imports: Array<DownloadImportResponse>;
};

export type CreateEmailBulkInput = {
  values: Array<CreateEmailInput>;
};

export type CreateEmailBulkResponse = {
  __typename?: 'CreateEmailBulkResponse';
  output: Array<CreateEmailResponse>;
};

export type CreateEmailInput = {
  address: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
  type: EmailTypeEnum;
};

export type CreateEmailResponse = {
  __typename?: 'CreateEmailResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Email;
};

export type CreateEquivalentTripBulkInput = {
  values: Array<CreateEquivalentTripInput>;
};

export type CreateEquivalentTripBulkResponse = {
  __typename?: 'CreateEquivalentTripBulkResponse';
  output: Array<CreateEquivalentTripResponse>;
};

export type CreateEquivalentTripInput = {
  companyId: Scalars['String']['input'];
  count?: InputMaybe<Scalars['Int']['input']>;
  endLocationId?: InputMaybe<Scalars['String']['input']>;
  startLocationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEquivalentTripResponse = {
  __typename?: 'CreateEquivalentTripResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: EquivalentTrip;
};

export type CreateFcrBulkInput = {
  values: Array<CreateFcrInput>;
};

export type CreateFcrBulkResponse = {
  __typename?: 'CreateFcrBulkResponse';
  output: Array<CreateFcrResponse>;
};

export type CreateFcrInput = {
  cause?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fcrTypeId: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
};

export type CreateFcrResponse = {
  __typename?: 'CreateFcrResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Fcr;
};

export type CreateFcrTypeBulkInput = {
  values: Array<CreateFcrTypeInput>;
};

export type CreateFcrTypeBulkResponse = {
  __typename?: 'CreateFcrTypeBulkResponse';
  output: Array<CreateFcrTypeResponse>;
};

export type CreateFcrTypeInput = {
  name: Scalars['String']['input'];
};

export type CreateFcrTypeResponse = {
  __typename?: 'CreateFcrTypeResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: FcrType;
};

export type CreateFlagBulkInput = {
  values: Array<CreateFlagInput>;
};

export type CreateFlagBulkResponse = {
  __typename?: 'CreateFlagBulkResponse';
  output: Array<CreateFlagResponse>;
};

export type CreateFlagHasTripBulkInput = {
  values: Array<CreateFlagHasTripInput>;
};

export type CreateFlagHasTripBulkResponse = {
  __typename?: 'CreateFlagHasTripBulkResponse';
  output: Array<CreateFlagHasTripResponse>;
};

export type CreateFlagHasTripInput = {
  flagId: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
};

export type CreateFlagHasTripResponse = {
  __typename?: 'CreateFlagHasTripResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: FlagHasTrip;
};

export type CreateFlagInput = {
  active: Scalars['Int']['input'];
  color: Scalars['String']['input'];
  companyId?: InputMaybe<Scalars['String']['input']>;
  global: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type CreateFlagResponse = {
  __typename?: 'CreateFlagResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Flag;
};

export type CreateGroupBulkInput = {
  values: Array<CreateGroupInput>;
};

export type CreateGroupBulkResponse = {
  __typename?: 'CreateGroupBulkResponse';
  output: Array<CreateGroupResponse>;
};

export type CreateGroupHasPermissionBulkInput = {
  values: Array<CreateGroupHasPermissionInput>;
};

export type CreateGroupHasPermissionBulkResponse = {
  __typename?: 'CreateGroupHasPermissionBulkResponse';
  output: Array<CreateGroupHasPermissionResponse>;
};

export type CreateGroupHasPermissionInput = {
  groupId: Scalars['String']['input'];
  permissionId: Scalars['String']['input'];
};

export type CreateGroupHasPermissionResponse = {
  __typename?: 'CreateGroupHasPermissionResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: GroupHasPermission;
};

export type CreateGroupHasUserBulkInput = {
  values: Array<CreateGroupHasUserInput>;
};

export type CreateGroupHasUserBulkResponse = {
  __typename?: 'CreateGroupHasUserBulkResponse';
  output: Array<CreateGroupHasUserResponse>;
};

export type CreateGroupHasUserInput = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreateGroupHasUserResponse = {
  __typename?: 'CreateGroupHasUserResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: GroupHasUser;
};

export type CreateGroupInput = {
  active: Scalars['Int']['input'];
  companyId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: GroupTypeEnum;
};

export type CreateGroupResponse = {
  __typename?: 'CreateGroupResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Group;
};

export type CreateImageBulkInput = {
  generateUploadUrl?: InputMaybe<Scalars['Int']['input']>;
  values: Array<CreateImageInput>;
};

export type CreateImageBulkResponse = {
  __typename?: 'CreateImageBulkResponse';
  output: Array<CreateImageResponse>;
};

export type CreateImageInput = {
  filename: Scalars['String']['input'];
  md5: Scalars['String']['input'];
  path: Scalars['String']['input'];
  pickupPointId: Scalars['String']['input'];
};

export type CreateImageResponse = {
  __typename?: 'CreateImageResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Image;
};

export type CreateImportBulkInput = {
  values: Array<CreateImportInput>;
};

export type CreateImportBulkResponse = {
  __typename?: 'CreateImportBulkResponse';
  output: Array<CreateImportResponse>;
};

export type CreateImportInput = {
  filename: Scalars['String']['input'];
  md5: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type CreateImportResponse = {
  __typename?: 'CreateImportResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Import;
};

export type CreateImportsInput = {
  imports: Array<CreateImportInput>;
};

export type CreateImportsResponse = {
  __typename?: 'CreateImportsResponse';
  imports?: Maybe<Array<ImportResponse>>;
};

export type CreateInvoiceAirportBulkInput = {
  values: Array<CreateInvoiceAirportInput>;
};

export type CreateInvoiceAirportBulkResponse = {
  __typename?: 'CreateInvoiceAirportBulkResponse';
  output: Array<CreateInvoiceAirportResponse>;
};

export type CreateInvoiceAirportInput = {
  code: Scalars['String']['input'];
  invoiceId: Scalars['Int']['input'];
};

export type CreateInvoiceAirportResponse = {
  __typename?: 'CreateInvoiceAirportResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: InvoiceAirport;
};

export type CreateInvoiceBulkInput = {
  values: Array<CreateInvoiceInput>;
};

export type CreateInvoiceBulkResponse = {
  __typename?: 'CreateInvoiceBulkResponse';
  output: Array<CreateInvoiceResponse>;
};

export type CreateInvoiceDocumentBulkInput = {
  values: Array<CreateInvoiceDocumentInput>;
};

export type CreateInvoiceDocumentBulkResponse = {
  __typename?: 'CreateInvoiceDocumentBulkResponse';
  output: Array<CreateInvoiceDocumentResponse>;
};

export type CreateInvoiceDocumentInput = {
  fileLocation: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  index: Scalars['Int']['input'];
  invoiceId: Scalars['Int']['input'];
  md5: Scalars['String']['input'];
  sentAt?: InputMaybe<Scalars['String']['input']>;
  sentBy?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInvoiceDocumentResponse = {
  __typename?: 'CreateInvoiceDocumentResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: InvoiceDocument;
};

export type CreateInvoiceEmailBulkInput = {
  values: Array<CreateInvoiceEmailInput>;
};

export type CreateInvoiceEmailBulkResponse = {
  __typename?: 'CreateInvoiceEmailBulkResponse';
  output: Array<CreateInvoiceEmailResponse>;
};

export type CreateInvoiceEmailInput = {
  address: Scalars['String']['input'];
  invoiceId: Scalars['Int']['input'];
};

export type CreateInvoiceEmailResponse = {
  __typename?: 'CreateInvoiceEmailResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: InvoiceEmail;
};

export type CreateInvoiceHeaderBulkInput = {
  values: Array<CreateInvoiceHeaderInput>;
};

export type CreateInvoiceHeaderBulkResponse = {
  __typename?: 'CreateInvoiceHeaderBulkResponse';
  output: Array<CreateInvoiceHeaderResponse>;
};

export type CreateInvoiceHeaderInput = {
  invoiceId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type CreateInvoiceHeaderResponse = {
  __typename?: 'CreateInvoiceHeaderResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: InvoiceHeader;
};

export type CreateInvoiceIataCodeBulkInput = {
  values: Array<CreateInvoiceIataCodeInput>;
};

export type CreateInvoiceIataCodeBulkResponse = {
  __typename?: 'CreateInvoiceIataCodeBulkResponse';
  output: Array<CreateInvoiceIataCodeResponse>;
};

export type CreateInvoiceIataCodeInput = {
  iataCode: Scalars['String']['input'];
  invoiceId: Scalars['Int']['input'];
};

export type CreateInvoiceIataCodeResponse = {
  __typename?: 'CreateInvoiceIataCodeResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: InvoiceIataCode;
};

export type CreateInvoiceInput = {
  companyId: Scalars['String']['input'];
  due: Scalars['String']['input'];
  endDatetime: Scalars['String']['input'];
  format: InvoiceFormatEnum;
  grandTotal: Scalars['Float']['input'];
  invoiced: Scalars['String']['input'];
  name: Scalars['String']['input'];
  payerProviderId: Scalars['String']['input'];
  startDatetime: Scalars['String']['input'];
  status: InvoiceStatusEnum;
  subTotal?: InputMaybe<Scalars['Float']['input']>;
  total: Scalars['Float']['input'];
};

export type CreateInvoiceLineItemBulkInput = {
  values: Array<CreateInvoiceLineItemInput>;
};

export type CreateInvoiceLineItemBulkResponse = {
  __typename?: 'CreateInvoiceLineItemBulkResponse';
  output: Array<CreateInvoiceLineItemResponse>;
};

export type CreateInvoiceLineItemInput = {
  invoiceId: Scalars['Int']['input'];
  lineItemTypeId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  total?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateInvoiceLineItemResponse = {
  __typename?: 'CreateInvoiceLineItemResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: InvoiceLineItem;
};

export type CreateInvoiceResponse = {
  __typename?: 'CreateInvoiceResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Invoice;
};

export type CreateInvoiceTermBulkInput = {
  values: Array<CreateInvoiceTermInput>;
};

export type CreateInvoiceTermBulkResponse = {
  __typename?: 'CreateInvoiceTermBulkResponse';
  output: Array<CreateInvoiceTermResponse>;
};

export type CreateInvoiceTermInput = {
  type: Scalars['String']['input'];
};

export type CreateInvoiceTermResponse = {
  __typename?: 'CreateInvoiceTermResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: InvoiceTerm;
};

export type CreateInvoiceTripBulkInput = {
  values: Array<CreateInvoiceTripInput>;
};

export type CreateInvoiceTripBulkResponse = {
  __typename?: 'CreateInvoiceTripBulkResponse';
  output: Array<CreateInvoiceTripResponse>;
};

export type CreateInvoiceTripInput = {
  airportCode: Scalars['String']['input'];
  attendants: Scalars['Int']['input'];
  combineId?: InputMaybe<Scalars['String']['input']>;
  crewId?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<InvoiceTripCurrencyEnum>;
  discount: Scalars['Float']['input'];
  doLocation: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  flightNumber: Scalars['Int']['input'];
  index: Scalars['Int']['input'];
  invoiceId: Scalars['Int']['input'];
  kind: InvoiceTripKindEnum;
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pilots: Scalars['Int']['input'];
  puLocation: Scalars['String']['input'];
  rate: Scalars['Float']['input'];
  rateId: Scalars['String']['input'];
  scheduled: Scalars['String']['input'];
  servicerIataAirlineCode: Scalars['String']['input'];
  status: InvoiceTripStatusEnum;
  tripId: Scalars['String']['input'];
  type: InvoiceTripTypeEnum;
};

export type CreateInvoiceTripResponse = {
  __typename?: 'CreateInvoiceTripResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: InvoiceTrip;
};

export type CreateInvoiceTripStatusBulkInput = {
  values: Array<CreateInvoiceTripStatusInput>;
};

export type CreateInvoiceTripStatusBulkResponse = {
  __typename?: 'CreateInvoiceTripStatusBulkResponse';
  output: Array<CreateInvoiceTripStatusResponse>;
};

export type CreateInvoiceTripStatusInput = {
  invoiceId: Scalars['Int']['input'];
  status: InvoiceTripStatusStatusEnum;
};

export type CreateInvoiceTripStatusResponse = {
  __typename?: 'CreateInvoiceTripStatusResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: InvoiceTripStatus;
};

export type CreateInvoiceTypeBulkInput = {
  values: Array<CreateInvoiceTypeInput>;
};

export type CreateInvoiceTypeBulkResponse = {
  __typename?: 'CreateInvoiceTypeBulkResponse';
  output: Array<CreateInvoiceTypeResponse>;
};

export type CreateInvoiceTypeInput = {
  invoiceId: Scalars['Int']['input'];
  type: InvoiceTypeTypeEnum;
};

export type CreateInvoiceTypeResponse = {
  __typename?: 'CreateInvoiceTypeResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: InvoiceType;
};

export type CreateIssueBulkInput = {
  values: Array<CreateIssueInput>;
};

export type CreateIssueBulkResponse = {
  __typename?: 'CreateIssueBulkResponse';
  output: Array<CreateIssueResponse>;
};

export type CreateIssueInput = {
  driverId: Scalars['String']['input'];
  message: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
};

export type CreateIssueResponse = {
  __typename?: 'CreateIssueResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Issue;
};

export type CreateLicenseBulkInput = {
  values: Array<CreateLicenseInput>;
};

export type CreateLicenseBulkResponse = {
  __typename?: 'CreateLicenseBulkResponse';
  output: Array<CreateLicenseResponse>;
};

export type CreateLicenseInput = {
  expirationDate?: InputMaybe<Scalars['String']['input']>;
  licenseTypeId: Scalars['String']['input'];
  number: Scalars['String']['input'];
  stateCode: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreateLicenseResponse = {
  __typename?: 'CreateLicenseResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: License;
};

export type CreateLicenseTypeBulkInput = {
  values: Array<CreateLicenseTypeInput>;
};

export type CreateLicenseTypeBulkResponse = {
  __typename?: 'CreateLicenseTypeBulkResponse';
  output: Array<CreateLicenseTypeResponse>;
};

export type CreateLicenseTypeInput = {
  displayName: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateLicenseTypeResponse = {
  __typename?: 'CreateLicenseTypeResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: LicenseType;
};

export type CreateLineItemTypeBulkInput = {
  values: Array<CreateLineItemTypeInput>;
};

export type CreateLineItemTypeBulkResponse = {
  __typename?: 'CreateLineItemTypeBulkResponse';
  output: Array<CreateLineItemTypeResponse>;
};

export type CreateLineItemTypeInput = {
  name: Scalars['String']['input'];
};

export type CreateLineItemTypeResponse = {
  __typename?: 'CreateLineItemTypeResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: LineItemType;
};

export type CreateLocationBulkInput = {
  values: Array<CreateLocationInput>;
};

export type CreateLocationBulkResponse = {
  __typename?: 'CreateLocationBulkResponse';
  output: Array<CreateLocationResponse>;
};

export type CreateLocationInput = {
  active: Scalars['Int']['input'];
  address: Scalars['String']['input'];
  airportCodes: Array<Scalars['String']['input']>;
  aliases: Array<Scalars['String']['input']>;
  cityName?: InputMaybe<Scalars['String']['input']>;
  coordinates: PointInput;
  countryCode: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  primaryAirportCode?: InputMaybe<Scalars['String']['input']>;
  radius: Scalars['Int']['input'];
  stateCode?: InputMaybe<Scalars['String']['input']>;
  traceId?: InputMaybe<Scalars['String']['input']>;
  type: LocationTypeEnum;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLocationResponse = {
  __typename?: 'CreateLocationResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Location;
};

export type CreateLocationsInput = {
  locations: Array<LocationInput>;
};

export type CreateLocationsResponse = {
  __typename?: 'CreateLocationsResponse';
  locations: Array<LocationResponse>;
};

export type CreateLoginEventBulkInput = {
  values: Array<CreateLoginEventInput>;
};

export type CreateLoginEventBulkResponse = {
  __typename?: 'CreateLoginEventBulkResponse';
  output: Array<CreateLoginEventResponse>;
};

export type CreateLoginEventInput = {
  cityName?: InputMaybe<Scalars['String']['input']>;
  datetime?: InputMaybe<Scalars['String']['input']>;
  flightNumber?: InputMaybe<Scalars['Int']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type CreateLoginEventResponse = {
  __typename?: 'CreateLoginEventResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: LoginEvent;
};

export type CreateLostAndFoundIssueBulkInput = {
  values: Array<CreateLostAndFoundIssueInput>;
};

export type CreateLostAndFoundIssueBulkResponse = {
  __typename?: 'CreateLostAndFoundIssueBulkResponse';
  output: Array<CreateLostAndFoundIssueResponse>;
};

export type CreateLostAndFoundIssueDocumentBulkInput = {
  values: Array<CreateLostAndFoundIssueDocumentInput>;
};

export type CreateLostAndFoundIssueDocumentBulkResponse = {
  __typename?: 'CreateLostAndFoundIssueDocumentBulkResponse';
  output: Array<CreateLostAndFoundIssueDocumentResponse>;
};

export type CreateLostAndFoundIssueDocumentInput = {
  contentType: Scalars['String']['input'];
  fileLocation: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
  filename: Scalars['String']['input'];
  issueId: Scalars['String']['input'];
  md5: Scalars['String']['input'];
};

export type CreateLostAndFoundIssueDocumentResponse = {
  __typename?: 'CreateLostAndFoundIssueDocumentResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: LostAndFoundIssueDocument;
};

export type CreateLostAndFoundIssueInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  crewMemberEmail: Scalars['String']['input'];
  crewMemberName: Scalars['String']['input'];
  crewMemberPhone: Scalars['String']['input'];
  description: Scalars['String']['input'];
  dispatcher: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  outboundDatetime: Scalars['String']['input'];
  outboundFlightNumber?: InputMaybe<Scalars['Int']['input']>;
  returnStatus?: InputMaybe<LostAndFoundIssueReturnStatusEnum>;
  shippingAddress?: InputMaybe<Scalars['String']['input']>;
  shippingCost?: InputMaybe<Scalars['Float']['input']>;
  shippingMethod?: InputMaybe<Scalars['String']['input']>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
  tripId: Scalars['String']['input'];
};

export type CreateLostAndFoundIssueResponse = {
  __typename?: 'CreateLostAndFoundIssueResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: LostAndFoundIssue;
};

export type CreateMessageBulkInput = {
  values: Array<CreateMessageInput>;
};

export type CreateMessageBulkResponse = {
  __typename?: 'CreateMessageBulkResponse';
  output: Array<CreateMessageResponse>;
};

export type CreateMessageInput = {
  companyId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  type: MessageTypeEnum;
};

export type CreateMessageResponse = {
  __typename?: 'CreateMessageResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Message;
};

export type CreatePermissionBulkInput = {
  values: Array<CreatePermissionInput>;
};

export type CreatePermissionBulkResponse = {
  __typename?: 'CreatePermissionBulkResponse';
  output: Array<CreatePermissionResponse>;
};

export type CreatePermissionHasRoleBulkInput = {
  values: Array<CreatePermissionHasRoleInput>;
};

export type CreatePermissionHasRoleBulkResponse = {
  __typename?: 'CreatePermissionHasRoleBulkResponse';
  output: Array<CreatePermissionHasRoleResponse>;
};

export type CreatePermissionHasRoleInput = {
  permissionId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};

export type CreatePermissionHasRoleResponse = {
  __typename?: 'CreatePermissionHasRoleResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: PermissionHasRole;
};

export type CreatePermissionHasUserBulkInput = {
  values: Array<CreatePermissionHasUserInput>;
};

export type CreatePermissionHasUserBulkResponse = {
  __typename?: 'CreatePermissionHasUserBulkResponse';
  output: Array<CreatePermissionHasUserResponse>;
};

export type CreatePermissionHasUserInput = {
  permissionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreatePermissionHasUserResponse = {
  __typename?: 'CreatePermissionHasUserResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: PermissionHasUser;
};

export type CreatePermissionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayGroup: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  effect: PermissionEffectEnum;
  entity: Scalars['String']['input'];
  resource: Scalars['String']['input'];
  verb: Scalars['String']['input'];
};

export type CreatePermissionResponse = {
  __typename?: 'CreatePermissionResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Permission;
};

export type CreatePickupPointBulkInput = {
  generateUploadUrl?: InputMaybe<Scalars['Int']['input']>;
  values: Array<CreatePickupPointInput>;
};

export type CreatePickupPointBulkResponse = {
  __typename?: 'CreatePickupPointBulkResponse';
  output: Array<CreatePickupPointResponse>;
};

export type CreatePickupPointInput = {
  coordinates: PointInput;
  endDatetime: Scalars['String']['input'];
  images: Array<CreateImageInput>;
  instructions: Scalars['String']['input'];
  keyword: Scalars['String']['input'];
  locationId: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
  radius: Scalars['Int']['input'];
  startDatetime: Scalars['String']['input'];
};

export type CreatePickupPointResponse = {
  __typename?: 'CreatePickupPointResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: PickupPoint;
};

export type CreatePotentialTripBulkInput = {
  values: Array<CreatePotentialTripInput>;
};

export type CreatePotentialTripBulkResponse = {
  __typename?: 'CreatePotentialTripBulkResponse';
  output: Array<CreatePotentialTripResponse>;
};

export type CreatePotentialTripInput = {
  airportCode: Scalars['String']['input'];
  attendants: Scalars['Int']['input'];
  bufferMinutes: Scalars['Int']['input'];
  combineId?: InputMaybe<Scalars['String']['input']>;
  doLocationId: Scalars['String']['input'];
  flightNumber?: InputMaybe<Scalars['Int']['input']>;
  fromManifest: Scalars['Int']['input'];
  importId: Scalars['String']['input'];
  kind: PotentialTripKindEnum;
  loopName?: InputMaybe<Scalars['String']['input']>;
  payerProviderId: Scalars['String']['input'];
  pilots: Scalars['Int']['input'];
  puLocationId: Scalars['String']['input'];
  scheduled: Scalars['String']['input'];
  servicerIataAirlineCode: Scalars['String']['input'];
  split: Scalars['Int']['input'];
  tripId: Scalars['String']['input'];
  typeName?: InputMaybe<PotentialTripTypeNameEnum>;
};

export type CreatePotentialTripResponse = {
  __typename?: 'CreatePotentialTripResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: PotentialTrip;
};

export type CreateProviderBulkInput = {
  values: Array<CreateProviderInput>;
};

export type CreateProviderBulkResponse = {
  __typename?: 'CreateProviderBulkResponse';
  output: Array<CreateProviderResponse>;
};

export type CreateProviderInput = {
  active: Scalars['Int']['input'];
  billFrequency?: InputMaybe<Scalars['String']['input']>;
  billingAddress?: InputMaybe<Scalars['String']['input']>;
  billingCityCode?: InputMaybe<Scalars['String']['input']>;
  billingStateCode?: InputMaybe<Scalars['String']['input']>;
  billingZipCode?: InputMaybe<Scalars['Int']['input']>;
  companyId: Scalars['String']['input'];
  contactPerson?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  iataAirlineCode?: InputMaybe<Scalars['String']['input']>;
  importCode?: InputMaybe<ProviderImportCodeEnum>;
  invoiceTermId?: InputMaybe<Scalars['String']['input']>;
  mobilePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  occPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  primaryAddress?: InputMaybe<Scalars['String']['input']>;
  primaryCityCode?: InputMaybe<Scalars['String']['input']>;
  primaryEmail?: InputMaybe<Scalars['String']['input']>;
  primaryLogoUrl?: InputMaybe<Scalars['String']['input']>;
  primaryPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  primaryStateCode?: InputMaybe<Scalars['String']['input']>;
  primaryZipCode?: InputMaybe<Scalars['Int']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  traceId?: InputMaybe<Scalars['String']['input']>;
  type: ProviderTypeEnum;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProviderLocationMappingBulkInput = {
  values: Array<CreateProviderLocationMappingInput>;
};

export type CreateProviderLocationMappingBulkResponse = {
  __typename?: 'CreateProviderLocationMappingBulkResponse';
  output: Array<CreateProviderLocationMappingResponse>;
};

export type CreateProviderLocationMappingInput = {
  active: Scalars['Int']['input'];
  contractId: Scalars['String']['input'];
  doLocationId: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
  puLocationId: Scalars['String']['input'];
};

export type CreateProviderLocationMappingResponse = {
  __typename?: 'CreateProviderLocationMappingResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: ProviderLocationMapping;
};

export type CreateProviderResponse = {
  __typename?: 'CreateProviderResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Provider;
};

export type CreatePunchHistoryBulkInput = {
  values: Array<CreatePunchHistoryInput>;
};

export type CreatePunchHistoryBulkResponse = {
  __typename?: 'CreatePunchHistoryBulkResponse';
  output: Array<CreatePunchHistoryResponse>;
};

export type CreatePunchHistoryInput = {
  approvalEmployee?: InputMaybe<Scalars['Int']['input']>;
  approvalSupervisor?: InputMaybe<Scalars['Int']['input']>;
  approvalSupervisorBy?: InputMaybe<Scalars['String']['input']>;
  companyCode: Scalars['String']['input'];
  date: Scalars['String']['input'];
  durationOt1?: InputMaybe<Scalars['Float']['input']>;
  durationOt2?: InputMaybe<Scalars['Float']['input']>;
  durationRegular?: InputMaybe<Scalars['Float']['input']>;
  employeeId: Scalars['String']['input'];
  fileLocation?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  payType: Scalars['String']['input'];
  punchInTime: Scalars['String']['input'];
  punchInType: PunchHistoryPunchInTypeEnum;
  punchOutTime: Scalars['String']['input'];
  punchOutType: PunchHistoryPunchOutTypeEnum;
  unpaidDuration?: InputMaybe<Scalars['Float']['input']>;
};

export type CreatePunchHistoryResponse = {
  __typename?: 'CreatePunchHistoryResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: PunchHistory;
};

export type CreatePunchPolicyBulkInput = {
  values: Array<CreatePunchPolicyInput>;
};

export type CreatePunchPolicyBulkResponse = {
  __typename?: 'CreatePunchPolicyBulkResponse';
  output: Array<CreatePunchPolicyResponse>;
};

export type CreatePunchPolicyInput = {
  companyId: Scalars['String']['input'];
  endTime: Scalars['String']['input'];
  eventEndDatetime?: InputMaybe<Scalars['String']['input']>;
  eventStartDatetime?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
  minutesIn: Scalars['Int']['input'];
  minutesOut: Scalars['Int']['input'];
  startTime: Scalars['String']['input'];
};

export type CreatePunchPolicyResponse = {
  __typename?: 'CreatePunchPolicyResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: PunchPolicy;
};

export type CreateRateBulkInput = {
  values: Array<CreateRateInput>;
};

export type CreateRateBulkResponse = {
  __typename?: 'CreateRateBulkResponse';
  output: Array<CreateRateResponse>;
};

export type CreateRateForTripsInput = {
  createRateRequest: CreateRateInput;
  tripIds: Array<Scalars['String']['input']>;
};

export type CreateRateForTripsResponse = {
  __typename?: 'CreateRateForTripsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  rate?: Maybe<Rate>;
};

export type CreateRateGroupBulkInput = {
  values: Array<CreateRateGroupInput>;
};

export type CreateRateGroupBulkResponse = {
  __typename?: 'CreateRateGroupBulkResponse';
  output: Array<CreateRateGroupResponse>;
};

export type CreateRateGroupInput = {
  airportCode?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  duration?: InputMaybe<Scalars['Int']['input']>;
  items: Array<CreateRateItemAndAmountInput>;
  payerProviderId?: InputMaybe<Scalars['String']['input']>;
  thatLocationId: Scalars['String']['input'];
  thisLocationId: Scalars['String']['input'];
  tripDistance?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateRateGroupResponse = {
  __typename?: 'CreateRateGroupResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: RateGroup;
};

export type CreateRateHistoryBulkInput = {
  values: Array<CreateRateHistoryInput>;
};

export type CreateRateHistoryBulkResponse = {
  __typename?: 'CreateRateHistoryBulkResponse';
  output: Array<CreateRateHistoryResponse>;
};

export type CreateRateHistoryInput = {
  currency: RateHistoryCurrencyEnum;
  endDatetime?: InputMaybe<Scalars['String']['input']>;
  rate: Scalars['Float']['input'];
  rateGroupId: Scalars['String']['input'];
  rateItemId?: InputMaybe<Scalars['String']['input']>;
  startDatetime?: InputMaybe<Scalars['String']['input']>;
  type: RateHistoryTypeEnum;
};

export type CreateRateHistoryResponse = {
  __typename?: 'CreateRateHistoryResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: RateHistory;
};

export type CreateRateInput = {
  airportCode?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  contractEndDatetime?: InputMaybe<Scalars['String']['input']>;
  contractId?: InputMaybe<Scalars['String']['input']>;
  contractStartDatetime?: InputMaybe<Scalars['String']['input']>;
  currency: RateCurrencyEnum;
  duration?: InputMaybe<Scalars['Int']['input']>;
  endDatetime?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  payerProviderId?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  radius: Scalars['Int']['input'];
  rate: Scalars['Float']['input'];
  startDatetime?: InputMaybe<Scalars['String']['input']>;
  thatLocationId: Scalars['String']['input'];
  thisLocationId: Scalars['String']['input'];
  tripDistance?: InputMaybe<Scalars['Int']['input']>;
  type: RateTypeEnum;
};

export type CreateRateItemAndAmountInput = {
  currency: RateItemCurrencyEnum;
  endDatetime?: InputMaybe<Scalars['String']['input']>;
  rate: Scalars['Float']['input'];
  startDatetime: Scalars['String']['input'];
  type: RateItemTypeEnum;
};

export type CreateRateItemBulkInput = {
  values: Array<CreateRateItemInput>;
};

export type CreateRateItemBulkResponse = {
  __typename?: 'CreateRateItemBulkResponse';
  output: Array<CreateRateItemResponse>;
};

export type CreateRateItemInput = {
  currency: RateItemCurrencyEnum;
  endDatetime?: InputMaybe<Scalars['String']['input']>;
  rate: Scalars['Float']['input'];
  rateGroupId: Scalars['String']['input'];
  startDatetime: Scalars['String']['input'];
  type: RateItemTypeEnum;
};

export type CreateRateItemResponse = {
  __typename?: 'CreateRateItemResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: RateItem;
};

export type CreateRateResponse = {
  __typename?: 'CreateRateResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Rate;
};

export type CreateRatingBulkInput = {
  values: Array<CreateRatingInput>;
};

export type CreateRatingBulkResponse = {
  __typename?: 'CreateRatingBulkResponse';
  output: Array<CreateRatingResponse>;
};

export type CreateRatingInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  ratingTypeId: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreateRatingRemarkBulkInput = {
  values: Array<CreateRatingRemarkInput>;
};

export type CreateRatingRemarkBulkResponse = {
  __typename?: 'CreateRatingRemarkBulkResponse';
  output: Array<CreateRatingRemarkResponse>;
};

export type CreateRatingRemarkInput = {
  ratingId: Scalars['String']['input'];
  remark: Scalars['String']['input'];
};

export type CreateRatingRemarkResponse = {
  __typename?: 'CreateRatingRemarkResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: RatingRemark;
};

export type CreateRatingResponse = {
  __typename?: 'CreateRatingResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Rating;
};

export type CreateRatingTypeBulkInput = {
  values: Array<CreateRatingTypeInput>;
};

export type CreateRatingTypeBulkResponse = {
  __typename?: 'CreateRatingTypeBulkResponse';
  output: Array<CreateRatingTypeResponse>;
};

export type CreateRatingTypeInput = {
  name: Scalars['String']['input'];
};

export type CreateRatingTypeResponse = {
  __typename?: 'CreateRatingTypeResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: RatingType;
};

export type CreateRemarksInput = {
  remarks: Array<RemarkInput>;
};

export type CreateRemarksResponse = {
  __typename?: 'CreateRemarksResponse';
  remarks: Array<RemarkResponse>;
};

export type CreateRoleBulkInput = {
  values: Array<CreateRoleInput>;
};

export type CreateRoleBulkResponse = {
  __typename?: 'CreateRoleBulkResponse';
  output: Array<CreateRoleResponse>;
};

export type CreateRoleInput = {
  name: Scalars['String']['input'];
};

export type CreateRoleResponse = {
  __typename?: 'CreateRoleResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Role;
};

export type CreateSettingsBulkInput = {
  values: Array<CreateSettingsInput>;
};

export type CreateSettingsBulkResponse = {
  __typename?: 'CreateSettingsBulkResponse';
  output: Array<CreateSettingsResponse>;
};

export type CreateSettingsInput = {
  chatReadTime?: InputMaybe<Scalars['Int']['input']>;
  crewChatReadTime?: InputMaybe<Scalars['Int']['input']>;
  messageReadTime?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  outboundLateAlertMinutes?: InputMaybe<Scalars['Int']['input']>;
  passwordExpirationDays?: InputMaybe<Scalars['Int']['input']>;
  vehicleTrackingVendor?: InputMaybe<SettingsVehicleTrackingVendorEnum>;
  vehicleTrackingVendorToken?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSettingsResponse = {
  __typename?: 'CreateSettingsResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Settings;
};

export type CreateSocialDistanceRuleBulkInput = {
  values: Array<CreateSocialDistanceRuleInput>;
};

export type CreateSocialDistanceRuleBulkResponse = {
  __typename?: 'CreateSocialDistanceRuleBulkResponse';
  output: Array<CreateSocialDistanceRuleResponse>;
};

export type CreateSocialDistanceRuleInput = {
  active: Scalars['Int']['input'];
  contractId: Scalars['String']['input'];
  max: Scalars['Int']['input'];
  type?: InputMaybe<SocialDistanceRuleTypeEnum>;
};

export type CreateSocialDistanceRuleResponse = {
  __typename?: 'CreateSocialDistanceRuleResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: SocialDistanceRule;
};

export type CreateStagedTripBulkInput = {
  values: Array<CreateStagedTripInput>;
};

export type CreateStagedTripBulkResponse = {
  __typename?: 'CreateStagedTripBulkResponse';
  output: Array<CreateStagedTripResponse>;
};

export type CreateStagedTripInput = {
  airportCode: Scalars['String']['input'];
  attendants: Scalars['Int']['input'];
  bufferMinutes: Scalars['Int']['input'];
  combineId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  doLocationId: Scalars['String']['input'];
  flightNumber?: InputMaybe<Scalars['Int']['input']>;
  fromManifest: Scalars['Int']['input'];
  importId: Scalars['String']['input'];
  ingestAction: StagedTripIngestActionEnum;
  kind: StagedTripKindEnum;
  loopName?: InputMaybe<Scalars['String']['input']>;
  payerProviderId: Scalars['String']['input'];
  pilots: Scalars['Int']['input'];
  potentialTripId?: InputMaybe<Scalars['String']['input']>;
  puLocationId: Scalars['String']['input'];
  scheduled: Scalars['String']['input'];
  servicerIataAirlineCode: Scalars['String']['input'];
  split: Scalars['Int']['input'];
  statusName: StagedTripStatusNameEnum;
  traceId: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
  typeName: StagedTripTypeNameEnum;
};

export type CreateStagedTripResponse = {
  __typename?: 'CreateStagedTripResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: StagedTrip;
};

export type CreateStopBulkInput = {
  values: Array<CreateStopInput>;
};

export type CreateStopBulkResponse = {
  __typename?: 'CreateStopBulkResponse';
  output: Array<CreateStopResponse>;
};

export type CreateStopInput = {
  actual?: InputMaybe<Scalars['String']['input']>;
  airportCode?: InputMaybe<Scalars['String']['input']>;
  completed?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
  scheduled: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
  type: StopTypeEnum;
};

export type CreateStopResponse = {
  __typename?: 'CreateStopResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Stop;
};

export type CreateStorageBulkInput = {
  values: Array<CreateStorageInput>;
};

export type CreateStorageBulkResponse = {
  __typename?: 'CreateStorageBulkResponse';
  output: Array<CreateStorageResponse>;
};

export type CreateStorageInput = {
  key: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type CreateStorageResponse = {
  __typename?: 'CreateStorageResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Storage;
};

export type CreateTrackFlightBulkInput = {
  values: Array<CreateTrackFlightInput>;
};

export type CreateTrackFlightBulkResponse = {
  __typename?: 'CreateTrackFlightBulkResponse';
  output: Array<CreateTrackFlightResponse>;
};

export type CreateTrackFlightInput = {
  actual?: InputMaybe<Scalars['String']['input']>;
  arrivalGate?: InputMaybe<Scalars['String']['input']>;
  arrivalTerminal?: InputMaybe<Scalars['String']['input']>;
  arrivalTimezone?: InputMaybe<Scalars['String']['input']>;
  arrivalUtcOffsetHours?: InputMaybe<Scalars['String']['input']>;
  departureGate?: InputMaybe<Scalars['String']['input']>;
  departureTerminal?: InputMaybe<Scalars['String']['input']>;
  departureTimezone?: InputMaybe<Scalars['String']['input']>;
  departureUtcOffsetHours?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<TrackFlightLabelEnum>;
  lastPingTime?: InputMaybe<Scalars['String']['input']>;
  nextPingTime?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['Int']['input'];
  raw: Scalars['String']['input'];
  scheduled: Scalars['String']['input'];
  servicerIataAirlineCode: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  trackerProvider?: InputMaybe<Scalars['String']['input']>;
  tripId: Scalars['String']['input'];
  type?: InputMaybe<TrackFlightTypeEnum>;
};

export type CreateTrackFlightResponse = {
  __typename?: 'CreateTrackFlightResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: TrackFlight;
};

export type CreateTripBulkInput = {
  values: Array<CreateTripInput>;
};

export type CreateTripBulkResponse = {
  __typename?: 'CreateTripBulkResponse';
  output: Array<CreateTripResponse>;
};

export type CreateTripInput = {
  airportCode: Scalars['String']['input'];
  attendants: Scalars['Int']['input'];
  comments?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  completionId?: InputMaybe<Scalars['String']['input']>;
  crewId?: InputMaybe<Scalars['Int']['input']>;
  doActualDatetime?: InputMaybe<Scalars['String']['input']>;
  doAirportCode?: InputMaybe<Scalars['String']['input']>;
  doLocationId: Scalars['String']['input'];
  driverId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  flightNumber: Scalars['Int']['input'];
  fromManifest: Scalars['Boolean']['input'];
  kind: TripKindEnum;
  lastName?: InputMaybe<Scalars['String']['input']>;
  loopName: Scalars['String']['input'];
  manifestImportId?: InputMaybe<Scalars['String']['input']>;
  payerProviderId: Scalars['String']['input'];
  pilots: Scalars['Int']['input'];
  providerId?: InputMaybe<Scalars['String']['input']>;
  puActualDatetime?: InputMaybe<Scalars['String']['input']>;
  puCompletedDatetime?: InputMaybe<Scalars['String']['input']>;
  puLocationId: Scalars['String']['input'];
  rateId?: InputMaybe<Scalars['String']['input']>;
  scheduled: Scalars['String']['input'];
  servicerIataAirlineCode: Scalars['String']['input'];
  split: Scalars['Int']['input'];
  status: TripStatusEnum;
  traceId?: InputMaybe<Scalars['String']['input']>;
  trackFlightId?: InputMaybe<Scalars['String']['input']>;
  type: TripTypeEnum;
  vehicleId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTripResponse = {
  __typename?: 'CreateTripResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Trip;
};

export type CreateTripScheduleTriggerBulkInput = {
  values: Array<CreateTripScheduleTriggerInput>;
};

export type CreateTripScheduleTriggerBulkResponse = {
  __typename?: 'CreateTripScheduleTriggerBulkResponse';
  output: Array<CreateTripScheduleTriggerResponse>;
};

export type CreateTripScheduleTriggerInput = {
  communicationCount: Scalars['Int']['input'];
  doLocationAddress?: InputMaybe<Scalars['String']['input']>;
  doLocationCityName?: InputMaybe<Scalars['String']['input']>;
  doLocationCoordinates?: InputMaybe<PointInput>;
  doLocationId?: InputMaybe<Scalars['String']['input']>;
  doLocationName?: InputMaybe<Scalars['String']['input']>;
  doLocationPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  doLocationStateCode?: InputMaybe<Scalars['String']['input']>;
  doLocationZipCode?: InputMaybe<Scalars['String']['input']>;
  driverAvatar?: InputMaybe<Scalars['String']['input']>;
  driverEmployeeId?: InputMaybe<Scalars['String']['input']>;
  driverFirstName?: InputMaybe<Scalars['String']['input']>;
  driverId?: InputMaybe<Scalars['String']['input']>;
  driverLastName?: InputMaybe<Scalars['String']['input']>;
  fcrCount: Scalars['Int']['input'];
  flagColors?: InputMaybe<Scalars['String']['input']>;
  latestScheduled?: InputMaybe<Scalars['String']['input']>;
  latestScheduledUtc?: InputMaybe<Scalars['String']['input']>;
  latestTriggerUsed?: InputMaybe<Scalars['String']['input']>;
  payerProviderDisplayName?: InputMaybe<Scalars['String']['input']>;
  payerProviderId?: InputMaybe<Scalars['String']['input']>;
  puLocationAddress?: InputMaybe<Scalars['String']['input']>;
  puLocationCityName?: InputMaybe<Scalars['String']['input']>;
  puLocationCoordinates?: InputMaybe<PointInput>;
  puLocationId?: InputMaybe<Scalars['String']['input']>;
  puLocationName?: InputMaybe<Scalars['String']['input']>;
  puLocationPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  puLocationStateCode?: InputMaybe<Scalars['String']['input']>;
  puLocationZipCode?: InputMaybe<Scalars['String']['input']>;
  scheduledUtc: Scalars['String']['input'];
  stopCount: Scalars['Int']['input'];
  tripId: Scalars['String']['input'];
  vehicleCapacity?: InputMaybe<Scalars['Int']['input']>;
  vehicleId?: InputMaybe<Scalars['String']['input']>;
  vehicleMake?: InputMaybe<Scalars['String']['input']>;
  vehicleModel?: InputMaybe<Scalars['String']['input']>;
  vehiclePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  vehicleTrackingId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTripScheduleTriggerResponse = {
  __typename?: 'CreateTripScheduleTriggerResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: TripScheduleTrigger;
};

export type CreateTripsInput = {
  values: Array<CreateTripInput>;
};

export type CreateTripsResponse = {
  __typename?: 'CreateTripsResponse';
  response: Array<TripResponse>;
};

export type CreateUpdateStrategyBulkInput = {
  values: Array<CreateUpdateStrategyInput>;
};

export type CreateUpdateStrategyBulkResponse = {
  __typename?: 'CreateUpdateStrategyBulkResponse';
  output: Array<CreateUpdateStrategyResponse>;
};

export type CreateUpdateStrategyInput = {
  active: Scalars['Int']['input'];
  addonStrategy: UpdateStrategyAddonStrategyEnum;
  addonType: UpdateStrategyAddonTypeEnum;
  contractId: Scalars['String']['input'];
  customAddonToleranceMinutes?: InputMaybe<Scalars['Int']['input']>;
  dailyStrategy: UpdateStrategyDailyStrategyEnum;
  importNameIncludesHotels: Scalars['Int']['input'];
  manifestStrategy: UpdateStrategyManifestStrategyEnum;
  strategy: UpdateStrategyStrategyEnum;
  updateKindFromLocations: Scalars['Int']['input'];
};

export type CreateUpdateStrategyResponse = {
  __typename?: 'CreateUpdateStrategyResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: UpdateStrategy;
};

export type CreateUserAvailabilityBulkInput = {
  values: Array<CreateUserAvailabilityInput>;
};

export type CreateUserAvailabilityBulkResponse = {
  __typename?: 'CreateUserAvailabilityBulkResponse';
  output: Array<CreateUserAvailabilityResponse>;
};

export type CreateUserAvailabilityDriverAppInput = {
  values: Array<CreateUserAvailabilityInput>;
};

export type CreateUserAvailabilityDriverAppResponse = {
  __typename?: 'CreateUserAvailabilityDriverAppResponse';
  Output?: Maybe<Array<Maybe<CreateUserAvailabilityResponse>>>;
  error?: Maybe<Error>;
};

export type CreateUserAvailabilityInput = {
  approved?: InputMaybe<Scalars['Int']['input']>;
  availabilityType?: InputMaybe<Scalars['String']['input']>;
  dayOfWeek: Scalars['Int']['input'];
  endDate: Scalars['String']['input'];
  endTime: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  repeatUntil?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
  type?: InputMaybe<UserAvailabilityTypeEnum>;
  userId: Scalars['String']['input'];
};

export type CreateUserAvailabilityResponse = {
  __typename?: 'CreateUserAvailabilityResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: UserAvailability;
};

export type CreateUserBulkInput = {
  values: Array<CreateUserInput>;
};

export type CreateUserBulkResponse = {
  __typename?: 'CreateUserBulkResponse';
  output: Array<CreateUserResponse>;
};

export type CreateUserInput = {
  active: Scalars['Int']['input'];
  address?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  cityName?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  countryCode?: InputMaybe<Scalars['Int']['input']>;
  dob?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  employeeId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  longTrip?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  resetClientIp?: InputMaybe<Scalars['String']['input']>;
  resetCode?: InputMaybe<Scalars['String']['input']>;
  resetCreatedAtUtc?: InputMaybe<Scalars['String']['input']>;
  roleId: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  traceId?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
  vehicleId?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: User;
};

export type CreateVariableTimeBulkInput = {
  values: Array<CreateVariableTimeInput>;
};

export type CreateVariableTimeBulkResponse = {
  __typename?: 'CreateVariableTimeBulkResponse';
  output: Array<CreateVariableTimeResponse>;
};

export type CreateVariableTimeInput = {
  airportCode: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  inboundDomestic?: InputMaybe<Scalars['Int']['input']>;
  inboundInternational?: InputMaybe<Scalars['Int']['input']>;
  outbound?: InputMaybe<Scalars['Int']['input']>;
  servicerIataAirlineCode: Scalars['String']['input'];
};

export type CreateVariableTimeResponse = {
  __typename?: 'CreateVariableTimeResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: VariableTime;
};

export type CreateVehicleBulkInput = {
  values: Array<CreateVehicleInput>;
};

export type CreateVehicleBulkResponse = {
  __typename?: 'CreateVehicleBulkResponse';
  output: Array<CreateVehicleResponse>;
};

export type CreateVehicleInput = {
  active: Scalars['Int']['input'];
  airportPermitExpirationDate?: InputMaybe<Scalars['String']['input']>;
  capacity: Scalars['Int']['input'];
  emissionPermitExpirationDate?: InputMaybe<Scalars['String']['input']>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  registrationExpirationDate?: InputMaybe<Scalars['String']['input']>;
  stateRegistered?: InputMaybe<Scalars['String']['input']>;
  tollTag?: InputMaybe<Scalars['String']['input']>;
  tracker: VehicleTrackerEnum;
  trackingId: Scalars['String']['input'];
  vin: Scalars['String']['input'];
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateVehicleResponse = {
  __typename?: 'CreateVehicleResponse';
  error?: Maybe<Scalars['String']['output']>;
  node: Vehicle;
};

export type CrewCheckinInput = {
  message: Scalars['String']['input'];
  tripId: Array<Scalars['String']['input']>;
};

export type CrewCheckinResponse = {
  __typename?: 'CrewCheckinResponse';
  error?: Maybe<Error>;
};

export type CrewNoShowInput = {
  comment: Scalars['String']['input'];
  datetime: Scalars['String']['input'];
  tripId?: InputMaybe<Scalars['String']['input']>;
  tripIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CrewNoShowResponse = {
  __typename?: 'CrewNoShowResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type DeleteActivityBulkInput = {
  search: Array<DeleteActivityInput>;
};

export type DeleteActivityBulkResponse = {
  __typename?: 'DeleteActivityBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteActivityInput = {
  id: Scalars['String']['input'];
};

export type DeleteActivityObjectBulkInput = {
  search: Array<DeleteActivityObjectInput>;
};

export type DeleteActivityObjectBulkResponse = {
  __typename?: 'DeleteActivityObjectBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteActivityObjectInput = {
  id: Scalars['String']['input'];
};

export type DeleteAirportBulkInput = {
  search: Array<DeleteAirportInput>;
};

export type DeleteAirportBulkResponse = {
  __typename?: 'DeleteAirportBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteAirportGroupBulkInput = {
  search: Array<DeleteAirportGroupInput>;
};

export type DeleteAirportGroupBulkResponse = {
  __typename?: 'DeleteAirportGroupBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteAirportGroupInput = {
  id: Scalars['String']['input'];
};

export type DeleteAirportHasCompanyBulkInput = {
  search: Array<DeleteAirportHasCompanyInput>;
};

export type DeleteAirportHasCompanyBulkResponse = {
  __typename?: 'DeleteAirportHasCompanyBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteAirportHasCompanyInput = {
  airportCode: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
};

export type DeleteAirportHasGroupBulkInput = {
  search: Array<DeleteAirportHasGroupInput>;
};

export type DeleteAirportHasGroupBulkResponse = {
  __typename?: 'DeleteAirportHasGroupBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteAirportHasGroupInput = {
  airportCode: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
};

export type DeleteAirportHasLocationBulkInput = {
  search: Array<DeleteAirportHasLocationInput>;
};

export type DeleteAirportHasLocationBulkResponse = {
  __typename?: 'DeleteAirportHasLocationBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteAirportHasLocationInput = {
  airportCode: Scalars['String']['input'];
  locationId: Scalars['String']['input'];
};

export type DeleteAirportHasRateBulkInput = {
  search: Array<DeleteAirportHasRateInput>;
};

export type DeleteAirportHasRateBulkResponse = {
  __typename?: 'DeleteAirportHasRateBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteAirportHasRateInput = {
  airportCode: Scalars['String']['input'];
};

export type DeleteAirportHasVehicleBulkInput = {
  search: Array<DeleteAirportHasVehicleInput>;
};

export type DeleteAirportHasVehicleBulkResponse = {
  __typename?: 'DeleteAirportHasVehicleBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteAirportHasVehicleInput = {
  airportCode: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type DeleteAirportInput = {
  code: Scalars['String']['input'];
};

export type DeleteAliasBulkInput = {
  search: Array<DeleteAliasInput>;
};

export type DeleteAliasBulkResponse = {
  __typename?: 'DeleteAliasBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteAliasInput = {
  id: Scalars['String']['input'];
};

export type DeleteAutoCombineRuleBulkInput = {
  search: Array<DeleteAutoCombineRuleInput>;
};

export type DeleteAutoCombineRuleBulkResponse = {
  __typename?: 'DeleteAutoCombineRuleBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteAutoCombineRuleInput = {
  id: Scalars['String']['input'];
};

export type DeleteBufferTimeBulkInput = {
  search: Array<DeleteBufferTimeInput>;
};

export type DeleteBufferTimeBulkResponse = {
  __typename?: 'DeleteBufferTimeBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteBufferTimeInput = {
  id: Scalars['String']['input'];
};

export type DeleteBufferTimesInput = {
  ids: Array<Scalars['String']['input']>;
};

export type DeleteBufferTimesResponse = {
  __typename?: 'DeleteBufferTimesResponse';
  bufferTimes: Array<DeleteResponse>;
};

export type DeleteCancellationRuleBulkInput = {
  search: Array<DeleteCancellationRuleInput>;
};

export type DeleteCancellationRuleBulkResponse = {
  __typename?: 'DeleteCancellationRuleBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteCancellationRuleInput = {
  id: Scalars['String']['input'];
};

export type DeleteCheckinEventBulkInput = {
  search: Array<DeleteCheckinEventInput>;
};

export type DeleteCheckinEventBulkResponse = {
  __typename?: 'DeleteCheckinEventBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteCheckinEventInput = {
  id: Scalars['String']['input'];
};

export type DeleteCombineRuleBulkInput = {
  search: Array<DeleteCombineRuleInput>;
};

export type DeleteCombineRuleBulkResponse = {
  __typename?: 'DeleteCombineRuleBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteCombineRuleInput = {
  id: Scalars['String']['input'];
};

export type DeleteCommunicationBulkInput = {
  search: Array<DeleteCommunicationInput>;
};

export type DeleteCommunicationBulkResponse = {
  __typename?: 'DeleteCommunicationBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteCommunicationInput = {
  id: Scalars['String']['input'];
};

export type DeleteCompanyBulkInput = {
  search: Array<DeleteCompanyInput>;
};

export type DeleteCompanyBulkResponse = {
  __typename?: 'DeleteCompanyBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteCompanyInput = {
  id: Scalars['String']['input'];
};

export type DeleteCompletionBulkInput = {
  search: Array<DeleteCompletionInput>;
};

export type DeleteCompletionBulkResponse = {
  __typename?: 'DeleteCompletionBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteCompletionInput = {
  id: Scalars['String']['input'];
};

export type DeleteCompletionTypeBulkInput = {
  search: Array<DeleteCompletionTypeInput>;
};

export type DeleteCompletionTypeBulkResponse = {
  __typename?: 'DeleteCompletionTypeBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteCompletionTypeInput = {
  id: Scalars['String']['input'];
};

export type DeleteContractBulkInput = {
  search: Array<DeleteContractInput>;
};

export type DeleteContractBulkResponse = {
  __typename?: 'DeleteContractBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteContractEmailBulkInput = {
  search: Array<DeleteContractEmailInput>;
};

export type DeleteContractEmailBulkResponse = {
  __typename?: 'DeleteContractEmailBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteContractEmailInput = {
  id: Scalars['String']['input'];
};

export type DeleteContractInput = {
  id: Scalars['String']['input'];
};

export type DeleteContractNumberBulkInput = {
  search: Array<DeleteContractNumberInput>;
};

export type DeleteContractNumberBulkResponse = {
  __typename?: 'DeleteContractNumberBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteContractNumberInput = {
  id: Scalars['String']['input'];
};

export type DeleteCountryRegionBulkInput = {
  search: Array<DeleteCountryRegionInput>;
};

export type DeleteCountryRegionBulkResponse = {
  __typename?: 'DeleteCountryRegionBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteCountryRegionInput = {
  id: Scalars['String']['input'];
};

export type DeleteDeviceBulkInput = {
  search: Array<DeleteDeviceInput>;
};

export type DeleteDeviceBulkResponse = {
  __typename?: 'DeleteDeviceBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteDeviceInput = {
  id: Scalars['String']['input'];
};

export type DeleteDistanceBulkInput = {
  search: Array<DeleteDistanceInput>;
};

export type DeleteDistanceBulkResponse = {
  __typename?: 'DeleteDistanceBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteDistanceInput = {
  id: Scalars['String']['input'];
};

export type DeleteEmailBulkInput = {
  search: Array<DeleteEmailInput>;
};

export type DeleteEmailBulkResponse = {
  __typename?: 'DeleteEmailBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteEmailInput = {
  id: Scalars['String']['input'];
};

export type DeleteEquivalentTripBulkInput = {
  search: Array<DeleteEquivalentTripInput>;
};

export type DeleteEquivalentTripBulkResponse = {
  __typename?: 'DeleteEquivalentTripBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteEquivalentTripInput = {
  id: Scalars['String']['input'];
};

export type DeleteFcrBulkInput = {
  search: Array<DeleteFcrInput>;
};

export type DeleteFcrBulkResponse = {
  __typename?: 'DeleteFcrBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteFcrInput = {
  id: Scalars['String']['input'];
};

export type DeleteFcrTypeBulkInput = {
  search: Array<DeleteFcrTypeInput>;
};

export type DeleteFcrTypeBulkResponse = {
  __typename?: 'DeleteFcrTypeBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteFcrTypeInput = {
  id: Scalars['String']['input'];
};

export type DeleteFlagBulkInput = {
  search: Array<DeleteFlagInput>;
};

export type DeleteFlagBulkResponse = {
  __typename?: 'DeleteFlagBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteFlagHasTripBulkInput = {
  search: Array<DeleteFlagHasTripInput>;
};

export type DeleteFlagHasTripBulkResponse = {
  __typename?: 'DeleteFlagHasTripBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteFlagHasTripInput = {
  flagId: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
};

export type DeleteFlagInput = {
  id: Scalars['String']['input'];
};

export type DeleteGroupBulkInput = {
  search: Array<DeleteGroupInput>;
};

export type DeleteGroupBulkResponse = {
  __typename?: 'DeleteGroupBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteGroupHasPermissionBulkInput = {
  search: Array<DeleteGroupHasPermissionInput>;
};

export type DeleteGroupHasPermissionBulkResponse = {
  __typename?: 'DeleteGroupHasPermissionBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteGroupHasPermissionInput = {
  groupId: Scalars['String']['input'];
  permissionId: Scalars['String']['input'];
};

export type DeleteGroupHasUserBulkInput = {
  search: Array<DeleteGroupHasUserInput>;
};

export type DeleteGroupHasUserBulkResponse = {
  __typename?: 'DeleteGroupHasUserBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteGroupHasUserInput = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type DeleteGroupInput = {
  id: Scalars['String']['input'];
};

export type DeleteImageBulkInput = {
  search: Array<DeleteImageInput>;
};

export type DeleteImageBulkResponse = {
  __typename?: 'DeleteImageBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteImageInput = {
  id: Scalars['String']['input'];
};

export type DeleteImportBulkInput = {
  search: Array<DeleteImportInput>;
};

export type DeleteImportBulkResponse = {
  __typename?: 'DeleteImportBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteImportInput = {
  id: Scalars['String']['input'];
};

export type DeleteInvoiceBulkInput = {
  search: Array<DeleteInvoiceInput>;
};

export type DeleteInvoiceBulkResponse = {
  __typename?: 'DeleteInvoiceBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteInvoiceInput = {
  id: Scalars['Int']['input'];
};

export type DeleteInvoiceTermBulkInput = {
  search: Array<DeleteInvoiceTermInput>;
};

export type DeleteInvoiceTermBulkResponse = {
  __typename?: 'DeleteInvoiceTermBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteInvoiceTermInput = {
  id: Scalars['String']['input'];
};

export type DeleteIssueBulkInput = {
  search: Array<DeleteIssueInput>;
};

export type DeleteIssueBulkResponse = {
  __typename?: 'DeleteIssueBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteIssueInput = {
  id: Scalars['String']['input'];
};

export type DeleteLicenseBulkInput = {
  search: Array<DeleteLicenseInput>;
};

export type DeleteLicenseBulkResponse = {
  __typename?: 'DeleteLicenseBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteLicenseInput = {
  id: Scalars['String']['input'];
};

export type DeleteLicenseTypeBulkInput = {
  search: Array<DeleteLicenseTypeInput>;
};

export type DeleteLicenseTypeBulkResponse = {
  __typename?: 'DeleteLicenseTypeBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteLicenseTypeInput = {
  id: Scalars['String']['input'];
};

export type DeleteLocationBulkInput = {
  search: Array<DeleteLocationInput>;
};

export type DeleteLocationBulkResponse = {
  __typename?: 'DeleteLocationBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteLocationInput = {
  id: Scalars['String']['input'];
};

export type DeleteLocationsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type DeleteLocationsResponse = {
  __typename?: 'DeleteLocationsResponse';
  locations: Array<DeleteResponse>;
};

export type DeleteLoginEventBulkInput = {
  search: Array<DeleteLoginEventInput>;
};

export type DeleteLoginEventBulkResponse = {
  __typename?: 'DeleteLoginEventBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteLoginEventInput = {
  id: Scalars['String']['input'];
};

export type DeleteLostAndFoundIssueBulkInput = {
  search: Array<DeleteLostAndFoundIssueInput>;
};

export type DeleteLostAndFoundIssueBulkResponse = {
  __typename?: 'DeleteLostAndFoundIssueBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteLostAndFoundIssueDocumentBulkInput = {
  search: Array<DeleteLostAndFoundIssueDocumentInput>;
};

export type DeleteLostAndFoundIssueDocumentBulkResponse = {
  __typename?: 'DeleteLostAndFoundIssueDocumentBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteLostAndFoundIssueDocumentInput = {
  id: Scalars['String']['input'];
};

export type DeleteLostAndFoundIssueInput = {
  id: Scalars['String']['input'];
};

export type DeleteMessageBulkInput = {
  search: Array<DeleteMessageInput>;
};

export type DeleteMessageBulkResponse = {
  __typename?: 'DeleteMessageBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteMessageInput = {
  id: Scalars['String']['input'];
};

export type DeletePermissionBulkInput = {
  search: Array<DeletePermissionInput>;
};

export type DeletePermissionBulkResponse = {
  __typename?: 'DeletePermissionBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeletePermissionHasRoleBulkInput = {
  search: Array<DeletePermissionHasRoleInput>;
};

export type DeletePermissionHasRoleBulkResponse = {
  __typename?: 'DeletePermissionHasRoleBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeletePermissionHasRoleInput = {
  permissionId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};

export type DeletePermissionHasUserBulkInput = {
  search: Array<DeletePermissionHasUserInput>;
};

export type DeletePermissionHasUserBulkResponse = {
  __typename?: 'DeletePermissionHasUserBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeletePermissionHasUserInput = {
  permissionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type DeletePermissionInput = {
  id: Scalars['String']['input'];
};

export type DeletePickupPointBulkInput = {
  search: Array<DeletePickupPointInput>;
};

export type DeletePickupPointBulkResponse = {
  __typename?: 'DeletePickupPointBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeletePickupPointInput = {
  id: Scalars['String']['input'];
};

export type DeletePotentialTripBulkInput = {
  search: Array<DeletePotentialTripInput>;
};

export type DeletePotentialTripBulkResponse = {
  __typename?: 'DeletePotentialTripBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeletePotentialTripInput = {
  id: Scalars['String']['input'];
};

export type DeleteProviderBulkInput = {
  search: Array<DeleteProviderInput>;
};

export type DeleteProviderBulkResponse = {
  __typename?: 'DeleteProviderBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteProviderInput = {
  id: Scalars['String']['input'];
};

export type DeleteProviderLocationMappingBulkInput = {
  search: Array<DeleteProviderLocationMappingInput>;
};

export type DeleteProviderLocationMappingBulkResponse = {
  __typename?: 'DeleteProviderLocationMappingBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteProviderLocationMappingInput = {
  id: Scalars['String']['input'];
};

export type DeletePunchHistoryBulkInput = {
  search: Array<DeletePunchHistoryInput>;
};

export type DeletePunchHistoryBulkResponse = {
  __typename?: 'DeletePunchHistoryBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeletePunchHistoryInput = {
  id: Scalars['String']['input'];
};

export type DeletePunchPolicyBulkInput = {
  search: Array<DeletePunchPolicyInput>;
};

export type DeletePunchPolicyBulkResponse = {
  __typename?: 'DeletePunchPolicyBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeletePunchPolicyInput = {
  id: Scalars['String']['input'];
};

export type DeleteRateBulkInput = {
  search: Array<DeleteRateInput>;
};

export type DeleteRateBulkResponse = {
  __typename?: 'DeleteRateBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteRateGroupBulkInput = {
  search: Array<DeleteRateGroupInput>;
};

export type DeleteRateGroupBulkResponse = {
  __typename?: 'DeleteRateGroupBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteRateGroupInput = {
  id: Scalars['String']['input'];
};

export type DeleteRateHistoryBulkInput = {
  search: Array<DeleteRateHistoryInput>;
};

export type DeleteRateHistoryBulkResponse = {
  __typename?: 'DeleteRateHistoryBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteRateHistoryInput = {
  id: Scalars['String']['input'];
};

export type DeleteRateInput = {
  id: Scalars['String']['input'];
};

export type DeleteRateItemBulkInput = {
  search: Array<DeleteRateItemInput>;
};

export type DeleteRateItemBulkResponse = {
  __typename?: 'DeleteRateItemBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteRateItemInput = {
  id: Scalars['String']['input'];
};

export type DeleteRatingBulkInput = {
  search: Array<DeleteRatingInput>;
};

export type DeleteRatingBulkResponse = {
  __typename?: 'DeleteRatingBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteRatingInput = {
  id: Scalars['String']['input'];
};

export type DeleteRatingRemarkBulkInput = {
  search: Array<DeleteRatingRemarkInput>;
};

export type DeleteRatingRemarkBulkResponse = {
  __typename?: 'DeleteRatingRemarkBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteRatingRemarkInput = {
  id: Scalars['String']['input'];
};

export type DeleteRatingTypeBulkInput = {
  search: Array<DeleteRatingTypeInput>;
};

export type DeleteRatingTypeBulkResponse = {
  __typename?: 'DeleteRatingTypeBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteRatingTypeInput = {
  id: Scalars['String']['input'];
};

export type DeleteRemarksInput = {
  ids: Array<Scalars['String']['input']>;
};

export type DeleteRemarksResponse = {
  __typename?: 'DeleteRemarksResponse';
  remarks: Array<DeleteResponse>;
};

export type DeleteResponse = {
  __typename?: 'DeleteResponse';
  deleted: Scalars['Boolean']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type DeleteRoleBulkInput = {
  search: Array<DeleteRoleInput>;
};

export type DeleteRoleBulkResponse = {
  __typename?: 'DeleteRoleBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteRoleInput = {
  id: Scalars['String']['input'];
};

export type DeleteSettingsBulkInput = {
  search: Array<DeleteSettingsInput>;
};

export type DeleteSettingsBulkResponse = {
  __typename?: 'DeleteSettingsBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteSettingsInput = {
  id: Scalars['String']['input'];
};

export type DeleteSocialDistanceRuleBulkInput = {
  search: Array<DeleteSocialDistanceRuleInput>;
};

export type DeleteSocialDistanceRuleBulkResponse = {
  __typename?: 'DeleteSocialDistanceRuleBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteSocialDistanceRuleInput = {
  id: Scalars['String']['input'];
};

export type DeleteStagedTripBulkInput = {
  search: Array<DeleteStagedTripInput>;
};

export type DeleteStagedTripBulkResponse = {
  __typename?: 'DeleteStagedTripBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteStagedTripInput = {
  id: Scalars['String']['input'];
};

export type DeleteStopBulkInput = {
  search: Array<DeleteStopInput>;
};

export type DeleteStopBulkResponse = {
  __typename?: 'DeleteStopBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteStopInput = {
  id: Scalars['String']['input'];
};

export type DeleteStorageBulkInput = {
  search: Array<DeleteStorageInput>;
};

export type DeleteStorageBulkResponse = {
  __typename?: 'DeleteStorageBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteStorageInput = {
  id: Scalars['String']['input'];
};

export type DeleteTrackFlightBulkInput = {
  search: Array<DeleteTrackFlightInput>;
};

export type DeleteTrackFlightBulkResponse = {
  __typename?: 'DeleteTrackFlightBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteTrackFlightInput = {
  id: Scalars['String']['input'];
};

export type DeleteTripBulkInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  search: Array<DeleteTripInput>;
};

export type DeleteTripBulkResponse = {
  __typename?: 'DeleteTripBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteTripHardBulkInput = {
  tripIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DeleteTripHardBulkResponse = {
  __typename?: 'DeleteTripHardBulkResponse';
  deleted: Scalars['Int']['output'];
  error?: Maybe<Error>;
};

export type DeleteTripInput = {
  id: Scalars['String']['input'];
};

export type DeleteTripScheduleTriggerBulkInput = {
  search: Array<DeleteTripScheduleTriggerInput>;
};

export type DeleteTripScheduleTriggerBulkResponse = {
  __typename?: 'DeleteTripScheduleTriggerBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteTripScheduleTriggerInput = {
  id: Scalars['String']['input'];
};

export type DeleteUpdateStrategyBulkInput = {
  search: Array<DeleteUpdateStrategyInput>;
};

export type DeleteUpdateStrategyBulkResponse = {
  __typename?: 'DeleteUpdateStrategyBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteUpdateStrategyInput = {
  id: Scalars['String']['input'];
};

export type DeleteUserAvailabilityBulkInput = {
  search: Array<DeleteUserAvailabilityInput>;
};

export type DeleteUserAvailabilityBulkResponse = {
  __typename?: 'DeleteUserAvailabilityBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteUserAvailabilityDriverAppInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DeleteUserAvailabilityDriverAppResponse = {
  __typename?: 'DeleteUserAvailabilityDriverAppResponse';
  deleted: Scalars['Int']['output'];
  error?: Maybe<Error>;
};

export type DeleteUserAvailabilityInput = {
  id: Scalars['String']['input'];
};

export type DeleteUserAvailabilitySeriesDriverAppInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DeleteUserAvailabilitySeriesDriverAppResponse = {
  __typename?: 'DeleteUserAvailabilitySeriesDriverAppResponse';
  deleted: Scalars['Int']['output'];
  error?: Maybe<Error>;
};

export type DeleteUserBulkInput = {
  search: Array<DeleteUserInput>;
};

export type DeleteUserBulkResponse = {
  __typename?: 'DeleteUserBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteUserInput = {
  id: Scalars['String']['input'];
};

export type DeleteUsersInput = {
  ids: Array<Scalars['String']['input']>;
};

export type DeleteUsersResponse = {
  __typename?: 'DeleteUsersResponse';
  users: Array<DeleteResponse>;
};

export type DeleteVariableTimeBulkInput = {
  search: Array<DeleteVariableTimeInput>;
};

export type DeleteVariableTimeBulkResponse = {
  __typename?: 'DeleteVariableTimeBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteVariableTimeInput = {
  id: Scalars['String']['input'];
};

export type DeleteVehicleBulkInput = {
  search: Array<DeleteVehicleInput>;
};

export type DeleteVehicleBulkResponse = {
  __typename?: 'DeleteVehicleBulkResponse';
  deleted: Scalars['Int']['output'];
};

export type DeleteVehicleInput = {
  id: Scalars['String']['input'];
};

export type Description = {
  __typename?: 'Description';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum Device {
  AndroidCrewV1 = 'ANDROID_CREW_V1',
  AndroidDriverV1 = 'ANDROID_DRIVER_V1',
  IosCrewV1 = 'IOS_CREW_V1',
  IosDriverV1 = 'IOS_DRIVER_V1',
  PortalV1 = 'PORTAL_V1'
}

export type DeviceConnection = {
  __typename?: 'DeviceConnection';
  edges?: Maybe<Array<Maybe<DeviceEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DeviceConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<DeviceMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<DeviceSearch>>;
};

export type DeviceEdge = {
  __typename?: 'DeviceEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Device>;
};

export type DeviceEventInput = {
  datetime: Scalars['String']['input'];
  eventType: DeviceEventTypeEnum;
  geoLocation?: InputMaybe<GeoLocation>;
  tripIds: Array<Scalars['String']['input']>;
};

export type DeviceEventResponse = {
  __typename?: 'DeviceEventResponse';
  output?: Maybe<Array<UpdateResponse>>;
};

export enum DeviceEventTypeEnum {
  ArrivedAtPickup = 'ARRIVED_AT_PICKUP',
  MarkedDroppedOff = 'MARKED_DROPPED_OFF',
  MarkedPickedUp = 'MARKED_PICKED_UP'
}

export type DeviceMapStyle = {
  __typename?: 'DeviceMapStyle';
  elementType: Scalars['String']['output'];
  featureType: Scalars['String']['output'];
  stylers: Array<Styler>;
};

export type DeviceMaps = {
  __typename?: 'DeviceMaps';
  style: Array<DeviceMapStyle>;
};

export type DeviceMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  appName?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeenAt?: InputMaybe<Scalars['Boolean']['input']>;
  platform?: InputMaybe<Scalars['Boolean']['input']>;
  token?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeviceMutationResponse = {
  __typename?: 'DeviceMutationResponse';
  abortTrip: AbortTripResponse;
  approveUserAvailabilityDriverApp: ApproveUserAvailabilityDriverAppResponse;
  assignDriver?: Maybe<AssignDriverResponse>;
  assignVehicle?: Maybe<AssignVehicleResponse>;
  assignVehicleToDriver?: Maybe<AssignVehicleToDriverResponse>;
  cancelTrip: CancelTripResponse;
  createCrewTrip: CreateCrewTripResponse;
  createRatingBulk: CreateRatingBulkResponse;
  createRatingRemarkBulk: CreateRatingRemarkBulkResponse;
  createUserAvailabilityDriverApp: CreateUserAvailabilityDriverAppResponse;
  crewCheckin?: Maybe<CrewCheckinResponse>;
  crewNoShow: CrewNoShowResponse;
  deleteUserAvailabilityAndUnassignDriverApp: DeleteUserAvailabilityDriverAppResponse;
  deleteUserAvailabilityDriverApp: DeleteUserAvailabilityDriverAppResponse;
  deleteUserAvailabilitySeriesAndUnassignDriverApp: DeleteUserAvailabilitySeriesDriverAppResponse;
  deleteUserAvailabilitySeriesDriverApp: DeleteUserAvailabilitySeriesDriverAppResponse;
  deviceEvent: DeviceEventResponse;
  deviceRegistration: DeviceRegistrationResponse;
  dropoffActual?: Maybe<DropoffActualResponse>;
  dropoffCompleted?: Maybe<DropoffCompletedResponse>;
  dropoffIncomplete?: Maybe<DropoffIncompleteResponse>;
  forgotPasswordCrew: ForgotPasswordCrewResponse;
  forgotPasswordDriver: ForgotPasswordDriverResponse;
  login: LoginResponse;
  pickupActual?: Maybe<PickupActualResponse>;
  pickupCompleted?: Maybe<PickupCompletedResponse>;
  pickupIncomplete?: Maybe<PickupIncompleteResponse>;
  reportProblemCrew?: Maybe<ReportProblemCrewResponse>;
  reportProblemDriver?: Maybe<ReportProblemDriverResponse>;
  stealTrips: StealTripsResponse;
  submitFeedbackDriver?: Maybe<SubmitFeedbackDriverResponse>;
  submitFitForDutyDriver?: Maybe<SubmitFitForDutyDriverResponse>;
  updateUserAvailabilityDriverApp: UpdateUserAvailabilityDriverAppResponse;
  updateUserAvailabilitySeriesDriverApp: UpdateUserAvailabilitySeriesDriverAppResponse;
};


export type DeviceMutationResponseAbortTripArgs = {
  input: AbortTripInput;
};


export type DeviceMutationResponseApproveUserAvailabilityDriverAppArgs = {
  input: ApproveUserAvailabilityDriverAppInput;
};


export type DeviceMutationResponseAssignDriverArgs = {
  input: AssignDriverInput;
};


export type DeviceMutationResponseAssignVehicleArgs = {
  input: AssignVehicleInput;
};


export type DeviceMutationResponseAssignVehicleToDriverArgs = {
  input: AssignVehicleToDriverInput;
};


export type DeviceMutationResponseCancelTripArgs = {
  input: CancelTripInput;
};


export type DeviceMutationResponseCreateCrewTripArgs = {
  input: CreateCrewTripInput;
};


export type DeviceMutationResponseCreateRatingBulkArgs = {
  input: CreateRatingBulkInput;
};


export type DeviceMutationResponseCreateRatingRemarkBulkArgs = {
  input: CreateRatingRemarkBulkInput;
};


export type DeviceMutationResponseCreateUserAvailabilityDriverAppArgs = {
  input: CreateUserAvailabilityDriverAppInput;
};


export type DeviceMutationResponseCrewCheckinArgs = {
  input: CrewCheckinInput;
};


export type DeviceMutationResponseCrewNoShowArgs = {
  input: CrewNoShowInput;
};


export type DeviceMutationResponseDeleteUserAvailabilityAndUnassignDriverAppArgs = {
  input: DeleteUserAvailabilityDriverAppInput;
};


export type DeviceMutationResponseDeleteUserAvailabilityDriverAppArgs = {
  input: DeleteUserAvailabilityDriverAppInput;
};


export type DeviceMutationResponseDeleteUserAvailabilitySeriesAndUnassignDriverAppArgs = {
  input: DeleteUserAvailabilitySeriesDriverAppInput;
};


export type DeviceMutationResponseDeleteUserAvailabilitySeriesDriverAppArgs = {
  input: DeleteUserAvailabilitySeriesDriverAppInput;
};


export type DeviceMutationResponseDeviceEventArgs = {
  input: DeviceEventInput;
};


export type DeviceMutationResponseDeviceRegistrationArgs = {
  input: DeviceRegistrationInput;
};


export type DeviceMutationResponseDropoffActualArgs = {
  input: DropoffActualInput;
};


export type DeviceMutationResponseDropoffCompletedArgs = {
  input: DropoffCompletedInput;
};


export type DeviceMutationResponseDropoffIncompleteArgs = {
  input: DropoffIncompleteInput;
};


export type DeviceMutationResponseForgotPasswordCrewArgs = {
  input: ForgotPasswordCrewInput;
};


export type DeviceMutationResponseForgotPasswordDriverArgs = {
  input: ForgotPasswordDriverInput;
};


export type DeviceMutationResponseLoginArgs = {
  input: LoginInput;
};


export type DeviceMutationResponsePickupActualArgs = {
  input: PickupActualInput;
};


export type DeviceMutationResponsePickupCompletedArgs = {
  input: PickupCompletedInput;
};


export type DeviceMutationResponsePickupIncompleteArgs = {
  input: PickupIncompleteInput;
};


export type DeviceMutationResponseReportProblemCrewArgs = {
  input: ReportProblemCrewInput;
};


export type DeviceMutationResponseReportProblemDriverArgs = {
  input: ReportProblemDriverInput;
};


export type DeviceMutationResponseStealTripsArgs = {
  input: StealTripsInput;
};


export type DeviceMutationResponseSubmitFeedbackDriverArgs = {
  input: SubmitFeedbackDriverInput;
};


export type DeviceMutationResponseSubmitFitForDutyDriverArgs = {
  input: SubmitFitForDutyDriverInput;
};


export type DeviceMutationResponseUpdateUserAvailabilityDriverAppArgs = {
  input: UpdateUserAvailabilityDriverAppInput;
};


export type DeviceMutationResponseUpdateUserAvailabilitySeriesDriverAppArgs = {
  input: UpdateUserAvailabilitySeriesDriverAppInput;
};

export type DeviceNavigation = {
  __typename?: 'DeviceNavigation';
  items: Array<DeviceNavigationItem>;
  type: DeviceNavigationTypeEnum;
};

export type DeviceNavigationItem = {
  __typename?: 'DeviceNavigationItem';
  key: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: DeviceNavigationItemTypeEnum;
};

export enum DeviceNavigationItemTypeEnum {
  Availability = 'AVAILABILITY',
  CallAirline = 'CALL_AIRLINE',
  CompanyFeedbackCrew = 'COMPANY_FEEDBACK_CREW',
  ContactDispatcher = 'CONTACT_DISPATCHER',
  ContactUs = 'CONTACT_US',
  CurrentTrip = 'CURRENT_TRIP',
  EmailManager = 'EMAIL_MANAGER',
  EmailUs = 'EMAIL_US',
  FlightLookup = 'FLIGHT_LOOKUP',
  FlightLookupAhead = 'FLIGHT_LOOKUP_AHEAD',
  Help = 'HELP',
  Home = 'HOME',
  IssueWithApp = 'ISSUE_WITH_APP',
  JobsList = 'JOBS_LIST',
  Leaderboard = 'LEADERBOARD',
  Legal = 'LEGAL',
  Logout = 'LOGOUT',
  LostAndFoundCrew = 'LOST_AND_FOUND_CREW',
  Messages = 'MESSAGES',
  Notifications = 'NOTIFICATIONS',
  PasswordReset = 'PASSWORD_RESET',
  Performance = 'PERFORMANCE',
  Privacy = 'PRIVACY',
  Profile = 'PROFILE',
  ProfileImageEdit = 'PROFILE_IMAGE_EDIT',
  ProfileSettings = 'PROFILE_SETTINGS',
  ReviewsAndRatings = 'REVIEWS_AND_RATINGS',
  SocialFacebook = 'SOCIAL_FACEBOOK',
  SocialInstagram = 'SOCIAL_INSTAGRAM',
  SocialTwitter = 'SOCIAL_TWITTER',
  Stats = 'STATS',
  SubmitFeedback = 'SUBMIT_FEEDBACK',
  SuggestionBoxCrew = 'SUGGESTION_BOX_CREW',
  Trips = 'TRIPS',
  VisitOurWebsite = 'VISIT_OUR_WEBSITE'
}

export enum DeviceNavigationTypeEnum {
  ContactUs = 'CONTACT_US',
  Main = 'MAIN',
  Profile = 'PROFILE',
  UserMenu = 'USER_MENU'
}

export type DeviceQueryResponse = {
  __typename?: 'DeviceQueryResponse';
  airportHasCompanyConnection?: Maybe<AirportHasCompanyConnection>;
  airportWithLocationConnection?: Maybe<AirportWithLocationConnection>;
  companyConnection?: Maybe<CompanyConnection>;
  flightLookupCrewApp?: Maybe<TripConnection>;
  getAvailableUsersForTrip: GetAvailableUsersForTripResponse;
  getConfig: GetDeviceConfigResponse;
  getDrivers: GetDriversResponse;
  getFaq: GetFaqResponse;
  getFilters: GetFiltersResponse;
  getFitForDrivingQuestions: GetFitForDrivingQuestionsResponse;
  getFlightStatus: GetFlightStatusResponse;
  getVehicles: GetVehiclesResponse;
  getVehiclesForTrip: GetVehiclesForTripResponse;
  jobLookupDriverApp?: Maybe<TripConnection>;
  leaderboardDriverApp?: Maybe<LeaderboardDriverAppResponse>;
  myDriverPerformance?: Maybe<DriverPerformance>;
  performanceReport?: Maybe<PerformanceReport>;
  ratingsAndReviewsDriverApp?: Maybe<RatingsAndReviewsDriverAppResponse>;
  searchUserAvailabilityDriverApp?: Maybe<UserConnection>;
  tripConnectionCrewApp?: Maybe<TripConnection>;
  tripConnectionDriverApp?: Maybe<TripConnection>;
  userConnection?: Maybe<UserConnection>;
};


export type DeviceQueryResponseAirportHasCompanyConnectionArgs = {
  input?: InputMaybe<AirportHasCompanyConnectionInput>;
};


export type DeviceQueryResponseAirportWithLocationConnectionArgs = {
  input?: InputMaybe<AirportWithLocationConnectionInput>;
};


export type DeviceQueryResponseCompanyConnectionArgs = {
  input?: InputMaybe<CompanyConnectionInput>;
};


export type DeviceQueryResponseFlightLookupCrewAppArgs = {
  input?: InputMaybe<FlightLookupCrewAppInput>;
};


export type DeviceQueryResponseGetAvailableUsersForTripArgs = {
  input: GetAvailableUsersForTripInput;
};


export type DeviceQueryResponseGetConfigArgs = {
  input: GetDeviceConfigInput;
};


export type DeviceQueryResponseGetFaqArgs = {
  input: GetFaqInput;
};


export type DeviceQueryResponseGetFlightStatusArgs = {
  input: GetFlightStatusInput;
};


export type DeviceQueryResponseGetVehiclesForTripArgs = {
  input: GetVehiclesForTripInput;
};


export type DeviceQueryResponseJobLookupDriverAppArgs = {
  input?: InputMaybe<JobLookupDriverAppInput>;
};


export type DeviceQueryResponseLeaderboardDriverAppArgs = {
  input?: InputMaybe<LeaderboardDriverAppInput>;
};


export type DeviceQueryResponseMyDriverPerformanceArgs = {
  input?: InputMaybe<DriverPerformanceSearchInput>;
};


export type DeviceQueryResponsePerformanceReportArgs = {
  input?: InputMaybe<PerformanceReportInput>;
};


export type DeviceQueryResponseRatingsAndReviewsDriverAppArgs = {
  input?: InputMaybe<RatingsAndReviewsDriverAppInput>;
};


export type DeviceQueryResponseSearchUserAvailabilityDriverAppArgs = {
  input?: InputMaybe<SearchUserAvailabilityDriverAppInput>;
};


export type DeviceQueryResponseTripConnectionCrewAppArgs = {
  input?: InputMaybe<TripConnectionInput>;
};


export type DeviceQueryResponseTripConnectionDriverAppArgs = {
  input?: InputMaybe<TripConnectionInput>;
};


export type DeviceQueryResponseUserConnectionArgs = {
  input?: InputMaybe<UserConnectionInput>;
};

export type DeviceRegistrationInput = {
  token: Scalars['String']['input'];
};

export type DeviceRegistrationResponse = {
  __typename?: 'DeviceRegistrationResponse';
  error?: Maybe<Error>;
  success: Scalars['Boolean']['output'];
};

export type DeviceSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  appName?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  lastSeenAt?: InputMaybe<Array<StringSearch>>;
  platform?: InputMaybe<Array<StringSearch>>;
  token?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  userId?: InputMaybe<Array<StringSearch>>;
};

export type DeviceUserString = {
  __typename?: 'DeviceUserString';
  id: Scalars['String']['output'];
  value: DeviceUserStringItem;
};

export type DeviceUserStringItem = {
  __typename?: 'DeviceUserStringItem';
  longDescription?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Distance = {
  __typename?: 'Distance';
  cachedAt: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  distanceText: Scalars['String']['output'];
  distanceValue: Scalars['Int']['output'];
  durationInTrafficText: Scalars['String']['output'];
  durationInTrafficValue: Scalars['Int']['output'];
  durationText: Scalars['String']['output'];
  durationValue: Scalars['Int']['output'];
  fromLocationId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  toLocationId: Scalars['String']['output'];
};

export type DistanceConnection = {
  __typename?: 'DistanceConnection';
  edges?: Maybe<Array<Maybe<DistanceEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DistanceConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<DistanceMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<DistanceSearch>>;
};

export type DistanceEdge = {
  __typename?: 'DistanceEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Distance>;
};

export type DistanceMaskInput = {
  cachedAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  distanceText?: InputMaybe<Scalars['Boolean']['input']>;
  distanceValue?: InputMaybe<Scalars['Boolean']['input']>;
  durationInTrafficText?: InputMaybe<Scalars['Boolean']['input']>;
  durationInTrafficValue?: InputMaybe<Scalars['Boolean']['input']>;
  durationText?: InputMaybe<Scalars['Boolean']['input']>;
  durationValue?: InputMaybe<Scalars['Boolean']['input']>;
  fromLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  toLocationId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DistanceSearch = {
  cachedAt?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  distanceText?: InputMaybe<Array<StringSearch>>;
  distanceValue?: InputMaybe<Array<IntSearch>>;
  durationInTrafficText?: InputMaybe<Array<StringSearch>>;
  durationInTrafficValue?: InputMaybe<Array<IntSearch>>;
  durationText?: InputMaybe<Array<StringSearch>>;
  durationValue?: InputMaybe<Array<IntSearch>>;
  fromLocationId?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  toLocationId?: InputMaybe<Array<StringSearch>>;
};

export type DownloadImportResponse = {
  __typename?: 'DownloadImportResponse';
  error?: Maybe<Scalars['String']['output']>;
  import?: Maybe<Import>;
};

export type DownloadImportUrlResponse = {
  __typename?: 'DownloadImportUrlResponse';
  contentType?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Error>;
  id: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type DownloadInvoiceResponse = {
  __typename?: 'DownloadInvoiceResponse';
  error?: Maybe<Error>;
  id: Scalars['Int']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type DriveTimeReport = {
  __typename?: 'DriveTimeReport';
  tables?: Maybe<Array<Maybe<DriveTimeReportTable>>>;
};

export type DriveTimeReportBucket = {
  hour: Scalars['Int']['input'];
  minute: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type DriveTimeReportChild = {
  __typename?: 'DriveTimeReportChild';
  averageDifference: Scalars['Float']['output'];
  fri: Scalars['Int']['output'];
  holidayAvgDriveMinutes: Scalars['Float']['output'];
  mon: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sat: Scalars['Int']['output'];
  sun: Scalars['Int']['output'];
  thr: Scalars['Int']['output'];
  totalTrips: Scalars['Int']['output'];
  tue: Scalars['Int']['output'];
  wed: Scalars['Int']['output'];
  weekdayAvgDriveMinutes: Scalars['Float']['output'];
  weekendAvgDriveMinutes: Scalars['Float']['output'];
};

export enum DriveTimeReportFormatEnum {
  Airline = 'AIRLINE',
  Client = 'CLIENT'
}

export type DriveTimeReportRow = {
  __typename?: 'DriveTimeReportRow';
  averageDifference: Scalars['Float']['output'];
  children: Array<Maybe<DriveTimeReportChild>>;
  dropoff: Scalars['String']['output'];
  fri: Scalars['Int']['output'];
  holidayAvgDriveMinutes: Scalars['Float']['output'];
  mon: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  pickup: Scalars['String']['output'];
  sat: Scalars['Int']['output'];
  sun: Scalars['Int']['output'];
  thr: Scalars['Int']['output'];
  totalTrips: Scalars['Int']['output'];
  tue: Scalars['Int']['output'];
  wed: Scalars['Int']['output'];
  weekdayAvgDriveMinutes: Scalars['Float']['output'];
  weekendAvgDriveMinutes: Scalars['Float']['output'];
};

export type DriveTimeReportTable = {
  __typename?: 'DriveTimeReportTable';
  averageDifference: Scalars['Float']['output'];
  holidayAvgDriveMinutes: Scalars['Float']['output'];
  holidayTrips: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  overallDriveMinutes: Scalars['Float']['output'];
  rows: Array<Maybe<DriveTimeReportRow>>;
  totalTrips: Scalars['Int']['output'];
  weekdayAvgDriveMinutes: Scalars['Float']['output'];
  weekdayTrips: Scalars['Int']['output'];
  weekendAvgDriveMinutes: Scalars['Float']['output'];
  weekendTrips: Scalars['Int']['output'];
};

export type DriverPerformance = {
  __typename?: 'DriverPerformance';
  error?: Maybe<Error>;
  inbound?: Maybe<TripPerformance>;
  outbound?: Maybe<TripPerformance>;
  overall?: Maybe<OverallPerformance>;
};

export type DriverPerformanceSearchInput = {
  endDateTime?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['String']['input']>;
  tripType?: InputMaybe<TripKindEnumSearch>;
};

export type DropoffActualInput = {
  doActualDatetime: Scalars['String']['input'];
  tripId: Array<Scalars['String']['input']>;
};

export type DropoffActualResponse = {
  __typename?: 'DropoffActualResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type DropoffCompletedInput = {
  doCompletedDatetime: Scalars['String']['input'];
  tripId: Array<Scalars['String']['input']>;
};

export type DropoffCompletedResponse = {
  __typename?: 'DropoffCompletedResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type DropoffIncompleteInput = {
  tripId: Array<Scalars['String']['input']>;
};

export type DropoffIncompleteResponse = {
  __typename?: 'DropoffIncompleteResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type Email = {
  __typename?: 'Email';
  address: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  type: EmailTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type EmailConnection = {
  __typename?: 'EmailConnection';
  edges?: Maybe<Array<Maybe<EmailEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type EmailConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<EmailMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<EmailSearch>>;
};

export type EmailEdge = {
  __typename?: 'EmailEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Email>;
};

export type EmailMaskInput = {
  address?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmailSearch = {
  address?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<EmailTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum EmailTypeEnum {
  Admin = 'ADMIN',
  Billing = 'BILLING',
  Confirmation = 'CONFIRMATION'
}

export type EmailTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<EmailTypeEnum>;
};

export type EnableFeature = {
  __typename?: 'EnableFeature';
  feature: Scalars['String']['output'];
  options?: Maybe<Array<Scalars['String']['output']>>;
};

export type Enabled = {
  __typename?: 'Enabled';
  enabled: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type EquivalentTrip = {
  __typename?: 'EquivalentTrip';
  companyId: Scalars['String']['output'];
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endLocationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  startLocationId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type EquivalentTripConnection = {
  __typename?: 'EquivalentTripConnection';
  edges?: Maybe<Array<Maybe<EquivalentTripEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type EquivalentTripConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<EquivalentTripMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<EquivalentTripSearch>>;
};

export type EquivalentTripEdge = {
  __typename?: 'EquivalentTripEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<EquivalentTrip>;
};

export type EquivalentTripMaskInput = {
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  count?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  endLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  startLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EquivalentTripSearch = {
  companyId?: InputMaybe<Array<StringSearch>>;
  count?: InputMaybe<Array<IntSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  endLocationId?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  startLocationId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['Int']['output'];
  debug: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type FaqResponse = {
  __typename?: 'FaqResponse';
  items: Array<Description>;
  lastUpdated: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Fcr = Node & {
  __typename?: 'Fcr';
  cause?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  fcrType?: Maybe<FcrType>;
  fcrTypeId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tripId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type FcrConnection = {
  __typename?: 'FcrConnection';
  edges?: Maybe<Array<Maybe<FcrEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FcrConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<FcrMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<FcrSearch>>;
};

export type FcrEdge = {
  __typename?: 'FcrEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Fcr>;
};

export type FcrMaskInput = {
  cause?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['Boolean']['input']>;
  fcrTypeId?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FcrSearch = {
  cause?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  description?: InputMaybe<Array<StringSearch>>;
  fcrTypeId?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type FcrType = {
  __typename?: 'FcrType';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type FcrTypeConnection = {
  __typename?: 'FcrTypeConnection';
  edges?: Maybe<Array<Maybe<FcrTypeEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FcrTypeConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<FcrTypeMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<FcrTypeSearch>>;
};

export type FcrTypeEdge = {
  __typename?: 'FcrTypeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<FcrType>;
};

export type FcrTypeMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FcrTypeSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type FileForUploadInput = {
  filename: Scalars['String']['input'];
  importNameId: Scalars['String']['input'];
  md5: Scalars['String']['input'];
};

export type FilterObject = {
  __typename?: 'FilterObject';
  key: Scalars['String']['output'];
  options?: Maybe<Array<Scalars['String']['output']>>;
  value?: Maybe<Array<Scalars['String']['output']>>;
};

export type FilterOption = {
  __typename?: 'FilterOption';
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type Filters = Node & {
  __typename?: 'Filters';
  airline: Array<FilterOption>;
  airportGroupPermissions: Array<FilterOption>;
  airportGroups: Array<FilterOption>;
  airports: Array<FilterOption>;
  availabilityTypes?: Maybe<Array<FilterOption>>;
  clients: Array<FilterOption>;
  companies: Array<FilterOption>;
  completionReasons: Array<AcceptInputOption>;
  complimentOptions: Array<AcceptInputOption>;
  exchangeLocations?: Maybe<Array<AcceptInputOption>>;
  fcrTypes?: Maybe<Array<AcceptInputOption>>;
  feedbackOptions: Array<AcceptInputOption>;
  flag: Array<FilterOption>;
  groups: Array<FilterOption>;
  id: Scalars['ID']['output'];
  importContractProvider: Array<FilterOption>;
  importProvider: Array<FilterOption>;
  imports?: Maybe<Array<FilterOption>>;
  invoiceTerms?: Maybe<Array<FilterOption>>;
  jobLookupFormat?: Maybe<Array<AcceptInputOption>>;
  kind: Array<FilterOption>;
  licenseType: Array<FilterOption>;
  lineItemType: Array<FilterOption>;
  locationType?: Maybe<Array<AcceptInputOption>>;
  locations: Array<FilterOption>;
  messageDriverOptions: Array<AcceptInputOption>;
  permissions: Array<FilterOption>;
  rateValue?: Maybe<RateValueResponse>;
  ratingType: Array<AcceptInputOption>;
  roles: Array<FilterOption>;
  servicerIataAirlineCode: Array<FilterOption>;
  states: Array<FilterOption>;
  status: Array<FilterOption>;
  statusOptions?: Maybe<Array<AcceptInputOption>>;
  summaryReportSnapshots?: Maybe<Array<FilterOption>>;
  transportCompany: Array<FilterOption>;
  type: Array<FilterOption>;
  vehicleMake?: Maybe<Array<AcceptInputOption>>;
  vehicleModel?: Maybe<VehicleModelResponse>;
  vehicleTracker?: Maybe<Array<AcceptInputOption>>;
};


export type FiltersRateValueArgs = {
  input: RateValueInput;
};


export type FiltersVehicleModelArgs = {
  input?: InputMaybe<VehicleModelInput>;
};

export type FitForDrivingQuestion = {
  __typename?: 'FitForDrivingQuestion';
  id: Scalars['Int']['output'];
  question: Scalars['String']['output'];
};

export type FitForDutyAnswer = {
  answer: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  question: Scalars['String']['input'];
};

export type Flag = {
  __typename?: 'Flag';
  active: Scalars['Int']['output'];
  color: Scalars['String']['output'];
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  global: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type FlagConnection = {
  __typename?: 'FlagConnection';
  edges?: Maybe<Array<Maybe<FlagEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FlagConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<FlagMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<FlagSearch>>;
};

export type FlagEdge = {
  __typename?: 'FlagEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Flag>;
};

export type FlagHasTrip = {
  __typename?: 'FlagHasTrip';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  flagId: Scalars['String']['output'];
  tripId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type FlagHasTripConnection = {
  __typename?: 'FlagHasTripConnection';
  edges?: Maybe<Array<Maybe<FlagHasTripEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FlagHasTripConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<FlagHasTripMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<FlagHasTripSearch>>;
};

export type FlagHasTripEdge = {
  __typename?: 'FlagHasTripEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<FlagHasTrip>;
};

export type FlagHasTripMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  flagId?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FlagHasTripSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  flagId?: InputMaybe<Array<StringSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type FlagMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  color?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FlagSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  color?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  global?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type FlagWithTrip = {
  __typename?: 'FlagWithTrip';
  active: Scalars['Int']['output'];
  color: Scalars['String']['output'];
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  global: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tripId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type FlagWithTripConnection = {
  __typename?: 'FlagWithTripConnection';
  edges?: Maybe<Array<Maybe<FlagWithTripEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FlagWithTripConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<FlagWithTripMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<FlagWithTripSearch>>;
};

export type FlagWithTripEdge = {
  __typename?: 'FlagWithTripEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<FlagWithTrip>;
};

export type FlagWithTripMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  color?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FlagWithTripSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  color?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  global?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type FlightLookupCrewAppInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  airportCode?: InputMaybe<Scalars['String']['input']>;
  completionStatus?: InputMaybe<CompletionStatusEnum>;
  first?: InputMaybe<Scalars['Int']['input']>;
  flightLookup?: InputMaybe<Scalars['String']['input']>;
  scheduledEndTime?: InputMaybe<Scalars['String']['input']>;
  scheduledStartTime?: InputMaybe<Scalars['String']['input']>;
  servicerIataAirlineCode?: InputMaybe<Scalars['String']['input']>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum FlightTypeEnum {
  Domestic = 'DOMESTIC',
  International = 'INTERNATIONAL'
}

export type FloatMetric = {
  __typename?: 'FloatMetric';
  count?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type FloatPipeline = {
  __typename?: 'FloatPipeline';
  bucketKey?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type FloatSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<Scalars['Float']['input']>;
};

export type ForgotPasswordCrewInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ForgotPasswordCrewResponse = {
  __typename?: 'ForgotPasswordCrewResponse';
  error?: Maybe<Error>;
  success: Scalars['Boolean']['output'];
};

export type ForgotPasswordDriverInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ForgotPasswordDriverResponse = {
  __typename?: 'ForgotPasswordDriverResponse';
  error?: Maybe<Error>;
  success: Scalars['Boolean']['output'];
};

export type ForgotPasswordInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  error?: Maybe<Error>;
  success: Scalars['Boolean']['output'];
};

export type GeneratePunchHistoryUploadUrlInput = {
  filename: Scalars['String']['input'];
  md5: Scalars['String']['input'];
};

export type GeneratePunchHistoryUploadUrlResponse = {
  __typename?: 'GeneratePunchHistoryUploadUrlResponse';
  contentType: Scalars['String']['output'];
  error?: Maybe<Error>;
  md5: Scalars['String']['output'];
  presignedUploadUrl: Scalars['String']['output'];
};

export type GeneratePunchHistoryUploadUrlsInput = {
  input: Array<GeneratePunchHistoryUploadUrlInput>;
};

export type GeneratePunchHistoryUploadUrlsResponse = {
  __typename?: 'GeneratePunchHistoryUploadUrlsResponse';
  output: Array<GeneratePunchHistoryUploadUrlResponse>;
};

export type GenerateUploadProfileImageUrlInput = {
  filename: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type GenerateUploadProfileImageUrlResponse = {
  __typename?: 'GenerateUploadProfileImageUrlResponse';
  contentType?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Error>;
  url?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type GeoLocation = {
  Latitude: Scalars['String']['input'];
  Longitude: Scalars['String']['input'];
};

export type GetAirportsResponse = {
  __typename?: 'GetAirportsResponse';
  airports: Array<Airport>;
};

export type GetAvailableUserForTripResponse = {
  __typename?: 'GetAvailableUserForTripResponse';
  employeeId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  vehicle?: Maybe<Vehicle>;
  vehicleId?: Maybe<Scalars['String']['output']>;
};

export type GetAvailableUsersForTripInput = {
  actual?: InputMaybe<Scalars['String']['input']>;
  airportCode: Scalars['String']['input'];
  scheduled: Scalars['String']['input'];
};

export type GetAvailableUsersForTripResponse = {
  __typename?: 'GetAvailableUsersForTripResponse';
  output: Array<GetAvailableUserForTripResponse>;
};

export type GetCorrectRatesForTripsInput = {
  tripIds: Array<Scalars['String']['input']>;
};

export type GetCorrectRatesForTripsResponse = {
  __typename?: 'GetCorrectRatesForTripsResponse';
  output?: Maybe<Array<TripWithRateChange>>;
};

export type GetDeviceConfigInput = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  device: Device;
};

export type GetDeviceConfigResponse = {
  __typename?: 'GetDeviceConfigResponse';
  allowCheckinWithinMinutes: Scalars['Int']['output'];
  auth: Auth;
  availabilityTypes?: Maybe<Array<Maybe<AvailabilityType>>>;
  chatHistoryPageSize?: Maybe<Scalars['Int']['output']>;
  chatRetryBackoffSeconds?: Maybe<Scalars['Int']['output']>;
  chatRetryLimit?: Maybe<Scalars['Int']['output']>;
  clientApiEndpoint: Scalars['String']['output'];
  driverOpenChatNextTripCount?: Maybe<Scalars['Int']['output']>;
  driverOpenChatPreviousTripCount?: Maybe<Scalars['Int']['output']>;
  featureGates: Array<Enabled>;
  jobListPageSize: Scalars['Int']['output'];
  legalDocumentEndpoint: Scalars['String']['output'];
  maps: DeviceMaps;
  maxImageSizeMb: Scalars['Int']['output'];
  navigation: Array<DeviceNavigation>;
  notificationsNamespace?: Maybe<NotificationsNamespaceResponse>;
  providerHasFeature: Array<ProviderHasFeature>;
  readyForPickupNotification: Scalars['String']['output'];
  requireUpdate: Scalars['Boolean']['output'];
  sendChatMessageOnCheckinForOutbounds: Scalars['Boolean']['output'];
  userStrings?: Maybe<Array<Maybe<DeviceUserString>>>;
};

export type GetDriveTimeReportAsCsvInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  airports?: InputMaybe<Array<Scalars['String']['input']>>;
  buckets?: InputMaybe<Array<DriveTimeReportBucket>>;
  dropoffLocationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  endDatetime: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  format: DriveTimeReportFormatEnum;
  iataAirlineCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  lowerThreshold?: InputMaybe<Scalars['Int']['input']>;
  payerProviderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  pickupLocationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startDatetime: Scalars['String']['input'];
  upperThreshold?: InputMaybe<Scalars['Int']['input']>;
};

export type GetDriveTimeReportAsCsvResponse = {
  __typename?: 'GetDriveTimeReportAsCSVResponse';
  output: Scalars['String']['output'];
};

export type GetDriveTimeReportInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  airports?: InputMaybe<Array<Scalars['String']['input']>>;
  buckets?: InputMaybe<Array<DriveTimeReportBucket>>;
  dropoffLocationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  endDatetime: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  format: DriveTimeReportFormatEnum;
  iataAirlineCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  lowerThreshold?: InputMaybe<Scalars['Int']['input']>;
  payerProviderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  pickupLocationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startDatetime: Scalars['String']['input'];
  upperThreshold?: InputMaybe<Scalars['Int']['input']>;
};

export type GetDriveTimeReportResponse = {
  __typename?: 'GetDriveTimeReportResponse';
  output?: Maybe<DriveTimeReport>;
};

export type GetDriversResponse = {
  __typename?: 'GetDriversResponse';
  drivers?: Maybe<Array<Maybe<User>>>;
  error?: Maybe<Error>;
};

export type GetFaqInput = {
  device: Device;
};

export type GetFaqResponse = {
  __typename?: 'GetFaqResponse';
  faqs: Array<FaqResponse>;
};

export type GetFiltersResponse = {
  __typename?: 'GetFiltersResponse';
  filters?: Maybe<Filters>;
};

export type GetFitForDrivingQuestionsResponse = {
  __typename?: 'GetFitForDrivingQuestionsResponse';
  output: Array<FitForDrivingQuestion>;
};

export type GetFlightStatusInput = {
  airportCode?: InputMaybe<Scalars['String']['input']>;
  carrier: Scalars['String']['input'];
  flightNumber: Scalars['Int']['input'];
  kind: KindEnum;
  scheduledUtc?: InputMaybe<Scalars['String']['input']>;
};

export type GetFlightStatusResponse = {
  __typename?: 'GetFlightStatusResponse';
  airplaneType?: Maybe<Scalars['String']['output']>;
  airportArrivalGate?: Maybe<Scalars['String']['output']>;
  airportArrivalTerminal?: Maybe<Scalars['String']['output']>;
  airportDepartureGate?: Maybe<Scalars['String']['output']>;
  airportDepartureTerminal?: Maybe<Scalars['String']['output']>;
  arrivalAirportCode?: Maybe<Scalars['String']['output']>;
  carrierName?: Maybe<Scalars['String']['output']>;
  departureAirportCode?: Maybe<Scalars['String']['output']>;
  flightDuration?: Maybe<Scalars['String']['output']>;
  flightLatestArrivalLocaltime?: Maybe<Scalars['String']['output']>;
  flightLatestDepartureLocaltime?: Maybe<Scalars['String']['output']>;
  flightNumber?: Maybe<Scalars['String']['output']>;
  flightStatus?: Maybe<Scalars['String']['output']>;
  originType?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  responseType?: Maybe<Scalars['String']['output']>;
  statusLabel?: Maybe<Scalars['String']['output']>;
  statusTime?: Maybe<Scalars['String']['output']>;
};

export type GetHeadersInput = {
  tableNames: Array<Scalars['String']['input']>;
};

export type GetHeadersResponse = {
  __typename?: 'GetHeadersResponse';
  headers: Array<HeaderResponse>;
};

export type GetInvoiceInput = {
  invoiceId: Scalars['Int']['input'];
};

export type GetInvoiceResponse = {
  __typename?: 'GetInvoiceResponse';
  error?: Maybe<Error>;
  invoiceTerms?: Maybe<Scalars['String']['output']>;
  output?: Maybe<InvoiceResponse>;
  subtotals?: Maybe<Array<Maybe<InvoiceRateSubtotal>>>;
};

export type GetMetricInput = {
  endDatetime: Scalars['String']['input'];
  interval: Scalars['String']['input'];
  keywordField: MetricKeywordFieldEnum;
  markets: Array<Scalars['String']['input']>;
  metrics: Array<Metric>;
  pipelines: Array<Pipeline>;
  providers: Array<Scalars['String']['input']>;
  sliceCount: Scalars['Int']['input'];
  startDatetime: Scalars['String']['input'];
};

export type GetMetricResponse = {
  __typename?: 'GetMetricResponse';
  history?: Maybe<Array<Maybe<HistoryMetric>>>;
  pipelines?: Maybe<Array<Maybe<FloatPipeline>>>;
};

export type GetNormalizedAddressInput = {
  address: Scalars['String']['input'];
};

export type GetNormalizedAddressResponse = {
  __typename?: 'GetNormalizedAddressResponse';
  building?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Error>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  geoLocationLat?: Maybe<Scalars['Float']['output']>;
  geoLocationLng?: Maybe<Scalars['Float']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stateAbbrev?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type GetOnTimePerformanceReportAsCsvInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  airports?: InputMaybe<Array<Scalars['String']['input']>>;
  companyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  endDatetime: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  format: OnTimePerformanceReportFormatEnum;
  iataAirlineCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  payerProviderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startDatetime: Scalars['String']['input'];
};

export type GetOnTimePerformanceReportAsCsvResponse = {
  __typename?: 'GetOnTimePerformanceReportAsCSVResponse';
  output: Scalars['String']['output'];
};

export type GetOnTimePerformanceReportInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  airports?: InputMaybe<Array<Scalars['String']['input']>>;
  companyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  endDatetime: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  format: OnTimePerformanceReportFormatEnum;
  iataAirlineCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  payerProviderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startDatetime: Scalars['String']['input'];
};

export type GetOnTimePerformanceReportResponse = {
  __typename?: 'GetOnTimePerformanceReportResponse';
  error?: Maybe<Error>;
  output?: Maybe<OnTimePerformanceReportTables>;
};

export type GetOverrideImportOptionsInput = {
  imports: Array<Scalars['String']['input']>;
};

export type GetOverrideImportOptionsResponse = {
  __typename?: 'GetOverrideImportOptionsResponse';
  imports: Array<OverrideOptionsResponse>;
};

export type GetPortalConfigInput = {
  device: Device;
};

export type GetPortalConfigResponse = {
  __typename?: 'GetPortalConfigResponse';
  auth: PortalAuth;
  driverRoleId: Scalars['String']['output'];
  genericLineItemTypeId: Scalars['String']['output'];
  googleMapsApiKey: Scalars['String']['output'];
  highlightApiKey: Scalars['String']['output'];
  indexDBVersion: Scalars['Int']['output'];
  requestAvailabilityIntervalSeconds: Scalars['Int']['output'];
  supportedManifestFileTypes?: Maybe<Array<Scalars['String']['output']>>;
  upsProviderId: Scalars['String']['output'];
  userConfig?: Maybe<UserConfig>;
};

export type GetReportAsCsvResponse = {
  __typename?: 'GetReportAsCsvResponse';
  error?: Maybe<Scalars['String']['output']>;
  output: Scalars['String']['output'];
};

export type GetReportResponse = {
  __typename?: 'GetReportResponse';
  error?: Maybe<Scalars['String']['output']>;
  output: ReportData;
};

export type GetSummaryReportInput = {
  airport?: InputMaybe<Array<Scalars['String']['input']>>;
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  currency?: InputMaybe<Array<RateCurrencyEnum>>;
  endDatetime: Scalars['String']['input'];
  format: SummaryReportFormatEnum;
  providerId?: InputMaybe<Array<Scalars['String']['input']>>;
  startDatetime: Scalars['String']['input'];
  status?: InputMaybe<Array<TripStatusEnum>>;
  type?: InputMaybe<Array<TripTypeEnum>>;
};

export type GetSummaryReportResponse = {
  __typename?: 'GetSummaryReportResponse';
  error?: Maybe<Error>;
  summary?: Maybe<Summary>;
  tables?: Maybe<Array<Maybe<Summary>>>;
};

export type GetTripsWithWrongContractedRateInput = {
  airportCode?: InputMaybe<Array<Scalars['String']['input']>>;
  payerProviderId?: InputMaybe<Array<Scalars['String']['input']>>;
  scheduledEndDatetime: Scalars['String']['input'];
  scheduledStartDatetime: Scalars['String']['input'];
};

export type GetTripsWithWrongContractedRateResponse = {
  __typename?: 'GetTripsWithWrongContractedRateResponse';
  output?: Maybe<Array<TripWithRateChange>>;
};

export type GetVehiclesForTripInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  airportCode: Array<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  scheduled: Array<Scalars['String']['input']>;
  tripId: Array<Scalars['String']['input']>;
};

export type GetVehiclesForTripResponse = {
  __typename?: 'GetVehiclesForTripResponse';
  error?: Maybe<Error>;
  vehicles?: Maybe<Array<Maybe<Vehicle>>>;
};

export type GetVehiclesResponse = {
  __typename?: 'GetVehiclesResponse';
  error?: Maybe<Error>;
  vehicles?: Maybe<Array<Maybe<Vehicle>>>;
};

export type Group = {
  __typename?: 'Group';
  active: Scalars['Int']['output'];
  companyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<Permission>>;
  type: GroupTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<User>>;
};

export type GroupConnection = {
  __typename?: 'GroupConnection';
  edges?: Maybe<Array<Maybe<GroupEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GroupConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<GroupMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<GroupSearch>>;
};

export type GroupEdge = {
  __typename?: 'GroupEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Group>;
};

export type GroupHasPermission = {
  __typename?: 'GroupHasPermission';
  groupId: Scalars['String']['output'];
  permissionId: Scalars['String']['output'];
};

export type GroupHasPermissionConnection = {
  __typename?: 'GroupHasPermissionConnection';
  edges?: Maybe<Array<Maybe<GroupHasPermissionEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GroupHasPermissionConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<GroupHasPermissionMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<GroupHasPermissionSearch>>;
};

export type GroupHasPermissionEdge = {
  __typename?: 'GroupHasPermissionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<GroupHasPermission>;
};

export type GroupHasPermissionMaskInput = {
  groupId?: InputMaybe<Scalars['Boolean']['input']>;
  permissionId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupHasPermissionSearch = {
  groupId?: InputMaybe<Array<StringSearch>>;
  permissionId?: InputMaybe<Array<StringSearch>>;
};

export type GroupHasUser = {
  __typename?: 'GroupHasUser';
  groupId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type GroupHasUserConnection = {
  __typename?: 'GroupHasUserConnection';
  edges?: Maybe<Array<Maybe<GroupHasUserEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GroupHasUserConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<GroupHasUserMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<GroupHasUserSearch>>;
};

export type GroupHasUserEdge = {
  __typename?: 'GroupHasUserEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<GroupHasUser>;
};

export type GroupHasUserMaskInput = {
  groupId?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupHasUserSearch = {
  groupId?: InputMaybe<Array<StringSearch>>;
  userId?: InputMaybe<Array<StringSearch>>;
};

export type GroupHasUserWithPermissions = {
  __typename?: 'GroupHasUserWithPermissions';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayGroup: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  effect: GroupHasUserWithPermissionsEffectEnum;
  entity: Scalars['String']['output'];
  groupId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  resource: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  verb: Scalars['String']['output'];
};

export type GroupHasUserWithPermissionsConnection = {
  __typename?: 'GroupHasUserWithPermissionsConnection';
  edges?: Maybe<Array<Maybe<GroupHasUserWithPermissionsEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GroupHasUserWithPermissionsConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<GroupHasUserWithPermissionsMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<GroupHasUserWithPermissionsSearch>>;
};

export type GroupHasUserWithPermissionsEdge = {
  __typename?: 'GroupHasUserWithPermissionsEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<GroupHasUserWithPermissions>;
};

export enum GroupHasUserWithPermissionsEffectEnum {
  Allow = 'ALLOW',
  Deny = 'DENY'
}

export type GroupHasUserWithPermissionsEffectEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<GroupHasUserWithPermissionsEffectEnum>;
};

export type GroupHasUserWithPermissionsMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['Boolean']['input']>;
  displayGroup?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['Boolean']['input']>;
  effect?: InputMaybe<Scalars['Boolean']['input']>;
  entity?: InputMaybe<Scalars['Boolean']['input']>;
  groupId?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  resource?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Boolean']['input']>;
  verb?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupHasUserWithPermissionsSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  description?: InputMaybe<Array<StringSearch>>;
  displayGroup?: InputMaybe<Array<StringSearch>>;
  displayName?: InputMaybe<Array<StringSearch>>;
  effect?: InputMaybe<Array<GroupHasUserWithPermissionsEffectEnumSearch>>;
  entity?: InputMaybe<Array<StringSearch>>;
  groupId?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  resource?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  userId?: InputMaybe<Array<StringSearch>>;
  verb?: InputMaybe<Array<StringSearch>>;
};

export type GroupMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<GroupTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum GroupTypeEnum {
  Airline = 'AIRLINE',
  Hotel = 'HOTEL',
  Transportation = 'TRANSPORTATION'
}

export type GroupTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<GroupTypeEnum>;
};

export type Header = {
  __typename?: 'Header';
  alt: Scalars['Boolean']['output'];
  center: Scalars['Boolean']['output'];
  edit: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  left: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  omit: Scalars['Boolean']['output'];
  right: Scalars['Boolean']['output'];
  selector: Scalars['String']['output'];
  sortable: Scalars['Boolean']['output'];
  wrap: Scalars['Boolean']['output'];
};

export type HeaderResponse = {
  __typename?: 'HeaderResponse';
  columns: Array<Header>;
  dataPath: Scalars['String']['output'];
  error?: Maybe<Scalars['String']['output']>;
  tableName: Scalars['String']['output'];
};

export type HistoryMetric = {
  __typename?: 'HistoryMetric';
  buckets?: Maybe<Array<Maybe<FloatMetric>>>;
  count?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  pipelines?: Maybe<Array<Maybe<FloatPipeline>>>;
};

export type Image = {
  __typename?: 'Image';
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  md5: Scalars['String']['output'];
  path: Scalars['String']['output'];
  pickupPointId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  uploadUrl?: Maybe<Scalars['String']['output']>;
};

export type ImageConnection = {
  __typename?: 'ImageConnection';
  edges?: Maybe<Array<Maybe<ImageEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ImageConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<ImageMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<ImageSearch>>;
};

export type ImageEdge = {
  __typename?: 'ImageEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Image>;
};

export type ImageMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  filename?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  md5?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['Boolean']['input']>;
  pickupPointId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ImageSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  filename?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  md5?: InputMaybe<Array<StringSearch>>;
  path?: InputMaybe<Array<StringSearch>>;
  pickupPointId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type Import = {
  __typename?: 'Import';
  airlineIcaoCode: Scalars['String']['output'];
  archivedAt?: Maybe<Scalars['String']['output']>;
  cancelled?: Maybe<Scalars['Int']['output']>;
  companyId: Scalars['String']['output'];
  contentType?: Maybe<Scalars['String']['output']>;
  createTripTolerance?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<ImportEmail>;
  emailId?: Maybe<Scalars['String']['output']>;
  excluded?: Maybe<Scalars['Int']['output']>;
  fileLocation?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  importedBy?: Maybe<Scalars['String']['output']>;
  ingestCompleted?: Maybe<Scalars['String']['output']>;
  ingestStarted?: Maybe<Scalars['String']['output']>;
  md5: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  noticeDatetime?: Maybe<Scalars['String']['output']>;
  potentialTrips?: Maybe<Array<PotentialTrip>>;
  previewedAt?: Maybe<Scalars['String']['output']>;
  previousImportId?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Provider>;
  providerId: Scalars['String']['output'];
  rolledBackAt?: Maybe<Scalars['String']['output']>;
  rolledBackBy?: Maybe<Scalars['String']['output']>;
  rolledBackUser?: Maybe<User>;
  status: ImportStatusEnum;
  statusDisplayName?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  type: ImportTypeEnum;
  unchanged?: Maybe<Scalars['Int']['output']>;
  updated?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};

export type ImportConnection = {
  __typename?: 'ImportConnection';
  edges?: Maybe<Array<Maybe<ImportEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ImportConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<ImportMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<ImportSearch>>;
};

export type ImportEdge = {
  __typename?: 'ImportEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Import>;
};

export type ImportEmail = {
  __typename?: 'ImportEmail';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  fileLocation?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  md5: Scalars['String']['output'];
  originalFileLocation: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ImportMaskInput = {
  airlineIcaoCode?: InputMaybe<Scalars['Boolean']['input']>;
  archivedAt?: InputMaybe<Scalars['Boolean']['input']>;
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createTripTolerance?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  emailId?: InputMaybe<Scalars['Boolean']['input']>;
  excluded?: InputMaybe<Scalars['Boolean']['input']>;
  fileLocation?: InputMaybe<Scalars['Boolean']['input']>;
  filename?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  ingestCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  ingestStarted?: InputMaybe<Scalars['Boolean']['input']>;
  md5?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  noticeDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  previewedAt?: InputMaybe<Scalars['Boolean']['input']>;
  previousImportId?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  rolledBackAt?: InputMaybe<Scalars['Boolean']['input']>;
  rolledBackBy?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  total?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  unchanged?: InputMaybe<Scalars['Boolean']['input']>;
  updated?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  version?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ImportResponse = {
  __typename?: 'ImportResponse';
  error?: Maybe<Error>;
  import?: Maybe<Import>;
};

export type ImportSearch = {
  airlineIcaoCode?: InputMaybe<Array<StringSearch>>;
  archivedAt?: InputMaybe<Array<StringSearch>>;
  cancelled?: InputMaybe<Array<IntSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  createTripTolerance?: InputMaybe<Array<IntSearch>>;
  created?: InputMaybe<Array<IntSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  deleted?: InputMaybe<Array<IntSearch>>;
  emailId?: InputMaybe<Array<StringSearch>>;
  excluded?: InputMaybe<Array<IntSearch>>;
  fileLocation?: InputMaybe<Array<StringSearch>>;
  filename?: InputMaybe<Array<StringSearch>>;
  from?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  ingestCompleted?: InputMaybe<Array<StringSearch>>;
  ingestStarted?: InputMaybe<Array<StringSearch>>;
  md5?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  noticeDatetime?: InputMaybe<Array<StringSearch>>;
  previewedAt?: InputMaybe<Array<StringSearch>>;
  previousImportId?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  rolledBackAt?: InputMaybe<Array<StringSearch>>;
  rolledBackBy?: InputMaybe<Array<StringSearch>>;
  status?: InputMaybe<Array<ImportStatusEnumSearch>>;
  total?: InputMaybe<Array<IntSearch>>;
  type?: InputMaybe<Array<ImportTypeEnumSearch>>;
  unchanged?: InputMaybe<Array<IntSearch>>;
  updated?: InputMaybe<Array<IntSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  version?: InputMaybe<Array<IntSearch>>;
};

export enum ImportStatusEnum {
  Archived = 'ARCHIVED',
  Failed = 'FAILED',
  Ingested = 'INGESTED',
  Ingesting = 'INGESTING',
  New = 'NEW',
  NotParsed = 'NOT_PARSED',
  Parsed = 'PARSED',
  Potential = 'POTENTIAL',
  RolledBack = 'ROLLED_BACK',
  RollingBack = 'ROLLING_BACK',
  Staged = 'STAGED',
  Staging = 'STAGING',
  Uploaded = 'UPLOADED'
}

export type ImportStatusEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ImportStatusEnum>;
};

export enum ImportTypeEnum {
  Addon = 'ADDON',
  Daily = 'DAILY',
  Manifest = 'MANIFEST',
  Training = 'TRAINING'
}

export type ImportTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ImportTypeEnum>;
};

export type ImportWithAirport = {
  __typename?: 'ImportWithAirport';
  airlineIcaoCode: Scalars['String']['output'];
  airportCode: Scalars['String']['output'];
  archivedAt?: Maybe<Scalars['String']['output']>;
  cancelled?: Maybe<Scalars['Int']['output']>;
  companyId: Scalars['String']['output'];
  createTripTolerance?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Int']['output']>;
  emailId?: Maybe<Scalars['String']['output']>;
  excluded?: Maybe<Scalars['Int']['output']>;
  fileLocation?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  ingestCompleted?: Maybe<Scalars['String']['output']>;
  ingestStarted?: Maybe<Scalars['String']['output']>;
  md5: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  noticeDatetime?: Maybe<Scalars['String']['output']>;
  previewedAt?: Maybe<Scalars['String']['output']>;
  previousImportId?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['String']['output'];
  rolledBackAt?: Maybe<Scalars['String']['output']>;
  rolledBackBy?: Maybe<Scalars['String']['output']>;
  status: ImportWithAirportStatusEnum;
  total?: Maybe<Scalars['Int']['output']>;
  type: ImportWithAirportTypeEnum;
  unchanged?: Maybe<Scalars['Int']['output']>;
  updated?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};

export type ImportWithAirportConnection = {
  __typename?: 'ImportWithAirportConnection';
  edges?: Maybe<Array<Maybe<ImportWithAirportEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ImportWithAirportConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<ImportWithAirportMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<ImportWithAirportSearch>>;
};

export type ImportWithAirportEdge = {
  __typename?: 'ImportWithAirportEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ImportWithAirport>;
};

export type ImportWithAirportMaskInput = {
  airlineIcaoCode?: InputMaybe<Scalars['Boolean']['input']>;
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  archivedAt?: InputMaybe<Scalars['Boolean']['input']>;
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createTripTolerance?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  emailId?: InputMaybe<Scalars['Boolean']['input']>;
  excluded?: InputMaybe<Scalars['Boolean']['input']>;
  fileLocation?: InputMaybe<Scalars['Boolean']['input']>;
  filename?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  ingestCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  ingestStarted?: InputMaybe<Scalars['Boolean']['input']>;
  md5?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  noticeDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  previewedAt?: InputMaybe<Scalars['Boolean']['input']>;
  previousImportId?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  rolledBackAt?: InputMaybe<Scalars['Boolean']['input']>;
  rolledBackBy?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  total?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  unchanged?: InputMaybe<Scalars['Boolean']['input']>;
  updated?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  version?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ImportWithAirportSearch = {
  airlineIcaoCode?: InputMaybe<Array<StringSearch>>;
  airportCode?: InputMaybe<Array<StringSearch>>;
  archivedAt?: InputMaybe<Array<StringSearch>>;
  cancelled?: InputMaybe<Array<IntSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  createTripTolerance?: InputMaybe<Array<IntSearch>>;
  created?: InputMaybe<Array<IntSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  deleted?: InputMaybe<Array<IntSearch>>;
  emailId?: InputMaybe<Array<StringSearch>>;
  excluded?: InputMaybe<Array<IntSearch>>;
  fileLocation?: InputMaybe<Array<StringSearch>>;
  filename?: InputMaybe<Array<StringSearch>>;
  from?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  ingestCompleted?: InputMaybe<Array<StringSearch>>;
  ingestStarted?: InputMaybe<Array<StringSearch>>;
  md5?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  noticeDatetime?: InputMaybe<Array<StringSearch>>;
  previewedAt?: InputMaybe<Array<StringSearch>>;
  previousImportId?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  rolledBackAt?: InputMaybe<Array<StringSearch>>;
  rolledBackBy?: InputMaybe<Array<StringSearch>>;
  status?: InputMaybe<Array<ImportWithAirportStatusEnumSearch>>;
  total?: InputMaybe<Array<IntSearch>>;
  type?: InputMaybe<Array<ImportWithAirportTypeEnumSearch>>;
  unchanged?: InputMaybe<Array<IntSearch>>;
  updated?: InputMaybe<Array<IntSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  version?: InputMaybe<Array<IntSearch>>;
};

export enum ImportWithAirportStatusEnum {
  Archived = 'ARCHIVED',
  Failed = 'FAILED',
  Ingested = 'INGESTED',
  Ingesting = 'INGESTING',
  New = 'NEW',
  NotParsed = 'NOT_PARSED',
  Parsed = 'PARSED',
  Potential = 'POTENTIAL',
  RolledBack = 'ROLLED_BACK',
  RollingBack = 'ROLLING_BACK',
  Staged = 'STAGED',
  Staging = 'STAGING',
  Uploaded = 'UPLOADED'
}

export type ImportWithAirportStatusEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ImportWithAirportStatusEnum>;
};

export enum ImportWithAirportTypeEnum {
  Addon = 'ADDON',
  Daily = 'DAILY',
  Manifest = 'MANIFEST',
  Training = 'TRAINING'
}

export type ImportWithAirportTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ImportWithAirportTypeEnum>;
};

export enum IngestActionEnum {
  Create = 'CREATE',
  Delete = 'DELETE',
  Noop = 'NOOP',
  Not = 'NOT',
  Update = 'UPDATE'
}

export type IngestImportResponse = {
  __typename?: 'IngestImportResponse';
  error?: Maybe<Error>;
  failures?: Maybe<Array<IngestTripError>>;
  id: Scalars['String']['output'];
};

export type IngestTripError = {
  __typename?: 'IngestTripError';
  action?: Maybe<IngestActionEnum>;
  error?: Maybe<Error>;
  id: Scalars['String']['output'];
};

export type IntSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<Scalars['Int']['input']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  airports?: Maybe<Array<Scalars['String']['output']>>;
  companyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<InvoiceDocument>>;
  due: Scalars['String']['output'];
  endDatetime: Scalars['String']['output'];
  format: InvoiceFormatEnum;
  grandTotal: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  invoiced: Scalars['String']['output'];
  name: Scalars['String']['output'];
  payerProviderId: Scalars['String']['output'];
  provider?: Maybe<Provider>;
  startDatetime: Scalars['String']['output'];
  status: InvoiceStatusEnum;
  subTotal?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type InvoiceAirport = {
  __typename?: 'InvoiceAirport';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoiceId: Scalars['Int']['output'];
};

export type InvoiceAirportInput = {
  code: Scalars['String']['input'];
};

export type InvoiceAirportSummary = {
  __typename?: 'InvoiceAirportSummary';
  average: Scalars['Float']['output'];
  code: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  rates?: Maybe<Array<Maybe<InvoiceRateSubtotal>>>;
  total: Scalars['Float']['output'];
};

export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type InvoiceConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<InvoiceMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<InvoiceSearch>>;
};

export type InvoiceDocument = {
  __typename?: 'InvoiceDocument';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<User>;
  fileLocation: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  invoiceId: Scalars['Int']['output'];
  md5: Scalars['String']['output'];
  sender?: Maybe<User>;
  sentAt?: Maybe<Scalars['String']['output']>;
  sentBy?: Maybe<Scalars['String']['output']>;
};

export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Invoice>;
};

export type InvoiceEmail = {
  __typename?: 'InvoiceEmail';
  address: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoiceId: Scalars['Int']['output'];
};

export type InvoiceEmailInput = {
  address: Scalars['String']['input'];
};

export enum InvoiceFormatEnum {
  Crew = 'CREW',
  Standard = 'STANDARD'
}

export type InvoiceFormatEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<InvoiceFormatEnum>;
};

export type InvoiceHeader = {
  __typename?: 'InvoiceHeader';
  id: Scalars['String']['output'];
  invoiceId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type InvoiceHeaderInput = {
  name: Scalars['String']['input'];
};

export type InvoiceIataCode = {
  __typename?: 'InvoiceIataCode';
  iataCode: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoiceId: Scalars['Int']['output'];
};

export type InvoiceIataCodeInput = {
  iataCode: Scalars['String']['input'];
};

export type InvoiceInput = {
  airports: Array<InvoiceAirportInput>;
  due: Scalars['String']['input'];
  emails: Array<InvoiceEmailInput>;
  endDatetime: Scalars['String']['input'];
  format: InvoiceFormatEnum;
  grandTotal: Scalars['Float']['input'];
  headers: Array<InvoiceHeaderInput>;
  iataAirlineCodes?: InputMaybe<Array<InvoiceIataCodeInput>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoiced: Scalars['String']['input'];
  items: Array<InvoiceLineItemInput>;
  name: Scalars['String']['input'];
  payerProviderId: Scalars['String']['input'];
  startDatetime: Scalars['String']['input'];
  status?: InputMaybe<InvoiceStatusEnum>;
  subTotal?: InputMaybe<Scalars['Float']['input']>;
  total: Scalars['Float']['input'];
  tripStatus?: InputMaybe<Array<InvoiceTripStatusInput>>;
  trips: Array<InvoiceTripInput>;
  types: Array<InvoiceTypeInput>;
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  id: Scalars['String']['output'];
  invoiceId: Scalars['Int']['output'];
  lineItemTypeId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  total?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceLineItemInput = {
  lineItemTypeId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  total?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceMaskInput = {
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  due?: InputMaybe<Scalars['Boolean']['input']>;
  endDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  format?: InputMaybe<Scalars['Boolean']['input']>;
  grandTotal?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  invoiced?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  startDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  subTotal?: InputMaybe<Scalars['Boolean']['input']>;
  total?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvoiceRateSubtotal = {
  __typename?: 'InvoiceRateSubtotal';
  amount: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  total: Scalars['Float']['output'];
};

export type InvoiceResponse = {
  __typename?: 'InvoiceResponse';
  airports: Array<InvoiceAirport>;
  company: Company;
  documents?: Maybe<Array<InvoiceDocument>>;
  emails: Array<InvoiceEmail>;
  headers: Array<InvoiceHeader>;
  iataAirlineCodes?: Maybe<Array<InvoiceIataCode>>;
  invoice: Invoice;
  items: Array<InvoiceLineItem>;
  payerProvider: Provider;
  tripStatus?: Maybe<Array<InvoiceTripStatus>>;
  trips: Array<InvoiceTrip>;
  types: Array<InvoiceType>;
};

export type InvoiceSearch = {
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  due?: InputMaybe<Array<StringSearch>>;
  endDatetime?: InputMaybe<Array<StringSearch>>;
  format?: InputMaybe<Array<InvoiceFormatEnumSearch>>;
  grandTotal?: InputMaybe<Array<FloatSearch>>;
  id?: InputMaybe<Array<IntSearch>>;
  invoiced?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  startDatetime?: InputMaybe<Array<StringSearch>>;
  status?: InputMaybe<Array<InvoiceStatusEnumSearch>>;
  subTotal?: InputMaybe<Array<FloatSearch>>;
  total?: InputMaybe<Array<FloatSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum InvoiceStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type InvoiceStatusEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<InvoiceStatusEnum>;
};

export type InvoiceSummary = {
  __typename?: 'InvoiceSummary';
  airports?: Maybe<Array<Maybe<InvoiceAirportSummary>>>;
  average: Scalars['Float']['output'];
  client: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  total: Scalars['Float']['output'];
};

export type InvoiceTerm = {
  __typename?: 'InvoiceTerm';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type InvoiceTermConnection = {
  __typename?: 'InvoiceTermConnection';
  edges?: Maybe<Array<Maybe<InvoiceTermEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type InvoiceTermConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<InvoiceTermMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<InvoiceTermSearch>>;
};

export type InvoiceTermEdge = {
  __typename?: 'InvoiceTermEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<InvoiceTerm>;
};

export type InvoiceTermMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvoiceTermSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type InvoiceTrip = {
  __typename?: 'InvoiceTrip';
  airportCode: Scalars['String']['output'];
  attendants: Scalars['Int']['output'];
  combineId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  crewId?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<InvoiceTripCurrencyEnum>;
  discount: Scalars['Float']['output'];
  doLocation: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  flightNumber: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  invoiceId: Scalars['Int']['output'];
  kind: InvoiceTripKindEnum;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pilots: Scalars['Int']['output'];
  puLocation: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
  rateId: Scalars['String']['output'];
  scheduled: Scalars['String']['output'];
  servicerIataAirlineCode: Scalars['String']['output'];
  status: InvoiceTripStatusEnum;
  tripId: Scalars['String']['output'];
  type: InvoiceTripTypeEnum;
};

export enum InvoiceTripCurrencyEnum {
  Usd = 'USD'
}

export type InvoiceTripInput = {
  index: Scalars['Int']['input'];
  rate: Scalars['Float']['input'];
  tripId: Scalars['String']['input'];
};

export enum InvoiceTripKindEnum {
  Arr = 'ARR',
  Dep = 'DEP'
}

export type InvoiceTripStatus = {
  __typename?: 'InvoiceTripStatus';
  id: Scalars['String']['output'];
  invoiceId: Scalars['Int']['output'];
  status: InvoiceTripStatusStatusEnum;
};

export enum InvoiceTripStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type InvoiceTripStatusInput = {
  status: TripStatusEnum;
};

export enum InvoiceTripStatusStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export enum InvoiceTripTypeEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type InvoiceType = {
  __typename?: 'InvoiceType';
  id: Scalars['String']['output'];
  invoiceId: Scalars['Int']['output'];
  type: InvoiceTypeTypeEnum;
};

export type InvoiceTypeInput = {
  type: InvoiceTypeTypeEnum;
};

export enum InvoiceTypeTypeEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type Issue = Node & {
  __typename?: 'Issue';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy: Scalars['String']['output'];
  driverId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  tripId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type IssueConnection = {
  __typename?: 'IssueConnection';
  edges?: Maybe<Array<Maybe<IssueEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type IssueConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<IssueMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<IssueSearch>>;
};

export type IssueEdge = {
  __typename?: 'IssueEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Issue>;
};

export type IssueMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  driverId?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IssueSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  driverId?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  message?: InputMaybe<Array<StringSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type JobLookupDriverAppInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  airportCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  completionStatus?: InputMaybe<CompletionStatusEnum>;
  driverId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  format?: InputMaybe<JobLookupFormatEnum>;
  formatType?: InputMaybe<Scalars['String']['input']>;
  scheduledEndTime?: InputMaybe<Scalars['String']['input']>;
  scheduledStartTime?: InputMaybe<Scalars['String']['input']>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum JobLookupFormatEnum {
  Completed = 'COMPLETED',
  Myjobs = 'MYJOBS',
  NotCompleted = 'NOT_COMPLETED',
  Unassigned = 'UNASSIGNED'
}

export type KeyValue = {
  __typename?: 'KeyValue';
  description: Scalars['String']['output'];
  display?: Maybe<Array<StyledValue>>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum KindEnum {
  Arr = 'ARR',
  Dep = 'DEP'
}

export type LastLogin = {
  __typename?: 'LastLogin';
  datetime: Scalars['String']['output'];
};

export type LatestRate = {
  __typename?: 'LatestRate';
  airportCode?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currency: LatestRateCurrencyEnum;
  duration?: Maybe<Scalars['Int']['output']>;
  endDatetime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payerProvider?: Maybe<Provider>;
  payerProviderId?: Maybe<Scalars['String']['output']>;
  rate: Scalars['Float']['output'];
  rateHistoryId: Scalars['String']['output'];
  rateItemId: Scalars['String']['output'];
  startDatetime: Scalars['String']['output'];
  thatLocation?: Maybe<Location>;
  thatLocationId: Scalars['String']['output'];
  thisLocation?: Maybe<Location>;
  thisLocationId: Scalars['String']['output'];
  tripDistance?: Maybe<Scalars['Int']['output']>;
  type: LatestRateTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type LatestRateConnection = {
  __typename?: 'LatestRateConnection';
  edges?: Maybe<Array<Maybe<LatestRateEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LatestRateConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<LatestRateMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<LatestRateSearch>>;
};

export enum LatestRateCurrencyEnum {
  Usd = 'USD'
}

export type LatestRateCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<LatestRateCurrencyEnum>;
};

export type LatestRateEdge = {
  __typename?: 'LatestRateEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<LatestRate>;
};

export type LatestRateHistory = {
  __typename?: 'LatestRateHistory';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currency: LatestRateHistoryCurrencyEnum;
  endDatetime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
  rateGroupId: Scalars['String']['output'];
  rateItemId?: Maybe<Scalars['String']['output']>;
  rn?: Maybe<Scalars['String']['output']>;
  startDatetime?: Maybe<Scalars['String']['output']>;
  type: LatestRateHistoryTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type LatestRateHistoryConnection = {
  __typename?: 'LatestRateHistoryConnection';
  edges?: Maybe<Array<Maybe<LatestRateHistoryEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LatestRateHistoryConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<LatestRateHistoryMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<LatestRateHistorySearch>>;
};

export enum LatestRateHistoryCurrencyEnum {
  Usd = 'USD'
}

export type LatestRateHistoryCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<LatestRateHistoryCurrencyEnum>;
};

export type LatestRateHistoryEdge = {
  __typename?: 'LatestRateHistoryEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<LatestRateHistory>;
};

export type LatestRateHistoryMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['Boolean']['input']>;
  endDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  rate?: InputMaybe<Scalars['Boolean']['input']>;
  rateGroupId?: InputMaybe<Scalars['Boolean']['input']>;
  rateItemId?: InputMaybe<Scalars['Boolean']['input']>;
  rn?: InputMaybe<Scalars['Boolean']['input']>;
  startDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LatestRateHistorySearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  currency?: InputMaybe<Array<LatestRateHistoryCurrencyEnumSearch>>;
  endDatetime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  rate?: InputMaybe<Array<FloatSearch>>;
  rateGroupId?: InputMaybe<Array<StringSearch>>;
  rateItemId?: InputMaybe<Array<StringSearch>>;
  rn?: InputMaybe<Array<StringSearch>>;
  startDatetime?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<LatestRateHistoryTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum LatestRateHistoryTypeEnum {
  Adhoc = 'ADHOC',
  Contract = 'CONTRACT',
  NonContract = 'NON_CONTRACT',
  Override = 'OVERRIDE'
}

export type LatestRateHistoryTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<LatestRateHistoryTypeEnum>;
};

export type LatestRateMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['Boolean']['input']>;
  duration?: InputMaybe<Scalars['Boolean']['input']>;
  endDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  rate?: InputMaybe<Scalars['Boolean']['input']>;
  rateHistoryId?: InputMaybe<Scalars['Boolean']['input']>;
  rateItemId?: InputMaybe<Scalars['Boolean']['input']>;
  startDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  thatLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  thisLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  tripDistance?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LatestRateSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  currency?: InputMaybe<Array<LatestRateCurrencyEnumSearch>>;
  duration?: InputMaybe<Array<IntSearch>>;
  endDatetime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  rate?: InputMaybe<Array<FloatSearch>>;
  rateHistoryId?: InputMaybe<Array<StringSearch>>;
  rateItemId?: InputMaybe<Array<StringSearch>>;
  startDatetime?: InputMaybe<Array<StringSearch>>;
  thatLocationId?: InputMaybe<Array<StringSearch>>;
  thisLocationId?: InputMaybe<Array<StringSearch>>;
  tripDistance?: InputMaybe<Array<IntSearch>>;
  type?: InputMaybe<Array<LatestRateTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum LatestRateTypeEnum {
  Contract = 'CONTRACT',
  NonContract = 'NON_CONTRACT'
}

export type LatestRateTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<LatestRateTypeEnum>;
};

export type Leaderboard = Node & {
  __typename?: 'Leaderboard';
  avatar: Scalars['String']['output'];
  bubbleName: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  markets?: Maybe<Array<Scalars['String']['output']>>;
  ranking: LeaderboardRanking;
  userId: Scalars['String']['output'];
};

export type LeaderboardDriverAppInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  airportCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  endDatetime?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupBy: Scalars['String']['input'];
  rankingType?: InputMaybe<Scalars['String']['input']>;
  startDatetime?: InputMaybe<Scalars['String']['input']>;
};

export type LeaderboardDriverAppResponse = {
  __typename?: 'LeaderboardDriverAppResponse';
  edges?: Maybe<Array<Maybe<LeaderboardEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LeaderboardEdge = {
  __typename?: 'LeaderboardEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Leaderboard>;
};

export type LeaderboardRanking = {
  __typename?: 'LeaderboardRanking';
  arrowUp?: Maybe<Scalars['Boolean']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
  percentageLong?: Maybe<Scalars['Float']['output']>;
  tripsTotal?: Maybe<Scalars['Int']['output']>;
};

export type License = {
  __typename?: 'License';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  licenseTypeId: Scalars['String']['output'];
  number: Scalars['String']['output'];
  stateCode: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type LicenseConnection = {
  __typename?: 'LicenseConnection';
  edges?: Maybe<Array<Maybe<LicenseEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LicenseConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<LicenseMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<LicenseSearch>>;
};

export type LicenseEdge = {
  __typename?: 'LicenseEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<License>;
};

export type LicenseMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  expirationDate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  licenseTypeId?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['Boolean']['input']>;
  stateCode?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LicenseSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  expirationDate?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  licenseTypeId?: InputMaybe<Array<StringSearch>>;
  number?: InputMaybe<Array<StringSearch>>;
  stateCode?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  userId?: InputMaybe<Array<StringSearch>>;
};

export type LicenseType = {
  __typename?: 'LicenseType';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type LicenseTypeConnection = {
  __typename?: 'LicenseTypeConnection';
  edges?: Maybe<Array<Maybe<LicenseTypeEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LicenseTypeConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<LicenseTypeMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<LicenseTypeSearch>>;
};

export type LicenseTypeEdge = {
  __typename?: 'LicenseTypeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<LicenseType>;
};

export type LicenseTypeMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LicenseTypeSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  displayName?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type LineItemType = {
  __typename?: 'LineItemType';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type Location = Node & {
  __typename?: 'Location';
  active: Scalars['Int']['output'];
  address: Scalars['String']['output'];
  airports?: Maybe<Array<AirportHasLocation>>;
  aliases?: Maybe<Array<Alias>>;
  cityName?: Maybe<Scalars['String']['output']>;
  coordinates: Point;
  countryCode: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  legacyId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pickupPoints?: Maybe<Array<PickupPoint>>;
  primaryAirportCode?: Maybe<Scalars['String']['output']>;
  punchPolicies?: Maybe<Array<PunchPolicy>>;
  radius: Scalars['Int']['output'];
  stateCode?: Maybe<Scalars['String']['output']>;
  traceId?: Maybe<Scalars['String']['output']>;
  type: LocationTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type LocationConnection = {
  __typename?: 'LocationConnection';
  edges?: Maybe<Array<Maybe<LocationEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LocationConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<LocationMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<LocationSearch>>;
};

export type LocationEdge = {
  __typename?: 'LocationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Location>;
};

export type LocationInput = {
  address: Scalars['String']['input'];
  airportCodes: Array<Scalars['String']['input']>;
  aliases: Array<Scalars['String']['input']>;
  cityName: Scalars['String']['input'];
  countryCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  stateCode: Scalars['String']['input'];
  type: LocationTypeEnum;
  zipCode: Scalars['String']['input'];
};

export type LocationMap = {
  locationId: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type LocationMappingInput = {
  locationId: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type LocationMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['Boolean']['input']>;
  cityName?: InputMaybe<Scalars['Boolean']['input']>;
  coordinates?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  primaryAirportCode?: InputMaybe<Scalars['Boolean']['input']>;
  radius?: InputMaybe<Scalars['Boolean']['input']>;
  stateCode?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LocationResponse = {
  __typename?: 'LocationResponse';
  error?: Maybe<Scalars['String']['output']>;
  location: Location;
};

export type LocationSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  address?: InputMaybe<Array<StringSearch>>;
  cityName?: InputMaybe<Array<StringSearch>>;
  coordinates?: InputMaybe<Array<PointSearch>>;
  countryCode?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  legacyId?: InputMaybe<Array<IntSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  phoneNumber?: InputMaybe<Array<StringSearch>>;
  primaryAirportCode?: InputMaybe<Array<StringSearch>>;
  radius?: InputMaybe<Array<IntSearch>>;
  stateCode?: InputMaybe<Array<StringSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<LocationTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  zipCode?: InputMaybe<Array<StringSearch>>;
};

export enum LocationTypeEnum {
  Airport = 'AIRPORT',
  Exchange = 'EXCHANGE',
  Hotel = 'HOTEL'
}

export type LocationTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<LocationTypeEnum>;
};

export type LocationWithAirportAndAlias = {
  __typename?: 'LocationWithAirportAndAlias';
  active: Scalars['Int']['output'];
  address: Scalars['String']['output'];
  airportCode: Scalars['String']['output'];
  alias: Scalars['String']['output'];
  aliasCreatedAt?: Maybe<Scalars['String']['output']>;
  aliasCreatedBy?: Maybe<Scalars['String']['output']>;
  aliasId: Scalars['String']['output'];
  aliasUpdatedAt?: Maybe<Scalars['String']['output']>;
  aliasUpdatedBy?: Maybe<Scalars['String']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  coordinates: Point;
  countryCode: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legacyId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  primaryAirportCode?: Maybe<Scalars['String']['output']>;
  radius: Scalars['Int']['output'];
  stateCode?: Maybe<Scalars['String']['output']>;
  traceId?: Maybe<Scalars['String']['output']>;
  type: LocationWithAirportAndAliasTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type LocationWithAirportAndAliasConnection = {
  __typename?: 'LocationWithAirportAndAliasConnection';
  edges?: Maybe<Array<Maybe<LocationWithAirportAndAliasEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LocationWithAirportAndAliasConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<LocationWithAirportAndAliasMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<LocationWithAirportAndAliasSearch>>;
};

export type LocationWithAirportAndAliasEdge = {
  __typename?: 'LocationWithAirportAndAliasEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<LocationWithAirportAndAlias>;
};

export type LocationWithAirportAndAliasMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['Boolean']['input']>;
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  alias?: InputMaybe<Scalars['Boolean']['input']>;
  aliasCreatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  aliasCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  aliasId?: InputMaybe<Scalars['Boolean']['input']>;
  aliasUpdatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  aliasUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  cityName?: InputMaybe<Scalars['Boolean']['input']>;
  coordinates?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  primaryAirportCode?: InputMaybe<Scalars['Boolean']['input']>;
  radius?: InputMaybe<Scalars['Boolean']['input']>;
  stateCode?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LocationWithAirportAndAliasSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  address?: InputMaybe<Array<StringSearch>>;
  airportCode?: InputMaybe<Array<StringSearch>>;
  alias?: InputMaybe<Array<StringSearch>>;
  aliasCreatedAt?: InputMaybe<Array<StringSearch>>;
  aliasCreatedBy?: InputMaybe<Array<StringSearch>>;
  aliasId?: InputMaybe<Array<StringSearch>>;
  aliasUpdatedAt?: InputMaybe<Array<StringSearch>>;
  aliasUpdatedBy?: InputMaybe<Array<StringSearch>>;
  cityName?: InputMaybe<Array<StringSearch>>;
  coordinates?: InputMaybe<Array<PointSearch>>;
  countryCode?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  legacyId?: InputMaybe<Array<IntSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  phoneNumber?: InputMaybe<Array<StringSearch>>;
  primaryAirportCode?: InputMaybe<Array<StringSearch>>;
  radius?: InputMaybe<Array<IntSearch>>;
  stateCode?: InputMaybe<Array<StringSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<LocationWithAirportAndAliasTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  zipCode?: InputMaybe<Array<StringSearch>>;
};

export enum LocationWithAirportAndAliasTypeEnum {
  Airport = 'AIRPORT',
  Exchange = 'EXCHANGE',
  Hotel = 'HOTEL'
}

export type LocationWithAirportAndAliasTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<LocationWithAirportAndAliasTypeEnum>;
};

export type LocationWithPunchPolicy = {
  __typename?: 'LocationWithPunchPolicy';
  active: Scalars['Int']['output'];
  address: Scalars['String']['output'];
  airportCode: Scalars['String']['output'];
  cityName?: Maybe<Scalars['String']['output']>;
  coordinates: Point;
  countryCode: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  endTime: Scalars['String']['output'];
  eventEndDatetime?: Maybe<Scalars['String']['output']>;
  eventStartDatetime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legacyId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  primaryAirportCode?: Maybe<Scalars['String']['output']>;
  punchPolicyCompanyId: Scalars['String']['output'];
  punchPolicyCreatedAt?: Maybe<Scalars['String']['output']>;
  punchPolicyCreatedBy?: Maybe<Scalars['String']['output']>;
  punchPolicyId: Scalars['String']['output'];
  punchPolicyMinutesIn: Scalars['Int']['output'];
  punchPolicyMinutesOut: Scalars['Int']['output'];
  punchPolicyUpdatedAt?: Maybe<Scalars['String']['output']>;
  punchPolicyUpdatedBy?: Maybe<Scalars['String']['output']>;
  radius: Scalars['Int']['output'];
  startTime: Scalars['String']['output'];
  stateCode?: Maybe<Scalars['String']['output']>;
  traceId?: Maybe<Scalars['String']['output']>;
  type: LocationWithPunchPolicyTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type LocationWithPunchPolicyConnection = {
  __typename?: 'LocationWithPunchPolicyConnection';
  edges?: Maybe<Array<Maybe<LocationWithPunchPolicyEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LocationWithPunchPolicyConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<LocationWithPunchPolicyMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<LocationWithPunchPolicySearch>>;
};

export type LocationWithPunchPolicyEdge = {
  __typename?: 'LocationWithPunchPolicyEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<LocationWithPunchPolicy>;
};

export type LocationWithPunchPolicyMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['Boolean']['input']>;
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  cityName?: InputMaybe<Scalars['Boolean']['input']>;
  coordinates?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Boolean']['input']>;
  eventEndDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  eventStartDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  primaryAirportCode?: InputMaybe<Scalars['Boolean']['input']>;
  punchPolicyCompanyId?: InputMaybe<Scalars['Boolean']['input']>;
  punchPolicyCreatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  punchPolicyCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  punchPolicyId?: InputMaybe<Scalars['Boolean']['input']>;
  punchPolicyMinutesIn?: InputMaybe<Scalars['Boolean']['input']>;
  punchPolicyMinutesOut?: InputMaybe<Scalars['Boolean']['input']>;
  punchPolicyUpdatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  punchPolicyUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  radius?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['Boolean']['input']>;
  stateCode?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LocationWithPunchPolicySearch = {
  active?: InputMaybe<Array<IntSearch>>;
  address?: InputMaybe<Array<StringSearch>>;
  airportCode?: InputMaybe<Array<StringSearch>>;
  cityName?: InputMaybe<Array<StringSearch>>;
  coordinates?: InputMaybe<Array<PointSearch>>;
  countryCode?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  endTime?: InputMaybe<Array<StringSearch>>;
  eventEndDatetime?: InputMaybe<Array<StringSearch>>;
  eventStartDatetime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  legacyId?: InputMaybe<Array<IntSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  phoneNumber?: InputMaybe<Array<StringSearch>>;
  primaryAirportCode?: InputMaybe<Array<StringSearch>>;
  punchPolicyCompanyId?: InputMaybe<Array<StringSearch>>;
  punchPolicyCreatedAt?: InputMaybe<Array<StringSearch>>;
  punchPolicyCreatedBy?: InputMaybe<Array<StringSearch>>;
  punchPolicyId?: InputMaybe<Array<StringSearch>>;
  punchPolicyMinutesIn?: InputMaybe<Array<IntSearch>>;
  punchPolicyMinutesOut?: InputMaybe<Array<IntSearch>>;
  punchPolicyUpdatedAt?: InputMaybe<Array<StringSearch>>;
  punchPolicyUpdatedBy?: InputMaybe<Array<StringSearch>>;
  radius?: InputMaybe<Array<IntSearch>>;
  startTime?: InputMaybe<Array<StringSearch>>;
  stateCode?: InputMaybe<Array<StringSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<LocationWithPunchPolicyTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  zipCode?: InputMaybe<Array<StringSearch>>;
};

export enum LocationWithPunchPolicyTypeEnum {
  Airport = 'AIRPORT',
  Exchange = 'EXCHANGE',
  Hotel = 'HOTEL'
}

export type LocationWithPunchPolicyTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<LocationWithPunchPolicyTypeEnum>;
};

export type LoginEvent = {
  __typename?: 'LoginEvent';
  cityName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  datetime?: Maybe<Scalars['String']['output']>;
  flightNumber?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  ipAddress?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userAgent?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type LoginEventConnection = {
  __typename?: 'LoginEventConnection';
  edges?: Maybe<Array<Maybe<LoginEventEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LoginEventConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<LoginEventMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<LoginEventSearch>>;
};

export type LoginEventEdge = {
  __typename?: 'LoginEventEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<LoginEvent>;
};

export type LoginEventMaskInput = {
  cityName?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  datetime?: InputMaybe<Scalars['Boolean']['input']>;
  flightNumber?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  ipAddress?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  userAgent?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoginEventSearch = {
  cityName?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  datetime?: InputMaybe<Array<StringSearch>>;
  flightNumber?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  ipAddress?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  userAgent?: InputMaybe<Array<StringSearch>>;
  userId?: InputMaybe<Array<StringSearch>>;
};

export type LoginInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token: Scalars['String']['output'];
  user: User;
};

export type LostAndFoundIssue = {
  __typename?: 'LostAndFoundIssue';
  city?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  crewMemberEmail: Scalars['String']['output'];
  crewMemberName: Scalars['String']['output'];
  crewMemberPhone: Scalars['String']['output'];
  description: Scalars['String']['output'];
  dispatcher: Scalars['String']['output'];
  id: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  outboundDatetime: Scalars['String']['output'];
  outboundFlightNumber?: Maybe<Scalars['Int']['output']>;
  returnStatus?: Maybe<LostAndFoundIssueReturnStatusEnum>;
  shippingAddress?: Maybe<Scalars['String']['output']>;
  shippingCost?: Maybe<Scalars['Float']['output']>;
  shippingMethod?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  tripId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type LostAndFoundIssueConnection = {
  __typename?: 'LostAndFoundIssueConnection';
  edges?: Maybe<Array<Maybe<LostAndFoundIssueEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LostAndFoundIssueConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<LostAndFoundIssueMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<LostAndFoundIssueSearch>>;
};

export type LostAndFoundIssueDocument = {
  __typename?: 'LostAndFoundIssueDocument';
  contentType: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  fileLocation: Scalars['String']['output'];
  fileSize: Scalars['Int']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  issueId: Scalars['String']['output'];
  md5: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type LostAndFoundIssueDocumentConnection = {
  __typename?: 'LostAndFoundIssueDocumentConnection';
  edges?: Maybe<Array<Maybe<LostAndFoundIssueDocumentEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LostAndFoundIssueDocumentConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<LostAndFoundIssueDocumentMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<LostAndFoundIssueDocumentSearch>>;
};

export type LostAndFoundIssueDocumentEdge = {
  __typename?: 'LostAndFoundIssueDocumentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<LostAndFoundIssueDocument>;
};

export type LostAndFoundIssueDocumentMaskInput = {
  contentType?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  fileLocation?: InputMaybe<Scalars['Boolean']['input']>;
  fileSize?: InputMaybe<Scalars['Boolean']['input']>;
  filename?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  issueId?: InputMaybe<Scalars['Boolean']['input']>;
  md5?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LostAndFoundIssueDocumentSearch = {
  contentType?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  fileLocation?: InputMaybe<Array<StringSearch>>;
  fileSize?: InputMaybe<Array<IntSearch>>;
  filename?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  issueId?: InputMaybe<Array<StringSearch>>;
  md5?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type LostAndFoundIssueEdge = {
  __typename?: 'LostAndFoundIssueEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<LostAndFoundIssue>;
};

export type LostAndFoundIssueMaskInput = {
  city?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  crewMemberEmail?: InputMaybe<Scalars['Boolean']['input']>;
  crewMemberName?: InputMaybe<Scalars['Boolean']['input']>;
  crewMemberPhone?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['Boolean']['input']>;
  dispatcher?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['Boolean']['input']>;
  outboundDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  outboundFlightNumber?: InputMaybe<Scalars['Boolean']['input']>;
  returnStatus?: InputMaybe<Scalars['Boolean']['input']>;
  shippingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  shippingCost?: InputMaybe<Scalars['Boolean']['input']>;
  shippingMethod?: InputMaybe<Scalars['Boolean']['input']>;
  stateCode?: InputMaybe<Scalars['Boolean']['input']>;
  trackingNumber?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum LostAndFoundIssueReturnStatusEnum {
  Created = 'CREATED',
  Pending = 'PENDING',
  Returned = 'RETURNED',
  Shipped = 'SHIPPED'
}

export type LostAndFoundIssueReturnStatusEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<LostAndFoundIssueReturnStatusEnum>;
};

export type LostAndFoundIssueSearch = {
  city?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  crewMemberEmail?: InputMaybe<Array<StringSearch>>;
  crewMemberName?: InputMaybe<Array<StringSearch>>;
  crewMemberPhone?: InputMaybe<Array<StringSearch>>;
  description?: InputMaybe<Array<StringSearch>>;
  dispatcher?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  notes?: InputMaybe<Array<StringSearch>>;
  outboundDatetime?: InputMaybe<Array<StringSearch>>;
  outboundFlightNumber?: InputMaybe<Array<IntSearch>>;
  returnStatus?: InputMaybe<Array<LostAndFoundIssueReturnStatusEnumSearch>>;
  shippingAddress?: InputMaybe<Array<StringSearch>>;
  shippingCost?: InputMaybe<Array<FloatSearch>>;
  shippingMethod?: InputMaybe<Array<StringSearch>>;
  stateCode?: InputMaybe<Array<StringSearch>>;
  trackingNumber?: InputMaybe<Array<StringSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type Message = {
  __typename?: 'Message';
  companyId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  type: MessageTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  edges?: Maybe<Array<Maybe<MessageEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MessageConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<MessageMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<MessageSearch>>;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Message>;
};

export type MessageMaskInput = {
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  content?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MessageSearch = {
  companyId?: InputMaybe<Array<StringSearch>>;
  content?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<MessageTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum MessageTypeEnum {
  Crew = 'CREW',
  Curbside = 'CURBSIDE',
  Driver = 'DRIVER'
}

export type MessageTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<MessageTypeEnum>;
};

export type Metadata = {
  __typename?: 'Metadata';
  columns?: Maybe<Array<KeyValue>>;
  footer?: Maybe<Array<KeyValue>>;
  header?: Maybe<Array<KeyValue>>;
};

export type Metric = {
  key: Scalars['String']['input'];
  math: MetricMathEnum;
  numberField: MetricNumberFieldEnum;
};

export enum MetricFunctionEnum {
  AverageBucket = 'AVERAGE_BUCKET',
  SumBucket = 'SUM_BUCKET'
}

export enum MetricKeywordFieldEnum {
  Airport = 'AIRPORT',
  Client = 'CLIENT',
  Market = 'MARKET',
  Status = 'STATUS'
}

export enum MetricMathEnum {
  Average = 'AVERAGE',
  Count = 'COUNT',
  Sum = 'SUM'
}

export enum MetricNumberFieldEnum {
  Rate = 'RATE'
}

export type Mutation = {
  __typename?: 'Mutation';
  addLocationAliases: AddLocationAliasesResponse;
  approveUserAvailability: ApproveUserAvailabilityResponse;
  combineTrips: CreateCombineTripResponse;
  createActivityBulk: CreateActivityBulkResponse;
  createAirlineBulk: CreateProviderBulkResponse;
  createAirportHasVehicleBulk: CreateAirportHasVehicleBulkResponse;
  createBufferTimeBulk: CreateBufferTimeBulkResponse;
  createBufferTimes: CreateBufferTimesResponse;
  createClientBulk: CreateProviderBulkResponse;
  createCombineRuleBulk: CreateCombineRuleBulkResponse;
  createCommunicationBulk: CreateCommunicationBulkResponse;
  createCompletionBulk: CreateCompletionBulkResponse;
  createCrewTrip: CreateCrewTripResponse;
  createDownloadImportURLs: CreateDownloadImportUrLsResponse;
  createEmailBulk: CreateEmailBulkResponse;
  createFcrBulk: CreateFcrBulkResponse;
  createFlagBulk: CreateFlagBulkResponse;
  createFlagHasTripBulk: CreateFlagHasTripBulkResponse;
  createGroupBulk: CreateGroupBulkResponse;
  createGroupHasPermissionBulk: CreateGroupHasPermissionBulkResponse;
  createGroupHasUserBulk: CreateGroupHasUserBulkResponse;
  createImageBulk: CreateImageBulkResponse;
  createImports: CreateImportsResponse;
  createLicenseBulk: CreateLicenseBulkResponse;
  createLocationBulk: CreateLocationBulkResponse;
  createLocations: CreateLocationsResponse;
  createLostAndFoundIssueBulk: CreateLostAndFoundIssueBulkResponse;
  createLostAndFoundIssueDocumentBulk: CreateLostAndFoundIssueDocumentBulkResponse;
  createPermissionHasRoleBulk: CreatePermissionHasRoleBulkResponse;
  createPermissionHasUserBulk: CreatePermissionHasUserBulkResponse;
  createPickupPointBulk: CreatePickupPointBulkResponse;
  createPotentialTripBulk: CreatePotentialTripBulkResponse;
  createProviderBulk: CreateProviderBulkResponse;
  createPunchPolicyBulk: CreatePunchPolicyBulkResponse;
  createRateBulk: CreateRateBulkResponse;
  createRateForTrips: CreateRateForTripsResponse;
  createRateGroupBulk: CreateRateGroupBulkResponse;
  createRateItemBulk: CreateRateItemBulkResponse;
  createRatingBulk: CreateRatingBulkResponse;
  createRatingRemarkBulk: CreateRatingRemarkBulkResponse;
  createRoleBulk: CreateRoleBulkResponse;
  createStopBulk: CreateStopBulkResponse;
  createTrips: CreateTripsResponse;
  createUserAvailabilityBulk: CreateUserAvailabilityBulkResponse;
  createUserBulk: CreateUserBulkResponse;
  createVariableTimeBulk: CreateVariableTimeBulkResponse;
  createVehicleBulk: CreateVehicleBulkResponse;
  deleteAirlineBulk: DeleteProviderBulkResponse;
  deleteAirportHasVehicleBulk: DeleteAirportHasVehicleBulkResponse;
  deleteBufferTimeBulk: DeleteBufferTimeBulkResponse;
  deleteBufferTimes: DeleteBufferTimesResponse;
  deleteClientBulk: DeleteProviderBulkResponse;
  deleteCombineRuleBulk: DeleteCombineRuleBulkResponse;
  deleteCommunicationBulk: DeleteCommunicationBulkResponse;
  deleteCompletionBulk: DeleteCompletionBulkResponse;
  deleteEmailBulk: DeleteEmailBulkResponse;
  deleteFcrBulk: DeleteFcrBulkResponse;
  deleteFlagBulk: DeleteFlagBulkResponse;
  deleteFlagHasTripBulk: DeleteFlagHasTripBulkResponse;
  deleteGroupBulk: DeleteGroupBulkResponse;
  deleteGroupHasPermissionBulk: DeleteGroupHasPermissionBulkResponse;
  deleteGroupHasUserBulk: DeleteGroupHasUserBulkResponse;
  deleteImageBulk: DeleteImageBulkResponse;
  deleteLicenseBulk: DeleteLicenseBulkResponse;
  deleteLocationBulk: DeleteLocationBulkResponse;
  deleteLostAndFoundIssueBulk: DeleteLostAndFoundIssueBulkResponse;
  deleteLostAndFoundIssueDocumentBulk: DeleteLostAndFoundIssueDocumentBulkResponse;
  deletePermissionHasRoleBulk: DeletePermissionHasRoleBulkResponse;
  deletePermissionHasUserBulk: DeletePermissionHasUserBulkResponse;
  deletePickupPointBulk: DeletePickupPointBulkResponse;
  deleteProviderBulk: DeleteProviderBulkResponse;
  deletePunchPolicyBulk: DeletePunchPolicyBulkResponse;
  deleteRateBulk: DeleteRateBulkResponse;
  deleteRateGroupBulk: DeleteRateGroupBulkResponse;
  deleteRateItemBulk: DeleteRateItemBulkResponse;
  deleteRoleBulk: DeleteRoleBulkResponse;
  deleteStopBulk: DeleteStopBulkResponse;
  deleteTripBulk: DeleteTripBulkResponse;
  deleteTripHardBulk: DeleteTripHardBulkResponse;
  deleteUserAvailabilityAndUnassignBulk: DeleteUserAvailabilityBulkResponse;
  deleteUserAvailabilityBulk: DeleteUserAvailabilityBulkResponse;
  deleteUserAvailabilitySeriesAndUnassignBulk: DeleteUserAvailabilityBulkResponse;
  deleteUserAvailabilitySeriesBulk: DeleteUserAvailabilityBulkResponse;
  deleteUserBulk: DeleteUserBulkResponse;
  deleteVariableTimeBulk: DeleteVariableTimeBulkResponse;
  deleteVehicleBulk: DeleteVehicleBulkResponse;
  deviceMutation?: Maybe<DeviceMutationResponse>;
  forgotPassword: ForgotPasswordResponse;
  generatePunchHistoryUploadUrls: GeneratePunchHistoryUploadUrlsResponse;
  generateUploadProfileImageUrl: GenerateUploadProfileImageUrlResponse;
  login: LoginResponse;
  reportProblem?: Maybe<ReportProblemResponse>;
  resetPasswordLinkCode: ResetPasswordLinkCodeResponse;
  runApplyRateToSimilarTrips: RunApplyRateToSimilarTripsResponse;
  runArchiveImports: RunArchiveImportsResponse;
  runArchiveInvoices: RunArchiveInvoicesResponse;
  runAssignDriver: RunAssignDriverResponse;
  runAssignVehicleToDriverBulk: RunAssignVehicleToDriverBulkResponse;
  runAttemptAssignDriverAndCombineTrips: RunAttemptAssignDriverAndCombineTripsResponse;
  runCompletionOnTrip: RunCompletionOnTripResponse;
  runDownloadImport: RunDownloadImportResponse;
  runDownloadInvoice: RunDownloadInvoiceResponse;
  runDownloadLatestInvoiceDocument: RunDownloadLatestInvoiceDocumentResponse;
  runIngestImports: RunIngestImportsResponse;
  runOverrideImports: RunOverrideImportsResponse;
  runParseImportLocations: RunParseImportLocationsResponse;
  runParseImports: RunParseImportsResponse;
  runPreviewImports: RunPreviewImportsResponse;
  runPreviewInvoice: RunPreviewInvoiceResponse;
  runRateAmountOnTrips: RunRateAmountOnTripsResponse;
  runRateOverride: RunRateOverrideResponse;
  runRemoveCompletionOnTrip: RunRemoveCompletionOnTripResponse;
  runReportIndexer: RunReportIndexerResponse;
  runResetUserPasswordBulk: RunResetUserPasswordBulkResponse;
  runRollbackImports: RunRollbackImportsResponse;
  runSaveAndSendInvoice: RunSaveAndSendInvoiceResponse;
  runSaveAsInvoice: RunSaveAsInvoiceResponse;
  runSaveInvoice: RunSaveInvoiceResponse;
  runUnarchiveInvoices: RunUnarchiveInvoicesResponse;
  runUnassignDriver: RunUnassignDriverResponse;
  runUpdateUserProfileBulk: RunUpdateUserProfileBulkResponse;
  runUpdateUserVehicleBulk: RunUpdateUserVehicleBulkResponse;
  undeleteTripBulk: UndeleteTripBulkResponse;
  updateAirlineBulk: UpdateProviderBulkResponse;
  updateBufferTimeBulk: UpdateBufferTimeBulkResponse;
  updateClientBulk: UpdateProviderBulkResponse;
  updateCombineRuleBulk: UpdateCombineRuleBulkResponse;
  updateCompletionBulk: UpdateCompletionBulkResponse;
  updateEmailBulk: UpdateEmailBulkResponse;
  updateFlagBulk: UpdateFlagBulkResponse;
  updateImportProvider: UpdateImportProviderResponse;
  updateImportsStatus: UpdateImportsStatusResponse;
  updateLicenseBulk: UpdateLicenseBulkResponse;
  updateLocationBulk: UpdateLocationBulkResponse;
  updateLocationById: UpdateLocationByIdResponse;
  updateLostAndFoundIssueBulk: UpdateLostAndFoundIssueBulkResponse;
  updateLostAndFoundIssueDocumentBulk: UpdateLostAndFoundIssueDocumentBulkResponse;
  updatePasswords: UpdatePasswordsResponse;
  updatePickupPointBulk: UpdatePickupPointBulkResponse;
  updatePotentialTripBulk: UpdatePotentialTripBulkResponse;
  updateProviderBulk: UpdateProviderBulkResponse;
  updatePunchPolicyBulk: UpdatePunchPolicyBulkResponse;
  updateRateBulk: UpdateRateBulkResponse;
  updateRateItemBulk: UpdateRateItemBulkResponse;
  updateRateOnTrips: UpdateRateOnTripsResponse;
  updateStagedTripBulk: UpdateStagedTripBulkResponse;
  updateStopBulk: UpdateStopBulkResponse;
  updateTrackFlightBulk: UpdateTrackFlightBulkResponse;
  updateTripBulk: UpdateTripBulkResponse;
  updateUserActivationBulk: UpdateUserActivationBulkResponse;
  updateUserAvailabilityBulk: UpdateUserAvailabilityBulkResponse;
  updateUserAvailabilitySeriesBulk: UpdateUserAvailabilityBulkResponse;
  updateUserBulk: UpdateUserBulkResponse;
  updateVariableTimeBulk: UpdateVariableTimeBulkResponse;
  updateVehicleBulk: UpdateVehicleBulkResponse;
};


export type MutationAddLocationAliasesArgs = {
  input: AddLocationAliasesInput;
};


export type MutationApproveUserAvailabilityArgs = {
  input: ApproveUserAvailabilityInput;
};


export type MutationCombineTripsArgs = {
  input: CreateCombineTripInput;
};


export type MutationCreateActivityBulkArgs = {
  input: CreateActivityBulkInput;
};


export type MutationCreateAirlineBulkArgs = {
  input: CreateProviderBulkInput;
};


export type MutationCreateAirportHasVehicleBulkArgs = {
  input: CreateAirportHasVehicleBulkInput;
};


export type MutationCreateBufferTimeBulkArgs = {
  input: CreateBufferTimeBulkInput;
};


export type MutationCreateBufferTimesArgs = {
  input: CreateBufferTimesInput;
};


export type MutationCreateClientBulkArgs = {
  input: CreateProviderBulkInput;
};


export type MutationCreateCombineRuleBulkArgs = {
  input: CreateCombineRuleBulkInput;
};


export type MutationCreateCommunicationBulkArgs = {
  input: CreateCommunicationBulkInput;
};


export type MutationCreateCompletionBulkArgs = {
  input: CreateCompletionBulkInput;
};


export type MutationCreateCrewTripArgs = {
  input: CreateCrewTripInput;
};


export type MutationCreateDownloadImportUrLsArgs = {
  input: CreateDownloadImportUrLsInput;
};


export type MutationCreateEmailBulkArgs = {
  input: CreateEmailBulkInput;
};


export type MutationCreateFcrBulkArgs = {
  input: CreateFcrBulkInput;
};


export type MutationCreateFlagBulkArgs = {
  input: CreateFlagBulkInput;
};


export type MutationCreateFlagHasTripBulkArgs = {
  input: CreateFlagHasTripBulkInput;
};


export type MutationCreateGroupBulkArgs = {
  input: CreateGroupBulkInput;
};


export type MutationCreateGroupHasPermissionBulkArgs = {
  input: CreateGroupHasPermissionBulkInput;
};


export type MutationCreateGroupHasUserBulkArgs = {
  input: CreateGroupHasUserBulkInput;
};


export type MutationCreateImageBulkArgs = {
  input: CreateImageBulkInput;
};


export type MutationCreateImportsArgs = {
  input: CreateImportsInput;
};


export type MutationCreateLicenseBulkArgs = {
  input: CreateLicenseBulkInput;
};


export type MutationCreateLocationBulkArgs = {
  input: CreateLocationBulkInput;
};


export type MutationCreateLocationsArgs = {
  input: CreateLocationsInput;
};


export type MutationCreateLostAndFoundIssueBulkArgs = {
  input: CreateLostAndFoundIssueBulkInput;
};


export type MutationCreateLostAndFoundIssueDocumentBulkArgs = {
  input: CreateLostAndFoundIssueDocumentBulkInput;
};


export type MutationCreatePermissionHasRoleBulkArgs = {
  input: CreatePermissionHasRoleBulkInput;
};


export type MutationCreatePermissionHasUserBulkArgs = {
  input: CreatePermissionHasUserBulkInput;
};


export type MutationCreatePickupPointBulkArgs = {
  input: CreatePickupPointBulkInput;
};


export type MutationCreatePotentialTripBulkArgs = {
  input: CreatePotentialTripBulkInput;
};


export type MutationCreateProviderBulkArgs = {
  input: CreateProviderBulkInput;
};


export type MutationCreatePunchPolicyBulkArgs = {
  input: CreatePunchPolicyBulkInput;
};


export type MutationCreateRateBulkArgs = {
  input: CreateRateBulkInput;
};


export type MutationCreateRateForTripsArgs = {
  input: CreateRateForTripsInput;
};


export type MutationCreateRateGroupBulkArgs = {
  input: CreateRateGroupBulkInput;
};


export type MutationCreateRateItemBulkArgs = {
  input: CreateRateItemBulkInput;
};


export type MutationCreateRatingBulkArgs = {
  input: CreateRatingBulkInput;
};


export type MutationCreateRatingRemarkBulkArgs = {
  input: CreateRatingRemarkBulkInput;
};


export type MutationCreateRoleBulkArgs = {
  input: CreateRoleBulkInput;
};


export type MutationCreateStopBulkArgs = {
  input: CreateStopBulkInput;
};


export type MutationCreateTripsArgs = {
  input: CreateTripsInput;
};


export type MutationCreateUserAvailabilityBulkArgs = {
  input: CreateUserAvailabilityBulkInput;
};


export type MutationCreateUserBulkArgs = {
  input: CreateUserBulkInput;
};


export type MutationCreateVariableTimeBulkArgs = {
  input: CreateVariableTimeBulkInput;
};


export type MutationCreateVehicleBulkArgs = {
  input: CreateVehicleBulkInput;
};


export type MutationDeleteAirlineBulkArgs = {
  input: DeleteProviderBulkInput;
};


export type MutationDeleteAirportHasVehicleBulkArgs = {
  input: DeleteAirportHasVehicleBulkInput;
};


export type MutationDeleteBufferTimeBulkArgs = {
  input: DeleteBufferTimeBulkInput;
};


export type MutationDeleteBufferTimesArgs = {
  input: DeleteBufferTimesInput;
};


export type MutationDeleteClientBulkArgs = {
  input: DeleteProviderBulkInput;
};


export type MutationDeleteCombineRuleBulkArgs = {
  input: DeleteCombineRuleBulkInput;
};


export type MutationDeleteCommunicationBulkArgs = {
  input: DeleteCommunicationBulkInput;
};


export type MutationDeleteCompletionBulkArgs = {
  input: DeleteCompletionBulkInput;
};


export type MutationDeleteEmailBulkArgs = {
  input: DeleteEmailBulkInput;
};


export type MutationDeleteFcrBulkArgs = {
  input: DeleteFcrBulkInput;
};


export type MutationDeleteFlagBulkArgs = {
  input: DeleteFlagBulkInput;
};


export type MutationDeleteFlagHasTripBulkArgs = {
  input: DeleteFlagHasTripBulkInput;
};


export type MutationDeleteGroupBulkArgs = {
  input: DeleteGroupBulkInput;
};


export type MutationDeleteGroupHasPermissionBulkArgs = {
  input: DeleteGroupHasPermissionBulkInput;
};


export type MutationDeleteGroupHasUserBulkArgs = {
  input: DeleteGroupHasUserBulkInput;
};


export type MutationDeleteImageBulkArgs = {
  input: DeleteImageBulkInput;
};


export type MutationDeleteLicenseBulkArgs = {
  input: DeleteLicenseBulkInput;
};


export type MutationDeleteLocationBulkArgs = {
  input: DeleteLocationBulkInput;
};


export type MutationDeleteLostAndFoundIssueBulkArgs = {
  input: DeleteLostAndFoundIssueBulkInput;
};


export type MutationDeleteLostAndFoundIssueDocumentBulkArgs = {
  input: DeleteLostAndFoundIssueDocumentBulkInput;
};


export type MutationDeletePermissionHasRoleBulkArgs = {
  input: DeletePermissionHasRoleBulkInput;
};


export type MutationDeletePermissionHasUserBulkArgs = {
  input: DeletePermissionHasUserBulkInput;
};


export type MutationDeletePickupPointBulkArgs = {
  input: DeletePickupPointBulkInput;
};


export type MutationDeleteProviderBulkArgs = {
  input: DeleteProviderBulkInput;
};


export type MutationDeletePunchPolicyBulkArgs = {
  input: DeletePunchPolicyBulkInput;
};


export type MutationDeleteRateBulkArgs = {
  input: DeleteRateBulkInput;
};


export type MutationDeleteRateGroupBulkArgs = {
  input: DeleteRateGroupBulkInput;
};


export type MutationDeleteRateItemBulkArgs = {
  input: DeleteRateItemBulkInput;
};


export type MutationDeleteRoleBulkArgs = {
  input: DeleteRoleBulkInput;
};


export type MutationDeleteStopBulkArgs = {
  input: DeleteStopBulkInput;
};


export type MutationDeleteTripBulkArgs = {
  input: DeleteTripBulkInput;
};


export type MutationDeleteTripHardBulkArgs = {
  input: DeleteTripHardBulkInput;
};


export type MutationDeleteUserAvailabilityAndUnassignBulkArgs = {
  input: DeleteUserAvailabilityBulkInput;
};


export type MutationDeleteUserAvailabilityBulkArgs = {
  input: DeleteUserAvailabilityBulkInput;
};


export type MutationDeleteUserAvailabilitySeriesAndUnassignBulkArgs = {
  input: DeleteUserAvailabilityBulkInput;
};


export type MutationDeleteUserAvailabilitySeriesBulkArgs = {
  input: DeleteUserAvailabilityBulkInput;
};


export type MutationDeleteUserBulkArgs = {
  input: DeleteUserBulkInput;
};


export type MutationDeleteVariableTimeBulkArgs = {
  input: DeleteVariableTimeBulkInput;
};


export type MutationDeleteVehicleBulkArgs = {
  input: DeleteVehicleBulkInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationGeneratePunchHistoryUploadUrlsArgs = {
  input: GeneratePunchHistoryUploadUrlsInput;
};


export type MutationGenerateUploadProfileImageUrlArgs = {
  input: GenerateUploadProfileImageUrlInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationReportProblemArgs = {
  input: ReportProblemInput;
};


export type MutationResetPasswordLinkCodeArgs = {
  input: ResetPasswordLinkCodeInput;
};


export type MutationRunApplyRateToSimilarTripsArgs = {
  input: RunApplyRateToSimilarTripsInput;
};


export type MutationRunArchiveImportsArgs = {
  input: RunArchiveImportsInput;
};


export type MutationRunArchiveInvoicesArgs = {
  input: RunArchiveInvoicesInput;
};


export type MutationRunAssignDriverArgs = {
  input: RunAssignDriverInput;
};


export type MutationRunAssignVehicleToDriverBulkArgs = {
  input: RunAssignVehicleToDriverBulkInput;
};


export type MutationRunAttemptAssignDriverAndCombineTripsArgs = {
  input: RunAttemptAssignDriverAndCombineTripsInput;
};


export type MutationRunCompletionOnTripArgs = {
  input: RunCompletionOnTripInput;
};


export type MutationRunDownloadImportArgs = {
  input: RunDownloadImportInput;
};


export type MutationRunDownloadInvoiceArgs = {
  input: RunDownloadInvoiceInput;
};


export type MutationRunDownloadLatestInvoiceDocumentArgs = {
  input: RunDownloadLatestInvoiceDocumentInput;
};


export type MutationRunIngestImportsArgs = {
  input: RunIngestImportsInput;
};


export type MutationRunOverrideImportsArgs = {
  input: RunOverrideImportsInput;
};


export type MutationRunParseImportLocationsArgs = {
  input: RunParseImportLocationsInput;
};


export type MutationRunParseImportsArgs = {
  input: RunParseImportsInput;
};


export type MutationRunPreviewImportsArgs = {
  input: RunPreviewImportsInput;
};


export type MutationRunPreviewInvoiceArgs = {
  input: RunPreviewInvoiceInput;
};


export type MutationRunRateAmountOnTripsArgs = {
  input: RunRateAmountOnTripsInput;
};


export type MutationRunRateOverrideArgs = {
  input: RunRateOverrideInput;
};


export type MutationRunRemoveCompletionOnTripArgs = {
  input: RunRemoveCompletionOnTripInput;
};


export type MutationRunResetUserPasswordBulkArgs = {
  input: RunResetUserPasswordBulkInput;
};


export type MutationRunRollbackImportsArgs = {
  input: RunRollbackImportsInput;
};


export type MutationRunSaveAndSendInvoiceArgs = {
  input: RunSaveAndSendInvoiceInput;
};


export type MutationRunSaveAsInvoiceArgs = {
  input: RunSaveAsInvoiceInput;
};


export type MutationRunSaveInvoiceArgs = {
  input: RunSaveInvoiceInput;
};


export type MutationRunUnarchiveInvoicesArgs = {
  input: RunUnarchiveInvoicesInput;
};


export type MutationRunUnassignDriverArgs = {
  input: RunUnassignDriverInput;
};


export type MutationRunUpdateUserProfileBulkArgs = {
  input: RunUpdateUserProfileBulkInput;
};


export type MutationRunUpdateUserVehicleBulkArgs = {
  input: RunUpdateUserVehicleBulkInput;
};


export type MutationUndeleteTripBulkArgs = {
  input: UndeleteTripBulkInput;
};


export type MutationUpdateAirlineBulkArgs = {
  input: UpdateProviderBulkInput;
};


export type MutationUpdateBufferTimeBulkArgs = {
  input: UpdateBufferTimeBulkInput;
};


export type MutationUpdateClientBulkArgs = {
  input: UpdateProviderBulkInput;
};


export type MutationUpdateCombineRuleBulkArgs = {
  input: UpdateCombineRuleBulkInput;
};


export type MutationUpdateCompletionBulkArgs = {
  input: UpdateCompletionBulkInput;
};


export type MutationUpdateEmailBulkArgs = {
  input: UpdateEmailBulkInput;
};


export type MutationUpdateFlagBulkArgs = {
  input: UpdateFlagBulkInput;
};


export type MutationUpdateImportProviderArgs = {
  input: UpdateImportProviderInput;
};


export type MutationUpdateImportsStatusArgs = {
  input: UpdateImportsStatusInput;
};


export type MutationUpdateLicenseBulkArgs = {
  input: UpdateLicenseBulkInput;
};


export type MutationUpdateLocationBulkArgs = {
  input: UpdateLocationBulkInput;
};


export type MutationUpdateLocationByIdArgs = {
  input: UpdateLocationByIdInput;
};


export type MutationUpdateLostAndFoundIssueBulkArgs = {
  input: UpdateLostAndFoundIssueBulkInput;
};


export type MutationUpdateLostAndFoundIssueDocumentBulkArgs = {
  input: UpdateLostAndFoundIssueDocumentBulkInput;
};


export type MutationUpdatePasswordsArgs = {
  input: UpdatePasswordsInput;
};


export type MutationUpdatePickupPointBulkArgs = {
  input: UpdatePickupPointBulkInput;
};


export type MutationUpdatePotentialTripBulkArgs = {
  input: UpdatePotentialTripBulkInput;
};


export type MutationUpdateProviderBulkArgs = {
  input: UpdateProviderBulkInput;
};


export type MutationUpdatePunchPolicyBulkArgs = {
  input: UpdatePunchPolicyBulkInput;
};


export type MutationUpdateRateBulkArgs = {
  input: UpdateRateBulkInput;
};


export type MutationUpdateRateItemBulkArgs = {
  input: UpdateRateItemBulkInput;
};


export type MutationUpdateRateOnTripsArgs = {
  input: UpdateRateOnTripsInput;
};


export type MutationUpdateStagedTripBulkArgs = {
  input: UpdateStagedTripBulkInput;
};


export type MutationUpdateStopBulkArgs = {
  input: UpdateStopBulkInput;
};


export type MutationUpdateTrackFlightBulkArgs = {
  input: UpdateTrackFlightBulkInput;
};


export type MutationUpdateTripBulkArgs = {
  input: UpdateTripBulkInput;
};


export type MutationUpdateUserActivationBulkArgs = {
  input: UpdateUserActivationBulkInput;
};


export type MutationUpdateUserAvailabilityBulkArgs = {
  input: UpdateUserAvailabilityBulkInput;
};


export type MutationUpdateUserAvailabilitySeriesBulkArgs = {
  input: UpdateUserAvailabilityBulkInput;
};


export type MutationUpdateUserBulkArgs = {
  input: UpdateUserBulkInput;
};


export type MutationUpdateVariableTimeBulkArgs = {
  input: UpdateVariableTimeBulkInput;
};


export type MutationUpdateVehicleBulkArgs = {
  input: UpdateVehicleBulkInput;
};

export type MutationCrud = {
  __typename?: 'MutationCrud';
  createActivityBulk: CreateActivityBulkResponse;
  createActivityObjectBulk: CreateActivityObjectBulkResponse;
  createAirportBulk: CreateAirportBulkResponse;
  createAirportGroupBulk: CreateAirportGroupBulkResponse;
  createAirportHasCompanyBulk: CreateAirportHasCompanyBulkResponse;
  createAirportHasGroupBulk: CreateAirportHasGroupBulkResponse;
  createAirportHasLocationBulk: CreateAirportHasLocationBulkResponse;
  createAirportHasRateBulk: CreateAirportHasRateBulkResponse;
  createAirportHasVehicleBulk: CreateAirportHasVehicleBulkResponse;
  createAliasBulk: CreateAliasBulkResponse;
  createAutoCombineRuleBulk: CreateAutoCombineRuleBulkResponse;
  createBufferTimeBulk: CreateBufferTimeBulkResponse;
  createCancellationRuleBulk: CreateCancellationRuleBulkResponse;
  createCheckinEventBulk: CreateCheckinEventBulkResponse;
  createCombineRuleBulk: CreateCombineRuleBulkResponse;
  createCommunicationBulk: CreateCommunicationBulkResponse;
  createCompanyBulk: CreateCompanyBulkResponse;
  createCompletionBulk: CreateCompletionBulkResponse;
  createCompletionTypeBulk: CreateCompletionTypeBulkResponse;
  createContractBulk: CreateContractBulkResponse;
  createContractEmailBulk: CreateContractEmailBulkResponse;
  createContractNumberBulk: CreateContractNumberBulkResponse;
  createCountryRegionBulk: CreateCountryRegionBulkResponse;
  createDeviceBulk: CreateDeviceBulkResponse;
  createDistanceBulk: CreateDistanceBulkResponse;
  createEmailBulk: CreateEmailBulkResponse;
  createEquivalentTripBulk: CreateEquivalentTripBulkResponse;
  createFcrBulk: CreateFcrBulkResponse;
  createFcrTypeBulk: CreateFcrTypeBulkResponse;
  createFlagBulk: CreateFlagBulkResponse;
  createFlagHasTripBulk: CreateFlagHasTripBulkResponse;
  createGroupBulk: CreateGroupBulkResponse;
  createGroupHasPermissionBulk: CreateGroupHasPermissionBulkResponse;
  createGroupHasUserBulk: CreateGroupHasUserBulkResponse;
  createImageBulk: CreateImageBulkResponse;
  createImportBulk: CreateImportBulkResponse;
  createInvoiceAirportBulk: CreateInvoiceAirportBulkResponse;
  createInvoiceBulk: CreateInvoiceBulkResponse;
  createInvoiceDocumentBulk: CreateInvoiceDocumentBulkResponse;
  createInvoiceEmailBulk: CreateInvoiceEmailBulkResponse;
  createInvoiceHeaderBulk: CreateInvoiceHeaderBulkResponse;
  createInvoiceIataCodeBulk: CreateInvoiceIataCodeBulkResponse;
  createInvoiceLineItemBulk: CreateInvoiceLineItemBulkResponse;
  createInvoiceTermBulk: CreateInvoiceTermBulkResponse;
  createInvoiceTripBulk: CreateInvoiceTripBulkResponse;
  createInvoiceTripStatusBulk: CreateInvoiceTripStatusBulkResponse;
  createInvoiceTypeBulk: CreateInvoiceTypeBulkResponse;
  createIssueBulk: CreateIssueBulkResponse;
  createLicenseBulk: CreateLicenseBulkResponse;
  createLicenseTypeBulk: CreateLicenseTypeBulkResponse;
  createLineItemTypeBulk: CreateLineItemTypeBulkResponse;
  createLocationBulk: CreateLocationBulkResponse;
  createLoginEventBulk: CreateLoginEventBulkResponse;
  createLostAndFoundIssueBulk: CreateLostAndFoundIssueBulkResponse;
  createLostAndFoundIssueDocumentBulk: CreateLostAndFoundIssueDocumentBulkResponse;
  createMessageBulk: CreateMessageBulkResponse;
  createPermissionBulk: CreatePermissionBulkResponse;
  createPermissionHasRoleBulk: CreatePermissionHasRoleBulkResponse;
  createPermissionHasUserBulk: CreatePermissionHasUserBulkResponse;
  createPickupPointBulk: CreatePickupPointBulkResponse;
  createPotentialTripBulk: CreatePotentialTripBulkResponse;
  createProviderBulk: CreateProviderBulkResponse;
  createProviderLocationMappingBulk: CreateProviderLocationMappingBulkResponse;
  createPunchHistoryBulk: CreatePunchHistoryBulkResponse;
  createPunchPolicyBulk: CreatePunchPolicyBulkResponse;
  createRateBulk: CreateRateBulkResponse;
  createRateGroupBulk: CreateRateGroupBulkResponse;
  createRateHistoryBulk: CreateRateHistoryBulkResponse;
  createRateItemBulk: CreateRateItemBulkResponse;
  createRatingBulk: CreateRatingBulkResponse;
  createRatingRemarkBulk: CreateRatingRemarkBulkResponse;
  createRatingTypeBulk: CreateRatingTypeBulkResponse;
  createRoleBulk: CreateRoleBulkResponse;
  createSettingsBulk: CreateSettingsBulkResponse;
  createSocialDistanceRuleBulk: CreateSocialDistanceRuleBulkResponse;
  createStagedTripBulk: CreateStagedTripBulkResponse;
  createStopBulk: CreateStopBulkResponse;
  createStorageBulk: CreateStorageBulkResponse;
  createTrackFlightBulk: CreateTrackFlightBulkResponse;
  createTripBulk: CreateTripBulkResponse;
  createTripScheduleTriggerBulk: CreateTripScheduleTriggerBulkResponse;
  createUpdateStrategyBulk: CreateUpdateStrategyBulkResponse;
  createUserAvailabilityBulk: CreateUserAvailabilityBulkResponse;
  createUserBulk: CreateUserBulkResponse;
  createVariableTimeBulk: CreateVariableTimeBulkResponse;
  createVehicleBulk: CreateVehicleBulkResponse;
  deleteActivityBulk: DeleteActivityBulkResponse;
  deleteActivityObjectBulk: DeleteActivityObjectBulkResponse;
  deleteAirportBulk: DeleteAirportBulkResponse;
  deleteAirportGroupBulk: DeleteAirportGroupBulkResponse;
  deleteAirportHasCompanyBulk: DeleteAirportHasCompanyBulkResponse;
  deleteAirportHasGroupBulk: DeleteAirportHasGroupBulkResponse;
  deleteAirportHasLocationBulk: DeleteAirportHasLocationBulkResponse;
  deleteAirportHasRateBulk: DeleteAirportHasRateBulkResponse;
  deleteAirportHasVehicleBulk: DeleteAirportHasVehicleBulkResponse;
  deleteAliasBulk: DeleteAliasBulkResponse;
  deleteAutoCombineRuleBulk: DeleteAutoCombineRuleBulkResponse;
  deleteBufferTimeBulk: DeleteBufferTimeBulkResponse;
  deleteCancellationRuleBulk: DeleteCancellationRuleBulkResponse;
  deleteCheckinEventBulk: DeleteCheckinEventBulkResponse;
  deleteCombineRuleBulk: DeleteCombineRuleBulkResponse;
  deleteCommunicationBulk: DeleteCommunicationBulkResponse;
  deleteCompanyBulk: DeleteCompanyBulkResponse;
  deleteCompletionBulk: DeleteCompletionBulkResponse;
  deleteCompletionTypeBulk: DeleteCompletionTypeBulkResponse;
  deleteContractBulk: DeleteContractBulkResponse;
  deleteContractEmailBulk: DeleteContractEmailBulkResponse;
  deleteContractNumberBulk: DeleteContractNumberBulkResponse;
  deleteCountryRegionBulk: DeleteCountryRegionBulkResponse;
  deleteDeviceBulk: DeleteDeviceBulkResponse;
  deleteDistanceBulk: DeleteDistanceBulkResponse;
  deleteEmailBulk: DeleteEmailBulkResponse;
  deleteEquivalentTripBulk: DeleteEquivalentTripBulkResponse;
  deleteFcrBulk: DeleteFcrBulkResponse;
  deleteFcrTypeBulk: DeleteFcrTypeBulkResponse;
  deleteFlagBulk: DeleteFlagBulkResponse;
  deleteFlagHasTripBulk: DeleteFlagHasTripBulkResponse;
  deleteGroupBulk: DeleteGroupBulkResponse;
  deleteGroupHasPermissionBulk: DeleteGroupHasPermissionBulkResponse;
  deleteGroupHasUserBulk: DeleteGroupHasUserBulkResponse;
  deleteImageBulk: DeleteImageBulkResponse;
  deleteImportBulk: DeleteImportBulkResponse;
  deleteInvoiceBulk: DeleteInvoiceBulkResponse;
  deleteInvoiceTermBulk: DeleteInvoiceTermBulkResponse;
  deleteIssueBulk: DeleteIssueBulkResponse;
  deleteLicenseBulk: DeleteLicenseBulkResponse;
  deleteLicenseTypeBulk: DeleteLicenseTypeBulkResponse;
  deleteLocationBulk: DeleteLocationBulkResponse;
  deleteLoginEventBulk: DeleteLoginEventBulkResponse;
  deleteLostAndFoundIssueBulk: DeleteLostAndFoundIssueBulkResponse;
  deleteLostAndFoundIssueDocumentBulk: DeleteLostAndFoundIssueDocumentBulkResponse;
  deleteMessageBulk: DeleteMessageBulkResponse;
  deletePermissionBulk: DeletePermissionBulkResponse;
  deletePermissionHasRoleBulk: DeletePermissionHasRoleBulkResponse;
  deletePermissionHasUserBulk: DeletePermissionHasUserBulkResponse;
  deletePickupPointBulk: DeletePickupPointBulkResponse;
  deletePotentialTripBulk: DeletePotentialTripBulkResponse;
  deleteProviderBulk: DeleteProviderBulkResponse;
  deleteProviderLocationMappingBulk: DeleteProviderLocationMappingBulkResponse;
  deletePunchHistoryBulk: DeletePunchHistoryBulkResponse;
  deletePunchPolicyBulk: DeletePunchPolicyBulkResponse;
  deleteRateBulk: DeleteRateBulkResponse;
  deleteRateGroupBulk: DeleteRateGroupBulkResponse;
  deleteRateHistoryBulk: DeleteRateHistoryBulkResponse;
  deleteRateItemBulk: DeleteRateItemBulkResponse;
  deleteRatingBulk: DeleteRatingBulkResponse;
  deleteRatingRemarkBulk: DeleteRatingRemarkBulkResponse;
  deleteRatingTypeBulk: DeleteRatingTypeBulkResponse;
  deleteRoleBulk: DeleteRoleBulkResponse;
  deleteSettingsBulk: DeleteSettingsBulkResponse;
  deleteSocialDistanceRuleBulk: DeleteSocialDistanceRuleBulkResponse;
  deleteStagedTripBulk: DeleteStagedTripBulkResponse;
  deleteStopBulk: DeleteStopBulkResponse;
  deleteStorageBulk: DeleteStorageBulkResponse;
  deleteTrackFlightBulk: DeleteTrackFlightBulkResponse;
  deleteTripBulk: DeleteTripBulkResponse;
  deleteTripScheduleTriggerBulk: DeleteTripScheduleTriggerBulkResponse;
  deleteUpdateStrategyBulk: DeleteUpdateStrategyBulkResponse;
  deleteUserAvailabilityBulk: DeleteUserAvailabilityBulkResponse;
  deleteUserBulk: DeleteUserBulkResponse;
  deleteVariableTimeBulk: DeleteVariableTimeBulkResponse;
  deleteVehicleBulk: DeleteVehicleBulkResponse;
  updateActivityBulk: UpdateActivityBulkResponse;
  updateActivityObjectBulk: UpdateActivityObjectBulkResponse;
  updateAirportBulk: UpdateAirportBulkResponse;
  updateAirportGroupBulk: UpdateAirportGroupBulkResponse;
  updateAirportHasCompanyBulk: UpdateAirportHasCompanyBulkResponse;
  updateAirportHasGroupBulk: UpdateAirportHasGroupBulkResponse;
  updateAirportHasLocationBulk: UpdateAirportHasLocationBulkResponse;
  updateAirportHasRateBulk: UpdateAirportHasRateBulkResponse;
  updateAirportHasVehicleBulk: UpdateAirportHasVehicleBulkResponse;
  updateAliasBulk: UpdateAliasBulkResponse;
  updateAutoCombineRuleBulk: UpdateAutoCombineRuleBulkResponse;
  updateBufferTimeBulk: UpdateBufferTimeBulkResponse;
  updateCancellationRuleBulk: UpdateCancellationRuleBulkResponse;
  updateCheckinEventBulk: UpdateCheckinEventBulkResponse;
  updateCombineRuleBulk: UpdateCombineRuleBulkResponse;
  updateCommunicationBulk: UpdateCommunicationBulkResponse;
  updateCompanyBulk: UpdateCompanyBulkResponse;
  updateCompletionBulk: UpdateCompletionBulkResponse;
  updateCompletionTypeBulk: UpdateCompletionTypeBulkResponse;
  updateContractBulk: UpdateContractBulkResponse;
  updateContractEmailBulk: UpdateContractEmailBulkResponse;
  updateContractNumberBulk: UpdateContractNumberBulkResponse;
  updateCountryRegionBulk: UpdateCountryRegionBulkResponse;
  updateDeviceBulk: UpdateDeviceBulkResponse;
  updateDistanceBulk: UpdateDistanceBulkResponse;
  updateEmailBulk: UpdateEmailBulkResponse;
  updateEquivalentTripBulk: UpdateEquivalentTripBulkResponse;
  updateFcrBulk: UpdateFcrBulkResponse;
  updateFcrTypeBulk: UpdateFcrTypeBulkResponse;
  updateFlagBulk: UpdateFlagBulkResponse;
  updateFlagHasTripBulk: UpdateFlagHasTripBulkResponse;
  updateGroupBulk: UpdateGroupBulkResponse;
  updateGroupHasPermissionBulk: UpdateGroupHasPermissionBulkResponse;
  updateGroupHasUserBulk: UpdateGroupHasUserBulkResponse;
  updateImageBulk: UpdateImageBulkResponse;
  updateImportBulk: UpdateImportBulkResponse;
  updateInvoiceTermBulk: UpdateInvoiceTermBulkResponse;
  updateIssueBulk: UpdateIssueBulkResponse;
  updateLicenseBulk: UpdateLicenseBulkResponse;
  updateLicenseTypeBulk: UpdateLicenseTypeBulkResponse;
  updateLocationBulk: UpdateLocationBulkResponse;
  updateLoginEventBulk: UpdateLoginEventBulkResponse;
  updateLostAndFoundIssueBulk: UpdateLostAndFoundIssueBulkResponse;
  updateLostAndFoundIssueDocumentBulk: UpdateLostAndFoundIssueDocumentBulkResponse;
  updateMessageBulk: UpdateMessageBulkResponse;
  updatePermissionBulk: UpdatePermissionBulkResponse;
  updatePermissionHasRoleBulk: UpdatePermissionHasRoleBulkResponse;
  updatePermissionHasUserBulk: UpdatePermissionHasUserBulkResponse;
  updatePickupPointBulk: UpdatePickupPointBulkResponse;
  updatePotentialTripBulk: UpdatePotentialTripBulkResponse;
  updateProviderBulk: UpdateProviderBulkResponse;
  updateProviderLocationMappingBulk: UpdateProviderLocationMappingBulkResponse;
  updatePunchHistoryBulk: UpdatePunchHistoryBulkResponse;
  updatePunchPolicyBulk: UpdatePunchPolicyBulkResponse;
  updateRateBulk: UpdateRateBulkResponse;
  updateRateGroupBulk: UpdateRateGroupBulkResponse;
  updateRateHistoryBulk: UpdateRateHistoryBulkResponse;
  updateRateItemBulk: UpdateRateItemBulkResponse;
  updateRatingBulk: UpdateRatingBulkResponse;
  updateRatingRemarkBulk: UpdateRatingRemarkBulkResponse;
  updateRatingTypeBulk: UpdateRatingTypeBulkResponse;
  updateRoleBulk: UpdateRoleBulkResponse;
  updateSettingsBulk: UpdateSettingsBulkResponse;
  updateSocialDistanceRuleBulk: UpdateSocialDistanceRuleBulkResponse;
  updateStagedTripBulk: UpdateStagedTripBulkResponse;
  updateStopBulk: UpdateStopBulkResponse;
  updateStorageBulk: UpdateStorageBulkResponse;
  updateTrackFlightBulk: UpdateTrackFlightBulkResponse;
  updateTripBulk: UpdateTripBulkResponse;
  updateTripScheduleTriggerBulk: UpdateTripScheduleTriggerBulkResponse;
  updateUpdateStrategyBulk: UpdateUpdateStrategyBulkResponse;
  updateUserAvailabilityBulk: UpdateUserAvailabilityBulkResponse;
  updateUserBulk: UpdateUserBulkResponse;
  updateVariableTimeBulk: UpdateVariableTimeBulkResponse;
  updateVehicleBulk: UpdateVehicleBulkResponse;
};


export type MutationCrudCreateActivityBulkArgs = {
  input: CreateActivityBulkInput;
};


export type MutationCrudCreateActivityObjectBulkArgs = {
  input: CreateActivityObjectBulkInput;
};


export type MutationCrudCreateAirportBulkArgs = {
  input: CreateAirportBulkInput;
};


export type MutationCrudCreateAirportGroupBulkArgs = {
  input: CreateAirportGroupBulkInput;
};


export type MutationCrudCreateAirportHasCompanyBulkArgs = {
  input: CreateAirportHasCompanyBulkInput;
};


export type MutationCrudCreateAirportHasGroupBulkArgs = {
  input: CreateAirportHasGroupBulkInput;
};


export type MutationCrudCreateAirportHasLocationBulkArgs = {
  input: CreateAirportHasLocationBulkInput;
};


export type MutationCrudCreateAirportHasRateBulkArgs = {
  input: CreateAirportHasRateBulkInput;
};


export type MutationCrudCreateAirportHasVehicleBulkArgs = {
  input: CreateAirportHasVehicleBulkInput;
};


export type MutationCrudCreateAliasBulkArgs = {
  input: CreateAliasBulkInput;
};


export type MutationCrudCreateAutoCombineRuleBulkArgs = {
  input: CreateAutoCombineRuleBulkInput;
};


export type MutationCrudCreateBufferTimeBulkArgs = {
  input: CreateBufferTimeBulkInput;
};


export type MutationCrudCreateCancellationRuleBulkArgs = {
  input: CreateCancellationRuleBulkInput;
};


export type MutationCrudCreateCheckinEventBulkArgs = {
  input: CreateCheckinEventBulkInput;
};


export type MutationCrudCreateCombineRuleBulkArgs = {
  input: CreateCombineRuleBulkInput;
};


export type MutationCrudCreateCommunicationBulkArgs = {
  input: CreateCommunicationBulkInput;
};


export type MutationCrudCreateCompanyBulkArgs = {
  input: CreateCompanyBulkInput;
};


export type MutationCrudCreateCompletionBulkArgs = {
  input: CreateCompletionBulkInput;
};


export type MutationCrudCreateCompletionTypeBulkArgs = {
  input: CreateCompletionTypeBulkInput;
};


export type MutationCrudCreateContractBulkArgs = {
  input: CreateContractBulkInput;
};


export type MutationCrudCreateContractEmailBulkArgs = {
  input: CreateContractEmailBulkInput;
};


export type MutationCrudCreateContractNumberBulkArgs = {
  input: CreateContractNumberBulkInput;
};


export type MutationCrudCreateCountryRegionBulkArgs = {
  input: CreateCountryRegionBulkInput;
};


export type MutationCrudCreateDeviceBulkArgs = {
  input: CreateDeviceBulkInput;
};


export type MutationCrudCreateDistanceBulkArgs = {
  input: CreateDistanceBulkInput;
};


export type MutationCrudCreateEmailBulkArgs = {
  input: CreateEmailBulkInput;
};


export type MutationCrudCreateEquivalentTripBulkArgs = {
  input: CreateEquivalentTripBulkInput;
};


export type MutationCrudCreateFcrBulkArgs = {
  input: CreateFcrBulkInput;
};


export type MutationCrudCreateFcrTypeBulkArgs = {
  input: CreateFcrTypeBulkInput;
};


export type MutationCrudCreateFlagBulkArgs = {
  input: CreateFlagBulkInput;
};


export type MutationCrudCreateFlagHasTripBulkArgs = {
  input: CreateFlagHasTripBulkInput;
};


export type MutationCrudCreateGroupBulkArgs = {
  input: CreateGroupBulkInput;
};


export type MutationCrudCreateGroupHasPermissionBulkArgs = {
  input: CreateGroupHasPermissionBulkInput;
};


export type MutationCrudCreateGroupHasUserBulkArgs = {
  input: CreateGroupHasUserBulkInput;
};


export type MutationCrudCreateImageBulkArgs = {
  input: CreateImageBulkInput;
};


export type MutationCrudCreateImportBulkArgs = {
  input: CreateImportBulkInput;
};


export type MutationCrudCreateInvoiceAirportBulkArgs = {
  input: CreateInvoiceAirportBulkInput;
};


export type MutationCrudCreateInvoiceBulkArgs = {
  input: CreateInvoiceBulkInput;
};


export type MutationCrudCreateInvoiceDocumentBulkArgs = {
  input: CreateInvoiceDocumentBulkInput;
};


export type MutationCrudCreateInvoiceEmailBulkArgs = {
  input: CreateInvoiceEmailBulkInput;
};


export type MutationCrudCreateInvoiceHeaderBulkArgs = {
  input: CreateInvoiceHeaderBulkInput;
};


export type MutationCrudCreateInvoiceIataCodeBulkArgs = {
  input: CreateInvoiceIataCodeBulkInput;
};


export type MutationCrudCreateInvoiceLineItemBulkArgs = {
  input: CreateInvoiceLineItemBulkInput;
};


export type MutationCrudCreateInvoiceTermBulkArgs = {
  input: CreateInvoiceTermBulkInput;
};


export type MutationCrudCreateInvoiceTripBulkArgs = {
  input: CreateInvoiceTripBulkInput;
};


export type MutationCrudCreateInvoiceTripStatusBulkArgs = {
  input: CreateInvoiceTripStatusBulkInput;
};


export type MutationCrudCreateInvoiceTypeBulkArgs = {
  input: CreateInvoiceTypeBulkInput;
};


export type MutationCrudCreateIssueBulkArgs = {
  input: CreateIssueBulkInput;
};


export type MutationCrudCreateLicenseBulkArgs = {
  input: CreateLicenseBulkInput;
};


export type MutationCrudCreateLicenseTypeBulkArgs = {
  input: CreateLicenseTypeBulkInput;
};


export type MutationCrudCreateLineItemTypeBulkArgs = {
  input: CreateLineItemTypeBulkInput;
};


export type MutationCrudCreateLocationBulkArgs = {
  input: CreateLocationBulkInput;
};


export type MutationCrudCreateLoginEventBulkArgs = {
  input: CreateLoginEventBulkInput;
};


export type MutationCrudCreateLostAndFoundIssueBulkArgs = {
  input: CreateLostAndFoundIssueBulkInput;
};


export type MutationCrudCreateLostAndFoundIssueDocumentBulkArgs = {
  input: CreateLostAndFoundIssueDocumentBulkInput;
};


export type MutationCrudCreateMessageBulkArgs = {
  input: CreateMessageBulkInput;
};


export type MutationCrudCreatePermissionBulkArgs = {
  input: CreatePermissionBulkInput;
};


export type MutationCrudCreatePermissionHasRoleBulkArgs = {
  input: CreatePermissionHasRoleBulkInput;
};


export type MutationCrudCreatePermissionHasUserBulkArgs = {
  input: CreatePermissionHasUserBulkInput;
};


export type MutationCrudCreatePickupPointBulkArgs = {
  input: CreatePickupPointBulkInput;
};


export type MutationCrudCreatePotentialTripBulkArgs = {
  input: CreatePotentialTripBulkInput;
};


export type MutationCrudCreateProviderBulkArgs = {
  input: CreateProviderBulkInput;
};


export type MutationCrudCreateProviderLocationMappingBulkArgs = {
  input: CreateProviderLocationMappingBulkInput;
};


export type MutationCrudCreatePunchHistoryBulkArgs = {
  input: CreatePunchHistoryBulkInput;
};


export type MutationCrudCreatePunchPolicyBulkArgs = {
  input: CreatePunchPolicyBulkInput;
};


export type MutationCrudCreateRateBulkArgs = {
  input: CreateRateBulkInput;
};


export type MutationCrudCreateRateGroupBulkArgs = {
  input: CreateRateGroupBulkInput;
};


export type MutationCrudCreateRateHistoryBulkArgs = {
  input: CreateRateHistoryBulkInput;
};


export type MutationCrudCreateRateItemBulkArgs = {
  input: CreateRateItemBulkInput;
};


export type MutationCrudCreateRatingBulkArgs = {
  input: CreateRatingBulkInput;
};


export type MutationCrudCreateRatingRemarkBulkArgs = {
  input: CreateRatingRemarkBulkInput;
};


export type MutationCrudCreateRatingTypeBulkArgs = {
  input: CreateRatingTypeBulkInput;
};


export type MutationCrudCreateRoleBulkArgs = {
  input: CreateRoleBulkInput;
};


export type MutationCrudCreateSettingsBulkArgs = {
  input: CreateSettingsBulkInput;
};


export type MutationCrudCreateSocialDistanceRuleBulkArgs = {
  input: CreateSocialDistanceRuleBulkInput;
};


export type MutationCrudCreateStagedTripBulkArgs = {
  input: CreateStagedTripBulkInput;
};


export type MutationCrudCreateStopBulkArgs = {
  input: CreateStopBulkInput;
};


export type MutationCrudCreateStorageBulkArgs = {
  input: CreateStorageBulkInput;
};


export type MutationCrudCreateTrackFlightBulkArgs = {
  input: CreateTrackFlightBulkInput;
};


export type MutationCrudCreateTripBulkArgs = {
  input: CreateTripBulkInput;
};


export type MutationCrudCreateTripScheduleTriggerBulkArgs = {
  input: CreateTripScheduleTriggerBulkInput;
};


export type MutationCrudCreateUpdateStrategyBulkArgs = {
  input: CreateUpdateStrategyBulkInput;
};


export type MutationCrudCreateUserAvailabilityBulkArgs = {
  input: CreateUserAvailabilityBulkInput;
};


export type MutationCrudCreateUserBulkArgs = {
  input: CreateUserBulkInput;
};


export type MutationCrudCreateVariableTimeBulkArgs = {
  input: CreateVariableTimeBulkInput;
};


export type MutationCrudCreateVehicleBulkArgs = {
  input: CreateVehicleBulkInput;
};


export type MutationCrudDeleteActivityBulkArgs = {
  input: DeleteActivityBulkInput;
};


export type MutationCrudDeleteActivityObjectBulkArgs = {
  input: DeleteActivityObjectBulkInput;
};


export type MutationCrudDeleteAirportBulkArgs = {
  input: DeleteAirportBulkInput;
};


export type MutationCrudDeleteAirportGroupBulkArgs = {
  input: DeleteAirportGroupBulkInput;
};


export type MutationCrudDeleteAirportHasCompanyBulkArgs = {
  input: DeleteAirportHasCompanyBulkInput;
};


export type MutationCrudDeleteAirportHasGroupBulkArgs = {
  input: DeleteAirportHasGroupBulkInput;
};


export type MutationCrudDeleteAirportHasLocationBulkArgs = {
  input: DeleteAirportHasLocationBulkInput;
};


export type MutationCrudDeleteAirportHasRateBulkArgs = {
  input: DeleteAirportHasRateBulkInput;
};


export type MutationCrudDeleteAirportHasVehicleBulkArgs = {
  input: DeleteAirportHasVehicleBulkInput;
};


export type MutationCrudDeleteAliasBulkArgs = {
  input: DeleteAliasBulkInput;
};


export type MutationCrudDeleteAutoCombineRuleBulkArgs = {
  input: DeleteAutoCombineRuleBulkInput;
};


export type MutationCrudDeleteBufferTimeBulkArgs = {
  input: DeleteBufferTimeBulkInput;
};


export type MutationCrudDeleteCancellationRuleBulkArgs = {
  input: DeleteCancellationRuleBulkInput;
};


export type MutationCrudDeleteCheckinEventBulkArgs = {
  input: DeleteCheckinEventBulkInput;
};


export type MutationCrudDeleteCombineRuleBulkArgs = {
  input: DeleteCombineRuleBulkInput;
};


export type MutationCrudDeleteCommunicationBulkArgs = {
  input: DeleteCommunicationBulkInput;
};


export type MutationCrudDeleteCompanyBulkArgs = {
  input: DeleteCompanyBulkInput;
};


export type MutationCrudDeleteCompletionBulkArgs = {
  input: DeleteCompletionBulkInput;
};


export type MutationCrudDeleteCompletionTypeBulkArgs = {
  input: DeleteCompletionTypeBulkInput;
};


export type MutationCrudDeleteContractBulkArgs = {
  input: DeleteContractBulkInput;
};


export type MutationCrudDeleteContractEmailBulkArgs = {
  input: DeleteContractEmailBulkInput;
};


export type MutationCrudDeleteContractNumberBulkArgs = {
  input: DeleteContractNumberBulkInput;
};


export type MutationCrudDeleteCountryRegionBulkArgs = {
  input: DeleteCountryRegionBulkInput;
};


export type MutationCrudDeleteDeviceBulkArgs = {
  input: DeleteDeviceBulkInput;
};


export type MutationCrudDeleteDistanceBulkArgs = {
  input: DeleteDistanceBulkInput;
};


export type MutationCrudDeleteEmailBulkArgs = {
  input: DeleteEmailBulkInput;
};


export type MutationCrudDeleteEquivalentTripBulkArgs = {
  input: DeleteEquivalentTripBulkInput;
};


export type MutationCrudDeleteFcrBulkArgs = {
  input: DeleteFcrBulkInput;
};


export type MutationCrudDeleteFcrTypeBulkArgs = {
  input: DeleteFcrTypeBulkInput;
};


export type MutationCrudDeleteFlagBulkArgs = {
  input: DeleteFlagBulkInput;
};


export type MutationCrudDeleteFlagHasTripBulkArgs = {
  input: DeleteFlagHasTripBulkInput;
};


export type MutationCrudDeleteGroupBulkArgs = {
  input: DeleteGroupBulkInput;
};


export type MutationCrudDeleteGroupHasPermissionBulkArgs = {
  input: DeleteGroupHasPermissionBulkInput;
};


export type MutationCrudDeleteGroupHasUserBulkArgs = {
  input: DeleteGroupHasUserBulkInput;
};


export type MutationCrudDeleteImageBulkArgs = {
  input: DeleteImageBulkInput;
};


export type MutationCrudDeleteImportBulkArgs = {
  input: DeleteImportBulkInput;
};


export type MutationCrudDeleteInvoiceBulkArgs = {
  input: DeleteInvoiceBulkInput;
};


export type MutationCrudDeleteInvoiceTermBulkArgs = {
  input: DeleteInvoiceTermBulkInput;
};


export type MutationCrudDeleteIssueBulkArgs = {
  input: DeleteIssueBulkInput;
};


export type MutationCrudDeleteLicenseBulkArgs = {
  input: DeleteLicenseBulkInput;
};


export type MutationCrudDeleteLicenseTypeBulkArgs = {
  input: DeleteLicenseTypeBulkInput;
};


export type MutationCrudDeleteLocationBulkArgs = {
  input: DeleteLocationBulkInput;
};


export type MutationCrudDeleteLoginEventBulkArgs = {
  input: DeleteLoginEventBulkInput;
};


export type MutationCrudDeleteLostAndFoundIssueBulkArgs = {
  input: DeleteLostAndFoundIssueBulkInput;
};


export type MutationCrudDeleteLostAndFoundIssueDocumentBulkArgs = {
  input: DeleteLostAndFoundIssueDocumentBulkInput;
};


export type MutationCrudDeleteMessageBulkArgs = {
  input: DeleteMessageBulkInput;
};


export type MutationCrudDeletePermissionBulkArgs = {
  input: DeletePermissionBulkInput;
};


export type MutationCrudDeletePermissionHasRoleBulkArgs = {
  input: DeletePermissionHasRoleBulkInput;
};


export type MutationCrudDeletePermissionHasUserBulkArgs = {
  input: DeletePermissionHasUserBulkInput;
};


export type MutationCrudDeletePickupPointBulkArgs = {
  input: DeletePickupPointBulkInput;
};


export type MutationCrudDeletePotentialTripBulkArgs = {
  input: DeletePotentialTripBulkInput;
};


export type MutationCrudDeleteProviderBulkArgs = {
  input: DeleteProviderBulkInput;
};


export type MutationCrudDeleteProviderLocationMappingBulkArgs = {
  input: DeleteProviderLocationMappingBulkInput;
};


export type MutationCrudDeletePunchHistoryBulkArgs = {
  input: DeletePunchHistoryBulkInput;
};


export type MutationCrudDeletePunchPolicyBulkArgs = {
  input: DeletePunchPolicyBulkInput;
};


export type MutationCrudDeleteRateBulkArgs = {
  input: DeleteRateBulkInput;
};


export type MutationCrudDeleteRateGroupBulkArgs = {
  input: DeleteRateGroupBulkInput;
};


export type MutationCrudDeleteRateHistoryBulkArgs = {
  input: DeleteRateHistoryBulkInput;
};


export type MutationCrudDeleteRateItemBulkArgs = {
  input: DeleteRateItemBulkInput;
};


export type MutationCrudDeleteRatingBulkArgs = {
  input: DeleteRatingBulkInput;
};


export type MutationCrudDeleteRatingRemarkBulkArgs = {
  input: DeleteRatingRemarkBulkInput;
};


export type MutationCrudDeleteRatingTypeBulkArgs = {
  input: DeleteRatingTypeBulkInput;
};


export type MutationCrudDeleteRoleBulkArgs = {
  input: DeleteRoleBulkInput;
};


export type MutationCrudDeleteSettingsBulkArgs = {
  input: DeleteSettingsBulkInput;
};


export type MutationCrudDeleteSocialDistanceRuleBulkArgs = {
  input: DeleteSocialDistanceRuleBulkInput;
};


export type MutationCrudDeleteStagedTripBulkArgs = {
  input: DeleteStagedTripBulkInput;
};


export type MutationCrudDeleteStopBulkArgs = {
  input: DeleteStopBulkInput;
};


export type MutationCrudDeleteStorageBulkArgs = {
  input: DeleteStorageBulkInput;
};


export type MutationCrudDeleteTrackFlightBulkArgs = {
  input: DeleteTrackFlightBulkInput;
};


export type MutationCrudDeleteTripBulkArgs = {
  input: DeleteTripBulkInput;
};


export type MutationCrudDeleteTripScheduleTriggerBulkArgs = {
  input: DeleteTripScheduleTriggerBulkInput;
};


export type MutationCrudDeleteUpdateStrategyBulkArgs = {
  input: DeleteUpdateStrategyBulkInput;
};


export type MutationCrudDeleteUserAvailabilityBulkArgs = {
  input: DeleteUserAvailabilityBulkInput;
};


export type MutationCrudDeleteUserBulkArgs = {
  input: DeleteUserBulkInput;
};


export type MutationCrudDeleteVariableTimeBulkArgs = {
  input: DeleteVariableTimeBulkInput;
};


export type MutationCrudDeleteVehicleBulkArgs = {
  input: DeleteVehicleBulkInput;
};


export type MutationCrudUpdateActivityBulkArgs = {
  input: UpdateActivityBulkInput;
};


export type MutationCrudUpdateActivityObjectBulkArgs = {
  input: UpdateActivityObjectBulkInput;
};


export type MutationCrudUpdateAirportBulkArgs = {
  input: UpdateAirportBulkInput;
};


export type MutationCrudUpdateAirportGroupBulkArgs = {
  input: UpdateAirportGroupBulkInput;
};


export type MutationCrudUpdateAirportHasCompanyBulkArgs = {
  input: UpdateAirportHasCompanyBulkInput;
};


export type MutationCrudUpdateAirportHasGroupBulkArgs = {
  input: UpdateAirportHasGroupBulkInput;
};


export type MutationCrudUpdateAirportHasLocationBulkArgs = {
  input: UpdateAirportHasLocationBulkInput;
};


export type MutationCrudUpdateAirportHasRateBulkArgs = {
  input: UpdateAirportHasRateBulkInput;
};


export type MutationCrudUpdateAirportHasVehicleBulkArgs = {
  input: UpdateAirportHasVehicleBulkInput;
};


export type MutationCrudUpdateAliasBulkArgs = {
  input: UpdateAliasBulkInput;
};


export type MutationCrudUpdateAutoCombineRuleBulkArgs = {
  input: UpdateAutoCombineRuleBulkInput;
};


export type MutationCrudUpdateBufferTimeBulkArgs = {
  input: UpdateBufferTimeBulkInput;
};


export type MutationCrudUpdateCancellationRuleBulkArgs = {
  input: UpdateCancellationRuleBulkInput;
};


export type MutationCrudUpdateCheckinEventBulkArgs = {
  input: UpdateCheckinEventBulkInput;
};


export type MutationCrudUpdateCombineRuleBulkArgs = {
  input: UpdateCombineRuleBulkInput;
};


export type MutationCrudUpdateCommunicationBulkArgs = {
  input: UpdateCommunicationBulkInput;
};


export type MutationCrudUpdateCompanyBulkArgs = {
  input: UpdateCompanyBulkInput;
};


export type MutationCrudUpdateCompletionBulkArgs = {
  input: UpdateCompletionBulkInput;
};


export type MutationCrudUpdateCompletionTypeBulkArgs = {
  input: UpdateCompletionTypeBulkInput;
};


export type MutationCrudUpdateContractBulkArgs = {
  input: UpdateContractBulkInput;
};


export type MutationCrudUpdateContractEmailBulkArgs = {
  input: UpdateContractEmailBulkInput;
};


export type MutationCrudUpdateContractNumberBulkArgs = {
  input: UpdateContractNumberBulkInput;
};


export type MutationCrudUpdateCountryRegionBulkArgs = {
  input: UpdateCountryRegionBulkInput;
};


export type MutationCrudUpdateDeviceBulkArgs = {
  input: UpdateDeviceBulkInput;
};


export type MutationCrudUpdateDistanceBulkArgs = {
  input: UpdateDistanceBulkInput;
};


export type MutationCrudUpdateEmailBulkArgs = {
  input: UpdateEmailBulkInput;
};


export type MutationCrudUpdateEquivalentTripBulkArgs = {
  input: UpdateEquivalentTripBulkInput;
};


export type MutationCrudUpdateFcrBulkArgs = {
  input: UpdateFcrBulkInput;
};


export type MutationCrudUpdateFcrTypeBulkArgs = {
  input: UpdateFcrTypeBulkInput;
};


export type MutationCrudUpdateFlagBulkArgs = {
  input: UpdateFlagBulkInput;
};


export type MutationCrudUpdateFlagHasTripBulkArgs = {
  input: UpdateFlagHasTripBulkInput;
};


export type MutationCrudUpdateGroupBulkArgs = {
  input: UpdateGroupBulkInput;
};


export type MutationCrudUpdateGroupHasPermissionBulkArgs = {
  input: UpdateGroupHasPermissionBulkInput;
};


export type MutationCrudUpdateGroupHasUserBulkArgs = {
  input: UpdateGroupHasUserBulkInput;
};


export type MutationCrudUpdateImageBulkArgs = {
  input: UpdateImageBulkInput;
};


export type MutationCrudUpdateImportBulkArgs = {
  input: UpdateImportBulkInput;
};


export type MutationCrudUpdateInvoiceTermBulkArgs = {
  input: UpdateInvoiceTermBulkInput;
};


export type MutationCrudUpdateIssueBulkArgs = {
  input: UpdateIssueBulkInput;
};


export type MutationCrudUpdateLicenseBulkArgs = {
  input: UpdateLicenseBulkInput;
};


export type MutationCrudUpdateLicenseTypeBulkArgs = {
  input: UpdateLicenseTypeBulkInput;
};


export type MutationCrudUpdateLocationBulkArgs = {
  input: UpdateLocationBulkInput;
};


export type MutationCrudUpdateLoginEventBulkArgs = {
  input: UpdateLoginEventBulkInput;
};


export type MutationCrudUpdateLostAndFoundIssueBulkArgs = {
  input: UpdateLostAndFoundIssueBulkInput;
};


export type MutationCrudUpdateLostAndFoundIssueDocumentBulkArgs = {
  input: UpdateLostAndFoundIssueDocumentBulkInput;
};


export type MutationCrudUpdateMessageBulkArgs = {
  input: UpdateMessageBulkInput;
};


export type MutationCrudUpdatePermissionBulkArgs = {
  input: UpdatePermissionBulkInput;
};


export type MutationCrudUpdatePermissionHasRoleBulkArgs = {
  input: UpdatePermissionHasRoleBulkInput;
};


export type MutationCrudUpdatePermissionHasUserBulkArgs = {
  input: UpdatePermissionHasUserBulkInput;
};


export type MutationCrudUpdatePickupPointBulkArgs = {
  input: UpdatePickupPointBulkInput;
};


export type MutationCrudUpdatePotentialTripBulkArgs = {
  input: UpdatePotentialTripBulkInput;
};


export type MutationCrudUpdateProviderBulkArgs = {
  input: UpdateProviderBulkInput;
};


export type MutationCrudUpdateProviderLocationMappingBulkArgs = {
  input: UpdateProviderLocationMappingBulkInput;
};


export type MutationCrudUpdatePunchHistoryBulkArgs = {
  input: UpdatePunchHistoryBulkInput;
};


export type MutationCrudUpdatePunchPolicyBulkArgs = {
  input: UpdatePunchPolicyBulkInput;
};


export type MutationCrudUpdateRateBulkArgs = {
  input: UpdateRateBulkInput;
};


export type MutationCrudUpdateRateGroupBulkArgs = {
  input: UpdateRateGroupBulkInput;
};


export type MutationCrudUpdateRateHistoryBulkArgs = {
  input: UpdateRateHistoryBulkInput;
};


export type MutationCrudUpdateRateItemBulkArgs = {
  input: UpdateRateItemBulkInput;
};


export type MutationCrudUpdateRatingBulkArgs = {
  input: UpdateRatingBulkInput;
};


export type MutationCrudUpdateRatingRemarkBulkArgs = {
  input: UpdateRatingRemarkBulkInput;
};


export type MutationCrudUpdateRatingTypeBulkArgs = {
  input: UpdateRatingTypeBulkInput;
};


export type MutationCrudUpdateRoleBulkArgs = {
  input: UpdateRoleBulkInput;
};


export type MutationCrudUpdateSettingsBulkArgs = {
  input: UpdateSettingsBulkInput;
};


export type MutationCrudUpdateSocialDistanceRuleBulkArgs = {
  input: UpdateSocialDistanceRuleBulkInput;
};


export type MutationCrudUpdateStagedTripBulkArgs = {
  input: UpdateStagedTripBulkInput;
};


export type MutationCrudUpdateStopBulkArgs = {
  input: UpdateStopBulkInput;
};


export type MutationCrudUpdateStorageBulkArgs = {
  input: UpdateStorageBulkInput;
};


export type MutationCrudUpdateTrackFlightBulkArgs = {
  input: UpdateTrackFlightBulkInput;
};


export type MutationCrudUpdateTripBulkArgs = {
  input: UpdateTripBulkInput;
};


export type MutationCrudUpdateTripScheduleTriggerBulkArgs = {
  input: UpdateTripScheduleTriggerBulkInput;
};


export type MutationCrudUpdateUpdateStrategyBulkArgs = {
  input: UpdateUpdateStrategyBulkInput;
};


export type MutationCrudUpdateUserAvailabilityBulkArgs = {
  input: UpdateUserAvailabilityBulkInput;
};


export type MutationCrudUpdateUserBulkArgs = {
  input: UpdateUserBulkInput;
};


export type MutationCrudUpdateVariableTimeBulkArgs = {
  input: UpdateVariableTimeBulkInput;
};


export type MutationCrudUpdateVehicleBulkArgs = {
  input: UpdateVehicleBulkInput;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type NotificationsNamespaceResponse = {
  __typename?: 'NotificationsNamespaceResponse';
  generalTopics?: Maybe<Array<Scalars['String']['output']>>;
  namespace: Scalars['String']['output'];
};

export type OnTimePerformanceReport = {
  __typename?: 'OnTimePerformanceReport';
  name: Scalars['String']['output'];
  ontimeOverall: Scalars['Int']['output'];
  percentEfficiency: Scalars['Int']['output'];
  percentGps: Scalars['Float']['output'];
  percentLate: Scalars['Int']['output'];
  percentPickup: Scalars['Float']['output'];
  percentReady: Scalars['Float']['output'];
  rating: Scalars['Float']['output'];
  rows?: Maybe<Array<OnTimePerformanceReportChild>>;
  totalDrivers: Scalars['Int']['output'];
  totalGps: Scalars['Int']['output'];
  totalPickups: Scalars['Int']['output'];
  totalReady: Scalars['Int']['output'];
  totalTrips: Scalars['Int']['output'];
};

export type OnTimePerformanceReportChild = {
  __typename?: 'OnTimePerformanceReportChild';
  children?: Maybe<Array<OnTimePerformanceReportChild>>;
  name: Scalars['String']['output'];
  ontimeOverall: Scalars['Int']['output'];
  percentEfficiency: Scalars['Int']['output'];
  percentGps: Scalars['Float']['output'];
  percentLate: Scalars['Int']['output'];
  percentPickup: Scalars['Float']['output'];
  percentReady: Scalars['Float']['output'];
  rating: Scalars['Float']['output'];
  totalGps: Scalars['Int']['output'];
  totalPickups: Scalars['Int']['output'];
  totalReady: Scalars['Int']['output'];
  totalTrips: Scalars['Int']['output'];
};

export enum OnTimePerformanceReportFormatEnum {
  Airport = 'AIRPORT',
  Group = 'GROUP'
}

export type OnTimePerformanceReportTables = {
  __typename?: 'OnTimePerformanceReportTables';
  tables?: Maybe<Array<OnTimePerformanceReport>>;
};

export type OverallPerformance = {
  __typename?: 'OverallPerformance';
  averageRating?: Maybe<Scalars['Float']['output']>;
  totalAmountOfBadReviews?: Maybe<Scalars['Float']['output']>;
  totalAmountOfReviews?: Maybe<Scalars['Float']['output']>;
  totalAmountOfTrips?: Maybe<Scalars['Float']['output']>;
  totalLateTrips?: Maybe<Scalars['Float']['output']>;
  totalRFP?: Maybe<Scalars['Float']['output']>;
  totalWORFP?: Maybe<Scalars['Float']['output']>;
};

export type OverrideImportInput = {
  bufferTimes?: InputMaybe<Array<BufferTimeInput>>;
  importId: Scalars['String']['input'];
  locationMapping?: InputMaybe<Array<LocationMappingInput>>;
  previousImportId?: InputMaybe<Scalars['String']['input']>;
  socialDistance: Scalars['Boolean']['input'];
  tolerance?: InputMaybe<Scalars['Int']['input']>;
};

export type OverrideLocation = {
  __typename?: 'OverrideLocation';
  bufferTimes: Array<BufferTime>;
  clientId: Scalars['String']['output'];
  clientIdDisplayName: Scalars['String']['output'];
  locationDisplayName: Scalars['String']['output'];
  locationId: Scalars['String']['output'];
};

export type OverrideOptions = {
  __typename?: 'OverrideOptions';
  airportCodes: Array<Scalars['String']['output']>;
  allowOverrideLocationMapping: Scalars['Boolean']['output'];
  allowOverrideMissingLocations: Scalars['Boolean']['output'];
  allowOverrideSocialDistancing: Scalars['Boolean']['output'];
  allowOverrideTolerance: Scalars['Boolean']['output'];
  contractId: Scalars['String']['output'];
  elapsedDataChecking: Scalars['Boolean']['output'];
  filename: Scalars['String']['output'];
  importId: Scalars['String']['output'];
  importName: Scalars['String']['output'];
  inaccessibleMarkets?: Maybe<Array<Scalars['String']['output']>>;
  locationMapping: Array<OverrideLocation>;
  missingLocations: Array<Scalars['String']['output']>;
  providerId: Scalars['String']['output'];
  socialDistancing: Scalars['Boolean']['output'];
  tolerance: Scalars['Int']['output'];
};

export type OverrideOptionsResponse = {
  __typename?: 'OverrideOptionsResponse';
  error?: Maybe<Scalars['String']['output']>;
  overrideOptions?: Maybe<OverrideOptions>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['String']['output'];
};

export type PerformanceReport = {
  __typename?: 'PerformanceReport';
  dashboard?: Maybe<PerformanceReportDashboard>;
  error?: Maybe<Error>;
};

export type PerformanceReportDashboard = {
  __typename?: 'PerformanceReportDashboard';
  ontime?: Maybe<PerformanceReportOnTime>;
  ratings?: Maybe<PerformanceReportRatings>;
};

export type PerformanceReportInput = {
  airportCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  driverIds?: InputMaybe<Array<Scalars['String']['input']>>;
  endDatetime?: InputMaybe<Scalars['String']['input']>;
  reportFilters?: InputMaybe<Array<ReportFilterEnum>>;
  startDatetime?: InputMaybe<Scalars['String']['input']>;
};

export type PerformanceReportOnTime = {
  __typename?: 'PerformanceReportOnTime';
  inboundMaxOnTime?: Maybe<Scalars['Int']['output']>;
  inboundMaxTrips?: Maybe<Scalars['Int']['output']>;
  inboundOnTime?: Maybe<Scalars['Int']['output']>;
  inboundTrips?: Maybe<Scalars['Int']['output']>;
  outboundMaxOnTime?: Maybe<Scalars['Int']['output']>;
  outboundMaxTrips?: Maybe<Scalars['Int']['output']>;
  outboundOnTime?: Maybe<Scalars['Int']['output']>;
  outboundTrips?: Maybe<Scalars['Int']['output']>;
};

export type PerformanceReportRatings = {
  __typename?: 'PerformanceReportRatings';
  inboundAvgRating?: Maybe<Scalars['Float']['output']>;
  inboundMaxRating?: Maybe<Scalars['Float']['output']>;
  inboundRatings?: Maybe<Scalars['Int']['output']>;
  outboundAvgRating?: Maybe<Scalars['Float']['output']>;
  outboundMaxRating?: Maybe<Scalars['Float']['output']>;
  outboundRatings?: Maybe<Scalars['Int']['output']>;
};

export type Permission = {
  __typename?: 'Permission';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayGroup: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  effect: PermissionEffectEnum;
  entity: Scalars['String']['output'];
  id: Scalars['String']['output'];
  resource: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  verb: Scalars['String']['output'];
};

export type PermissionConnection = {
  __typename?: 'PermissionConnection';
  edges?: Maybe<Array<Maybe<PermissionEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PermissionConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<PermissionMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<PermissionSearch>>;
};

export type PermissionEdge = {
  __typename?: 'PermissionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Permission>;
};

export enum PermissionEffectEnum {
  Allow = 'ALLOW',
  Deny = 'DENY'
}

export type PermissionEffectEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<PermissionEffectEnum>;
};

export type PermissionHasRole = {
  __typename?: 'PermissionHasRole';
  permissionId: Scalars['String']['output'];
  roleId: Scalars['String']['output'];
};

export type PermissionHasRoleConnection = {
  __typename?: 'PermissionHasRoleConnection';
  edges?: Maybe<Array<Maybe<PermissionHasRoleEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PermissionHasRoleConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<PermissionHasRoleMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<PermissionHasRoleSearch>>;
};

export type PermissionHasRoleEdge = {
  __typename?: 'PermissionHasRoleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PermissionHasRole>;
};

export type PermissionHasRoleMaskInput = {
  permissionId?: InputMaybe<Scalars['Boolean']['input']>;
  roleId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PermissionHasRoleSearch = {
  permissionId?: InputMaybe<Array<StringSearch>>;
  roleId?: InputMaybe<Array<StringSearch>>;
};

export type PermissionHasRoleWithPermissions = {
  __typename?: 'PermissionHasRoleWithPermissions';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayGroup: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  effect: PermissionHasRoleWithPermissionsEffectEnum;
  entity: Scalars['String']['output'];
  id: Scalars['String']['output'];
  permissionId: Scalars['String']['output'];
  resource: Scalars['String']['output'];
  roleId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  verb: Scalars['String']['output'];
};

export type PermissionHasRoleWithPermissionsConnection = {
  __typename?: 'PermissionHasRoleWithPermissionsConnection';
  edges?: Maybe<Array<Maybe<PermissionHasRoleWithPermissionsEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PermissionHasRoleWithPermissionsConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<PermissionHasRoleWithPermissionsMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<PermissionHasRoleWithPermissionsSearch>>;
};

export type PermissionHasRoleWithPermissionsEdge = {
  __typename?: 'PermissionHasRoleWithPermissionsEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PermissionHasRoleWithPermissions>;
};

export enum PermissionHasRoleWithPermissionsEffectEnum {
  Allow = 'ALLOW',
  Deny = 'DENY'
}

export type PermissionHasRoleWithPermissionsEffectEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<PermissionHasRoleWithPermissionsEffectEnum>;
};

export type PermissionHasRoleWithPermissionsMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['Boolean']['input']>;
  displayGroup?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['Boolean']['input']>;
  effect?: InputMaybe<Scalars['Boolean']['input']>;
  entity?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  permissionId?: InputMaybe<Scalars['Boolean']['input']>;
  resource?: InputMaybe<Scalars['Boolean']['input']>;
  roleId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  verb?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PermissionHasRoleWithPermissionsSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  description?: InputMaybe<Array<StringSearch>>;
  displayGroup?: InputMaybe<Array<StringSearch>>;
  displayName?: InputMaybe<Array<StringSearch>>;
  effect?: InputMaybe<Array<PermissionHasRoleWithPermissionsEffectEnumSearch>>;
  entity?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  permissionId?: InputMaybe<Array<StringSearch>>;
  resource?: InputMaybe<Array<StringSearch>>;
  roleId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  verb?: InputMaybe<Array<StringSearch>>;
};

export type PermissionHasUser = {
  __typename?: 'PermissionHasUser';
  permissionId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type PermissionHasUserConnection = {
  __typename?: 'PermissionHasUserConnection';
  edges?: Maybe<Array<Maybe<PermissionHasUserEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PermissionHasUserConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<PermissionHasUserMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<PermissionHasUserSearch>>;
};

export type PermissionHasUserEdge = {
  __typename?: 'PermissionHasUserEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PermissionHasUser>;
};

export type PermissionHasUserMaskInput = {
  permissionId?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PermissionHasUserSearch = {
  permissionId?: InputMaybe<Array<StringSearch>>;
  userId?: InputMaybe<Array<StringSearch>>;
};

export type PermissionHasUserWithPermissions = {
  __typename?: 'PermissionHasUserWithPermissions';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayGroup: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  effect: PermissionHasUserWithPermissionsEffectEnum;
  entity: Scalars['String']['output'];
  id: Scalars['String']['output'];
  permissionId: Scalars['String']['output'];
  resource: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  verb: Scalars['String']['output'];
};

export type PermissionHasUserWithPermissionsConnection = {
  __typename?: 'PermissionHasUserWithPermissionsConnection';
  edges?: Maybe<Array<Maybe<PermissionHasUserWithPermissionsEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PermissionHasUserWithPermissionsConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<PermissionHasUserWithPermissionsMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<PermissionHasUserWithPermissionsSearch>>;
};

export type PermissionHasUserWithPermissionsEdge = {
  __typename?: 'PermissionHasUserWithPermissionsEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PermissionHasUserWithPermissions>;
};

export enum PermissionHasUserWithPermissionsEffectEnum {
  Allow = 'ALLOW',
  Deny = 'DENY'
}

export type PermissionHasUserWithPermissionsEffectEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<PermissionHasUserWithPermissionsEffectEnum>;
};

export type PermissionHasUserWithPermissionsMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['Boolean']['input']>;
  displayGroup?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['Boolean']['input']>;
  effect?: InputMaybe<Scalars['Boolean']['input']>;
  entity?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  permissionId?: InputMaybe<Scalars['Boolean']['input']>;
  resource?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Boolean']['input']>;
  verb?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PermissionHasUserWithPermissionsSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  description?: InputMaybe<Array<StringSearch>>;
  displayGroup?: InputMaybe<Array<StringSearch>>;
  displayName?: InputMaybe<Array<StringSearch>>;
  effect?: InputMaybe<Array<PermissionHasUserWithPermissionsEffectEnumSearch>>;
  entity?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  permissionId?: InputMaybe<Array<StringSearch>>;
  resource?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  userId?: InputMaybe<Array<StringSearch>>;
  verb?: InputMaybe<Array<StringSearch>>;
};

export type PermissionMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['Boolean']['input']>;
  displayGroup?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['Boolean']['input']>;
  effect?: InputMaybe<Scalars['Boolean']['input']>;
  entity?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  resource?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  verb?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PermissionSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  description?: InputMaybe<Array<StringSearch>>;
  displayGroup?: InputMaybe<Array<StringSearch>>;
  displayName?: InputMaybe<Array<StringSearch>>;
  effect?: InputMaybe<Array<PermissionEffectEnumSearch>>;
  entity?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  resource?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  verb?: InputMaybe<Array<StringSearch>>;
};

export type PickupActualInput = {
  puActualDatetime: Scalars['String']['input'];
  tripId: Array<Scalars['String']['input']>;
};

export type PickupActualResponse = {
  __typename?: 'PickupActualResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type PickupCompletedInput = {
  puCompletedDatetime: Scalars['String']['input'];
  tripId: Array<Scalars['String']['input']>;
};

export type PickupCompletedResponse = {
  __typename?: 'PickupCompletedResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type PickupIncompleteInput = {
  tripId: Array<Scalars['String']['input']>;
};

export type PickupIncompleteResponse = {
  __typename?: 'PickupIncompleteResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type PickupPoint = {
  __typename?: 'PickupPoint';
  coordinates: Point;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endDatetime: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images?: Maybe<Array<Image>>;
  instructions: Scalars['String']['output'];
  keyword: Scalars['String']['output'];
  locationId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  radius: Scalars['Int']['output'];
  startDatetime: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type PickupPointConnection = {
  __typename?: 'PickupPointConnection';
  edges?: Maybe<Array<Maybe<PickupPointEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PickupPointConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<PickupPointMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<PickupPointSearch>>;
};

export type PickupPointEdge = {
  __typename?: 'PickupPointEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PickupPoint>;
};

export type PickupPointMaskInput = {
  coordinates?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  endDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  instructions?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  radius?: InputMaybe<Scalars['Boolean']['input']>;
  startDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PickupPointSearch = {
  coordinates?: InputMaybe<Array<PointSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  endDatetime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  instructions?: InputMaybe<Array<StringSearch>>;
  keyword?: InputMaybe<Array<StringSearch>>;
  locationId?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  radius?: InputMaybe<Array<IntSearch>>;
  startDatetime?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type Pipeline = {
  function: MetricFunctionEnum;
  key: Scalars['String']['input'];
};

export type Point = {
  __typename?: 'Point';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type PointInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type PointSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<PointInput>;
};

export type PortalAuth = {
  __typename?: 'PortalAuth';
  allowAssignAndAutoCombine: Scalars['Boolean']['output'];
  allowCreateClient: Scalars['Boolean']['output'];
  allowCustomizeReports: Scalars['Boolean']['output'];
  allowDeleteTripHard: Scalars['Boolean']['output'];
  allowGetDriverEstimatedDriveTime: Scalars['Boolean']['output'];
  allowHideChat: Scalars['Boolean']['output'];
  allowInvoiceDuplicate: Scalars['Boolean']['output'];
  allowOverrideRate: Scalars['Boolean']['output'];
  allowRecordSession: Scalars['Boolean']['output'];
  allowSaveAndCopyExistingTrips: Scalars['Boolean']['output'];
  allowSearchTripsByRate: Scalars['Boolean']['output'];
  allowToViewMap: Scalars['Boolean']['output'];
  allowTripStatusInvoiceFilter: Scalars['Boolean']['output'];
  allowUndeleteTrip: Scalars['Boolean']['output'];
  allowViewBufferTime: Scalars['Boolean']['output'];
  allowViewChat: Scalars['Boolean']['output'];
  allowViewCombineRule: Scalars['Boolean']['output'];
  allowViewTripShortcuts: Scalars['Boolean']['output'];
  allowViewVariableTime: Scalars['Boolean']['output'];
};

export type PotentialTrip = {
  __typename?: 'PotentialTrip';
  airportCode: Scalars['String']['output'];
  attendants: Scalars['Int']['output'];
  bufferMinutes: Scalars['Int']['output'];
  combineId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  doLocation?: Maybe<Location>;
  doLocationId: Scalars['String']['output'];
  flightNumber?: Maybe<Scalars['Int']['output']>;
  fromManifest: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  importId: Scalars['String']['output'];
  kind: PotentialTripKindEnum;
  loopName?: Maybe<Scalars['String']['output']>;
  payerProvider?: Maybe<Provider>;
  payerProviderId: Scalars['String']['output'];
  pilots: Scalars['Int']['output'];
  puLocation?: Maybe<Location>;
  puLocationId: Scalars['String']['output'];
  scheduled: Scalars['String']['output'];
  servicerIataAirlineCode: Scalars['String']['output'];
  split: Scalars['Int']['output'];
  tripId: Scalars['String']['output'];
  typeName?: Maybe<PotentialTripTypeNameEnum>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type PotentialTripConnection = {
  __typename?: 'PotentialTripConnection';
  edges?: Maybe<Array<Maybe<PotentialTripEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PotentialTripConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<PotentialTripMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<PotentialTripSearch>>;
};

export type PotentialTripEdge = {
  __typename?: 'PotentialTripEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PotentialTrip>;
};

export enum PotentialTripKindEnum {
  Arr = 'ARR',
  Dep = 'DEP'
}

export type PotentialTripKindEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<PotentialTripKindEnum>;
};

export type PotentialTripMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  attendants?: InputMaybe<Scalars['Boolean']['input']>;
  bufferMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  combineId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  flightNumber?: InputMaybe<Scalars['Boolean']['input']>;
  fromManifest?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['Boolean']['input']>;
  loopName?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  pilots?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  servicerIataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  split?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  typeName?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PotentialTripSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  attendants?: InputMaybe<Array<IntSearch>>;
  bufferMinutes?: InputMaybe<Array<IntSearch>>;
  combineId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  flightNumber?: InputMaybe<Array<IntSearch>>;
  fromManifest?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  importId?: InputMaybe<Array<StringSearch>>;
  kind?: InputMaybe<Array<PotentialTripKindEnumSearch>>;
  loopName?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  pilots?: InputMaybe<Array<IntSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  servicerIataAirlineCode?: InputMaybe<Array<StringSearch>>;
  split?: InputMaybe<Array<IntSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  typeName?: InputMaybe<Array<PotentialTripTypeNameEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum PotentialTripTypeNameEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type PotentialTripTypeNameEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<PotentialTripTypeNameEnum>;
};

export type PreviewDetails = {
  __typename?: 'PreviewDetails';
  error?: Maybe<Error>;
  import?: Maybe<Import>;
  notUpdatable?: Maybe<Scalars['Int']['output']>;
  stagedTrips?: Maybe<Array<Maybe<StagedTrip>>>;
  updatable?: Maybe<Scalars['Int']['output']>;
  warnings?: Maybe<Array<Error>>;
};

export type PreviousImportConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  importId: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type Provider = {
  __typename?: 'Provider';
  active: Scalars['Int']['output'];
  billFrequency?: Maybe<Scalars['String']['output']>;
  billingAddress?: Maybe<Scalars['String']['output']>;
  billingCityCode?: Maybe<Scalars['String']['output']>;
  billingStateCode?: Maybe<Scalars['String']['output']>;
  billingZipCode?: Maybe<Scalars['Int']['output']>;
  companyId: Scalars['String']['output'];
  contactPerson?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Array<Email>>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  iataAirlineCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  importCode?: Maybe<ProviderImportCodeEnum>;
  invoiceTermId?: Maybe<Scalars['String']['output']>;
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  occPhoneNumber?: Maybe<Scalars['String']['output']>;
  primaryAddress?: Maybe<Scalars['String']['output']>;
  primaryCityCode?: Maybe<Scalars['String']['output']>;
  primaryEmail?: Maybe<Scalars['String']['output']>;
  primaryLogoUrl?: Maybe<Scalars['String']['output']>;
  primaryPhoneNumber?: Maybe<Scalars['String']['output']>;
  primaryStateCode?: Maybe<Scalars['String']['output']>;
  primaryZipCode?: Maybe<Scalars['Int']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  traceId?: Maybe<Scalars['String']['output']>;
  type: ProviderTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type ProviderConnection = {
  __typename?: 'ProviderConnection';
  edges?: Maybe<Array<Maybe<ProviderEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProviderConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<ProviderMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<ProviderSearch>>;
};

export type ProviderEdge = {
  __typename?: 'ProviderEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Provider>;
};

export type ProviderHasFeature = {
  __typename?: 'ProviderHasFeature';
  features?: Maybe<Array<EnableFeature>>;
  id: Scalars['String']['output'];
};

export enum ProviderImportCodeEnum {
  Aal = 'AAL',
  Api = 'API',
  Dal = 'DAL',
  Skw = 'SKW',
  Sky = 'SKY',
  Swa = 'SWA',
  Train = 'TRAIN',
  Ual = 'UAL'
}

export type ProviderImportCodeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ProviderImportCodeEnum>;
};

export type ProviderLocationMapping = {
  __typename?: 'ProviderLocationMapping';
  active: Scalars['Int']['output'];
  contractId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  doLocationId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  puLocationId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ProviderLocationMappingConnection = {
  __typename?: 'ProviderLocationMappingConnection';
  edges?: Maybe<Array<Maybe<ProviderLocationMappingEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProviderLocationMappingConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<ProviderLocationMappingMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<ProviderLocationMappingSearch>>;
};

export type ProviderLocationMappingEdge = {
  __typename?: 'ProviderLocationMappingEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProviderLocationMapping>;
};

export type ProviderLocationMappingMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProviderLocationMappingSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  contractId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type ProviderMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  billFrequency?: InputMaybe<Scalars['Boolean']['input']>;
  billingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  billingCityCode?: InputMaybe<Scalars['Boolean']['input']>;
  billingStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  billingZipCode?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  contactPerson?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['Boolean']['input']>;
  faxNumber?: InputMaybe<Scalars['Boolean']['input']>;
  iataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  importCode?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceTermId?: InputMaybe<Scalars['Boolean']['input']>;
  mobilePhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  occPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  primaryAddress?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCityCode?: InputMaybe<Scalars['Boolean']['input']>;
  primaryEmail?: InputMaybe<Scalars['Boolean']['input']>;
  primaryLogoUrl?: InputMaybe<Scalars['Boolean']['input']>;
  primaryPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  primaryStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  primaryZipCode?: InputMaybe<Scalars['Boolean']['input']>;
  referenceNumber?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  website?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProviderSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  billFrequency?: InputMaybe<Array<StringSearch>>;
  billingAddress?: InputMaybe<Array<StringSearch>>;
  billingCityCode?: InputMaybe<Array<StringSearch>>;
  billingStateCode?: InputMaybe<Array<StringSearch>>;
  billingZipCode?: InputMaybe<Array<IntSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  contactPerson?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  displayName?: InputMaybe<Array<StringSearch>>;
  faxNumber?: InputMaybe<Array<StringSearch>>;
  iataAirlineCode?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  importCode?: InputMaybe<Array<ProviderImportCodeEnumSearch>>;
  invoiceTermId?: InputMaybe<Array<StringSearch>>;
  mobilePhoneNumber?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  occPhoneNumber?: InputMaybe<Array<StringSearch>>;
  primaryAddress?: InputMaybe<Array<StringSearch>>;
  primaryCityCode?: InputMaybe<Array<StringSearch>>;
  primaryEmail?: InputMaybe<Array<StringSearch>>;
  primaryLogoUrl?: InputMaybe<Array<StringSearch>>;
  primaryPhoneNumber?: InputMaybe<Array<StringSearch>>;
  primaryStateCode?: InputMaybe<Array<StringSearch>>;
  primaryZipCode?: InputMaybe<Array<IntSearch>>;
  referenceNumber?: InputMaybe<Array<StringSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<ProviderTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  website?: InputMaybe<Array<StringSearch>>;
};

export enum ProviderTypeEnum {
  Airline = 'AIRLINE',
  Client = 'CLIENT',
  Hotel = 'HOTEL'
}

export type ProviderTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ProviderTypeEnum>;
};

export type ProviderWithContract = {
  __typename?: 'ProviderWithContract';
  active: Scalars['Int']['output'];
  billFrequency?: Maybe<Scalars['String']['output']>;
  billingAddress?: Maybe<Scalars['String']['output']>;
  billingCityCode?: Maybe<Scalars['String']['output']>;
  billingStateCode?: Maybe<Scalars['String']['output']>;
  billingZipCode?: Maybe<Scalars['Int']['output']>;
  companyId: Scalars['String']['output'];
  contactPerson?: Maybe<Scalars['String']['output']>;
  contractId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  iataAirlineCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  importCode?: Maybe<ProviderWithContractImportCodeEnum>;
  invoiceTermId?: Maybe<Scalars['String']['output']>;
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  occPhoneNumber?: Maybe<Scalars['String']['output']>;
  primaryAddress?: Maybe<Scalars['String']['output']>;
  primaryCityCode?: Maybe<Scalars['String']['output']>;
  primaryEmail?: Maybe<Scalars['String']['output']>;
  primaryLogoUrl?: Maybe<Scalars['String']['output']>;
  primaryPhoneNumber?: Maybe<Scalars['String']['output']>;
  primaryStateCode?: Maybe<Scalars['String']['output']>;
  primaryZipCode?: Maybe<Scalars['Int']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  traceId?: Maybe<Scalars['String']['output']>;
  type: ProviderWithContractTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type ProviderWithContractConnection = {
  __typename?: 'ProviderWithContractConnection';
  edges?: Maybe<Array<Maybe<ProviderWithContractEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProviderWithContractConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<ProviderWithContractMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<ProviderWithContractSearch>>;
};

export type ProviderWithContractEdge = {
  __typename?: 'ProviderWithContractEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProviderWithContract>;
};

export enum ProviderWithContractImportCodeEnum {
  Aal = 'AAL',
  Api = 'API',
  Dal = 'DAL',
  Skw = 'SKW',
  Sky = 'SKY',
  Swa = 'SWA',
  Train = 'TRAIN',
  Ual = 'UAL'
}

export type ProviderWithContractImportCodeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ProviderWithContractImportCodeEnum>;
};

export type ProviderWithContractMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  billFrequency?: InputMaybe<Scalars['Boolean']['input']>;
  billingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  billingCityCode?: InputMaybe<Scalars['Boolean']['input']>;
  billingStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  billingZipCode?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  contactPerson?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['Boolean']['input']>;
  faxNumber?: InputMaybe<Scalars['Boolean']['input']>;
  iataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  importCode?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceTermId?: InputMaybe<Scalars['Boolean']['input']>;
  mobilePhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  occPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  primaryAddress?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCityCode?: InputMaybe<Scalars['Boolean']['input']>;
  primaryEmail?: InputMaybe<Scalars['Boolean']['input']>;
  primaryLogoUrl?: InputMaybe<Scalars['Boolean']['input']>;
  primaryPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  primaryStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  primaryZipCode?: InputMaybe<Scalars['Boolean']['input']>;
  referenceNumber?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  website?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProviderWithContractSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  billFrequency?: InputMaybe<Array<StringSearch>>;
  billingAddress?: InputMaybe<Array<StringSearch>>;
  billingCityCode?: InputMaybe<Array<StringSearch>>;
  billingStateCode?: InputMaybe<Array<StringSearch>>;
  billingZipCode?: InputMaybe<Array<IntSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  contactPerson?: InputMaybe<Array<StringSearch>>;
  contractId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  displayName?: InputMaybe<Array<StringSearch>>;
  faxNumber?: InputMaybe<Array<StringSearch>>;
  iataAirlineCode?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  importCode?: InputMaybe<Array<ProviderWithContractImportCodeEnumSearch>>;
  invoiceTermId?: InputMaybe<Array<StringSearch>>;
  mobilePhoneNumber?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  occPhoneNumber?: InputMaybe<Array<StringSearch>>;
  primaryAddress?: InputMaybe<Array<StringSearch>>;
  primaryCityCode?: InputMaybe<Array<StringSearch>>;
  primaryEmail?: InputMaybe<Array<StringSearch>>;
  primaryLogoUrl?: InputMaybe<Array<StringSearch>>;
  primaryPhoneNumber?: InputMaybe<Array<StringSearch>>;
  primaryStateCode?: InputMaybe<Array<StringSearch>>;
  primaryZipCode?: InputMaybe<Array<IntSearch>>;
  referenceNumber?: InputMaybe<Array<StringSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<ProviderWithContractTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  website?: InputMaybe<Array<StringSearch>>;
};

export enum ProviderWithContractTypeEnum {
  Airline = 'AIRLINE',
  Client = 'CLIENT',
  Hotel = 'HOTEL'
}

export type ProviderWithContractTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ProviderWithContractTypeEnum>;
};

export type ProviderWithUpdateStrategy = {
  __typename?: 'ProviderWithUpdateStrategy';
  active: Scalars['Int']['output'];
  addonStrategy: ProviderWithUpdateStrategyAddonStrategyEnum;
  addonType: ProviderWithUpdateStrategyAddonTypeEnum;
  billFrequency?: Maybe<Scalars['String']['output']>;
  billingAddress?: Maybe<Scalars['String']['output']>;
  billingCityCode?: Maybe<Scalars['String']['output']>;
  billingStateCode?: Maybe<Scalars['String']['output']>;
  billingZipCode?: Maybe<Scalars['Int']['output']>;
  companyId: Scalars['String']['output'];
  contactPerson?: Maybe<Scalars['String']['output']>;
  contractId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  dailyStrategy: ProviderWithUpdateStrategyDailyStrategyEnum;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  iataAirlineCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  importCode?: Maybe<ProviderWithUpdateStrategyImportCodeEnum>;
  importNameIncludesHotels: Scalars['Int']['output'];
  invoiceTermId?: Maybe<Scalars['String']['output']>;
  manifestStrategy: ProviderWithUpdateStrategyManifestStrategyEnum;
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  occPhoneNumber?: Maybe<Scalars['String']['output']>;
  primaryAddress?: Maybe<Scalars['String']['output']>;
  primaryCityCode?: Maybe<Scalars['String']['output']>;
  primaryEmail?: Maybe<Scalars['String']['output']>;
  primaryLogoUrl?: Maybe<Scalars['String']['output']>;
  primaryPhoneNumber?: Maybe<Scalars['String']['output']>;
  primaryStateCode?: Maybe<Scalars['String']['output']>;
  primaryZipCode?: Maybe<Scalars['Int']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  traceId?: Maybe<Scalars['String']['output']>;
  type: ProviderWithUpdateStrategyTypeEnum;
  updateKindFromLocations: Scalars['Int']['output'];
  updateStrategy: ProviderWithUpdateStrategyUpdateStrategyEnum;
  updateStrategyActive: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export enum ProviderWithUpdateStrategyAddonStrategyEnum {
  Merge = 'MERGE',
  Replace = 'REPLACE'
}

export type ProviderWithUpdateStrategyAddonStrategyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ProviderWithUpdateStrategyAddonStrategyEnum>;
};

export enum ProviderWithUpdateStrategyAddonTypeEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type ProviderWithUpdateStrategyAddonTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ProviderWithUpdateStrategyAddonTypeEnum>;
};

export type ProviderWithUpdateStrategyConnection = {
  __typename?: 'ProviderWithUpdateStrategyConnection';
  edges?: Maybe<Array<Maybe<ProviderWithUpdateStrategyEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProviderWithUpdateStrategyConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<ProviderWithUpdateStrategyMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<ProviderWithUpdateStrategySearch>>;
};

export enum ProviderWithUpdateStrategyDailyStrategyEnum {
  Merge = 'MERGE',
  Replace = 'REPLACE'
}

export type ProviderWithUpdateStrategyDailyStrategyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ProviderWithUpdateStrategyDailyStrategyEnum>;
};

export type ProviderWithUpdateStrategyEdge = {
  __typename?: 'ProviderWithUpdateStrategyEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProviderWithUpdateStrategy>;
};

export enum ProviderWithUpdateStrategyImportCodeEnum {
  Aal = 'AAL',
  Api = 'API',
  Dal = 'DAL',
  Skw = 'SKW',
  Sky = 'SKY',
  Swa = 'SWA',
  Train = 'TRAIN',
  Ual = 'UAL'
}

export type ProviderWithUpdateStrategyImportCodeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ProviderWithUpdateStrategyImportCodeEnum>;
};

export enum ProviderWithUpdateStrategyManifestStrategyEnum {
  Merge = 'MERGE',
  Replace = 'REPLACE'
}

export type ProviderWithUpdateStrategyManifestStrategyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ProviderWithUpdateStrategyManifestStrategyEnum>;
};

export type ProviderWithUpdateStrategyMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  addonStrategy?: InputMaybe<Scalars['Boolean']['input']>;
  addonType?: InputMaybe<Scalars['Boolean']['input']>;
  billFrequency?: InputMaybe<Scalars['Boolean']['input']>;
  billingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  billingCityCode?: InputMaybe<Scalars['Boolean']['input']>;
  billingStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  billingZipCode?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  contactPerson?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  dailyStrategy?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['Boolean']['input']>;
  faxNumber?: InputMaybe<Scalars['Boolean']['input']>;
  iataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  importCode?: InputMaybe<Scalars['Boolean']['input']>;
  importNameIncludesHotels?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceTermId?: InputMaybe<Scalars['Boolean']['input']>;
  manifestStrategy?: InputMaybe<Scalars['Boolean']['input']>;
  mobilePhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  occPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  primaryAddress?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCityCode?: InputMaybe<Scalars['Boolean']['input']>;
  primaryEmail?: InputMaybe<Scalars['Boolean']['input']>;
  primaryLogoUrl?: InputMaybe<Scalars['Boolean']['input']>;
  primaryPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  primaryStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  primaryZipCode?: InputMaybe<Scalars['Boolean']['input']>;
  referenceNumber?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updateKindFromLocations?: InputMaybe<Scalars['Boolean']['input']>;
  updateStrategy?: InputMaybe<Scalars['Boolean']['input']>;
  updateStrategyActive?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  website?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProviderWithUpdateStrategySearch = {
  active?: InputMaybe<Array<IntSearch>>;
  addonStrategy?: InputMaybe<Array<ProviderWithUpdateStrategyAddonStrategyEnumSearch>>;
  addonType?: InputMaybe<Array<ProviderWithUpdateStrategyAddonTypeEnumSearch>>;
  billFrequency?: InputMaybe<Array<StringSearch>>;
  billingAddress?: InputMaybe<Array<StringSearch>>;
  billingCityCode?: InputMaybe<Array<StringSearch>>;
  billingStateCode?: InputMaybe<Array<StringSearch>>;
  billingZipCode?: InputMaybe<Array<IntSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  contactPerson?: InputMaybe<Array<StringSearch>>;
  contractId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  dailyStrategy?: InputMaybe<Array<ProviderWithUpdateStrategyDailyStrategyEnumSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  displayName?: InputMaybe<Array<StringSearch>>;
  faxNumber?: InputMaybe<Array<StringSearch>>;
  iataAirlineCode?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  importCode?: InputMaybe<Array<ProviderWithUpdateStrategyImportCodeEnumSearch>>;
  importNameIncludesHotels?: InputMaybe<Array<IntSearch>>;
  invoiceTermId?: InputMaybe<Array<StringSearch>>;
  manifestStrategy?: InputMaybe<Array<ProviderWithUpdateStrategyManifestStrategyEnumSearch>>;
  mobilePhoneNumber?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  occPhoneNumber?: InputMaybe<Array<StringSearch>>;
  primaryAddress?: InputMaybe<Array<StringSearch>>;
  primaryCityCode?: InputMaybe<Array<StringSearch>>;
  primaryEmail?: InputMaybe<Array<StringSearch>>;
  primaryLogoUrl?: InputMaybe<Array<StringSearch>>;
  primaryPhoneNumber?: InputMaybe<Array<StringSearch>>;
  primaryStateCode?: InputMaybe<Array<StringSearch>>;
  primaryZipCode?: InputMaybe<Array<IntSearch>>;
  referenceNumber?: InputMaybe<Array<StringSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<ProviderWithUpdateStrategyTypeEnumSearch>>;
  updateKindFromLocations?: InputMaybe<Array<IntSearch>>;
  updateStrategy?: InputMaybe<Array<ProviderWithUpdateStrategyUpdateStrategyEnumSearch>>;
  updateStrategyActive?: InputMaybe<Array<IntSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  website?: InputMaybe<Array<StringSearch>>;
};

export enum ProviderWithUpdateStrategyTypeEnum {
  Airline = 'AIRLINE',
  Client = 'CLIENT',
  Hotel = 'HOTEL'
}

export type ProviderWithUpdateStrategyTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ProviderWithUpdateStrategyTypeEnum>;
};

export enum ProviderWithUpdateStrategyUpdateStrategyEnum {
  Merge = 'MERGE',
  Replace = 'REPLACE'
}

export type ProviderWithUpdateStrategyUpdateStrategyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<ProviderWithUpdateStrategyUpdateStrategyEnum>;
};

export type PunchHistory = {
  __typename?: 'PunchHistory';
  approvalEmployee?: Maybe<Scalars['Int']['output']>;
  approvalSupervisor?: Maybe<Scalars['Int']['output']>;
  approvalSupervisorBy?: Maybe<Scalars['String']['output']>;
  companyCode: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  durationOt1?: Maybe<Scalars['Float']['output']>;
  durationOt2?: Maybe<Scalars['Float']['output']>;
  durationRegular?: Maybe<Scalars['Float']['output']>;
  employeeId: Scalars['String']['output'];
  fileLocation?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  payType: Scalars['String']['output'];
  punchInTime: Scalars['String']['output'];
  punchInType: PunchHistoryPunchInTypeEnum;
  punchOutTime: Scalars['String']['output'];
  punchOutType: PunchHistoryPunchOutTypeEnum;
  unpaidDuration?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type PunchHistoryConnection = {
  __typename?: 'PunchHistoryConnection';
  edges?: Maybe<Array<Maybe<PunchHistoryEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PunchHistoryConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<PunchHistoryMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<PunchHistorySearch>>;
};

export type PunchHistoryEdge = {
  __typename?: 'PunchHistoryEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PunchHistory>;
};

export type PunchHistoryMaskInput = {
  approvalEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  approvalSupervisor?: InputMaybe<Scalars['Boolean']['input']>;
  approvalSupervisorBy?: InputMaybe<Scalars['Boolean']['input']>;
  companyCode?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  date?: InputMaybe<Scalars['Boolean']['input']>;
  durationOt1?: InputMaybe<Scalars['Boolean']['input']>;
  durationOt2?: InputMaybe<Scalars['Boolean']['input']>;
  durationRegular?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Boolean']['input']>;
  fileLocation?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['Boolean']['input']>;
  payType?: InputMaybe<Scalars['Boolean']['input']>;
  punchInTime?: InputMaybe<Scalars['Boolean']['input']>;
  punchInType?: InputMaybe<Scalars['Boolean']['input']>;
  punchOutTime?: InputMaybe<Scalars['Boolean']['input']>;
  punchOutType?: InputMaybe<Scalars['Boolean']['input']>;
  unpaidDuration?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum PunchHistoryPunchInTypeEnum {
  Empty = 'EMPTY',
  In = 'IN',
  NonWork = 'NON_WORK',
  Split = 'SPLIT',
  Unknown = 'UNKNOWN'
}

export type PunchHistoryPunchInTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<PunchHistoryPunchInTypeEnum>;
};

export enum PunchHistoryPunchOutTypeEnum {
  Empty = 'EMPTY',
  NonWork = 'NON_WORK',
  Out = 'OUT',
  Split = 'SPLIT',
  Unknown = 'UNKNOWN'
}

export type PunchHistoryPunchOutTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<PunchHistoryPunchOutTypeEnum>;
};

export type PunchHistorySearch = {
  approvalEmployee?: InputMaybe<Array<IntSearch>>;
  approvalSupervisor?: InputMaybe<Array<IntSearch>>;
  approvalSupervisorBy?: InputMaybe<Array<StringSearch>>;
  companyCode?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  date?: InputMaybe<Array<StringSearch>>;
  durationOt1?: InputMaybe<Array<FloatSearch>>;
  durationOt2?: InputMaybe<Array<FloatSearch>>;
  durationRegular?: InputMaybe<Array<FloatSearch>>;
  employeeId?: InputMaybe<Array<StringSearch>>;
  fileLocation?: InputMaybe<Array<StringSearch>>;
  firstName?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  lastName?: InputMaybe<Array<StringSearch>>;
  payType?: InputMaybe<Array<StringSearch>>;
  punchInTime?: InputMaybe<Array<StringSearch>>;
  punchInType?: InputMaybe<Array<PunchHistoryPunchInTypeEnumSearch>>;
  punchOutTime?: InputMaybe<Array<StringSearch>>;
  punchOutType?: InputMaybe<Array<PunchHistoryPunchOutTypeEnumSearch>>;
  unpaidDuration?: InputMaybe<Array<FloatSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type PunchPolicy = {
  __typename?: 'PunchPolicy';
  companyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endTime: Scalars['String']['output'];
  eventEndDatetime?: Maybe<Scalars['String']['output']>;
  eventStartDatetime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  locationId: Scalars['String']['output'];
  minutesIn: Scalars['Int']['output'];
  minutesOut: Scalars['Int']['output'];
  startTime: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type PunchPolicyConnection = {
  __typename?: 'PunchPolicyConnection';
  edges?: Maybe<Array<Maybe<PunchPolicyEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PunchPolicyConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<PunchPolicyMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<PunchPolicySearch>>;
};

export type PunchPolicyEdge = {
  __typename?: 'PunchPolicyEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PunchPolicy>;
};

export type PunchPolicyMaskInput = {
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Boolean']['input']>;
  eventEndDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  eventStartDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['Boolean']['input']>;
  minutesIn?: InputMaybe<Scalars['Boolean']['input']>;
  minutesOut?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PunchPolicySearch = {
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  endTime?: InputMaybe<Array<StringSearch>>;
  eventEndDatetime?: InputMaybe<Array<StringSearch>>;
  eventStartDatetime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  locationId?: InputMaybe<Array<StringSearch>>;
  minutesIn?: InputMaybe<Array<IntSearch>>;
  minutesOut?: InputMaybe<Array<IntSearch>>;
  startTime?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type Query = {
  __typename?: 'Query';
  deviceQuery?: Maybe<DeviceQueryResponse>;
  getAvailableUsersForTrip: GetAvailableUsersForTripResponse;
  getCorrectRatesForTrips: GetCorrectRatesForTripsResponse;
  getDeviceConfig: GetDeviceConfigResponse;
  getDriveTimeReport: GetDriveTimeReportResponse;
  getDriveTimeReportAsCSV: GetDriveTimeReportAsCsvResponse;
  getFaq: GetFaqResponse;
  getFilters: GetFiltersResponse;
  getFlightStatus: GetFlightStatusResponse;
  getHeaders: GetHeadersResponse;
  getInvoice: GetInvoiceResponse;
  getMetric: GetMetricResponse;
  getNormalizedAddress: GetNormalizedAddressResponse;
  getOnTimePerformanceReport: GetOnTimePerformanceReportResponse;
  getOnTimePerformanceReportAsCSV: GetOnTimePerformanceReportAsCsvResponse;
  getOverrideImportOptions: GetOverrideImportOptionsResponse;
  getPortalConfig: GetPortalConfigResponse;
  getSummaryReport: GetSummaryReportResponse;
  getTripsWithWrongContractedRate: GetTripsWithWrongContractedRateResponse;
  node?: Maybe<Node>;
  reportQuery?: Maybe<ReportQueryResponse>;
  runGetDriverEstimatedDriveTime: RunGetDriverEstimatedDriveTimeResponse;
  runRateOverridePreview: RunRateOverridePreviewResponse;
  searchActivity: SearchActivityResponse;
  searchAirportHasCompany: SearchAirportHasCompanyResponse;
  searchAirportHasVehicle: SearchAirportHasVehicleResponse;
  searchAirportWithGroup: SearchAirportWithGroupResponse;
  searchAirportWithLocation: SearchAirportWithLocationResponse;
  searchAirportWithVehicle: SearchAirportWithVehicleResponse;
  searchAlias: SearchAliasResponse;
  searchBufferTimes: SearchBufferTimeResponse;
  searchCombinableTrips: SearchCombinableTripsResponse;
  searchCombineRules: SearchCombineRuleResponse;
  searchCommunications: SearchCommunicationResponse;
  searchCompanies: SearchCompaniesResponse;
  searchFcrTypes: SearchFcrTypeResponse;
  searchFcrs: SearchFcrResponse;
  searchFlags: SearchFlagResponse;
  searchGroups: SearchGroupResponse;
  searchImports: SearchImportsResponse;
  searchInvoices: SearchInvoiceResponse;
  searchLocations: SearchLocationsResponse;
  searchLostAndFoundIssue: SearchLostAndFoundIssueResponse;
  searchLostAndFoundIssueDocument: SearchLostAndFoundIssueDocumentResponse;
  searchPermissions: SearchPermissionResponse;
  searchPreviousImports: SearchPreviousImportsResponse;
  searchProviders: SearchProviderResponse;
  searchPunchPolicies: SearchPunchPolicyResponse;
  searchRates: SearchRateResponse;
  searchRatings: SearchRatingResponse;
  searchRoles: SearchRoleResponse;
  searchStops: SearchStopResponse;
  searchSummaryReportSnapshots?: Maybe<SearchSummaryReportSnapshotsResponse>;
  searchTrips: SearchTripResponse;
  searchUserWithAvailabilityAndPermissions: SearchUserWithAvailabilityAndPermissionsResponse;
  searchUserWithPermissions: SearchUserWithPermissionsResponse;
  searchUsers: SearchUserResponse;
  searchVariableTimes: SearchVariableTimeResponse;
  searchVehicles: SearchVehicleResponse;
};


export type QueryGetAvailableUsersForTripArgs = {
  input: GetAvailableUsersForTripInput;
};


export type QueryGetCorrectRatesForTripsArgs = {
  input: GetCorrectRatesForTripsInput;
};


export type QueryGetDeviceConfigArgs = {
  input: GetDeviceConfigInput;
};


export type QueryGetDriveTimeReportArgs = {
  input: GetDriveTimeReportInput;
};


export type QueryGetDriveTimeReportAsCsvArgs = {
  input: GetDriveTimeReportAsCsvInput;
};


export type QueryGetFaqArgs = {
  input: GetFaqInput;
};


export type QueryGetFlightStatusArgs = {
  input: GetFlightStatusInput;
};


export type QueryGetHeadersArgs = {
  input: GetHeadersInput;
};


export type QueryGetInvoiceArgs = {
  input: GetInvoiceInput;
};


export type QueryGetMetricArgs = {
  input: GetMetricInput;
};


export type QueryGetNormalizedAddressArgs = {
  input: GetNormalizedAddressInput;
};


export type QueryGetOnTimePerformanceReportArgs = {
  input: GetOnTimePerformanceReportInput;
};


export type QueryGetOnTimePerformanceReportAsCsvArgs = {
  input: GetOnTimePerformanceReportAsCsvInput;
};


export type QueryGetOverrideImportOptionsArgs = {
  input: GetOverrideImportOptionsInput;
};


export type QueryGetPortalConfigArgs = {
  input: GetPortalConfigInput;
};


export type QueryGetSummaryReportArgs = {
  input: GetSummaryReportInput;
};


export type QueryGetTripsWithWrongContractedRateArgs = {
  input: GetTripsWithWrongContractedRateInput;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRunGetDriverEstimatedDriveTimeArgs = {
  input: RunGetDriverEstimatedDriveTimeInput;
};


export type QueryRunRateOverridePreviewArgs = {
  input: RunRateOverridePreviewInput;
};

export type Rate = {
  __typename?: 'Rate';
  airportCode?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  contractEndDatetime?: Maybe<Scalars['String']['output']>;
  contractId?: Maybe<Scalars['String']['output']>;
  contractStartDatetime?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currency: RateCurrencyEnum;
  duration?: Maybe<Scalars['Int']['output']>;
  endDatetime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  payerProvider?: Maybe<Provider>;
  payerProviderId?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['String']['output']>;
  radius: Scalars['Int']['output'];
  rate: Scalars['Float']['output'];
  rateGroupId?: Maybe<Scalars['String']['output']>;
  rateItemId?: Maybe<Scalars['String']['output']>;
  startDatetime?: Maybe<Scalars['String']['output']>;
  thatLocation?: Maybe<Location>;
  thatLocationId: Scalars['String']['output'];
  thisLocation?: Maybe<Location>;
  thisLocationId: Scalars['String']['output'];
  tripDistance?: Maybe<Scalars['Int']['output']>;
  type: RateTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type RateConnection = {
  __typename?: 'RateConnection';
  edges?: Maybe<Array<Maybe<RateEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RateConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<RateMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<RateSearch>>;
};

export enum RateCurrencyEnum {
  Usd = 'USD'
}

export type RateCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<RateCurrencyEnum>;
};

export type RateEdge = {
  __typename?: 'RateEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Rate>;
};

export type RateGroup = {
  __typename?: 'RateGroup';
  airportCode?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  items?: Maybe<Array<RateItem>>;
  payerProvider?: Maybe<Provider>;
  payerProviderId?: Maybe<Scalars['String']['output']>;
  thatLocation?: Maybe<Location>;
  thatLocationId: Scalars['String']['output'];
  thisLocation?: Maybe<Location>;
  thisLocationId: Scalars['String']['output'];
  tripDistance?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type RateGroupConnection = {
  __typename?: 'RateGroupConnection';
  edges?: Maybe<Array<Maybe<RateGroupEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RateGroupConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<RateGroupMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<RateGroupSearch>>;
};

export type RateGroupEdge = {
  __typename?: 'RateGroupEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<RateGroup>;
};

export type RateGroupMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  duration?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  thatLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  thisLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  tripDistance?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RateGroupSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  duration?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  thatLocationId?: InputMaybe<Array<StringSearch>>;
  thisLocationId?: InputMaybe<Array<StringSearch>>;
  tripDistance?: InputMaybe<Array<IntSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type RateGroupWithLatestRateHistory = {
  __typename?: 'RateGroupWithLatestRateHistory';
  companyId: Scalars['String']['output'];
  payerProviderId?: Maybe<Scalars['String']['output']>;
  rate: Scalars['Float']['output'];
  rateGroupId: Scalars['String']['output'];
  rateHistoryId: Scalars['String']['output'];
  rateItemCurrency: RateGroupWithLatestRateHistoryRateItemCurrencyEnum;
  rateItemEndDatetime?: Maybe<Scalars['String']['output']>;
  rateItemId: Scalars['String']['output'];
  rateItemStartDatetime: Scalars['String']['output'];
  rateItemType: RateGroupWithLatestRateHistoryRateItemTypeEnum;
  thatLocationId: Scalars['String']['output'];
  thisLocationId: Scalars['String']['output'];
};

export type RateGroupWithLatestRateHistoryConnection = {
  __typename?: 'RateGroupWithLatestRateHistoryConnection';
  edges?: Maybe<Array<Maybe<RateGroupWithLatestRateHistoryEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RateGroupWithLatestRateHistoryConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<RateGroupWithLatestRateHistoryMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<RateGroupWithLatestRateHistorySearch>>;
};

export type RateGroupWithLatestRateHistoryEdge = {
  __typename?: 'RateGroupWithLatestRateHistoryEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<RateGroupWithLatestRateHistory>;
};

export type RateGroupWithLatestRateHistoryMaskInput = {
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  rate?: InputMaybe<Scalars['Boolean']['input']>;
  rateGroupId?: InputMaybe<Scalars['Boolean']['input']>;
  rateHistoryId?: InputMaybe<Scalars['Boolean']['input']>;
  rateItemCurrency?: InputMaybe<Scalars['Boolean']['input']>;
  rateItemEndDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  rateItemId?: InputMaybe<Scalars['Boolean']['input']>;
  rateItemStartDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  rateItemType?: InputMaybe<Scalars['Boolean']['input']>;
  thatLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  thisLocationId?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum RateGroupWithLatestRateHistoryRateItemCurrencyEnum {
  Usd = 'USD'
}

export type RateGroupWithLatestRateHistoryRateItemCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<RateGroupWithLatestRateHistoryRateItemCurrencyEnum>;
};

export enum RateGroupWithLatestRateHistoryRateItemTypeEnum {
  Contract = 'CONTRACT',
  NonContract = 'NON_CONTRACT'
}

export type RateGroupWithLatestRateHistoryRateItemTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<RateGroupWithLatestRateHistoryRateItemTypeEnum>;
};

export type RateGroupWithLatestRateHistorySearch = {
  companyId?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  rate?: InputMaybe<Array<FloatSearch>>;
  rateGroupId?: InputMaybe<Array<StringSearch>>;
  rateHistoryId?: InputMaybe<Array<StringSearch>>;
  rateItemCurrency?: InputMaybe<Array<RateGroupWithLatestRateHistoryRateItemCurrencyEnumSearch>>;
  rateItemEndDatetime?: InputMaybe<Array<StringSearch>>;
  rateItemId?: InputMaybe<Array<StringSearch>>;
  rateItemStartDatetime?: InputMaybe<Array<StringSearch>>;
  rateItemType?: InputMaybe<Array<RateGroupWithLatestRateHistoryRateItemTypeEnumSearch>>;
  thatLocationId?: InputMaybe<Array<StringSearch>>;
  thisLocationId?: InputMaybe<Array<StringSearch>>;
};

export type RateHistory = {
  __typename?: 'RateHistory';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currency: RateHistoryCurrencyEnum;
  endDatetime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  rate: Scalars['Float']['output'];
  rateGroupId: Scalars['String']['output'];
  rateItemId?: Maybe<Scalars['String']['output']>;
  startDatetime?: Maybe<Scalars['String']['output']>;
  type: RateHistoryTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type RateHistoryConnection = {
  __typename?: 'RateHistoryConnection';
  edges?: Maybe<Array<Maybe<RateHistoryEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RateHistoryConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<RateHistoryMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<RateHistorySearch>>;
};

export enum RateHistoryCurrencyEnum {
  Usd = 'USD'
}

export type RateHistoryCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<RateHistoryCurrencyEnum>;
};

export type RateHistoryEdge = {
  __typename?: 'RateHistoryEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<RateHistory>;
};

export type RateHistoryMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['Boolean']['input']>;
  endDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  rate?: InputMaybe<Scalars['Boolean']['input']>;
  rateGroupId?: InputMaybe<Scalars['Boolean']['input']>;
  rateItemId?: InputMaybe<Scalars['Boolean']['input']>;
  startDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RateHistorySearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  currency?: InputMaybe<Array<RateHistoryCurrencyEnumSearch>>;
  endDatetime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  rate?: InputMaybe<Array<FloatSearch>>;
  rateGroupId?: InputMaybe<Array<StringSearch>>;
  rateItemId?: InputMaybe<Array<StringSearch>>;
  startDatetime?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<RateHistoryTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum RateHistoryTypeEnum {
  Adhoc = 'ADHOC',
  Contract = 'CONTRACT',
  NonContract = 'NON_CONTRACT',
  Override = 'OVERRIDE'
}

export type RateHistoryTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<RateHistoryTypeEnum>;
};

export type RateHistoryWithRateGroup = {
  __typename?: 'RateHistoryWithRateGroup';
  airportCode?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currency: RateHistoryWithRateGroupCurrencyEnum;
  duration?: Maybe<Scalars['Int']['output']>;
  endDatetime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payerProviderId?: Maybe<Scalars['String']['output']>;
  rate: Scalars['Float']['output'];
  rateGroupId: Scalars['String']['output'];
  rateItemId?: Maybe<Scalars['String']['output']>;
  startDatetime?: Maybe<Scalars['String']['output']>;
  thatLocationId: Scalars['String']['output'];
  thisLocationId: Scalars['String']['output'];
  tripDistance?: Maybe<Scalars['Int']['output']>;
  type: RateHistoryWithRateGroupTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type RateHistoryWithRateGroupConnection = {
  __typename?: 'RateHistoryWithRateGroupConnection';
  edges?: Maybe<Array<Maybe<RateHistoryWithRateGroupEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RateHistoryWithRateGroupConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<RateHistoryWithRateGroupMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<RateHistoryWithRateGroupSearch>>;
};

export enum RateHistoryWithRateGroupCurrencyEnum {
  Usd = 'USD'
}

export type RateHistoryWithRateGroupCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<RateHistoryWithRateGroupCurrencyEnum>;
};

export type RateHistoryWithRateGroupEdge = {
  __typename?: 'RateHistoryWithRateGroupEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<RateHistoryWithRateGroup>;
};

export type RateHistoryWithRateGroupMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['Boolean']['input']>;
  duration?: InputMaybe<Scalars['Boolean']['input']>;
  endDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  rate?: InputMaybe<Scalars['Boolean']['input']>;
  rateGroupId?: InputMaybe<Scalars['Boolean']['input']>;
  rateItemId?: InputMaybe<Scalars['Boolean']['input']>;
  startDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  thatLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  thisLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  tripDistance?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RateHistoryWithRateGroupSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  currency?: InputMaybe<Array<RateHistoryWithRateGroupCurrencyEnumSearch>>;
  duration?: InputMaybe<Array<IntSearch>>;
  endDatetime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  rate?: InputMaybe<Array<FloatSearch>>;
  rateGroupId?: InputMaybe<Array<StringSearch>>;
  rateItemId?: InputMaybe<Array<StringSearch>>;
  startDatetime?: InputMaybe<Array<StringSearch>>;
  thatLocationId?: InputMaybe<Array<StringSearch>>;
  thisLocationId?: InputMaybe<Array<StringSearch>>;
  tripDistance?: InputMaybe<Array<IntSearch>>;
  type?: InputMaybe<Array<RateHistoryWithRateGroupTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum RateHistoryWithRateGroupTypeEnum {
  Adhoc = 'ADHOC',
  Contract = 'CONTRACT',
  NonContract = 'NON_CONTRACT',
  Override = 'OVERRIDE'
}

export type RateHistoryWithRateGroupTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<RateHistoryWithRateGroupTypeEnum>;
};

export type RateItem = {
  __typename?: 'RateItem';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currency: RateItemCurrencyEnum;
  endDatetime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  rateGroupId: Scalars['String']['output'];
  startDatetime: Scalars['String']['output'];
  type: RateItemTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type RateItemConnection = {
  __typename?: 'RateItemConnection';
  edges?: Maybe<Array<Maybe<RateItemEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RateItemConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<RateItemMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<RateItemSearch>>;
};

export enum RateItemCurrencyEnum {
  Usd = 'USD'
}

export type RateItemCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<RateItemCurrencyEnum>;
};

export type RateItemEdge = {
  __typename?: 'RateItemEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<RateItem>;
};

export type RateItemMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['Boolean']['input']>;
  endDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  rateGroupId?: InputMaybe<Scalars['Boolean']['input']>;
  startDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RateItemSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  currency?: InputMaybe<Array<RateItemCurrencyEnumSearch>>;
  endDatetime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  rateGroupId?: InputMaybe<Array<StringSearch>>;
  startDatetime?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<RateItemTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum RateItemTypeEnum {
  Contract = 'CONTRACT',
  NonContract = 'NON_CONTRACT'
}

export type RateItemTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<RateItemTypeEnum>;
};

export type RateItemWithLatestRateHistory = {
  __typename?: 'RateItemWithLatestRateHistory';
  companyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currency: RateItemWithLatestRateHistoryCurrencyEnum;
  endDatetime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payerProviderId?: Maybe<Scalars['String']['output']>;
  rate: Scalars['Float']['output'];
  rateGroupId: Scalars['String']['output'];
  rateHistoryId: Scalars['String']['output'];
  startDatetime: Scalars['String']['output'];
  thatLocationId: Scalars['String']['output'];
  thisLocationId: Scalars['String']['output'];
  type: RateItemWithLatestRateHistoryTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type RateItemWithLatestRateHistoryConnection = {
  __typename?: 'RateItemWithLatestRateHistoryConnection';
  edges?: Maybe<Array<Maybe<RateItemWithLatestRateHistoryEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RateItemWithLatestRateHistoryConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<RateItemWithLatestRateHistoryMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<RateItemWithLatestRateHistorySearch>>;
};

export enum RateItemWithLatestRateHistoryCurrencyEnum {
  Usd = 'USD'
}

export type RateItemWithLatestRateHistoryCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<RateItemWithLatestRateHistoryCurrencyEnum>;
};

export type RateItemWithLatestRateHistoryEdge = {
  __typename?: 'RateItemWithLatestRateHistoryEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<RateItemWithLatestRateHistory>;
};

export type RateItemWithLatestRateHistoryMaskInput = {
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['Boolean']['input']>;
  endDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  rate?: InputMaybe<Scalars['Boolean']['input']>;
  rateGroupId?: InputMaybe<Scalars['Boolean']['input']>;
  rateHistoryId?: InputMaybe<Scalars['Boolean']['input']>;
  startDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  thatLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  thisLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RateItemWithLatestRateHistorySearch = {
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  currency?: InputMaybe<Array<RateItemWithLatestRateHistoryCurrencyEnumSearch>>;
  endDatetime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  rate?: InputMaybe<Array<FloatSearch>>;
  rateGroupId?: InputMaybe<Array<StringSearch>>;
  rateHistoryId?: InputMaybe<Array<StringSearch>>;
  startDatetime?: InputMaybe<Array<StringSearch>>;
  thatLocationId?: InputMaybe<Array<StringSearch>>;
  thisLocationId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<RateItemWithLatestRateHistoryTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum RateItemWithLatestRateHistoryTypeEnum {
  Contract = 'CONTRACT',
  NonContract = 'NON_CONTRACT'
}

export type RateItemWithLatestRateHistoryTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<RateItemWithLatestRateHistoryTypeEnum>;
};

export type RateMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  contractEndDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Boolean']['input']>;
  contractStartDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['Boolean']['input']>;
  duration?: InputMaybe<Scalars['Boolean']['input']>;
  endDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  radius?: InputMaybe<Scalars['Boolean']['input']>;
  rate?: InputMaybe<Scalars['Boolean']['input']>;
  startDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  thatLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  thisLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  tripDistance?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RateSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  contractEndDatetime?: InputMaybe<Array<StringSearch>>;
  contractId?: InputMaybe<Array<StringSearch>>;
  contractStartDatetime?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  currency?: InputMaybe<Array<RateCurrencyEnumSearch>>;
  duration?: InputMaybe<Array<IntSearch>>;
  endDatetime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  notes?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  radius?: InputMaybe<Array<IntSearch>>;
  rate?: InputMaybe<Array<FloatSearch>>;
  startDatetime?: InputMaybe<Array<StringSearch>>;
  thatLocationId?: InputMaybe<Array<StringSearch>>;
  thisLocationId?: InputMaybe<Array<StringSearch>>;
  tripDistance?: InputMaybe<Array<IntSearch>>;
  type?: InputMaybe<Array<RateTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum RateTableConnectionFormatEnum {
  Adhoc = 'ADHOC',
  Contract = 'CONTRACT',
  Current = 'CURRENT',
  NonContract = 'NON_CONTRACT'
}

export type RateTableConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<LatestRateMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  format: RateTableConnectionFormatEnum;
  query?: InputMaybe<Array<LatestRateSearch>>;
};

export enum RateTypeEnum {
  Adhoc = 'ADHOC',
  Contract = 'CONTRACT',
  NonContract = 'NON_CONTRACT',
  Override = 'OVERRIDE'
}

export type RateTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<RateTypeEnum>;
};

export type RateValueInput = {
  currency: RateHistoryCurrencyEnum;
};

export type RateValueResponse = {
  __typename?: 'RateValueResponse';
  rates?: Maybe<Array<FilterOption>>;
};

export type Rating = Node & {
  __typename?: 'Rating';
  comment?: Maybe<Scalars['String']['output']>;
  compliments?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  rating: Scalars['Int']['output'];
  ratingType?: Maybe<RatingType>;
  ratingTypeId: Scalars['String']['output'];
  tripId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type RatingConnection = {
  __typename?: 'RatingConnection';
  edges?: Maybe<Array<Maybe<RatingEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RatingConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<RatingMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<RatingSearch>>;
};

export type RatingDetails = {
  __typename?: 'RatingDetails';
  averageRating: Scalars['Float']['output'];
  rating?: Maybe<Array<Rating>>;
};

export type RatingEdge = {
  __typename?: 'RatingEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Rating>;
};

export type RatingMaskInput = {
  comment?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  rating?: InputMaybe<Scalars['Boolean']['input']>;
  ratingTypeId?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RatingRemark = {
  __typename?: 'RatingRemark';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  ratingId: Scalars['String']['output'];
  remark: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type RatingRemarkConnection = {
  __typename?: 'RatingRemarkConnection';
  edges?: Maybe<Array<Maybe<RatingRemarkEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RatingRemarkConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<RatingRemarkMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<RatingRemarkSearch>>;
};

export type RatingRemarkEdge = {
  __typename?: 'RatingRemarkEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<RatingRemark>;
};

export type RatingRemarkMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  ratingId?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RatingRemarkSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  ratingId?: InputMaybe<Array<StringSearch>>;
  remark?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type RatingRemarksWithRating = {
  __typename?: 'RatingRemarksWithRating';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  ratingComment?: Maybe<Scalars['String']['output']>;
  ratingCreatedAt?: Maybe<Scalars['String']['output']>;
  ratingCreatedBy?: Maybe<Scalars['String']['output']>;
  ratingId: Scalars['String']['output'];
  ratingRating: Scalars['Int']['output'];
  ratingTripId: Scalars['String']['output'];
  ratingTypeCreatedAt?: Maybe<Scalars['String']['output']>;
  ratingTypeCreatedBy?: Maybe<Scalars['String']['output']>;
  ratingTypeId: Scalars['String']['output'];
  ratingTypeName: Scalars['String']['output'];
  ratingTypeUpdatedAt?: Maybe<Scalars['String']['output']>;
  ratingTypeUpdatedBy?: Maybe<Scalars['String']['output']>;
  ratingUpdatedAt?: Maybe<Scalars['String']['output']>;
  ratingUpdatedBy?: Maybe<Scalars['String']['output']>;
  ratingUserId: Scalars['String']['output'];
  remark: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type RatingRemarksWithRatingConnection = {
  __typename?: 'RatingRemarksWithRatingConnection';
  edges?: Maybe<Array<Maybe<RatingRemarksWithRatingEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RatingRemarksWithRatingConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<RatingRemarksWithRatingMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<RatingRemarksWithRatingSearch>>;
};

export type RatingRemarksWithRatingEdge = {
  __typename?: 'RatingRemarksWithRatingEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<RatingRemarksWithRating>;
};

export type RatingRemarksWithRatingMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  ratingComment?: InputMaybe<Scalars['Boolean']['input']>;
  ratingCreatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  ratingCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  ratingId?: InputMaybe<Scalars['Boolean']['input']>;
  ratingRating?: InputMaybe<Scalars['Boolean']['input']>;
  ratingTripId?: InputMaybe<Scalars['Boolean']['input']>;
  ratingTypeCreatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  ratingTypeCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  ratingTypeId?: InputMaybe<Scalars['Boolean']['input']>;
  ratingTypeName?: InputMaybe<Scalars['Boolean']['input']>;
  ratingTypeUpdatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  ratingTypeUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  ratingUpdatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  ratingUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  ratingUserId?: InputMaybe<Scalars['Boolean']['input']>;
  remark?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RatingRemarksWithRatingSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  ratingComment?: InputMaybe<Array<StringSearch>>;
  ratingCreatedAt?: InputMaybe<Array<StringSearch>>;
  ratingCreatedBy?: InputMaybe<Array<StringSearch>>;
  ratingId?: InputMaybe<Array<StringSearch>>;
  ratingRating?: InputMaybe<Array<IntSearch>>;
  ratingTripId?: InputMaybe<Array<StringSearch>>;
  ratingTypeCreatedAt?: InputMaybe<Array<StringSearch>>;
  ratingTypeCreatedBy?: InputMaybe<Array<StringSearch>>;
  ratingTypeId?: InputMaybe<Array<StringSearch>>;
  ratingTypeName?: InputMaybe<Array<StringSearch>>;
  ratingTypeUpdatedAt?: InputMaybe<Array<StringSearch>>;
  ratingTypeUpdatedBy?: InputMaybe<Array<StringSearch>>;
  ratingUpdatedAt?: InputMaybe<Array<StringSearch>>;
  ratingUpdatedBy?: InputMaybe<Array<StringSearch>>;
  ratingUserId?: InputMaybe<Array<StringSearch>>;
  remark?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type RatingSearch = {
  comment?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  rating?: InputMaybe<Array<IntSearch>>;
  ratingTypeId?: InputMaybe<Array<StringSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  userId?: InputMaybe<Array<StringSearch>>;
};

export type RatingType = {
  __typename?: 'RatingType';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type RatingTypeConnection = {
  __typename?: 'RatingTypeConnection';
  edges?: Maybe<Array<Maybe<RatingTypeEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RatingTypeConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<RatingTypeMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<RatingTypeSearch>>;
};

export type RatingTypeEdge = {
  __typename?: 'RatingTypeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<RatingType>;
};

export type RatingTypeMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RatingTypeSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type RatingWithRemarks = {
  __typename?: 'RatingWithRemarks';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  rating: Scalars['Int']['output'];
  ratingRemarkId: Scalars['String']['output'];
  ratingRemarkRemark: Scalars['String']['output'];
  ratingTypeName: Scalars['String']['output'];
  tripId: Scalars['String']['output'];
  typeId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type RatingWithRemarksConnection = {
  __typename?: 'RatingWithRemarksConnection';
  edges?: Maybe<Array<Maybe<RatingWithRemarksEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RatingWithRemarksConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<RatingWithRemarksMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<RatingWithRemarksSearch>>;
};

export type RatingWithRemarksEdge = {
  __typename?: 'RatingWithRemarksEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<RatingWithRemarks>;
};

export type RatingWithRemarksMaskInput = {
  comment?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  rating?: InputMaybe<Scalars['Boolean']['input']>;
  ratingRemarkId?: InputMaybe<Scalars['Boolean']['input']>;
  ratingRemarkRemark?: InputMaybe<Scalars['Boolean']['input']>;
  ratingTypeName?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  typeId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RatingWithRemarksSearch = {
  comment?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  rating?: InputMaybe<Array<IntSearch>>;
  ratingRemarkId?: InputMaybe<Array<StringSearch>>;
  ratingRemarkRemark?: InputMaybe<Array<StringSearch>>;
  ratingTypeName?: InputMaybe<Array<StringSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  typeId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  userId?: InputMaybe<Array<StringSearch>>;
};

export type RatingsAndReviewNode = {
  __typename?: 'RatingsAndReviewNode';
  amountOfStars?: Maybe<Scalars['Int']['output']>;
  datetime?: Maybe<Scalars['String']['output']>;
  flightNumber?: Maybe<Scalars['Int']['output']>;
  ratingId?: Maybe<Scalars['String']['output']>;
  servicerIataAirlineCode?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  tripId: Scalars['ID']['output'];
};

export type RatingsAndReviewsDriverAppInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  airportCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  amountOfStars?: InputMaybe<Scalars['Int']['input']>;
  driverId?: InputMaybe<Array<Scalars['String']['input']>>;
  endDatetime?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  startDatetime?: InputMaybe<Scalars['String']['input']>;
};

export type RatingsAndReviewsDriverAppResponse = {
  __typename?: 'RatingsAndReviewsDriverAppResponse';
  ratings?: Maybe<RatingsAndReviewsRatings>;
  remarks?: Maybe<Array<RatingsAndReviewsRemarks>>;
  reviews?: Maybe<RatingsAndReviewsReviewsConnection>;
};

export type RatingsAndReviewsEdge = {
  __typename?: 'RatingsAndReviewsEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<RatingsAndReviewNode>;
};

export type RatingsAndReviewsRatings = {
  __typename?: 'RatingsAndReviewsRatings';
  average?: Maybe<Scalars['Float']['output']>;
  fiveStar?: Maybe<Scalars['Int']['output']>;
  fourStar?: Maybe<Scalars['Int']['output']>;
  oneStar?: Maybe<Scalars['Int']['output']>;
  threeStar?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  twoStar?: Maybe<Scalars['Int']['output']>;
};

export type RatingsAndReviewsRemarks = {
  __typename?: 'RatingsAndReviewsRemarks';
  remark: Scalars['String']['output'];
  totalCount: Scalars['Int']['output'];
};

export type RatingsAndReviewsReviewsConnection = {
  __typename?: 'RatingsAndReviewsReviewsConnection';
  edges?: Maybe<Array<Maybe<RatingsAndReviewsEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Remark = Node & {
  __typename?: 'Remark';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  tripId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type RemarkInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  tripId?: InputMaybe<Scalars['String']['input']>;
};

export type RemarkResponse = {
  __typename?: 'RemarkResponse';
  error?: Maybe<Scalars['String']['output']>;
  remark?: Maybe<Remark>;
};

export type ReportData = {
  __typename?: 'ReportData';
  filters?: Maybe<Array<FilterObject>>;
  header?: Maybe<Array<KeyValue>>;
  tables?: Maybe<Array<Table>>;
};

export enum ReportFilterEnum {
  Default = 'DEFAULT',
  HasCompletion = 'HAS_COMPLETION',
  HasNoDriver = 'HAS_NO_DRIVER',
  IllegalCombine = 'ILLEGAL_COMBINE',
  Late = 'LATE',
  LegalCombine = 'LEGAL_COMBINE',
  ZeroRate = 'ZERO_RATE'
}

export type ReportFilters = {
  __typename?: 'ReportFilters';
  airlines?: Maybe<Array<Scalars['String']['output']>>;
  airports?: Maybe<Array<Scalars['String']['output']>>;
  buckets?: Maybe<Array<TimeIntervalBucketFilter>>;
  clients?: Maybe<Array<Scalars['String']['output']>>;
  companies?: Maybe<Array<Scalars['String']['output']>>;
  completionTypes?: Maybe<Array<Scalars['String']['output']>>;
  complexFilters?: Maybe<Array<ReportFilterEnum>>;
  csvColumns?: Maybe<Array<ColumnObjectFilter>>;
  currencies?: Maybe<Array<Scalars['String']['output']>>;
  doLocations?: Maybe<Array<Scalars['String']['output']>>;
  drivers?: Maybe<Array<Scalars['String']['output']>>;
  endDatetime?: Maybe<Scalars['String']['output']>;
  groupBy?: Maybe<Scalars['String']['output']>;
  kinds?: Maybe<Array<Scalars['String']['output']>>;
  puLocations?: Maybe<Array<Scalars['String']['output']>>;
  rateTypes?: Maybe<Array<Scalars['String']['output']>>;
  rates?: Maybe<Array<Scalars['String']['output']>>;
  rating?: Maybe<Array<Scalars['String']['output']>>;
  reason?: Maybe<Array<ReportReasonEnum>>;
  reportType?: Maybe<ReportTypeEnum>;
  series?: Maybe<ReportSeriesEnum>;
  startDatetime?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Array<Scalars['String']['output']>>;
  thresholds?: Maybe<Array<Scalars['String']['output']>>;
  types?: Maybe<Array<Scalars['String']['output']>>;
};

export enum ReportGroupByEnum {
  Airline = 'AIRLINE',
  City = 'CITY',
  Client = 'CLIENT',
  Date = 'DATE',
  Driver = 'DRIVER',
  Market = 'MARKET',
  Status = 'STATUS',
  Type = 'TYPE',
  Vehicle = 'VEHICLE'
}

export type ReportInput = {
  airlines?: InputMaybe<Array<Scalars['String']['input']>>;
  airports?: InputMaybe<Array<Scalars['String']['input']>>;
  buckets?: InputMaybe<Array<TimeIntervalBucket>>;
  clients?: InputMaybe<Array<Scalars['String']['input']>>;
  companies?: InputMaybe<Array<Scalars['String']['input']>>;
  completionTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  complexFilters?: InputMaybe<Array<ReportFilterEnum>>;
  csvColumns?: InputMaybe<Array<ColumnObject>>;
  currencies?: InputMaybe<Array<Scalars['String']['input']>>;
  details?: InputMaybe<Array<RowDetailsInput>>;
  doLocations?: InputMaybe<Array<Scalars['String']['input']>>;
  drivers?: InputMaybe<Array<Scalars['String']['input']>>;
  endDatetime?: InputMaybe<Scalars['String']['input']>;
  groupBy?: InputMaybe<Scalars['String']['input']>;
  kinds?: InputMaybe<Array<Scalars['String']['input']>>;
  puLocations?: InputMaybe<Array<Scalars['String']['input']>>;
  rateTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  rates?: InputMaybe<Array<Scalars['String']['input']>>;
  rating?: InputMaybe<Array<Scalars['String']['input']>>;
  reason?: InputMaybe<Array<ReportReasonEnum>>;
  reportType?: InputMaybe<ReportTypeEnum>;
  series?: InputMaybe<ReportSeriesEnum>;
  snapshotIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startDatetime?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<Scalars['String']['input']>>;
  thresholds?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
  userSessionTimezone?: InputMaybe<Scalars['String']['input']>;
};

export type ReportProblemCrewInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
};

export type ReportProblemCrewResponse = {
  __typename?: 'ReportProblemCrewResponse';
  error?: Maybe<Error>;
  message: Scalars['String']['output'];
};

export type ReportProblemDriverInput = {
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  message: Scalars['String']['input'];
};

export type ReportProblemDriverResponse = {
  __typename?: 'ReportProblemDriverResponse';
  error?: Maybe<Error>;
  message: Scalars['String']['output'];
};

export type ReportProblemInput = {
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  message: Scalars['String']['input'];
};

export type ReportProblemResponse = {
  __typename?: 'ReportProblemResponse';
  error?: Maybe<Error>;
  message: Scalars['String']['output'];
};

export type ReportQueryResponse = {
  __typename?: 'ReportQueryResponse';
  clientSummaryReport: GetReportResponse;
  clientSummaryReportAsCsv: GetReportAsCsvResponse;
  clientSummaryReportSnapshotAsCsv: GetReportAsCsvResponse;
  completionReport: GetReportResponse;
  completionReportAsCsv: GetReportAsCsvResponse;
  detailedReport: GetReportResponse;
  detailedReportAsCsv: GetReportAsCsvResponse;
  drivetimeReport: GetReportResponse;
  drivetimeReportAsCsv: GetReportAsCsvResponse;
  fcrReport: GetReportResponse;
  fcrReportAsCsv: GetReportAsCsvResponse;
  genericReport: GetReportResponse;
  kpiReport: GetReportResponse;
  kpiReportAsCsv: GetReportAsCsvResponse;
  onTimePerformanceReport: GetReportResponse;
  onTimePerformanceReportAsCsv: GetReportAsCsvResponse;
  punchReport: GetReportResponse;
  punchReportAsCsv: GetReportAsCsvResponse;
  ratingsReport: GetReportResponse;
  ratingsReportAsCsv: GetReportAsCsvResponse;
  ratingsTable: GetReportResponse;
  vehicleReport: GetReportResponse;
  vehicleReportAsCsv: GetReportAsCsvResponse;
};


export type ReportQueryResponseClientSummaryReportArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseClientSummaryReportAsCsvArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseClientSummaryReportSnapshotAsCsvArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseCompletionReportArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseCompletionReportAsCsvArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseDetailedReportArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseDetailedReportAsCsvArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseDrivetimeReportArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseDrivetimeReportAsCsvArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseFcrReportArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseFcrReportAsCsvArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseGenericReportArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseKpiReportArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseKpiReportAsCsvArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseOnTimePerformanceReportArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseOnTimePerformanceReportAsCsvArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponsePunchReportArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponsePunchReportAsCsvArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseRatingsReportArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseRatingsReportAsCsvArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseRatingsTableArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseVehicleReportArgs = {
  input?: InputMaybe<ReportInput>;
};


export type ReportQueryResponseVehicleReportAsCsvArgs = {
  input?: InputMaybe<ReportInput>;
};

export enum ReportReasonEnum {
  Airline = 'AIRLINE',
  Crew = 'CREW',
  Dispatch = 'DISPATCH',
  Driver = 'DRIVER',
  Equipment = 'EQUIPMENT',
  Irop = 'IROP',
  Positive = 'POSITIVE',
  Safety = 'SAFETY',
  Traffic = 'TRAFFIC'
}

export enum ReportSeriesEnum {
  SeriesDay = 'SERIES_DAY',
  SeriesDefault = 'SERIES_DEFAULT',
  SeriesHour = 'SERIES_HOUR',
  SeriesMinute = 'SERIES_MINUTE',
  SeriesMonth = 'SERIES_MONTH',
  SeriesQuarter = 'SERIES_QUARTER',
  SeriesWeek = 'SERIES_WEEK',
  SeriesYear = 'SERIES_YEAR'
}

export enum ReportTypeEnum {
  ClientSummaryReport = 'CLIENT_SUMMARY_REPORT',
  ClientSummaryReportSnapshot = 'CLIENT_SUMMARY_REPORT_SNAPSHOT',
  CompletionReport = 'COMPLETION_REPORT',
  DetailedReport = 'DETAILED_REPORT',
  DetailedReportFull = 'DETAILED_REPORT_FULL',
  DriveTimeReport = 'DRIVE_TIME_REPORT',
  DriveTimeReportFull = 'DRIVE_TIME_REPORT_FULL',
  FcrReport = 'FCR_REPORT',
  KpiReport = 'KPI_REPORT',
  OntimePerformanceReport = 'ONTIME_PERFORMANCE_REPORT',
  PunchReport = 'PUNCH_REPORT',
  PunchReportDetails = 'PUNCH_REPORT_DETAILS',
  PunchReportSimple = 'PUNCH_REPORT_SIMPLE',
  RatingsReport = 'RATINGS_REPORT',
  RatingsTable = 'RATINGS_TABLE',
  Unknown = 'UNKNOWN',
  VehicleReport = 'VEHICLE_REPORT'
}

export type ResetPasswordLinkCodeInput = {
  code: Scalars['String']['input'];
  confirmPassword: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ResetPasswordLinkCodeResponse = {
  __typename?: 'ResetPasswordLinkCodeResponse';
  error?: Maybe<Error>;
  success: Scalars['Boolean']['output'];
};

export type Role = {
  __typename?: 'Role';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<Permission>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type RoleConnection = {
  __typename?: 'RoleConnection';
  edges?: Maybe<Array<Maybe<RoleEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RoleConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<RoleMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<RoleSearch>>;
};

export type RoleEdge = {
  __typename?: 'RoleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Role>;
};

export type RoleMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RoleSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type Row = {
  __typename?: 'Row';
  children?: Maybe<Array<Row>>;
  details?: Maybe<Array<RowDetails>>;
  values?: Maybe<Array<KeyValue>>;
};

export type RowDetails = {
  __typename?: 'RowDetails';
  filters?: Maybe<Array<ReportFilters>>;
  id: Scalars['String']['output'];
};

export type RowDetailsInput = {
  id: Scalars['String']['input'];
};

export type RunApplyRateToSimilarTripsInput = {
  rateIds: Array<Scalars['String']['input']>;
};

export type RunApplyRateToSimilarTripsResponse = {
  __typename?: 'RunApplyRateToSimilarTripsResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type RunArchiveImportsInput = {
  importIds: Array<Scalars['String']['input']>;
};

export type RunArchiveImportsResponse = {
  __typename?: 'RunArchiveImportsResponse';
  error?: Maybe<Error>;
  updated?: Maybe<Scalars['Int']['output']>;
};

export type RunArchiveInvoicesInput = {
  invoiceIds: Array<Scalars['Int']['input']>;
};

export type RunArchiveInvoicesResponse = {
  __typename?: 'RunArchiveInvoicesResponse';
  error?: Maybe<Error>;
  updated?: Maybe<Scalars['Int']['output']>;
};

export type RunAssignDriverInput = {
  combines?: InputMaybe<Array<CombineTripInput>>;
  driverId: Scalars['String']['input'];
  scheduled?: InputMaybe<Scalars['String']['input']>;
  tripId: Scalars['String']['input'];
  vehicleId?: InputMaybe<Scalars['String']['input']>;
};

export type RunAssignDriverResponse = {
  __typename?: 'RunAssignDriverResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
  vehicle?: Maybe<Vehicle>;
};

export type RunAssignVehicleToDriverBulkInput = {
  input: Array<RunAssignVehicleToDriverInput>;
};

export type RunAssignVehicleToDriverBulkResponse = {
  __typename?: 'RunAssignVehicleToDriverBulkResponse';
  output?: Maybe<Array<Maybe<RunAssignVehicleToDriverResponse>>>;
};

export type RunAssignVehicleToDriverInput = {
  scheduled: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type RunAssignVehicleToDriverResponse = {
  __typename?: 'RunAssignVehicleToDriverResponse';
  error?: Maybe<Error>;
  updated?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['String']['output'];
  vehicleId: Scalars['String']['output'];
};

export type RunAttemptAssignDriverAndCombineTripsInput = {
  airportCode?: InputMaybe<Scalars['String']['input']>;
  attendants: Scalars['Int']['input'];
  doLocationId: Scalars['String']['input'];
  driverId: Scalars['String']['input'];
  pilots: Scalars['Int']['input'];
  providerId: Scalars['String']['input'];
  puLocationId: Scalars['String']['input'];
  scheduled: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
  vehicleCapacity?: InputMaybe<Scalars['Int']['input']>;
  vehicleId?: InputMaybe<Scalars['String']['input']>;
};

export type RunAttemptAssignDriverAndCombineTripsResponse = {
  __typename?: 'RunAttemptAssignDriverAndCombineTripsResponse';
  appliedCombineType?: Maybe<TripCombineTypeEnum>;
  output?: Maybe<Array<TripAndCombineType>>;
  updated: Scalars['Int']['output'];
  vehicle?: Maybe<Vehicle>;
};

export type RunCompletionOnTripInput = {
  completion: CreateCompletionInput;
  tripIds: Array<Scalars['String']['input']>;
};

export type RunCompletionOnTripResponse = {
  __typename?: 'RunCompletionOnTripResponse';
  error?: Maybe<Error>;
  updated?: Maybe<Scalars['Int']['output']>;
};

export type RunDownloadImportInput = {
  importIds: Array<Scalars['String']['input']>;
};

export type RunDownloadImportResponse = {
  __typename?: 'RunDownloadImportResponse';
  output?: Maybe<Array<DownloadImportUrlResponse>>;
};

export type RunDownloadInvoiceInput = {
  input: InvoiceInput;
};

export type RunDownloadInvoiceResponse = {
  __typename?: 'RunDownloadInvoiceResponse';
  error?: Maybe<Error>;
  output?: Maybe<DownloadInvoiceResponse>;
};

export type RunDownloadLatestInvoiceDocumentInput = {
  invoiceId: Scalars['Int']['input'];
};

export type RunDownloadLatestInvoiceDocumentResponse = {
  __typename?: 'RunDownloadLatestInvoiceDocumentResponse';
  error?: Maybe<Error>;
  output?: Maybe<DownloadInvoiceResponse>;
};

export type RunGetDriverEstimatedDriveTimeInput = {
  driverId: Scalars['String']['input'];
  scheduled: Scalars['String']['input'];
  toLocationId: Scalars['String']['input'];
  tripId: Scalars['String']['input'];
};

export type RunGetDriverEstimatedDriveTimeResponse = {
  __typename?: 'RunGetDriverEstimatedDriveTimeResponse';
  estimatedTimeMinutes: Scalars['Int']['output'];
  fromLocationId: Scalars['String']['output'];
  fromTrip: Trip;
  toLocationId: Scalars['String']['output'];
  warning?: Maybe<Scalars['String']['output']>;
};

export type RunIngestImportsInput = {
  ingests: Array<RunIngestInput>;
};

export type RunIngestImportsResponse = {
  __typename?: 'RunIngestImportsResponse';
  error?: Maybe<Error>;
  ingests: Array<IngestImportResponse>;
};

export type RunIngestInput = {
  excludes: Array<Scalars['String']['input']>;
  importId: Scalars['String']['input'];
};

export type RunOverrideImportsInput = {
  overrides: Array<OverrideImportInput>;
};

export type RunOverrideImportsResponse = {
  __typename?: 'RunOverrideImportsResponse';
  imports: Array<UpdateResponse>;
};

export type RunParseImportLocationsInput = {
  id: Scalars['ID']['input'];
  importIds: Array<Scalars['String']['input']>;
};

export type RunParseImportLocationsResponse = {
  __typename?: 'RunParseImportLocationsResponse';
  result: Array<UpdateResponse>;
};

export type RunParseImportsInput = {
  id: Scalars['ID']['input'];
  importIds: Array<Scalars['String']['input']>;
};

export type RunParseImportsResponse = {
  __typename?: 'RunParseImportsResponse';
  imports: Array<UpdateResponse>;
};

export type RunPreviewImportsInput = {
  importIds: Array<Scalars['String']['input']>;
};

export type RunPreviewImportsResponse = {
  __typename?: 'RunPreviewImportsResponse';
  error?: Maybe<Error>;
  previews?: Maybe<Array<PreviewDetails>>;
};

export type RunPreviewInvoiceInput = {
  input: InvoiceInput;
};

export type RunPreviewInvoiceResponse = {
  __typename?: 'RunPreviewInvoiceResponse';
  error?: Maybe<Error>;
  invoiceTerms?: Maybe<Scalars['String']['output']>;
  output?: Maybe<InvoiceResponse>;
  subtotals?: Maybe<Array<Maybe<InvoiceRateSubtotal>>>;
  summary?: Maybe<InvoiceSummary>;
};

export type RunRateAmountOnTripInput = {
  amount: Scalars['Float']['input'];
  tripId: Scalars['String']['input'];
};

export type RunRateAmountOnTripResponse = {
  __typename?: 'RunRateAmountOnTripResponse';
  created?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Error>;
  rateGroupId?: Maybe<Scalars['String']['output']>;
  rateId?: Maybe<Scalars['String']['output']>;
  tripId?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['Int']['output']>;
};

export type RunRateAmountOnTripsInput = {
  input: Array<RunRateAmountOnTripInput>;
};

export type RunRateAmountOnTripsResponse = {
  __typename?: 'RunRateAmountOnTripsResponse';
  output?: Maybe<Array<RunRateAmountOnTripResponse>>;
};

export type RunRateOverrideInput = {
  companyId: Scalars['String']['input'];
  currency: RateItemCurrencyEnum;
  endDatetime: Scalars['String']['input'];
  payerProviderId: Scalars['String']['input'];
  rate: Scalars['Float']['input'];
  rateGroupId: Scalars['String']['input'];
  startDatetime: Scalars['String']['input'];
  thatLocationId: Scalars['String']['input'];
  thisLocationId: Scalars['String']['input'];
};

export type RunRateOverridePreviewInput = {
  companyId: Scalars['String']['input'];
  endDatetime: Scalars['String']['input'];
  payerProviderId: Scalars['String']['input'];
  rate: Scalars['Float']['input'];
  startDatetime: Scalars['String']['input'];
  thatLocationId: Scalars['String']['input'];
  thisLocationId: Scalars['String']['input'];
};

export type RunRateOverridePreviewResponse = {
  __typename?: 'RunRateOverridePreviewResponse';
  error?: Maybe<Error>;
  newAmount: Scalars['Float']['output'];
  totalAmount: Scalars['Float']['output'];
  totalTrips: Scalars['Int']['output'];
};

export type RunRateOverrideResponse = {
  __typename?: 'RunRateOverrideResponse';
  error?: Maybe<Error>;
  updated?: Maybe<Scalars['Int']['output']>;
};

export type RunRemoveCompletionOnTripInput = {
  tripIds: Array<Scalars['String']['input']>;
};

export type RunRemoveCompletionOnTripResponse = {
  __typename?: 'RunRemoveCompletionOnTripResponse';
  error?: Maybe<Error>;
  updated?: Maybe<Scalars['Int']['output']>;
};

export type RunReportIndexerResponse = {
  __typename?: 'RunReportIndexerResponse';
  count?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Error>;
};

export type RunResetUserPasswordBulkInput = {
  input: Array<RunResetUserPasswordInput>;
};

export type RunResetUserPasswordBulkResponse = {
  __typename?: 'RunResetUserPasswordBulkResponse';
  output: Array<UpdateResponse>;
};

export type RunResetUserPasswordInput = {
  newPassword: Scalars['String']['input'];
  userIds: Array<Scalars['String']['input']>;
};

export type RunRollbackImportResponse = {
  __typename?: 'RunRollbackImportResponse';
  error?: Maybe<Error>;
  failures?: Maybe<Array<IngestTripError>>;
  id: Scalars['String']['output'];
};

export type RunRollbackImportsInput = {
  importIds: Array<Scalars['String']['input']>;
};

export type RunRollbackImportsResponse = {
  __typename?: 'RunRollbackImportsResponse';
  output: Array<RunRollbackImportResponse>;
};

export type RunSaveAndSendInvoiceInput = {
  input: InvoiceInput;
};

export type RunSaveAndSendInvoiceResponse = {
  __typename?: 'RunSaveAndSendInvoiceResponse';
  error?: Maybe<Error>;
  output?: Maybe<InvoiceResponse>;
};

export type RunSaveAsInvoiceInput = {
  input: InvoiceInput;
};

export type RunSaveAsInvoiceResponse = {
  __typename?: 'RunSaveAsInvoiceResponse';
  error?: Maybe<Error>;
  output?: Maybe<InvoiceResponse>;
};

export type RunSaveInvoiceInput = {
  input: InvoiceInput;
};

export type RunSaveInvoiceResponse = {
  __typename?: 'RunSaveInvoiceResponse';
  error?: Maybe<Error>;
  output?: Maybe<InvoiceResponse>;
};

export type RunUnarchiveInvoicesInput = {
  invoiceIds: Array<Scalars['Int']['input']>;
};

export type RunUnarchiveInvoicesResponse = {
  __typename?: 'RunUnarchiveInvoicesResponse';
  error?: Maybe<Error>;
  updated?: Maybe<Scalars['Int']['output']>;
};

export type RunUnassignDriverInput = {
  tripId: Scalars['String']['input'];
};

export type RunUnassignDriverResponse = {
  __typename?: 'RunUnassignDriverResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type RunUpdateUserProfileBulkInput = {
  updates?: InputMaybe<Array<RunUpdateUserProfileInput>>;
};

export type RunUpdateUserProfileBulkResponse = {
  __typename?: 'RunUpdateUserProfileBulkResponse';
  response?: Maybe<Array<UpdateResponse>>;
};

export type RunUpdateUserProfileInput = {
  createGroupHasUserBulk?: InputMaybe<Array<CreateGroupHasUserInput>>;
  createLicenseBulk?: InputMaybe<Array<CreateLicenseInput>>;
  createPermissionHasUserBulk?: InputMaybe<Array<CreatePermissionHasUserInput>>;
  deleteGroupHasUserBulk?: InputMaybe<Array<DeleteGroupHasUserInput>>;
  deleteLicenseBulk?: InputMaybe<Array<DeleteLicenseInput>>;
  deletePermissionHasUserBulk?: InputMaybe<Array<DeletePermissionHasUserInput>>;
  query: Array<UserSearch>;
  updateLicenseBulk?: InputMaybe<Array<UpdateLicenseInput>>;
  updateUserValues?: InputMaybe<UpdateUserValuesInput>;
};

export type RunUpdateUserVehicleBulkInput = {
  input: Array<RunUpdateUserVehicleInput>;
};

export type RunUpdateUserVehicleBulkResponse = {
  __typename?: 'RunUpdateUserVehicleBulkResponse';
  output?: Maybe<Array<Maybe<RunUpdateUserVehicleResponse>>>;
};

export type RunUpdateUserVehicleInput = {
  scheduled?: InputMaybe<Scalars['String']['input']>;
  tripId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type RunUpdateUserVehicleResponse = {
  __typename?: 'RunUpdateUserVehicleResponse';
  error?: Maybe<Error>;
  updated?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  vehicleId?: Maybe<Scalars['String']['output']>;
};

export type SearchActivityObjectResponse = {
  __typename?: 'SearchActivityObjectResponse';
  activityObjectConnection?: Maybe<ActivityObjectConnection>;
};


export type SearchActivityObjectResponseActivityObjectConnectionArgs = {
  input?: InputMaybe<ActivityObjectConnectionInput>;
};

export type SearchActivityResponse = {
  __typename?: 'SearchActivityResponse';
  activityConnection?: Maybe<ActivityConnection>;
};


export type SearchActivityResponseActivityConnectionArgs = {
  input?: InputMaybe<ActivityConnectionInput>;
};

export type SearchAirportGroupResponse = {
  __typename?: 'SearchAirportGroupResponse';
  airportGroupConnection?: Maybe<AirportGroupConnection>;
};


export type SearchAirportGroupResponseAirportGroupConnectionArgs = {
  input?: InputMaybe<AirportGroupConnectionInput>;
};

export type SearchAirportHasCompanyResponse = {
  __typename?: 'SearchAirportHasCompanyResponse';
  airportHasCompanyConnection?: Maybe<AirportHasCompanyConnection>;
};


export type SearchAirportHasCompanyResponseAirportHasCompanyConnectionArgs = {
  input?: InputMaybe<AirportHasCompanyConnectionInput>;
};

export type SearchAirportHasGroupResponse = {
  __typename?: 'SearchAirportHasGroupResponse';
  airportHasGroupConnection?: Maybe<AirportHasGroupConnection>;
};


export type SearchAirportHasGroupResponseAirportHasGroupConnectionArgs = {
  input?: InputMaybe<AirportHasGroupConnectionInput>;
};

export type SearchAirportHasLocationResponse = {
  __typename?: 'SearchAirportHasLocationResponse';
  airportHasLocationConnection?: Maybe<AirportHasLocationConnection>;
};


export type SearchAirportHasLocationResponseAirportHasLocationConnectionArgs = {
  input?: InputMaybe<AirportHasLocationConnectionInput>;
};

export type SearchAirportHasRateResponse = {
  __typename?: 'SearchAirportHasRateResponse';
  airportHasRateConnection?: Maybe<AirportHasRateConnection>;
};


export type SearchAirportHasRateResponseAirportHasRateConnectionArgs = {
  input?: InputMaybe<AirportHasRateConnectionInput>;
};

export type SearchAirportHasVehicleResponse = {
  __typename?: 'SearchAirportHasVehicleResponse';
  airportHasVehicleConnection?: Maybe<AirportHasVehicleConnection>;
};


export type SearchAirportHasVehicleResponseAirportHasVehicleConnectionArgs = {
  input?: InputMaybe<AirportHasVehicleConnectionInput>;
};

export type SearchAirportResponse = {
  __typename?: 'SearchAirportResponse';
  airportConnection?: Maybe<AirportConnection>;
};


export type SearchAirportResponseAirportConnectionArgs = {
  input?: InputMaybe<AirportConnectionInput>;
};

export type SearchAirportWithCompanyResponse = {
  __typename?: 'SearchAirportWithCompanyResponse';
  airportWithCompanyConnection?: Maybe<AirportWithCompanyConnection>;
};


export type SearchAirportWithCompanyResponseAirportWithCompanyConnectionArgs = {
  input?: InputMaybe<AirportWithCompanyConnectionInput>;
};

export type SearchAirportWithGroupResponse = {
  __typename?: 'SearchAirportWithGroupResponse';
  airportWithGroupConnection?: Maybe<AirportWithGroupConnection>;
};


export type SearchAirportWithGroupResponseAirportWithGroupConnectionArgs = {
  input?: InputMaybe<AirportWithGroupConnectionInput>;
};

export type SearchAirportWithLocationResponse = {
  __typename?: 'SearchAirportWithLocationResponse';
  airportWithLocationConnection?: Maybe<AirportWithLocationConnection>;
};


export type SearchAirportWithLocationResponseAirportWithLocationConnectionArgs = {
  input?: InputMaybe<AirportWithLocationConnectionInput>;
};

export type SearchAirportWithVehicleResponse = {
  __typename?: 'SearchAirportWithVehicleResponse';
  airportWithVehicleConnection?: Maybe<AirportWithVehicleConnection>;
};


export type SearchAirportWithVehicleResponseAirportWithVehicleConnectionArgs = {
  input?: InputMaybe<AirportWithVehicleConnectionInput>;
};

export type SearchAliasResponse = {
  __typename?: 'SearchAliasResponse';
  aliasConnection?: Maybe<AliasConnection>;
};


export type SearchAliasResponseAliasConnectionArgs = {
  input?: InputMaybe<AliasConnectionInput>;
};

export type SearchAuditSnapshotWithNameResponse = {
  __typename?: 'SearchAuditSnapshotWithNameResponse';
  auditSnapshotWithNameConnection?: Maybe<AuditSnapshotWithNameConnection>;
};


export type SearchAuditSnapshotWithNameResponseAuditSnapshotWithNameConnectionArgs = {
  input?: InputMaybe<AuditSnapshotWithNameConnectionInput>;
};

export type SearchAutoCombineRuleResponse = {
  __typename?: 'SearchAutoCombineRuleResponse';
  autoCombineRuleConnection?: Maybe<AutoCombineRuleConnection>;
};


export type SearchAutoCombineRuleResponseAutoCombineRuleConnectionArgs = {
  input?: InputMaybe<AutoCombineRuleConnectionInput>;
};

export type SearchBufferTimeResponse = {
  __typename?: 'SearchBufferTimeResponse';
  bufferTimeTableConnection?: Maybe<BufferTimeConnection>;
};


export type SearchBufferTimeResponseBufferTimeTableConnectionArgs = {
  input?: InputMaybe<BufferTimeWithContractConnectionInput>;
};

export type SearchBufferTimeWithContractResponse = {
  __typename?: 'SearchBufferTimeWithContractResponse';
  bufferTimeWithContractConnection?: Maybe<BufferTimeWithContractConnection>;
};


export type SearchBufferTimeWithContractResponseBufferTimeWithContractConnectionArgs = {
  input?: InputMaybe<BufferTimeWithContractConnectionInput>;
};

export type SearchCancellationRuleResponse = {
  __typename?: 'SearchCancellationRuleResponse';
  cancellationRuleConnection?: Maybe<CancellationRuleConnection>;
};


export type SearchCancellationRuleResponseCancellationRuleConnectionArgs = {
  input?: InputMaybe<CancellationRuleConnectionInput>;
};

export type SearchCheckinEventResponse = {
  __typename?: 'SearchCheckinEventResponse';
  checkinEventConnection?: Maybe<CheckinEventConnection>;
};


export type SearchCheckinEventResponseCheckinEventConnectionArgs = {
  input?: InputMaybe<CheckinEventConnectionInput>;
};

export type SearchClosestRateItemResponse = {
  __typename?: 'SearchClosestRateItemResponse';
  closestRateItemConnection?: Maybe<ClosestRateItemConnection>;
};


export type SearchClosestRateItemResponseClosestRateItemConnectionArgs = {
  input?: InputMaybe<ClosestRateItemConnectionInput>;
};

export type SearchCombinableTripConnection = {
  __typename?: 'SearchCombinableTripConnection';
  edges?: Maybe<Array<Maybe<CombinableTripEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SearchCombinableTripConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<TripSearch>>;
};

export type SearchCombinableTripsResponse = {
  __typename?: 'SearchCombinableTripsResponse';
  combinableTripsConnection?: Maybe<SearchCombinableTripConnection>;
};


export type SearchCombinableTripsResponseCombinableTripsConnectionArgs = {
  input?: InputMaybe<SearchCombinableTripConnectionInput>;
};

export type SearchCombineRuleResponse = {
  __typename?: 'SearchCombineRuleResponse';
  combineRuleConnection?: Maybe<CombineRuleConnection>;
  combineRuleTableConnection?: Maybe<CombineRuleConnection>;
};


export type SearchCombineRuleResponseCombineRuleConnectionArgs = {
  input?: InputMaybe<CombineRuleConnectionInput>;
};


export type SearchCombineRuleResponseCombineRuleTableConnectionArgs = {
  input?: InputMaybe<CombineRuleWithContractConnectionInput>;
};

export type SearchCombineRuleWithContractResponse = {
  __typename?: 'SearchCombineRuleWithContractResponse';
  combineRuleWithContractConnection?: Maybe<CombineRuleWithContractConnection>;
};


export type SearchCombineRuleWithContractResponseCombineRuleWithContractConnectionArgs = {
  input?: InputMaybe<CombineRuleWithContractConnectionInput>;
};

export type SearchCommunicationResponse = {
  __typename?: 'SearchCommunicationResponse';
  communicationConnection?: Maybe<CommunicationConnection>;
};


export type SearchCommunicationResponseCommunicationConnectionArgs = {
  input?: InputMaybe<CommunicationConnectionInput>;
};

export type SearchCompaniesResponse = {
  __typename?: 'SearchCompaniesResponse';
  companyConnection?: Maybe<CompanyConnection>;
};


export type SearchCompaniesResponseCompanyConnectionArgs = {
  input?: InputMaybe<CompanyConnectionInput>;
};

export type SearchCompanyResponse = {
  __typename?: 'SearchCompanyResponse';
  companyConnection?: Maybe<CompanyConnection>;
};


export type SearchCompanyResponseCompanyConnectionArgs = {
  input?: InputMaybe<CompanyConnectionInput>;
};

export type SearchCompletionResponse = {
  __typename?: 'SearchCompletionResponse';
  completionConnection?: Maybe<CompletionConnection>;
};


export type SearchCompletionResponseCompletionConnectionArgs = {
  input?: InputMaybe<CompletionConnectionInput>;
};

export type SearchCompletionTypeResponse = {
  __typename?: 'SearchCompletionTypeResponse';
  completionTypeConnection?: Maybe<CompletionTypeConnection>;
};


export type SearchCompletionTypeResponseCompletionTypeConnectionArgs = {
  input?: InputMaybe<CompletionTypeConnectionInput>;
};

export type SearchCompletionWithTypeResponse = {
  __typename?: 'SearchCompletionWithTypeResponse';
  completionWithTypeConnection?: Maybe<CompletionWithTypeConnection>;
};


export type SearchCompletionWithTypeResponseCompletionWithTypeConnectionArgs = {
  input?: InputMaybe<CompletionWithTypeConnectionInput>;
};

export type SearchContractEmailResponse = {
  __typename?: 'SearchContractEmailResponse';
  contractEmailConnection?: Maybe<ContractEmailConnection>;
};


export type SearchContractEmailResponseContractEmailConnectionArgs = {
  input?: InputMaybe<ContractEmailConnectionInput>;
};

export type SearchContractNumberResponse = {
  __typename?: 'SearchContractNumberResponse';
  contractNumberConnection?: Maybe<ContractNumberConnection>;
};


export type SearchContractNumberResponseContractNumberConnectionArgs = {
  input?: InputMaybe<ContractNumberConnectionInput>;
};

export type SearchContractResponse = {
  __typename?: 'SearchContractResponse';
  contractConnection?: Maybe<ContractConnection>;
};


export type SearchContractResponseContractConnectionArgs = {
  input?: InputMaybe<ContractConnectionInput>;
};

export type SearchCountryRegionResponse = {
  __typename?: 'SearchCountryRegionResponse';
  countryRegionConnection?: Maybe<CountryRegionConnection>;
};


export type SearchCountryRegionResponseCountryRegionConnectionArgs = {
  input?: InputMaybe<CountryRegionConnectionInput>;
};

export type SearchDeviceResponse = {
  __typename?: 'SearchDeviceResponse';
  deviceConnection?: Maybe<DeviceConnection>;
};


export type SearchDeviceResponseDeviceConnectionArgs = {
  input?: InputMaybe<DeviceConnectionInput>;
};

export type SearchDistanceResponse = {
  __typename?: 'SearchDistanceResponse';
  distanceConnection?: Maybe<DistanceConnection>;
};


export type SearchDistanceResponseDistanceConnectionArgs = {
  input?: InputMaybe<DistanceConnectionInput>;
};

export type SearchEmailResponse = {
  __typename?: 'SearchEmailResponse';
  emailConnection?: Maybe<EmailConnection>;
};


export type SearchEmailResponseEmailConnectionArgs = {
  input?: InputMaybe<EmailConnectionInput>;
};

export type SearchEquivalentTripResponse = {
  __typename?: 'SearchEquivalentTripResponse';
  equivalentTripConnection?: Maybe<EquivalentTripConnection>;
};


export type SearchEquivalentTripResponseEquivalentTripConnectionArgs = {
  input?: InputMaybe<EquivalentTripConnectionInput>;
};

export type SearchFcrResponse = {
  __typename?: 'SearchFcrResponse';
  fcrConnection?: Maybe<FcrConnection>;
  tripWithFcrInnerJoinConnection?: Maybe<TripWithFcrInnerJoinConnection>;
};


export type SearchFcrResponseFcrConnectionArgs = {
  input?: InputMaybe<FcrConnectionInput>;
};


export type SearchFcrResponseTripWithFcrInnerJoinConnectionArgs = {
  input?: InputMaybe<TripWithFcrInnerJoinConnectionInput>;
};

export type SearchFcrTypeResponse = {
  __typename?: 'SearchFcrTypeResponse';
  fcrTypeConnection?: Maybe<FcrTypeConnection>;
};


export type SearchFcrTypeResponseFcrTypeConnectionArgs = {
  input?: InputMaybe<FcrTypeConnectionInput>;
};

export type SearchFlagHasTripResponse = {
  __typename?: 'SearchFlagHasTripResponse';
  flagHasTripConnection?: Maybe<FlagHasTripConnection>;
};


export type SearchFlagHasTripResponseFlagHasTripConnectionArgs = {
  input?: InputMaybe<FlagHasTripConnectionInput>;
};

export type SearchFlagResponse = {
  __typename?: 'SearchFlagResponse';
  flagConnection?: Maybe<FlagConnection>;
  flagWithTripConnection?: Maybe<FlagWithTripConnection>;
};


export type SearchFlagResponseFlagConnectionArgs = {
  input?: InputMaybe<FlagConnectionInput>;
};


export type SearchFlagResponseFlagWithTripConnectionArgs = {
  input?: InputMaybe<FlagWithTripConnectionInput>;
};

export type SearchFlagWithTripResponse = {
  __typename?: 'SearchFlagWithTripResponse';
  flagWithTripConnection?: Maybe<FlagWithTripConnection>;
};


export type SearchFlagWithTripResponseFlagWithTripConnectionArgs = {
  input?: InputMaybe<FlagWithTripConnectionInput>;
};

export type SearchGroupHasPermissionResponse = {
  __typename?: 'SearchGroupHasPermissionResponse';
  groupHasPermissionConnection?: Maybe<GroupHasPermissionConnection>;
};


export type SearchGroupHasPermissionResponseGroupHasPermissionConnectionArgs = {
  input?: InputMaybe<GroupHasPermissionConnectionInput>;
};

export type SearchGroupHasUserResponse = {
  __typename?: 'SearchGroupHasUserResponse';
  groupHasUserConnection?: Maybe<GroupHasUserConnection>;
};


export type SearchGroupHasUserResponseGroupHasUserConnectionArgs = {
  input?: InputMaybe<GroupHasUserConnectionInput>;
};

export type SearchGroupHasUserWithPermissionsResponse = {
  __typename?: 'SearchGroupHasUserWithPermissionsResponse';
  groupHasUserWithPermissionsConnection?: Maybe<GroupHasUserWithPermissionsConnection>;
};


export type SearchGroupHasUserWithPermissionsResponseGroupHasUserWithPermissionsConnectionArgs = {
  input?: InputMaybe<GroupHasUserWithPermissionsConnectionInput>;
};

export type SearchGroupResponse = {
  __typename?: 'SearchGroupResponse';
  groupConnection?: Maybe<GroupConnection>;
};


export type SearchGroupResponseGroupConnectionArgs = {
  input?: InputMaybe<GroupConnectionInput>;
};

export type SearchImageResponse = {
  __typename?: 'SearchImageResponse';
  imageConnection?: Maybe<ImageConnection>;
};


export type SearchImageResponseImageConnectionArgs = {
  input?: InputMaybe<ImageConnectionInput>;
};

export type SearchImportResponse = {
  __typename?: 'SearchImportResponse';
  importConnection?: Maybe<ImportConnection>;
};


export type SearchImportResponseImportConnectionArgs = {
  input?: InputMaybe<ImportConnectionInput>;
};

export type SearchImportWithAirportResponse = {
  __typename?: 'SearchImportWithAirportResponse';
  importWithAirportConnection?: Maybe<ImportWithAirportConnection>;
};


export type SearchImportWithAirportResponseImportWithAirportConnectionArgs = {
  input?: InputMaybe<ImportWithAirportConnectionInput>;
};

export type SearchImportsResponse = {
  __typename?: 'SearchImportsResponse';
  importConnection?: Maybe<ImportConnection>;
  importWithAirportConnection?: Maybe<ImportConnection>;
};


export type SearchImportsResponseImportConnectionArgs = {
  input?: InputMaybe<ImportConnectionInput>;
};


export type SearchImportsResponseImportWithAirportConnectionArgs = {
  input?: InputMaybe<ImportWithAirportConnectionInput>;
};

export type SearchInvoiceResponse = {
  __typename?: 'SearchInvoiceResponse';
  invoiceConnection?: Maybe<InvoiceConnection>;
};


export type SearchInvoiceResponseInvoiceConnectionArgs = {
  input?: InputMaybe<InvoiceConnectionInput>;
};

export type SearchInvoiceTermResponse = {
  __typename?: 'SearchInvoiceTermResponse';
  invoiceTermConnection?: Maybe<InvoiceTermConnection>;
};


export type SearchInvoiceTermResponseInvoiceTermConnectionArgs = {
  input?: InputMaybe<InvoiceTermConnectionInput>;
};

export type SearchIssueResponse = {
  __typename?: 'SearchIssueResponse';
  issueConnection?: Maybe<IssueConnection>;
};


export type SearchIssueResponseIssueConnectionArgs = {
  input?: InputMaybe<IssueConnectionInput>;
};

export type SearchLatestRateHistoryResponse = {
  __typename?: 'SearchLatestRateHistoryResponse';
  latestRateHistoryConnection?: Maybe<LatestRateHistoryConnection>;
};


export type SearchLatestRateHistoryResponseLatestRateHistoryConnectionArgs = {
  input?: InputMaybe<LatestRateHistoryConnectionInput>;
};

export type SearchLatestRateResponse = {
  __typename?: 'SearchLatestRateResponse';
  latestRateConnection?: Maybe<LatestRateConnection>;
};


export type SearchLatestRateResponseLatestRateConnectionArgs = {
  input?: InputMaybe<LatestRateConnectionInput>;
};

export type SearchLicenseResponse = {
  __typename?: 'SearchLicenseResponse';
  licenseConnection?: Maybe<LicenseConnection>;
};


export type SearchLicenseResponseLicenseConnectionArgs = {
  input?: InputMaybe<LicenseConnectionInput>;
};

export type SearchLicenseTypeResponse = {
  __typename?: 'SearchLicenseTypeResponse';
  licenseTypeConnection?: Maybe<LicenseTypeConnection>;
};


export type SearchLicenseTypeResponseLicenseTypeConnectionArgs = {
  input?: InputMaybe<LicenseTypeConnectionInput>;
};

export type SearchLocationResponse = {
  __typename?: 'SearchLocationResponse';
  locationConnection?: Maybe<LocationConnection>;
};


export type SearchLocationResponseLocationConnectionArgs = {
  input?: InputMaybe<LocationConnectionInput>;
};

export type SearchLocationWithAirportAndAliasResponse = {
  __typename?: 'SearchLocationWithAirportAndAliasResponse';
  locationWithAirportAndAliasConnection?: Maybe<LocationWithAirportAndAliasConnection>;
};


export type SearchLocationWithAirportAndAliasResponseLocationWithAirportAndAliasConnectionArgs = {
  input?: InputMaybe<LocationWithAirportAndAliasConnectionInput>;
};

export type SearchLocationWithPunchPolicyResponse = {
  __typename?: 'SearchLocationWithPunchPolicyResponse';
  locationWithPunchPolicyConnection?: Maybe<LocationWithPunchPolicyConnection>;
};


export type SearchLocationWithPunchPolicyResponseLocationWithPunchPolicyConnectionArgs = {
  input?: InputMaybe<LocationWithPunchPolicyConnectionInput>;
};

export type SearchLocationsResponse = {
  __typename?: 'SearchLocationsResponse';
  locationConnection?: Maybe<LocationConnection>;
  locationWithAirportConnection?: Maybe<LocationConnection>;
  locationWithPunchPolicyConnection?: Maybe<LocationConnection>;
};


export type SearchLocationsResponseLocationConnectionArgs = {
  input?: InputMaybe<LocationConnectionInput>;
};


export type SearchLocationsResponseLocationWithAirportConnectionArgs = {
  input?: InputMaybe<AirportWithLocationConnectionInput>;
};


export type SearchLocationsResponseLocationWithPunchPolicyConnectionArgs = {
  input?: InputMaybe<LocationWithPunchPolicyConnectionInput>;
};

export type SearchLoginEventResponse = {
  __typename?: 'SearchLoginEventResponse';
  loginEventConnection?: Maybe<LoginEventConnection>;
};


export type SearchLoginEventResponseLoginEventConnectionArgs = {
  input?: InputMaybe<LoginEventConnectionInput>;
};

export type SearchLostAndFoundIssueDocumentResponse = {
  __typename?: 'SearchLostAndFoundIssueDocumentResponse';
  lostAndFoundIssueDocumentConnection?: Maybe<LostAndFoundIssueDocumentConnection>;
};


export type SearchLostAndFoundIssueDocumentResponseLostAndFoundIssueDocumentConnectionArgs = {
  input?: InputMaybe<LostAndFoundIssueDocumentConnectionInput>;
};

export type SearchLostAndFoundIssueResponse = {
  __typename?: 'SearchLostAndFoundIssueResponse';
  lostAndFoundIssueConnection?: Maybe<LostAndFoundIssueConnection>;
};


export type SearchLostAndFoundIssueResponseLostAndFoundIssueConnectionArgs = {
  input?: InputMaybe<LostAndFoundIssueConnectionInput>;
};

export type SearchMessageResponse = {
  __typename?: 'SearchMessageResponse';
  messageConnection?: Maybe<MessageConnection>;
};


export type SearchMessageResponseMessageConnectionArgs = {
  input?: InputMaybe<MessageConnectionInput>;
};

export type SearchPermissionHasRoleResponse = {
  __typename?: 'SearchPermissionHasRoleResponse';
  permissionHasRoleConnection?: Maybe<PermissionHasRoleConnection>;
};


export type SearchPermissionHasRoleResponsePermissionHasRoleConnectionArgs = {
  input?: InputMaybe<PermissionHasRoleConnectionInput>;
};

export type SearchPermissionHasRoleWithPermissionsResponse = {
  __typename?: 'SearchPermissionHasRoleWithPermissionsResponse';
  permissionHasRoleWithPermissionsConnection?: Maybe<PermissionHasRoleWithPermissionsConnection>;
};


export type SearchPermissionHasRoleWithPermissionsResponsePermissionHasRoleWithPermissionsConnectionArgs = {
  input?: InputMaybe<PermissionHasRoleWithPermissionsConnectionInput>;
};

export type SearchPermissionHasUserResponse = {
  __typename?: 'SearchPermissionHasUserResponse';
  permissionHasUserConnection?: Maybe<PermissionHasUserConnection>;
};


export type SearchPermissionHasUserResponsePermissionHasUserConnectionArgs = {
  input?: InputMaybe<PermissionHasUserConnectionInput>;
};

export type SearchPermissionHasUserWithPermissionsResponse = {
  __typename?: 'SearchPermissionHasUserWithPermissionsResponse';
  permissionHasUserWithPermissionsConnection?: Maybe<PermissionHasUserWithPermissionsConnection>;
};


export type SearchPermissionHasUserWithPermissionsResponsePermissionHasUserWithPermissionsConnectionArgs = {
  input?: InputMaybe<PermissionHasUserWithPermissionsConnectionInput>;
};

export type SearchPermissionResponse = {
  __typename?: 'SearchPermissionResponse';
  permissionConnection?: Maybe<PermissionConnection>;
};


export type SearchPermissionResponsePermissionConnectionArgs = {
  input?: InputMaybe<PermissionConnectionInput>;
};

export type SearchPickupPointResponse = {
  __typename?: 'SearchPickupPointResponse';
  pickupPointConnection?: Maybe<PickupPointConnection>;
};


export type SearchPickupPointResponsePickupPointConnectionArgs = {
  input?: InputMaybe<PickupPointConnectionInput>;
};

export type SearchPotentialTripResponse = {
  __typename?: 'SearchPotentialTripResponse';
  potentialTripConnection?: Maybe<PotentialTripConnection>;
};


export type SearchPotentialTripResponsePotentialTripConnectionArgs = {
  input?: InputMaybe<PotentialTripConnectionInput>;
};

export type SearchPreviousImportsResponse = {
  __typename?: 'SearchPreviousImportsResponse';
  previousImportConnection?: Maybe<ImportConnection>;
};


export type SearchPreviousImportsResponsePreviousImportConnectionArgs = {
  input?: InputMaybe<PreviousImportConnectionInput>;
};

export type SearchProviderLocationMappingResponse = {
  __typename?: 'SearchProviderLocationMappingResponse';
  providerLocationMappingConnection?: Maybe<ProviderLocationMappingConnection>;
};


export type SearchProviderLocationMappingResponseProviderLocationMappingConnectionArgs = {
  input?: InputMaybe<ProviderLocationMappingConnectionInput>;
};

export type SearchProviderResponse = {
  __typename?: 'SearchProviderResponse';
  airlineConnection?: Maybe<ProviderConnection>;
  clientConnection?: Maybe<ProviderConnection>;
  providerConnection?: Maybe<ProviderConnection>;
};


export type SearchProviderResponseAirlineConnectionArgs = {
  input?: InputMaybe<ProviderConnectionInput>;
};


export type SearchProviderResponseClientConnectionArgs = {
  input?: InputMaybe<ProviderConnectionInput>;
};


export type SearchProviderResponseProviderConnectionArgs = {
  input?: InputMaybe<ProviderConnectionInput>;
};

export type SearchProviderWithContractResponse = {
  __typename?: 'SearchProviderWithContractResponse';
  providerWithContractConnection?: Maybe<ProviderWithContractConnection>;
};


export type SearchProviderWithContractResponseProviderWithContractConnectionArgs = {
  input?: InputMaybe<ProviderWithContractConnectionInput>;
};

export type SearchProviderWithUpdateStrategyResponse = {
  __typename?: 'SearchProviderWithUpdateStrategyResponse';
  providerWithUpdateStrategyConnection?: Maybe<ProviderWithUpdateStrategyConnection>;
};


export type SearchProviderWithUpdateStrategyResponseProviderWithUpdateStrategyConnectionArgs = {
  input?: InputMaybe<ProviderWithUpdateStrategyConnectionInput>;
};

export type SearchPunchHistoryResponse = {
  __typename?: 'SearchPunchHistoryResponse';
  punchHistoryConnection?: Maybe<PunchHistoryConnection>;
};


export type SearchPunchHistoryResponsePunchHistoryConnectionArgs = {
  input?: InputMaybe<PunchHistoryConnectionInput>;
};

export type SearchPunchPolicyResponse = {
  __typename?: 'SearchPunchPolicyResponse';
  punchPolicyConnection?: Maybe<PunchPolicyConnection>;
};


export type SearchPunchPolicyResponsePunchPolicyConnectionArgs = {
  input?: InputMaybe<PunchPolicyConnectionInput>;
};

export type SearchRateGroupResponse = {
  __typename?: 'SearchRateGroupResponse';
  rateGroupConnection?: Maybe<RateGroupConnection>;
};


export type SearchRateGroupResponseRateGroupConnectionArgs = {
  input?: InputMaybe<RateGroupConnectionInput>;
};

export type SearchRateGroupWithLatestRateHistoryResponse = {
  __typename?: 'SearchRateGroupWithLatestRateHistoryResponse';
  rateGroupWithLatestRateHistoryConnection?: Maybe<RateGroupWithLatestRateHistoryConnection>;
};


export type SearchRateGroupWithLatestRateHistoryResponseRateGroupWithLatestRateHistoryConnectionArgs = {
  input?: InputMaybe<RateGroupWithLatestRateHistoryConnectionInput>;
};

export type SearchRateHistoryResponse = {
  __typename?: 'SearchRateHistoryResponse';
  rateHistoryConnection?: Maybe<RateHistoryConnection>;
};


export type SearchRateHistoryResponseRateHistoryConnectionArgs = {
  input?: InputMaybe<RateHistoryConnectionInput>;
};

export type SearchRateHistoryWithRateGroupResponse = {
  __typename?: 'SearchRateHistoryWithRateGroupResponse';
  rateHistoryWithRateGroupConnection?: Maybe<RateHistoryWithRateGroupConnection>;
};


export type SearchRateHistoryWithRateGroupResponseRateHistoryWithRateGroupConnectionArgs = {
  input?: InputMaybe<RateHistoryWithRateGroupConnectionInput>;
};

export type SearchRateItemResponse = {
  __typename?: 'SearchRateItemResponse';
  rateItemConnection?: Maybe<RateItemConnection>;
};


export type SearchRateItemResponseRateItemConnectionArgs = {
  input?: InputMaybe<RateItemConnectionInput>;
};

export type SearchRateItemWithLatestRateHistoryResponse = {
  __typename?: 'SearchRateItemWithLatestRateHistoryResponse';
  rateItemWithLatestRateHistoryConnection?: Maybe<RateItemWithLatestRateHistoryConnection>;
};


export type SearchRateItemWithLatestRateHistoryResponseRateItemWithLatestRateHistoryConnectionArgs = {
  input?: InputMaybe<RateItemWithLatestRateHistoryConnectionInput>;
};

export type SearchRateResponse = {
  __typename?: 'SearchRateResponse';
  activeRateItemWithLatestRateHistory?: Maybe<RateItemWithLatestRateHistoryConnection>;
  latestRateConnection?: Maybe<LatestRateConnection>;
  rateConnection?: Maybe<RateConnection>;
  rateGroupConnection?: Maybe<RateGroupConnection>;
  rateHistoryConnection?: Maybe<RateHistoryConnection>;
  rateTableConnection?: Maybe<LatestRateConnection>;
};


export type SearchRateResponseActiveRateItemWithLatestRateHistoryArgs = {
  input?: InputMaybe<RateItemWithLatestRateHistoryConnectionInput>;
};


export type SearchRateResponseLatestRateConnectionArgs = {
  input?: InputMaybe<LatestRateConnectionInput>;
};


export type SearchRateResponseRateConnectionArgs = {
  input?: InputMaybe<RateConnectionInput>;
};


export type SearchRateResponseRateGroupConnectionArgs = {
  input?: InputMaybe<RateGroupConnectionInput>;
};


export type SearchRateResponseRateHistoryConnectionArgs = {
  input?: InputMaybe<RateHistoryConnectionInput>;
};


export type SearchRateResponseRateTableConnectionArgs = {
  input?: InputMaybe<RateTableConnectionInput>;
};

export type SearchRatingRemarkResponse = {
  __typename?: 'SearchRatingRemarkResponse';
  ratingRemarkConnection?: Maybe<RatingRemarkConnection>;
};


export type SearchRatingRemarkResponseRatingRemarkConnectionArgs = {
  input?: InputMaybe<RatingRemarkConnectionInput>;
};

export type SearchRatingRemarksWithRatingResponse = {
  __typename?: 'SearchRatingRemarksWithRatingResponse';
  ratingRemarksWithRatingConnection?: Maybe<RatingRemarksWithRatingConnection>;
};


export type SearchRatingRemarksWithRatingResponseRatingRemarksWithRatingConnectionArgs = {
  input?: InputMaybe<RatingRemarksWithRatingConnectionInput>;
};

export type SearchRatingResponse = {
  __typename?: 'SearchRatingResponse';
  ratingConnection?: Maybe<RatingConnection>;
};


export type SearchRatingResponseRatingConnectionArgs = {
  input?: InputMaybe<RatingConnectionInput>;
};

export type SearchRatingTypeResponse = {
  __typename?: 'SearchRatingTypeResponse';
  ratingTypeConnection?: Maybe<RatingTypeConnection>;
};


export type SearchRatingTypeResponseRatingTypeConnectionArgs = {
  input?: InputMaybe<RatingTypeConnectionInput>;
};

export type SearchRatingWithRemarksResponse = {
  __typename?: 'SearchRatingWithRemarksResponse';
  ratingWithRemarksConnection?: Maybe<RatingWithRemarksConnection>;
};


export type SearchRatingWithRemarksResponseRatingWithRemarksConnectionArgs = {
  input?: InputMaybe<RatingWithRemarksConnectionInput>;
};

export type SearchRoleResponse = {
  __typename?: 'SearchRoleResponse';
  roleConnection?: Maybe<RoleConnection>;
};


export type SearchRoleResponseRoleConnectionArgs = {
  input?: InputMaybe<RoleConnectionInput>;
};

export type SearchSettingsResponse = {
  __typename?: 'SearchSettingsResponse';
  settingsConnection?: Maybe<SettingsConnection>;
};


export type SearchSettingsResponseSettingsConnectionArgs = {
  input?: InputMaybe<SettingsConnectionInput>;
};

export type SearchSocialDistanceRuleResponse = {
  __typename?: 'SearchSocialDistanceRuleResponse';
  socialDistanceRuleConnection?: Maybe<SocialDistanceRuleConnection>;
};


export type SearchSocialDistanceRuleResponseSocialDistanceRuleConnectionArgs = {
  input?: InputMaybe<SocialDistanceRuleConnectionInput>;
};

export type SearchStagedTripResponse = {
  __typename?: 'SearchStagedTripResponse';
  stagedTripConnection?: Maybe<StagedTripConnection>;
};


export type SearchStagedTripResponseStagedTripConnectionArgs = {
  input?: InputMaybe<StagedTripConnectionInput>;
};

export type SearchStagedTripsResponse = {
  __typename?: 'SearchStagedTripsResponse';
  stagedTripConnection?: Maybe<StagedTripConnection>;
};


export type SearchStagedTripsResponseStagedTripConnectionArgs = {
  input?: InputMaybe<StagedTripConnectionInput>;
};

export type SearchStopResponse = {
  __typename?: 'SearchStopResponse';
  stopConnection?: Maybe<StopConnection>;
};


export type SearchStopResponseStopConnectionArgs = {
  input?: InputMaybe<StopConnectionInput>;
};

export type SearchStorageResponse = {
  __typename?: 'SearchStorageResponse';
  storageConnection?: Maybe<StorageConnection>;
};


export type SearchStorageResponseStorageConnectionArgs = {
  input?: InputMaybe<StorageConnectionInput>;
};

export type SearchSummaryReportSnapshotsResponse = {
  __typename?: 'SearchSummaryReportSnapshotsResponse';
  summaryReportSnapshotConnection?: Maybe<AuditSnapshotWithNameConnection>;
};


export type SearchSummaryReportSnapshotsResponseSummaryReportSnapshotConnectionArgs = {
  input?: InputMaybe<AuditSnapshotWithNameConnectionInput>;
};

export type SearchTrackFlightResponse = {
  __typename?: 'SearchTrackFlightResponse';
  trackFlightConnection?: Maybe<TrackFlightConnection>;
};


export type SearchTrackFlightResponseTrackFlightConnectionArgs = {
  input?: InputMaybe<TrackFlightConnectionInput>;
};

export type SearchTripResponse = {
  __typename?: 'SearchTripResponse';
  tripConnection?: Maybe<TripConnection>;
  tripConnectionDriverApp?: Maybe<TripConnection>;
  tripTableConnection?: Maybe<TripConnection>;
};


export type SearchTripResponseTripConnectionArgs = {
  input?: InputMaybe<TripConnectionInput>;
};


export type SearchTripResponseTripConnectionDriverAppArgs = {
  input?: InputMaybe<TripConnectionInput>;
};


export type SearchTripResponseTripTableConnectionArgs = {
  input?: InputMaybe<TripTableConnectionInput>;
};

export type SearchTripScheduleTriggerResponse = {
  __typename?: 'SearchTripScheduleTriggerResponse';
  tripScheduleTriggerConnection?: Maybe<TripScheduleTriggerConnection>;
};


export type SearchTripScheduleTriggerResponseTripScheduleTriggerConnectionArgs = {
  input?: InputMaybe<TripScheduleTriggerConnectionInput>;
};

export type SearchTripWithCompletionAndRateResponse = {
  __typename?: 'SearchTripWithCompletionAndRateResponse';
  tripWithCompletionAndRateConnection?: Maybe<TripWithCompletionAndRateConnection>;
};


export type SearchTripWithCompletionAndRateResponseTripWithCompletionAndRateConnectionArgs = {
  input?: InputMaybe<TripWithCompletionAndRateConnectionInput>;
};

export type SearchTripWithCompletionResponse = {
  __typename?: 'SearchTripWithCompletionResponse';
  tripWithCompletionConnection?: Maybe<TripWithCompletionConnection>;
};


export type SearchTripWithCompletionResponseTripWithCompletionConnectionArgs = {
  input?: InputMaybe<TripWithCompletionConnectionInput>;
};

export type SearchTripWithFcrInnerJoinResponse = {
  __typename?: 'SearchTripWithFcrInnerJoinResponse';
  tripWithFcrInnerJoinConnection?: Maybe<TripWithFcrInnerJoinConnection>;
};


export type SearchTripWithFcrInnerJoinResponseTripWithFcrInnerJoinConnectionArgs = {
  input?: InputMaybe<TripWithFcrInnerJoinConnectionInput>;
};

export type SearchTripWithFlagInnerJoinResponse = {
  __typename?: 'SearchTripWithFlagInnerJoinResponse';
  tripWithFlagInnerJoinConnection?: Maybe<TripWithFlagInnerJoinConnection>;
};


export type SearchTripWithFlagInnerJoinResponseTripWithFlagInnerJoinConnectionArgs = {
  input?: InputMaybe<TripWithFlagInnerJoinConnectionInput>;
};

export type SearchTripWithRateForAuditReportResponse = {
  __typename?: 'SearchTripWithRateForAuditReportResponse';
  tripWithRateForAuditReportConnection?: Maybe<TripWithRateForAuditReportConnection>;
};


export type SearchTripWithRateForAuditReportResponseTripWithRateForAuditReportConnectionArgs = {
  input?: InputMaybe<TripWithRateForAuditReportConnectionInput>;
};

export type SearchTripWithRateResponse = {
  __typename?: 'SearchTripWithRateResponse';
  tripWithRateConnection?: Maybe<TripWithRateConnection>;
};


export type SearchTripWithRateResponseTripWithRateConnectionArgs = {
  input?: InputMaybe<TripWithRateConnectionInput>;
};

export type SearchTripWithTrackFlightResponse = {
  __typename?: 'SearchTripWithTrackFlightResponse';
  tripWithTrackFlightConnection?: Maybe<TripWithTrackFlightConnection>;
};


export type SearchTripWithTrackFlightResponseTripWithTrackFlightConnectionArgs = {
  input?: InputMaybe<TripWithTrackFlightConnectionInput>;
};

export type SearchTripWithUtcResponse = {
  __typename?: 'SearchTripWithUtcResponse';
  tripWithUtcConnection?: Maybe<TripWithUtcConnection>;
};


export type SearchTripWithUtcResponseTripWithUtcConnectionArgs = {
  input?: InputMaybe<TripWithUtcConnectionInput>;
};

export enum SearchTypeEnum {
  Default = 'DEFAULT',
  Inornull = 'INORNULL',
  Isnotnull = 'ISNOTNULL',
  Isnull = 'ISNULL',
  Notin = 'NOTIN',
  Or = 'OR',
  Range = 'RANGE'
}

export type SearchUniqueTripResponse = {
  __typename?: 'SearchUniqueTripResponse';
  uniqueTripConnection?: Maybe<UniqueTripConnection>;
};


export type SearchUniqueTripResponseUniqueTripConnectionArgs = {
  input?: InputMaybe<UniqueTripConnectionInput>;
};

export type SearchUpdateStrategyResponse = {
  __typename?: 'SearchUpdateStrategyResponse';
  updateStrategyConnection?: Maybe<UpdateStrategyConnection>;
};


export type SearchUpdateStrategyResponseUpdateStrategyConnectionArgs = {
  input?: InputMaybe<UpdateStrategyConnectionInput>;
};

export type SearchUserAvailabilityDriverAppInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  airportCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  driverIds?: InputMaybe<Array<Scalars['String']['input']>>;
  endDatetime: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  sortDate?: InputMaybe<Scalars['String']['input']>;
  startDatetime: Scalars['String']['input'];
  types?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SearchUserAvailabilityResponse = {
  __typename?: 'SearchUserAvailabilityResponse';
  userAvailabilityConnection?: Maybe<UserAvailabilityConnection>;
};


export type SearchUserAvailabilityResponseUserAvailabilityConnectionArgs = {
  input?: InputMaybe<UserAvailabilityConnectionInput>;
};

export type SearchUserAvailabilityWithDatetimesResponse = {
  __typename?: 'SearchUserAvailabilityWithDatetimesResponse';
  userAvailabilityWithDatetimesConnection?: Maybe<UserAvailabilityWithDatetimesConnection>;
};


export type SearchUserAvailabilityWithDatetimesResponseUserAvailabilityWithDatetimesConnectionArgs = {
  input?: InputMaybe<UserAvailabilityWithDatetimesConnectionInput>;
};

export type SearchUserResponse = {
  __typename?: 'SearchUserResponse';
  userConnection?: Maybe<UserConnection>;
  userWithAirportAppendAvailabilityConnection?: Maybe<UserConnection>;
  userWithAirportConnection?: Maybe<UserConnection>;
};


export type SearchUserResponseUserConnectionArgs = {
  input?: InputMaybe<UserConnectionInput>;
};


export type SearchUserResponseUserWithAirportAppendAvailabilityConnectionArgs = {
  input?: InputMaybe<UserWithAirportAppendAvailabilityConnectionInput>;
};


export type SearchUserResponseUserWithAirportConnectionArgs = {
  input?: InputMaybe<UserWithAirportConnectionInput>;
};

export type SearchUserWithAvailabilityAndPermissionsResponse = {
  __typename?: 'SearchUserWithAvailabilityAndPermissionsResponse';
  userWithAvailabilityAndPermissionsGroupByUserConnection?: Maybe<UserWithAvailabilityAndPermissionsGroupByUserConnection>;
};


export type SearchUserWithAvailabilityAndPermissionsResponseUserWithAvailabilityAndPermissionsGroupByUserConnectionArgs = {
  input?: InputMaybe<UserWithAvailabilityAndPermissionsGroupByUserConnectionInput>;
};

export type SearchUserWithPermissionsResponse = {
  __typename?: 'SearchUserWithPermissionsResponse';
  userWithPermissionsConnection?: Maybe<UserWithPermissionsConnection>;
};


export type SearchUserWithPermissionsResponseUserWithPermissionsConnectionArgs = {
  input?: InputMaybe<UserWithPermissionsConnectionInput>;
};

export type SearchVariableTimeResponse = {
  __typename?: 'SearchVariableTimeResponse';
  variableTimeConnection?: Maybe<VariableTimeConnection>;
};


export type SearchVariableTimeResponseVariableTimeConnectionArgs = {
  input?: InputMaybe<VariableTimeConnectionInput>;
};

export type SearchVehicleResponse = {
  __typename?: 'SearchVehicleResponse';
  vehicleConnection?: Maybe<VehicleConnection>;
  vehicleWithAirportConnection?: Maybe<VehicleConnection>;
};


export type SearchVehicleResponseVehicleConnectionArgs = {
  input?: InputMaybe<VehicleConnectionInput>;
};


export type SearchVehicleResponseVehicleWithAirportConnectionArgs = {
  input?: InputMaybe<AirportWithVehicleConnectionInput>;
};

export type Settings = {
  __typename?: 'Settings';
  chatReadTime?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  crewChatReadTime?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  messageReadTime?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  outboundLateAlertMinutes?: Maybe<Scalars['Int']['output']>;
  passwordExpirationDays?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vehicleTrackingVendor?: Maybe<SettingsVehicleTrackingVendorEnum>;
  vehicleTrackingVendorToken?: Maybe<Scalars['String']['output']>;
};

export type SettingsConnection = {
  __typename?: 'SettingsConnection';
  edges?: Maybe<Array<Maybe<SettingsEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SettingsConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<SettingsMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<SettingsSearch>>;
};

export type SettingsEdge = {
  __typename?: 'SettingsEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Settings>;
};

export type SettingsMaskInput = {
  chatReadTime?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  crewChatReadTime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  messageReadTime?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  outboundLateAlertMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  passwordExpirationDays?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleTrackingVendor?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleTrackingVendorToken?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SettingsSearch = {
  chatReadTime?: InputMaybe<Array<IntSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  crewChatReadTime?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  messageReadTime?: InputMaybe<Array<IntSearch>>;
  name?: InputMaybe<Array<StringSearch>>;
  outboundLateAlertMinutes?: InputMaybe<Array<IntSearch>>;
  passwordExpirationDays?: InputMaybe<Array<IntSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  vehicleTrackingVendor?: InputMaybe<Array<SettingsVehicleTrackingVendorEnumSearch>>;
  vehicleTrackingVendorToken?: InputMaybe<Array<StringSearch>>;
};

export enum SettingsVehicleTrackingVendorEnum {
  Fleetmatics = 'FLEETMATICS',
  Samsara = 'SAMSARA'
}

export type SettingsVehicleTrackingVendorEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<SettingsVehicleTrackingVendorEnum>;
};

export type SocialDistanceRule = {
  __typename?: 'SocialDistanceRule';
  active: Scalars['Int']['output'];
  contractId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  max: Scalars['Int']['output'];
  type?: Maybe<SocialDistanceRuleTypeEnum>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type SocialDistanceRuleConnection = {
  __typename?: 'SocialDistanceRuleConnection';
  edges?: Maybe<Array<Maybe<SocialDistanceRuleEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SocialDistanceRuleConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<SocialDistanceRuleMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<SocialDistanceRuleSearch>>;
};

export type SocialDistanceRuleEdge = {
  __typename?: 'SocialDistanceRuleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<SocialDistanceRule>;
};

export type SocialDistanceRuleMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  max?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SocialDistanceRuleSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  contractId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  max?: InputMaybe<Array<IntSearch>>;
  type?: InputMaybe<Array<SocialDistanceRuleTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum SocialDistanceRuleTypeEnum {
  Addon = 'ADDON',
  Daily = 'DAILY',
  Manifest = 'MANIFEST',
  Training = 'TRAINING'
}

export type SocialDistanceRuleTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<SocialDistanceRuleTypeEnum>;
};

export enum SortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortOptions = {
  direction?: InputMaybe<SortDirectionEnum>;
  index?: InputMaybe<Scalars['Int']['input']>;
};

export type StagedTrip = {
  __typename?: 'StagedTrip';
  airportCode: Scalars['String']['output'];
  attendants: Scalars['Int']['output'];
  bufferMinutes: Scalars['Int']['output'];
  combineId?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  doLocation?: Maybe<Location>;
  doLocationId: Scalars['String']['output'];
  flightNumber?: Maybe<Scalars['Int']['output']>;
  fromManifest: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  importId: Scalars['String']['output'];
  ingestAction: StagedTripIngestActionEnum;
  kind: StagedTripKindEnum;
  loopName?: Maybe<Scalars['String']['output']>;
  payerProvider?: Maybe<Provider>;
  payerProviderId: Scalars['String']['output'];
  pilots: Scalars['Int']['output'];
  potentialTripId?: Maybe<Scalars['String']['output']>;
  puLocation?: Maybe<Location>;
  puLocationId: Scalars['String']['output'];
  scheduled: Scalars['String']['output'];
  servicerIataAirlineCode: Scalars['String']['output'];
  split: Scalars['Int']['output'];
  statusName: StagedTripStatusNameEnum;
  traceId: Scalars['String']['output'];
  tripId: Scalars['String']['output'];
  typeName: StagedTripTypeNameEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  warnings?: Maybe<Array<Maybe<Error>>>;
};

export type StagedTripConnection = {
  __typename?: 'StagedTripConnection';
  edges?: Maybe<Array<Maybe<StagedTripEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StagedTripConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<StagedTripMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<StagedTripSearch>>;
};

export type StagedTripEdge = {
  __typename?: 'StagedTripEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<StagedTrip>;
};

export enum StagedTripIngestActionEnum {
  Create = 'CREATE',
  Delete = 'DELETE',
  Exclude = 'EXCLUDE',
  Noop = 'NOOP',
  Not = 'NOT',
  Replace = 'REPLACE',
  Restore = 'RESTORE',
  Update = 'UPDATE'
}

export type StagedTripIngestActionEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<StagedTripIngestActionEnum>;
};

export enum StagedTripKindEnum {
  Arr = 'ARR',
  Dep = 'DEP'
}

export type StagedTripKindEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<StagedTripKindEnum>;
};

export type StagedTripMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  attendants?: InputMaybe<Scalars['Boolean']['input']>;
  bufferMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  combineId?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  flightNumber?: InputMaybe<Scalars['Boolean']['input']>;
  fromManifest?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  importId?: InputMaybe<Scalars['Boolean']['input']>;
  ingestAction?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['Boolean']['input']>;
  loopName?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  pilots?: InputMaybe<Scalars['Boolean']['input']>;
  potentialTripId?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  servicerIataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  split?: InputMaybe<Scalars['Boolean']['input']>;
  statusName?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  typeName?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StagedTripSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  attendants?: InputMaybe<Array<IntSearch>>;
  bufferMinutes?: InputMaybe<Array<IntSearch>>;
  combineId?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  flightNumber?: InputMaybe<Array<IntSearch>>;
  fromManifest?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  importId?: InputMaybe<Array<StringSearch>>;
  ingestAction?: InputMaybe<Array<StagedTripIngestActionEnumSearch>>;
  kind?: InputMaybe<Array<StagedTripKindEnumSearch>>;
  loopName?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  pilots?: InputMaybe<Array<IntSearch>>;
  potentialTripId?: InputMaybe<Array<StringSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  servicerIataAirlineCode?: InputMaybe<Array<StringSearch>>;
  split?: InputMaybe<Array<IntSearch>>;
  statusName?: InputMaybe<Array<StagedTripStatusNameEnumSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  typeName?: InputMaybe<Array<StagedTripTypeNameEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum StagedTripStatusNameEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type StagedTripStatusNameEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<StagedTripStatusNameEnum>;
};

export enum StagedTripTypeNameEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type StagedTripTypeNameEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<StagedTripTypeNameEnum>;
};

export enum StatusNameEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type StealTripsInput = {
  tripIds: Array<Scalars['String']['input']>;
};

export type StealTripsResponse = {
  __typename?: 'StealTripsResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type Stop = Node & {
  __typename?: 'Stop';
  actual?: Maybe<Scalars['String']['output']>;
  airportCode?: Maybe<Scalars['String']['output']>;
  completed?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  locationId: Scalars['String']['output'];
  scheduled: Scalars['String']['output'];
  trip?: Maybe<Trip>;
  tripId: Scalars['String']['output'];
  type: StopTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type StopConnection = {
  __typename?: 'StopConnection';
  edges?: Maybe<Array<Maybe<StopEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StopConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<StopMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<StopSearch>>;
};

export type StopEdge = {
  __typename?: 'StopEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Stop>;
};

export type StopMaskInput = {
  actual?: InputMaybe<Scalars['Boolean']['input']>;
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StopSearch = {
  actual?: InputMaybe<Array<StringSearch>>;
  airportCode?: InputMaybe<Array<StringSearch>>;
  completed?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  locationId?: InputMaybe<Array<StringSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<StopTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum StopTypeEnum {
  Do = 'DO',
  Pu = 'PU'
}

export type StopTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<StopTypeEnum>;
};

export type Storage = {
  __typename?: 'Storage';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type StorageConnection = {
  __typename?: 'StorageConnection';
  edges?: Maybe<Array<Maybe<StorageEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StorageConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<StorageMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<StorageSearch>>;
};

export type StorageEdge = {
  __typename?: 'StorageEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Storage>;
};

export type StorageMaskInput = {
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StorageSearch = {
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  key?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  userId?: InputMaybe<Array<StringSearch>>;
  value?: InputMaybe<Array<StringSearch>>;
};

export type StringSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<Scalars['String']['input']>;
};

export enum StyleEnum {
  ColorGreen = 'COLOR_GREEN',
  ColorRed = 'COLOR_RED',
  Subtext = 'SUBTEXT'
}

export type StyledValue = {
  __typename?: 'StyledValue';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  styles?: Maybe<Array<StyleEnum>>;
  value: Scalars['String']['output'];
};

export type Styler = {
  __typename?: 'Styler';
  property: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SubmitFeedbackDriverInput = {
  market?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitFeedbackDriverResponse = {
  __typename?: 'SubmitFeedbackDriverResponse';
  error?: Maybe<Error>;
  message: Scalars['String']['output'];
};

export type SubmitFitForDutyDriverInput = {
  answers: Array<FitForDutyAnswer>;
};

export type SubmitFitForDutyDriverResponse = {
  __typename?: 'SubmitFitForDutyDriverResponse';
  error?: Maybe<Error>;
  message: Scalars['String']['output'];
  updated: Scalars['Int']['output'];
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean']['output'];
};

export type Summary = {
  __typename?: 'Summary';
  average: Scalars['Float']['output'];
  children?: Maybe<Array<Maybe<Summary>>>;
  count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  values?: Maybe<Array<Maybe<SummaryValue>>>;
};

export enum SummaryReportFormatEnum {
  Airline = 'AIRLINE',
  Market = 'MARKET'
}

export type SummaryValue = {
  __typename?: 'SummaryValue';
  amount: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  total: Scalars['Float']['output'];
};

export type Table = {
  __typename?: 'Table';
  children?: Maybe<Array<Row>>;
  details?: Maybe<Array<TableDetails>>;
  metadata?: Maybe<Metadata>;
};

export type TableDetails = {
  __typename?: 'TableDetails';
  filters?: Maybe<Array<ReportFilters>>;
  id: Scalars['String']['output'];
};

export type TimeIntervalBucket = {
  hour: Scalars['Int']['input'];
  minute: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type TimeIntervalBucketFilter = {
  __typename?: 'TimeIntervalBucketFilter';
  hour: Scalars['Int']['output'];
  minute: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type TrackFlight = {
  __typename?: 'TrackFlight';
  actual?: Maybe<Scalars['String']['output']>;
  arrivalGate?: Maybe<Scalars['String']['output']>;
  arrivalTerminal?: Maybe<Scalars['String']['output']>;
  arrivalTimezone?: Maybe<Scalars['String']['output']>;
  arrivalUtcOffsetHours?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  departureGate?: Maybe<Scalars['String']['output']>;
  departureTerminal?: Maybe<Scalars['String']['output']>;
  departureTimezone?: Maybe<Scalars['String']['output']>;
  departureUtcOffsetHours?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  label?: Maybe<TrackFlightLabelEnum>;
  lastPingTime?: Maybe<Scalars['String']['output']>;
  nextPingTime?: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  raw: Scalars['String']['output'];
  scheduled: Scalars['String']['output'];
  servicerIataAirlineCode: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  trackerProvider?: Maybe<Scalars['String']['output']>;
  tripId: Scalars['String']['output'];
  type?: Maybe<TrackFlightTypeEnum>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type TrackFlightConnection = {
  __typename?: 'TrackFlightConnection';
  edges?: Maybe<Array<Maybe<TrackFlightEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TrackFlightConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<TrackFlightMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<TrackFlightSearch>>;
};

export type TrackFlightEdge = {
  __typename?: 'TrackFlightEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TrackFlight>;
};

export enum TrackFlightLabelEnum {
  Delayed = 'DELAYED',
  EnRoute = 'EN_ROUTE',
  Gated = 'GATED',
  Landed = 'LANDED',
  Unknown = 'UNKNOWN'
}

export type TrackFlightLabelEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TrackFlightLabelEnum>;
};

export type TrackFlightMaskInput = {
  actual?: InputMaybe<Scalars['Boolean']['input']>;
  arrivalGate?: InputMaybe<Scalars['Boolean']['input']>;
  arrivalTerminal?: InputMaybe<Scalars['Boolean']['input']>;
  arrivalTimezone?: InputMaybe<Scalars['Boolean']['input']>;
  arrivalUtcOffsetHours?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  departureGate?: InputMaybe<Scalars['Boolean']['input']>;
  departureTerminal?: InputMaybe<Scalars['Boolean']['input']>;
  departureTimezone?: InputMaybe<Scalars['Boolean']['input']>;
  departureUtcOffsetHours?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['Boolean']['input']>;
  lastPingTime?: InputMaybe<Scalars['Boolean']['input']>;
  nextPingTime?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['Boolean']['input']>;
  raw?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  servicerIataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  trackerProvider?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TrackFlightSearch = {
  actual?: InputMaybe<Array<StringSearch>>;
  arrivalGate?: InputMaybe<Array<StringSearch>>;
  arrivalTerminal?: InputMaybe<Array<StringSearch>>;
  arrivalTimezone?: InputMaybe<Array<StringSearch>>;
  arrivalUtcOffsetHours?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  departureGate?: InputMaybe<Array<StringSearch>>;
  departureTerminal?: InputMaybe<Array<StringSearch>>;
  departureTimezone?: InputMaybe<Array<StringSearch>>;
  departureUtcOffsetHours?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  label?: InputMaybe<Array<TrackFlightLabelEnumSearch>>;
  lastPingTime?: InputMaybe<Array<StringSearch>>;
  nextPingTime?: InputMaybe<Array<StringSearch>>;
  number?: InputMaybe<Array<IntSearch>>;
  raw?: InputMaybe<Array<StringSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  servicerIataAirlineCode?: InputMaybe<Array<StringSearch>>;
  status?: InputMaybe<Array<StringSearch>>;
  trackerProvider?: InputMaybe<Array<StringSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<TrackFlightTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum TrackFlightTypeEnum {
  Domestic = 'DOMESTIC',
  International = 'INTERNATIONAL',
  Unknown = 'UNKNOWN'
}

export type TrackFlightTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TrackFlightTypeEnum>;
};

export type Trip = Node & {
  __typename?: 'Trip';
  airportCode: Scalars['String']['output'];
  attendants: Scalars['Int']['output'];
  combineId?: Maybe<Scalars['String']['output']>;
  combineType?: Maybe<TripCombineTypeEnum>;
  communicationCount?: Maybe<Scalars['Int']['output']>;
  communications?: Maybe<Array<Communication>>;
  companyId: Scalars['String']['output'];
  completion?: Maybe<Completion>;
  completionId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  crewId?: Maybe<Scalars['Int']['output']>;
  curbsideAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  doActualDatetime?: Maybe<Scalars['String']['output']>;
  doAirportCode?: Maybe<Scalars['String']['output']>;
  doLocation?: Maybe<Location>;
  doLocationId: Scalars['String']['output'];
  driver?: Maybe<User>;
  driverId?: Maybe<Scalars['String']['output']>;
  etaMinutes?: Maybe<Scalars['Int']['output']>;
  fcrCount?: Maybe<Scalars['Int']['output']>;
  fcrs?: Maybe<Array<Fcr>>;
  firstName?: Maybe<Scalars['String']['output']>;
  flags?: Maybe<Array<FlagWithTrip>>;
  flightNumber: Scalars['Int']['output'];
  fromManifest: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isIllegalCombine?: Maybe<Scalars['Int']['output']>;
  isLate?: Maybe<Scalars['Boolean']['output']>;
  issues?: Maybe<Array<Issue>>;
  kind: TripKindEnum;
  lastName?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  loopName: Scalars['String']['output'];
  manifestImportId?: Maybe<Scalars['String']['output']>;
  offset: Scalars['String']['output'];
  payerProvider?: Maybe<Provider>;
  payerProviderId: Scalars['String']['output'];
  pilots: Scalars['Int']['output'];
  plannedAt?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Provider>;
  providerId: Scalars['String']['output'];
  puActualDatetime?: Maybe<Scalars['String']['output']>;
  puCompletedDatetime?: Maybe<Scalars['String']['output']>;
  puGpsDatetime?: Maybe<Scalars['String']['output']>;
  puLocation?: Maybe<Location>;
  puLocationId: Scalars['String']['output'];
  rate?: Maybe<Rate>;
  rateHistoryId?: Maybe<Scalars['String']['output']>;
  rateId?: Maybe<Scalars['String']['output']>;
  ratings?: Maybe<Array<Rating>>;
  remarks?: Maybe<Array<Activity>>;
  scheduled: Scalars['String']['output'];
  scheduledUtc?: Maybe<Scalars['String']['output']>;
  servicerIataAirlineCode: Scalars['String']['output'];
  split: Scalars['Int']['output'];
  state?: Maybe<TripState>;
  status: TripStatusEnum;
  stopCount?: Maybe<Scalars['Int']['output']>;
  stops?: Maybe<Array<Stop>>;
  traceId?: Maybe<Scalars['String']['output']>;
  trackFlight?: Maybe<TrackFlight>;
  trackFlightId?: Maybe<Scalars['String']['output']>;
  type: TripTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<Vehicle>;
  vehicleId?: Maybe<Scalars['String']['output']>;
};

export type TripAndCombineType = {
  __typename?: 'TripAndCombineType';
  combineType: TripCombineTypeEnum;
  trips?: Maybe<Array<Trip>>;
};

export enum TripCombineTypeEnum {
  Illegal = 'ILLEGAL',
  IllegalOverCapacity = 'ILLEGAL_OVER_CAPACITY',
  Legal = 'LEGAL',
  LegalOverCapacity = 'LEGAL_OVER_CAPACITY'
}

export type TripCombineTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripCombineTypeEnum>;
};

export type TripConnection = {
  __typename?: 'TripConnection';
  edges?: Maybe<Array<Maybe<TripEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TripConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<TripMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<TripSearch>>;
};

export type TripDetails = {
  __typename?: 'TripDetails';
  onTimePercentage: Scalars['Float']['output'];
  totalAmountOfTrips: Scalars['Int']['output'];
};

export type TripEdge = {
  __typename?: 'TripEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Trip>;
};

export enum TripKindEnum {
  Arr = 'ARR',
  Dep = 'DEP'
}

export type TripKindEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripKindEnum>;
};

export type TripMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  attendants?: InputMaybe<Scalars['Boolean']['input']>;
  combineId?: InputMaybe<Scalars['Boolean']['input']>;
  combineType?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  completionId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  crewId?: InputMaybe<Scalars['Boolean']['input']>;
  curbsideAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  doActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  doAirportCode?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  driverId?: InputMaybe<Scalars['Boolean']['input']>;
  etaMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['Boolean']['input']>;
  flightNumber?: InputMaybe<Scalars['Boolean']['input']>;
  fromManifest?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  isIllegalCombine?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Boolean']['input']>;
  loopName?: InputMaybe<Scalars['Boolean']['input']>;
  manifestImportId?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  pilots?: InputMaybe<Scalars['Boolean']['input']>;
  plannedAt?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  puActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puCompletedDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puGpsDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  rateHistoryId?: InputMaybe<Scalars['Boolean']['input']>;
  rateId?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  servicerIataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  split?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TripPerformance = {
  __typename?: 'TripPerformance';
  averageRating?: Maybe<Scalars['Float']['output']>;
  onTimePercentage?: Maybe<Scalars['Float']['output']>;
  totalAmountOfTrips?: Maybe<Scalars['Float']['output']>;
  totalLateTrips?: Maybe<Scalars['Float']['output']>;
  totalOnTimeTrips?: Maybe<Scalars['Float']['output']>;
  tripKind?: Maybe<TripKindEnum>;
};

export type TripResponse = {
  __typename?: 'TripResponse';
  error?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Trip>;
};

export type TripScheduleTrigger = {
  __typename?: 'TripScheduleTrigger';
  communicationCount: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  doLocationAddress?: Maybe<Scalars['String']['output']>;
  doLocationCityName?: Maybe<Scalars['String']['output']>;
  doLocationCoordinates?: Maybe<Point>;
  doLocationId?: Maybe<Scalars['String']['output']>;
  doLocationName?: Maybe<Scalars['String']['output']>;
  doLocationPhoneNumber?: Maybe<Scalars['String']['output']>;
  doLocationStateCode?: Maybe<Scalars['String']['output']>;
  doLocationZipCode?: Maybe<Scalars['String']['output']>;
  driverAvatar?: Maybe<Scalars['String']['output']>;
  driverEmployeeId?: Maybe<Scalars['String']['output']>;
  driverFirstName?: Maybe<Scalars['String']['output']>;
  driverId?: Maybe<Scalars['String']['output']>;
  driverLastName?: Maybe<Scalars['String']['output']>;
  fcrCount: Scalars['Int']['output'];
  flagColors?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  latestScheduled?: Maybe<Scalars['String']['output']>;
  latestScheduledUtc?: Maybe<Scalars['String']['output']>;
  latestTriggerUsed?: Maybe<Scalars['String']['output']>;
  payerProviderDisplayName?: Maybe<Scalars['String']['output']>;
  payerProviderId?: Maybe<Scalars['String']['output']>;
  puLocationAddress?: Maybe<Scalars['String']['output']>;
  puLocationCityName?: Maybe<Scalars['String']['output']>;
  puLocationCoordinates?: Maybe<Point>;
  puLocationId?: Maybe<Scalars['String']['output']>;
  puLocationName?: Maybe<Scalars['String']['output']>;
  puLocationPhoneNumber?: Maybe<Scalars['String']['output']>;
  puLocationStateCode?: Maybe<Scalars['String']['output']>;
  puLocationZipCode?: Maybe<Scalars['String']['output']>;
  scheduledUtc: Scalars['String']['output'];
  stopCount: Scalars['Int']['output'];
  tripId: Scalars['String']['output'];
  vehicleCapacity?: Maybe<Scalars['Int']['output']>;
  vehicleId?: Maybe<Scalars['String']['output']>;
  vehicleMake?: Maybe<Scalars['String']['output']>;
  vehicleModel?: Maybe<Scalars['String']['output']>;
  vehiclePhoneNumber?: Maybe<Scalars['String']['output']>;
  vehicleTrackingId?: Maybe<Scalars['String']['output']>;
};

export type TripScheduleTriggerConnection = {
  __typename?: 'TripScheduleTriggerConnection';
  edges?: Maybe<Array<Maybe<TripScheduleTriggerEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TripScheduleTriggerConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<TripScheduleTriggerMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<TripScheduleTriggerSearch>>;
};

export type TripScheduleTriggerEdge = {
  __typename?: 'TripScheduleTriggerEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TripScheduleTrigger>;
};

export type TripScheduleTriggerMaskInput = {
  communicationCount?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationAddress?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationCityName?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationCoordinates?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationName?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationZipCode?: InputMaybe<Scalars['Boolean']['input']>;
  driverAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  driverEmployeeId?: InputMaybe<Scalars['Boolean']['input']>;
  driverFirstName?: InputMaybe<Scalars['Boolean']['input']>;
  driverId?: InputMaybe<Scalars['Boolean']['input']>;
  driverLastName?: InputMaybe<Scalars['Boolean']['input']>;
  fcrCount?: InputMaybe<Scalars['Boolean']['input']>;
  flagColors?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  latestScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  latestScheduledUtc?: InputMaybe<Scalars['Boolean']['input']>;
  latestTriggerUsed?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderDisplayName?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationAddress?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationCityName?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationCoordinates?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationName?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationZipCode?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledUtc?: InputMaybe<Scalars['Boolean']['input']>;
  stopCount?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleCapacity?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleMake?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleModel?: InputMaybe<Scalars['Boolean']['input']>;
  vehiclePhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleTrackingId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TripScheduleTriggerSearch = {
  communicationCount?: InputMaybe<Array<IntSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  doLocationAddress?: InputMaybe<Array<StringSearch>>;
  doLocationCityName?: InputMaybe<Array<StringSearch>>;
  doLocationCoordinates?: InputMaybe<Array<PointSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  doLocationName?: InputMaybe<Array<StringSearch>>;
  doLocationPhoneNumber?: InputMaybe<Array<StringSearch>>;
  doLocationStateCode?: InputMaybe<Array<StringSearch>>;
  doLocationZipCode?: InputMaybe<Array<StringSearch>>;
  driverAvatar?: InputMaybe<Array<StringSearch>>;
  driverEmployeeId?: InputMaybe<Array<StringSearch>>;
  driverFirstName?: InputMaybe<Array<StringSearch>>;
  driverId?: InputMaybe<Array<StringSearch>>;
  driverLastName?: InputMaybe<Array<StringSearch>>;
  fcrCount?: InputMaybe<Array<IntSearch>>;
  flagColors?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  latestScheduled?: InputMaybe<Array<StringSearch>>;
  latestScheduledUtc?: InputMaybe<Array<StringSearch>>;
  latestTriggerUsed?: InputMaybe<Array<StringSearch>>;
  payerProviderDisplayName?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  puLocationAddress?: InputMaybe<Array<StringSearch>>;
  puLocationCityName?: InputMaybe<Array<StringSearch>>;
  puLocationCoordinates?: InputMaybe<Array<PointSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  puLocationName?: InputMaybe<Array<StringSearch>>;
  puLocationPhoneNumber?: InputMaybe<Array<StringSearch>>;
  puLocationStateCode?: InputMaybe<Array<StringSearch>>;
  puLocationZipCode?: InputMaybe<Array<StringSearch>>;
  scheduledUtc?: InputMaybe<Array<StringSearch>>;
  stopCount?: InputMaybe<Array<IntSearch>>;
  tripId?: InputMaybe<Array<StringSearch>>;
  vehicleCapacity?: InputMaybe<Array<IntSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
  vehicleMake?: InputMaybe<Array<StringSearch>>;
  vehicleModel?: InputMaybe<Array<StringSearch>>;
  vehiclePhoneNumber?: InputMaybe<Array<StringSearch>>;
  vehicleTrackingId?: InputMaybe<Array<StringSearch>>;
};

export type TripSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  attendants?: InputMaybe<Array<IntSearch>>;
  combineId?: InputMaybe<Array<StringSearch>>;
  combineType?: InputMaybe<Array<TripCombineTypeEnumSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  completionId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  crewId?: InputMaybe<Array<IntSearch>>;
  curbsideAt?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  doActualDatetime?: InputMaybe<Array<StringSearch>>;
  doAirportCode?: InputMaybe<Array<StringSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  driverId?: InputMaybe<Array<StringSearch>>;
  etaMinutes?: InputMaybe<Array<IntSearch>>;
  firstName?: InputMaybe<Array<StringSearch>>;
  flightNumber?: InputMaybe<Array<IntSearch>>;
  fromManifest?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  isIllegalCombine?: InputMaybe<Array<IntSearch>>;
  kind?: InputMaybe<Array<TripKindEnumSearch>>;
  lastName?: InputMaybe<Array<StringSearch>>;
  legacyId?: InputMaybe<Array<IntSearch>>;
  loopName?: InputMaybe<Array<StringSearch>>;
  manifestImportId?: InputMaybe<Array<StringSearch>>;
  offset?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  pilots?: InputMaybe<Array<IntSearch>>;
  plannedAt?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  puActualDatetime?: InputMaybe<Array<StringSearch>>;
  puCompletedDatetime?: InputMaybe<Array<StringSearch>>;
  puGpsDatetime?: InputMaybe<Array<StringSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  rateHistoryId?: InputMaybe<Array<StringSearch>>;
  rateId?: InputMaybe<Array<StringSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  servicerIataAirlineCode?: InputMaybe<Array<StringSearch>>;
  split?: InputMaybe<Array<IntSearch>>;
  status?: InputMaybe<Array<TripStatusEnumSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  trackFlightId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<TripTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
};

export type TripState = {
  __typename?: 'TripState';
  completion?: Maybe<Completion>;
  datetimeOfChange?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  displayNameShort: Scalars['String']['output'];
};

export enum TripStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type TripStatusEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripStatusEnum>;
};

export type TripTableConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  format: TripTableFormatEnum;
  keyword?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Array<TripTableSearch>>;
};

export enum TripTableFormatEnum {
  All = 'ALL',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Current = 'CURRENT',
  Deleted = 'DELETED',
  Fcr = 'FCR',
  FlagTrips = 'FLAG_TRIPS',
  Incomplete = 'INCOMPLETE',
  LoopTrips = 'LOOP_TRIPS',
  NoShow = 'NO_SHOW',
  Priority = 'PRIORITY',
  TaxiCalled = 'TAXI_CALLED',
  Unassigned = 'UNASSIGNED',
  United = 'UNITED',
  ZeroRates = 'ZERO_RATES'
}

export type TripTableSearch = {
  actual?: InputMaybe<Array<StringSearch>>;
  airportCode?: InputMaybe<Array<StringSearch>>;
  attendants?: InputMaybe<Array<IntSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  doLocationName?: InputMaybe<Array<StringSearch>>;
  driverFirstName?: InputMaybe<Array<StringSearch>>;
  driverId?: InputMaybe<Array<StringSearch>>;
  driverLastName?: InputMaybe<Array<StringSearch>>;
  flightNumber?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  kind?: InputMaybe<Array<TripWithCompletionKindEnumSearch>>;
  latestScheduled?: InputMaybe<Array<StringSearch>>;
  latestScheduledUtc?: InputMaybe<Array<StringSearch>>;
  loopName?: InputMaybe<Array<StringSearch>>;
  payerProviderDisplayName?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  pilots?: InputMaybe<Array<IntSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  puLocationName?: InputMaybe<Array<StringSearch>>;
  rateAmount?: InputMaybe<Array<FloatSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  scheduledUtc?: InputMaybe<Array<StringSearch>>;
  servicerIataAirlineCode?: InputMaybe<Array<StringSearch>>;
  status?: InputMaybe<Array<TripWithCompletionStatusEnumSearch>>;
  type?: InputMaybe<Array<TripWithCompletionTypeEnumSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
  vehicleTrackingId?: InputMaybe<Array<StringSearch>>;
};

export enum TripTypeEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type TripTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripTypeEnum>;
};

export type TripWithCompletion = {
  __typename?: 'TripWithCompletion';
  actual?: Maybe<Scalars['String']['output']>;
  airportCode: Scalars['String']['output'];
  attendants: Scalars['Int']['output'];
  combineId?: Maybe<Scalars['String']['output']>;
  combineType?: Maybe<TripWithCompletionCombineTypeEnum>;
  companyId: Scalars['String']['output'];
  completionCost: Scalars['Float']['output'];
  completionDatetime: Scalars['String']['output'];
  completionId?: Maybe<Scalars['String']['output']>;
  completionTypeId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  crewId?: Maybe<Scalars['Int']['output']>;
  curbsideAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  doActualDatetime?: Maybe<Scalars['String']['output']>;
  doAirportCode?: Maybe<Scalars['String']['output']>;
  doLocationId: Scalars['String']['output'];
  driverId?: Maybe<Scalars['String']['output']>;
  etaMinutes?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  flightNumber: Scalars['Int']['output'];
  fromManifest: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isIllegalCombine?: Maybe<Scalars['Int']['output']>;
  kind: TripWithCompletionKindEnum;
  lastName?: Maybe<Scalars['String']['output']>;
  latestScheduled?: Maybe<Scalars['String']['output']>;
  latestScheduledUtc?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  loopName: Scalars['String']['output'];
  manifestImportId?: Maybe<Scalars['String']['output']>;
  offset: Scalars['String']['output'];
  payerProviderId: Scalars['String']['output'];
  pilots: Scalars['Int']['output'];
  plannedAt?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['String']['output'];
  puActualDatetime?: Maybe<Scalars['String']['output']>;
  puCompletedDatetime?: Maybe<Scalars['String']['output']>;
  puGpsDatetime?: Maybe<Scalars['String']['output']>;
  puLocationId: Scalars['String']['output'];
  rateHistoryId?: Maybe<Scalars['String']['output']>;
  rateId?: Maybe<Scalars['String']['output']>;
  scheduled: Scalars['String']['output'];
  scheduledUtc: Scalars['String']['output'];
  servicerIataAirlineCode: Scalars['String']['output'];
  split: Scalars['Int']['output'];
  status: TripWithCompletionStatusEnum;
  traceId?: Maybe<Scalars['String']['output']>;
  trackFlightId?: Maybe<Scalars['String']['output']>;
  trackFlightOrigin?: Maybe<TripWithCompletionTrackFlightOriginEnum>;
  type: TripWithCompletionTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vehicleId?: Maybe<Scalars['String']['output']>;
};

export type TripWithCompletionAndRate = {
  __typename?: 'TripWithCompletionAndRate';
  actual?: Maybe<Scalars['String']['output']>;
  airportCode: Scalars['String']['output'];
  attendants: Scalars['Int']['output'];
  cachedDoLocationId?: Maybe<Scalars['String']['output']>;
  cachedDriverId?: Maybe<Scalars['String']['output']>;
  cachedPayerProviderId?: Maybe<Scalars['String']['output']>;
  cachedPuLocationId?: Maybe<Scalars['String']['output']>;
  cachedVehicleId?: Maybe<Scalars['String']['output']>;
  combineId?: Maybe<Scalars['String']['output']>;
  combineType?: Maybe<TripWithCompletionAndRateCombineTypeEnum>;
  communicationCount: Scalars['Int']['output'];
  companyId: Scalars['String']['output'];
  completionComment: Scalars['String']['output'];
  completionCost: Scalars['Float']['output'];
  completionCreatedAt?: Maybe<Scalars['String']['output']>;
  completionCreatedBy?: Maybe<Scalars['String']['output']>;
  completionCurrency: TripWithCompletionAndRateCompletionCurrencyEnum;
  completionDatetime: Scalars['String']['output'];
  completionDistanceMeters: Scalars['Int']['output'];
  completionEfficiency: Scalars['Float']['output'];
  completionEquivalency: Scalars['Float']['output'];
  completionId?: Maybe<Scalars['String']['output']>;
  completionTypeId: Scalars['String']['output'];
  completionUpdatedAt?: Maybe<Scalars['String']['output']>;
  completionUpdatedBy?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  crewId?: Maybe<Scalars['Int']['output']>;
  curbsideAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  doActualDatetime?: Maybe<Scalars['String']['output']>;
  doAirportCode?: Maybe<Scalars['String']['output']>;
  doLocationAddress?: Maybe<Scalars['String']['output']>;
  doLocationCityName?: Maybe<Scalars['String']['output']>;
  doLocationCoordinates?: Maybe<Point>;
  doLocationId: Scalars['String']['output'];
  doLocationName?: Maybe<Scalars['String']['output']>;
  doLocationPhoneNumber?: Maybe<Scalars['String']['output']>;
  doLocationStateCode?: Maybe<Scalars['String']['output']>;
  doLocationZipCode?: Maybe<Scalars['String']['output']>;
  driverAvatar?: Maybe<Scalars['String']['output']>;
  driverEmployeeId?: Maybe<Scalars['String']['output']>;
  driverFirstName?: Maybe<Scalars['String']['output']>;
  driverId?: Maybe<Scalars['String']['output']>;
  driverLastName?: Maybe<Scalars['String']['output']>;
  etaMinutes?: Maybe<Scalars['Int']['output']>;
  fcrCount: Scalars['Int']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  flagColors?: Maybe<Scalars['String']['output']>;
  flightNumber: Scalars['Int']['output'];
  fromManifest: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isIllegalCombine?: Maybe<Scalars['Int']['output']>;
  kind: TripWithCompletionAndRateKindEnum;
  lastName?: Maybe<Scalars['String']['output']>;
  latestScheduled?: Maybe<Scalars['String']['output']>;
  latestScheduledUtc?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  loopName: Scalars['String']['output'];
  manifestImportId?: Maybe<Scalars['String']['output']>;
  offset: Scalars['String']['output'];
  payerProviderDisplayName?: Maybe<Scalars['String']['output']>;
  payerProviderId: Scalars['String']['output'];
  pilots: Scalars['Int']['output'];
  plannedAt?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['String']['output'];
  puActualDatetime?: Maybe<Scalars['String']['output']>;
  puCompletedDatetime?: Maybe<Scalars['String']['output']>;
  puGpsDatetime?: Maybe<Scalars['String']['output']>;
  puLocationAddress?: Maybe<Scalars['String']['output']>;
  puLocationCityName?: Maybe<Scalars['String']['output']>;
  puLocationCoordinates?: Maybe<Point>;
  puLocationId: Scalars['String']['output'];
  puLocationName?: Maybe<Scalars['String']['output']>;
  puLocationPhoneNumber?: Maybe<Scalars['String']['output']>;
  puLocationStateCode?: Maybe<Scalars['String']['output']>;
  puLocationZipCode?: Maybe<Scalars['String']['output']>;
  rateAmount: Scalars['Float']['output'];
  rateCreatedAt?: Maybe<Scalars['String']['output']>;
  rateCreatedBy?: Maybe<Scalars['String']['output']>;
  rateCurrency: TripWithCompletionAndRateRateCurrencyEnum;
  rateEndDatetime?: Maybe<Scalars['String']['output']>;
  rateGroupId: Scalars['String']['output'];
  rateHistoryId?: Maybe<Scalars['String']['output']>;
  rateId?: Maybe<Scalars['String']['output']>;
  rateStartDatetime?: Maybe<Scalars['String']['output']>;
  rateType: TripWithCompletionAndRateRateTypeEnum;
  rateUpdatedAt?: Maybe<Scalars['String']['output']>;
  rateUpdatedBy?: Maybe<Scalars['String']['output']>;
  scheduled: Scalars['String']['output'];
  scheduledUtc: Scalars['String']['output'];
  servicerIataAirlineCode: Scalars['String']['output'];
  split: Scalars['Int']['output'];
  status: TripWithCompletionAndRateStatusEnum;
  stopCount: Scalars['Int']['output'];
  traceId?: Maybe<Scalars['String']['output']>;
  trackFlightArrivalGate?: Maybe<Scalars['String']['output']>;
  trackFlightArrivalTerminal?: Maybe<Scalars['String']['output']>;
  trackFlightId: Scalars['String']['output'];
  trackFlightLabel?: Maybe<TripWithCompletionAndRateTrackFlightLabelEnum>;
  trackFlightOrigin?: Maybe<TripWithCompletionAndRateTrackFlightOriginEnum>;
  type: TripWithCompletionAndRateTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vehicleCapacity?: Maybe<Scalars['Int']['output']>;
  vehicleId?: Maybe<Scalars['String']['output']>;
  vehicleMake?: Maybe<Scalars['String']['output']>;
  vehicleModel?: Maybe<Scalars['String']['output']>;
  vehiclePhoneNumber?: Maybe<Scalars['String']['output']>;
  vehicleTrackingId?: Maybe<Scalars['String']['output']>;
};

export enum TripWithCompletionAndRateCombineTypeEnum {
  Illegal = 'ILLEGAL',
  IllegalOverCapacity = 'ILLEGAL_OVER_CAPACITY',
  Legal = 'LEGAL',
  LegalOverCapacity = 'LEGAL_OVER_CAPACITY'
}

export type TripWithCompletionAndRateCombineTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionAndRateCombineTypeEnum>;
};

export enum TripWithCompletionAndRateCompletionCurrencyEnum {
  Usd = 'USD'
}

export type TripWithCompletionAndRateCompletionCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionAndRateCompletionCurrencyEnum>;
};

export type TripWithCompletionAndRateConnection = {
  __typename?: 'TripWithCompletionAndRateConnection';
  edges?: Maybe<Array<Maybe<TripWithCompletionAndRateEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TripWithCompletionAndRateConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<TripWithCompletionAndRateMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<TripWithCompletionAndRateSearch>>;
};

export type TripWithCompletionAndRateEdge = {
  __typename?: 'TripWithCompletionAndRateEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TripWithCompletionAndRate>;
};

export enum TripWithCompletionAndRateKindEnum {
  Arr = 'ARR',
  Dep = 'DEP'
}

export type TripWithCompletionAndRateKindEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionAndRateKindEnum>;
};

export type TripWithCompletionAndRateMaskInput = {
  actual?: InputMaybe<Scalars['Boolean']['input']>;
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  attendants?: InputMaybe<Scalars['Boolean']['input']>;
  cachedDoLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  cachedDriverId?: InputMaybe<Scalars['Boolean']['input']>;
  cachedPayerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  cachedPuLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  cachedVehicleId?: InputMaybe<Scalars['Boolean']['input']>;
  combineId?: InputMaybe<Scalars['Boolean']['input']>;
  combineType?: InputMaybe<Scalars['Boolean']['input']>;
  communicationCount?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  completionComment?: InputMaybe<Scalars['Boolean']['input']>;
  completionCost?: InputMaybe<Scalars['Boolean']['input']>;
  completionCreatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  completionCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  completionCurrency?: InputMaybe<Scalars['Boolean']['input']>;
  completionDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  completionDistanceMeters?: InputMaybe<Scalars['Boolean']['input']>;
  completionEfficiency?: InputMaybe<Scalars['Boolean']['input']>;
  completionEquivalency?: InputMaybe<Scalars['Boolean']['input']>;
  completionId?: InputMaybe<Scalars['Boolean']['input']>;
  completionTypeId?: InputMaybe<Scalars['Boolean']['input']>;
  completionUpdatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  completionUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  crewId?: InputMaybe<Scalars['Boolean']['input']>;
  curbsideAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  doActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  doAirportCode?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationAddress?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationCityName?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationCoordinates?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationName?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationZipCode?: InputMaybe<Scalars['Boolean']['input']>;
  driverAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  driverEmployeeId?: InputMaybe<Scalars['Boolean']['input']>;
  driverFirstName?: InputMaybe<Scalars['Boolean']['input']>;
  driverId?: InputMaybe<Scalars['Boolean']['input']>;
  driverLastName?: InputMaybe<Scalars['Boolean']['input']>;
  etaMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  fcrCount?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['Boolean']['input']>;
  flagColors?: InputMaybe<Scalars['Boolean']['input']>;
  flightNumber?: InputMaybe<Scalars['Boolean']['input']>;
  fromManifest?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  isIllegalCombine?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['Boolean']['input']>;
  latestScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  latestScheduledUtc?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Boolean']['input']>;
  loopName?: InputMaybe<Scalars['Boolean']['input']>;
  manifestImportId?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderDisplayName?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  pilots?: InputMaybe<Scalars['Boolean']['input']>;
  plannedAt?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  puActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puCompletedDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puGpsDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationAddress?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationCityName?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationCoordinates?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationName?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationZipCode?: InputMaybe<Scalars['Boolean']['input']>;
  rateAmount?: InputMaybe<Scalars['Boolean']['input']>;
  rateCreatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  rateCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  rateCurrency?: InputMaybe<Scalars['Boolean']['input']>;
  rateEndDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  rateGroupId?: InputMaybe<Scalars['Boolean']['input']>;
  rateHistoryId?: InputMaybe<Scalars['Boolean']['input']>;
  rateId?: InputMaybe<Scalars['Boolean']['input']>;
  rateStartDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  rateType?: InputMaybe<Scalars['Boolean']['input']>;
  rateUpdatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  rateUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledUtc?: InputMaybe<Scalars['Boolean']['input']>;
  servicerIataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  split?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  stopCount?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightArrivalGate?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightArrivalTerminal?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightId?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightLabel?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightOrigin?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleCapacity?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleMake?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleModel?: InputMaybe<Scalars['Boolean']['input']>;
  vehiclePhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleTrackingId?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TripWithCompletionAndRateRateCurrencyEnum {
  Usd = 'USD'
}

export type TripWithCompletionAndRateRateCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionAndRateRateCurrencyEnum>;
};

export enum TripWithCompletionAndRateRateTypeEnum {
  Adhoc = 'ADHOC',
  Contract = 'CONTRACT',
  NonContract = 'NON_CONTRACT',
  Override = 'OVERRIDE'
}

export type TripWithCompletionAndRateRateTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionAndRateRateTypeEnum>;
};

export type TripWithCompletionAndRateSearch = {
  actual?: InputMaybe<Array<StringSearch>>;
  airportCode?: InputMaybe<Array<StringSearch>>;
  attendants?: InputMaybe<Array<IntSearch>>;
  cachedDoLocationId?: InputMaybe<Array<StringSearch>>;
  cachedDriverId?: InputMaybe<Array<StringSearch>>;
  cachedPayerProviderId?: InputMaybe<Array<StringSearch>>;
  cachedPuLocationId?: InputMaybe<Array<StringSearch>>;
  cachedVehicleId?: InputMaybe<Array<StringSearch>>;
  combineId?: InputMaybe<Array<StringSearch>>;
  combineType?: InputMaybe<Array<TripWithCompletionAndRateCombineTypeEnumSearch>>;
  communicationCount?: InputMaybe<Array<IntSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  completionComment?: InputMaybe<Array<StringSearch>>;
  completionCost?: InputMaybe<Array<FloatSearch>>;
  completionCreatedAt?: InputMaybe<Array<StringSearch>>;
  completionCreatedBy?: InputMaybe<Array<StringSearch>>;
  completionCurrency?: InputMaybe<Array<TripWithCompletionAndRateCompletionCurrencyEnumSearch>>;
  completionDatetime?: InputMaybe<Array<StringSearch>>;
  completionDistanceMeters?: InputMaybe<Array<IntSearch>>;
  completionEfficiency?: InputMaybe<Array<FloatSearch>>;
  completionEquivalency?: InputMaybe<Array<FloatSearch>>;
  completionId?: InputMaybe<Array<StringSearch>>;
  completionTypeId?: InputMaybe<Array<StringSearch>>;
  completionUpdatedAt?: InputMaybe<Array<StringSearch>>;
  completionUpdatedBy?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  crewId?: InputMaybe<Array<IntSearch>>;
  curbsideAt?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  doActualDatetime?: InputMaybe<Array<StringSearch>>;
  doAirportCode?: InputMaybe<Array<StringSearch>>;
  doLocationAddress?: InputMaybe<Array<StringSearch>>;
  doLocationCityName?: InputMaybe<Array<StringSearch>>;
  doLocationCoordinates?: InputMaybe<Array<PointSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  doLocationName?: InputMaybe<Array<StringSearch>>;
  doLocationPhoneNumber?: InputMaybe<Array<StringSearch>>;
  doLocationStateCode?: InputMaybe<Array<StringSearch>>;
  doLocationZipCode?: InputMaybe<Array<StringSearch>>;
  driverAvatar?: InputMaybe<Array<StringSearch>>;
  driverEmployeeId?: InputMaybe<Array<StringSearch>>;
  driverFirstName?: InputMaybe<Array<StringSearch>>;
  driverId?: InputMaybe<Array<StringSearch>>;
  driverLastName?: InputMaybe<Array<StringSearch>>;
  etaMinutes?: InputMaybe<Array<IntSearch>>;
  fcrCount?: InputMaybe<Array<IntSearch>>;
  firstName?: InputMaybe<Array<StringSearch>>;
  flagColors?: InputMaybe<Array<StringSearch>>;
  flightNumber?: InputMaybe<Array<IntSearch>>;
  fromManifest?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  isIllegalCombine?: InputMaybe<Array<IntSearch>>;
  kind?: InputMaybe<Array<TripWithCompletionAndRateKindEnumSearch>>;
  lastName?: InputMaybe<Array<StringSearch>>;
  latestScheduled?: InputMaybe<Array<StringSearch>>;
  latestScheduledUtc?: InputMaybe<Array<StringSearch>>;
  legacyId?: InputMaybe<Array<IntSearch>>;
  loopName?: InputMaybe<Array<StringSearch>>;
  manifestImportId?: InputMaybe<Array<StringSearch>>;
  offset?: InputMaybe<Array<StringSearch>>;
  payerProviderDisplayName?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  pilots?: InputMaybe<Array<IntSearch>>;
  plannedAt?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  puActualDatetime?: InputMaybe<Array<StringSearch>>;
  puCompletedDatetime?: InputMaybe<Array<StringSearch>>;
  puGpsDatetime?: InputMaybe<Array<StringSearch>>;
  puLocationAddress?: InputMaybe<Array<StringSearch>>;
  puLocationCityName?: InputMaybe<Array<StringSearch>>;
  puLocationCoordinates?: InputMaybe<Array<PointSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  puLocationName?: InputMaybe<Array<StringSearch>>;
  puLocationPhoneNumber?: InputMaybe<Array<StringSearch>>;
  puLocationStateCode?: InputMaybe<Array<StringSearch>>;
  puLocationZipCode?: InputMaybe<Array<StringSearch>>;
  rateAmount?: InputMaybe<Array<FloatSearch>>;
  rateCreatedAt?: InputMaybe<Array<StringSearch>>;
  rateCreatedBy?: InputMaybe<Array<StringSearch>>;
  rateCurrency?: InputMaybe<Array<TripWithCompletionAndRateRateCurrencyEnumSearch>>;
  rateEndDatetime?: InputMaybe<Array<StringSearch>>;
  rateGroupId?: InputMaybe<Array<StringSearch>>;
  rateHistoryId?: InputMaybe<Array<StringSearch>>;
  rateId?: InputMaybe<Array<StringSearch>>;
  rateStartDatetime?: InputMaybe<Array<StringSearch>>;
  rateType?: InputMaybe<Array<TripWithCompletionAndRateRateTypeEnumSearch>>;
  rateUpdatedAt?: InputMaybe<Array<StringSearch>>;
  rateUpdatedBy?: InputMaybe<Array<StringSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  scheduledUtc?: InputMaybe<Array<StringSearch>>;
  servicerIataAirlineCode?: InputMaybe<Array<StringSearch>>;
  split?: InputMaybe<Array<IntSearch>>;
  status?: InputMaybe<Array<TripWithCompletionAndRateStatusEnumSearch>>;
  stopCount?: InputMaybe<Array<IntSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  trackFlightArrivalGate?: InputMaybe<Array<StringSearch>>;
  trackFlightArrivalTerminal?: InputMaybe<Array<StringSearch>>;
  trackFlightId?: InputMaybe<Array<StringSearch>>;
  trackFlightLabel?: InputMaybe<Array<TripWithCompletionAndRateTrackFlightLabelEnumSearch>>;
  trackFlightOrigin?: InputMaybe<Array<TripWithCompletionAndRateTrackFlightOriginEnumSearch>>;
  type?: InputMaybe<Array<TripWithCompletionAndRateTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  vehicleCapacity?: InputMaybe<Array<IntSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
  vehicleMake?: InputMaybe<Array<StringSearch>>;
  vehicleModel?: InputMaybe<Array<StringSearch>>;
  vehiclePhoneNumber?: InputMaybe<Array<StringSearch>>;
  vehicleTrackingId?: InputMaybe<Array<StringSearch>>;
};

export enum TripWithCompletionAndRateStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type TripWithCompletionAndRateStatusEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionAndRateStatusEnum>;
};

export enum TripWithCompletionAndRateTrackFlightLabelEnum {
  Delayed = 'DELAYED',
  EnRoute = 'EN_ROUTE',
  Gated = 'GATED',
  Landed = 'LANDED',
  Unknown = 'UNKNOWN'
}

export type TripWithCompletionAndRateTrackFlightLabelEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionAndRateTrackFlightLabelEnum>;
};

export enum TripWithCompletionAndRateTrackFlightOriginEnum {
  Domestic = 'DOMESTIC',
  International = 'INTERNATIONAL',
  Unknown = 'UNKNOWN'
}

export type TripWithCompletionAndRateTrackFlightOriginEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionAndRateTrackFlightOriginEnum>;
};

export enum TripWithCompletionAndRateTypeEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type TripWithCompletionAndRateTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionAndRateTypeEnum>;
};

export enum TripWithCompletionCombineTypeEnum {
  Illegal = 'ILLEGAL',
  IllegalOverCapacity = 'ILLEGAL_OVER_CAPACITY',
  Legal = 'LEGAL',
  LegalOverCapacity = 'LEGAL_OVER_CAPACITY'
}

export type TripWithCompletionCombineTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionCombineTypeEnum>;
};

export type TripWithCompletionConnection = {
  __typename?: 'TripWithCompletionConnection';
  edges?: Maybe<Array<Maybe<TripWithCompletionEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TripWithCompletionConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<TripWithCompletionMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<TripWithCompletionSearch>>;
};

export type TripWithCompletionEdge = {
  __typename?: 'TripWithCompletionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TripWithCompletion>;
};

export enum TripWithCompletionKindEnum {
  Arr = 'ARR',
  Dep = 'DEP'
}

export type TripWithCompletionKindEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionKindEnum>;
};

export type TripWithCompletionMaskInput = {
  actual?: InputMaybe<Scalars['Boolean']['input']>;
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  attendants?: InputMaybe<Scalars['Boolean']['input']>;
  combineId?: InputMaybe<Scalars['Boolean']['input']>;
  combineType?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  completionCost?: InputMaybe<Scalars['Boolean']['input']>;
  completionDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  completionId?: InputMaybe<Scalars['Boolean']['input']>;
  completionTypeId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  crewId?: InputMaybe<Scalars['Boolean']['input']>;
  curbsideAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  doActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  doAirportCode?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  driverId?: InputMaybe<Scalars['Boolean']['input']>;
  etaMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['Boolean']['input']>;
  flightNumber?: InputMaybe<Scalars['Boolean']['input']>;
  fromManifest?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  isIllegalCombine?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['Boolean']['input']>;
  latestScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  latestScheduledUtc?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Boolean']['input']>;
  loopName?: InputMaybe<Scalars['Boolean']['input']>;
  manifestImportId?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  pilots?: InputMaybe<Scalars['Boolean']['input']>;
  plannedAt?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  puActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puCompletedDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puGpsDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  rateHistoryId?: InputMaybe<Scalars['Boolean']['input']>;
  rateId?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledUtc?: InputMaybe<Scalars['Boolean']['input']>;
  servicerIataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  split?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightId?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightOrigin?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TripWithCompletionSearch = {
  actual?: InputMaybe<Array<StringSearch>>;
  airportCode?: InputMaybe<Array<StringSearch>>;
  attendants?: InputMaybe<Array<IntSearch>>;
  combineId?: InputMaybe<Array<StringSearch>>;
  combineType?: InputMaybe<Array<TripWithCompletionCombineTypeEnumSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  completionCost?: InputMaybe<Array<FloatSearch>>;
  completionDatetime?: InputMaybe<Array<StringSearch>>;
  completionId?: InputMaybe<Array<StringSearch>>;
  completionTypeId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  crewId?: InputMaybe<Array<IntSearch>>;
  curbsideAt?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  doActualDatetime?: InputMaybe<Array<StringSearch>>;
  doAirportCode?: InputMaybe<Array<StringSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  driverId?: InputMaybe<Array<StringSearch>>;
  etaMinutes?: InputMaybe<Array<IntSearch>>;
  firstName?: InputMaybe<Array<StringSearch>>;
  flightNumber?: InputMaybe<Array<IntSearch>>;
  fromManifest?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  isIllegalCombine?: InputMaybe<Array<IntSearch>>;
  kind?: InputMaybe<Array<TripWithCompletionKindEnumSearch>>;
  lastName?: InputMaybe<Array<StringSearch>>;
  latestScheduled?: InputMaybe<Array<StringSearch>>;
  latestScheduledUtc?: InputMaybe<Array<StringSearch>>;
  legacyId?: InputMaybe<Array<IntSearch>>;
  loopName?: InputMaybe<Array<StringSearch>>;
  manifestImportId?: InputMaybe<Array<StringSearch>>;
  offset?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  pilots?: InputMaybe<Array<IntSearch>>;
  plannedAt?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  puActualDatetime?: InputMaybe<Array<StringSearch>>;
  puCompletedDatetime?: InputMaybe<Array<StringSearch>>;
  puGpsDatetime?: InputMaybe<Array<StringSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  rateHistoryId?: InputMaybe<Array<StringSearch>>;
  rateId?: InputMaybe<Array<StringSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  scheduledUtc?: InputMaybe<Array<StringSearch>>;
  servicerIataAirlineCode?: InputMaybe<Array<StringSearch>>;
  split?: InputMaybe<Array<IntSearch>>;
  status?: InputMaybe<Array<TripWithCompletionStatusEnumSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  trackFlightId?: InputMaybe<Array<StringSearch>>;
  trackFlightOrigin?: InputMaybe<Array<TripWithCompletionTrackFlightOriginEnumSearch>>;
  type?: InputMaybe<Array<TripWithCompletionTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
};

export enum TripWithCompletionStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type TripWithCompletionStatusEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionStatusEnum>;
};

export enum TripWithCompletionTrackFlightOriginEnum {
  Domestic = 'DOMESTIC',
  International = 'INTERNATIONAL',
  Unknown = 'UNKNOWN'
}

export type TripWithCompletionTrackFlightOriginEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionTrackFlightOriginEnum>;
};

export enum TripWithCompletionTypeEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type TripWithCompletionTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithCompletionTypeEnum>;
};

export type TripWithFcrInnerJoin = {
  __typename?: 'TripWithFcrInnerJoin';
  airportCode: Scalars['String']['output'];
  attendants: Scalars['Int']['output'];
  combineId?: Maybe<Scalars['String']['output']>;
  combineType?: Maybe<TripWithFcrInnerJoinCombineTypeEnum>;
  companyId: Scalars['String']['output'];
  completionId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  crewId?: Maybe<Scalars['Int']['output']>;
  curbsideAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  doActualDatetime?: Maybe<Scalars['String']['output']>;
  doAirportCode?: Maybe<Scalars['String']['output']>;
  doLocationId: Scalars['String']['output'];
  driverId?: Maybe<Scalars['String']['output']>;
  etaMinutes?: Maybe<Scalars['Int']['output']>;
  fcrCause?: Maybe<Scalars['String']['output']>;
  fcrDescription?: Maybe<Scalars['String']['output']>;
  fcrId: Scalars['String']['output'];
  fcrReason: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  flightNumber: Scalars['Int']['output'];
  fromManifest: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isIllegalCombine?: Maybe<Scalars['Int']['output']>;
  kind: TripWithFcrInnerJoinKindEnum;
  lastName?: Maybe<Scalars['String']['output']>;
  latestScheduled?: Maybe<Scalars['String']['output']>;
  latestScheduledUtc?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  loopName: Scalars['String']['output'];
  manifestImportId?: Maybe<Scalars['String']['output']>;
  offset: Scalars['String']['output'];
  payerProviderId: Scalars['String']['output'];
  pilots: Scalars['Int']['output'];
  plannedAt?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Provider>;
  providerId: Scalars['String']['output'];
  puActualDatetime?: Maybe<Scalars['String']['output']>;
  puCompletedDatetime?: Maybe<Scalars['String']['output']>;
  puGpsDatetime?: Maybe<Scalars['String']['output']>;
  puLocationId: Scalars['String']['output'];
  rateHistoryId?: Maybe<Scalars['String']['output']>;
  rateId?: Maybe<Scalars['String']['output']>;
  scheduled: Scalars['String']['output'];
  scheduledUtc: Scalars['String']['output'];
  servicerIataAirlineCode: Scalars['String']['output'];
  split: Scalars['Int']['output'];
  status: TripWithFcrInnerJoinStatusEnum;
  traceId?: Maybe<Scalars['String']['output']>;
  trackFlightId?: Maybe<Scalars['String']['output']>;
  type: TripWithFcrInnerJoinTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vehicleId?: Maybe<Scalars['String']['output']>;
};

export enum TripWithFcrInnerJoinCombineTypeEnum {
  Illegal = 'ILLEGAL',
  IllegalOverCapacity = 'ILLEGAL_OVER_CAPACITY',
  Legal = 'LEGAL',
  LegalOverCapacity = 'LEGAL_OVER_CAPACITY'
}

export type TripWithFcrInnerJoinCombineTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithFcrInnerJoinCombineTypeEnum>;
};

export type TripWithFcrInnerJoinConnection = {
  __typename?: 'TripWithFcrInnerJoinConnection';
  edges?: Maybe<Array<Maybe<TripWithFcrInnerJoinEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TripWithFcrInnerJoinConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<TripWithFcrInnerJoinMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<TripWithFcrInnerJoinSearch>>;
};

export type TripWithFcrInnerJoinEdge = {
  __typename?: 'TripWithFcrInnerJoinEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TripWithFcrInnerJoin>;
};

export enum TripWithFcrInnerJoinKindEnum {
  Arr = 'ARR',
  Dep = 'DEP'
}

export type TripWithFcrInnerJoinKindEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithFcrInnerJoinKindEnum>;
};

export type TripWithFcrInnerJoinMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  attendants?: InputMaybe<Scalars['Boolean']['input']>;
  combineId?: InputMaybe<Scalars['Boolean']['input']>;
  combineType?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  completionId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  crewId?: InputMaybe<Scalars['Boolean']['input']>;
  curbsideAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  doActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  doAirportCode?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  driverId?: InputMaybe<Scalars['Boolean']['input']>;
  etaMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  fcrCause?: InputMaybe<Scalars['Boolean']['input']>;
  fcrDescription?: InputMaybe<Scalars['Boolean']['input']>;
  fcrId?: InputMaybe<Scalars['Boolean']['input']>;
  fcrReason?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['Boolean']['input']>;
  flightNumber?: InputMaybe<Scalars['Boolean']['input']>;
  fromManifest?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  isIllegalCombine?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['Boolean']['input']>;
  latestScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  latestScheduledUtc?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Boolean']['input']>;
  loopName?: InputMaybe<Scalars['Boolean']['input']>;
  manifestImportId?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  pilots?: InputMaybe<Scalars['Boolean']['input']>;
  plannedAt?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  puActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puCompletedDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puGpsDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  rateHistoryId?: InputMaybe<Scalars['Boolean']['input']>;
  rateId?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledUtc?: InputMaybe<Scalars['Boolean']['input']>;
  servicerIataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  split?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TripWithFcrInnerJoinSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  attendants?: InputMaybe<Array<IntSearch>>;
  combineId?: InputMaybe<Array<StringSearch>>;
  combineType?: InputMaybe<Array<TripWithFcrInnerJoinCombineTypeEnumSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  completionId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  crewId?: InputMaybe<Array<IntSearch>>;
  curbsideAt?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  doActualDatetime?: InputMaybe<Array<StringSearch>>;
  doAirportCode?: InputMaybe<Array<StringSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  driverId?: InputMaybe<Array<StringSearch>>;
  etaMinutes?: InputMaybe<Array<IntSearch>>;
  fcrCause?: InputMaybe<Array<StringSearch>>;
  fcrDescription?: InputMaybe<Array<StringSearch>>;
  fcrId?: InputMaybe<Array<StringSearch>>;
  fcrReason?: InputMaybe<Array<StringSearch>>;
  firstName?: InputMaybe<Array<StringSearch>>;
  flightNumber?: InputMaybe<Array<IntSearch>>;
  fromManifest?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  isIllegalCombine?: InputMaybe<Array<IntSearch>>;
  kind?: InputMaybe<Array<TripWithFcrInnerJoinKindEnumSearch>>;
  lastName?: InputMaybe<Array<StringSearch>>;
  latestScheduled?: InputMaybe<Array<StringSearch>>;
  latestScheduledUtc?: InputMaybe<Array<StringSearch>>;
  legacyId?: InputMaybe<Array<IntSearch>>;
  loopName?: InputMaybe<Array<StringSearch>>;
  manifestImportId?: InputMaybe<Array<StringSearch>>;
  offset?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  pilots?: InputMaybe<Array<IntSearch>>;
  plannedAt?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  puActualDatetime?: InputMaybe<Array<StringSearch>>;
  puCompletedDatetime?: InputMaybe<Array<StringSearch>>;
  puGpsDatetime?: InputMaybe<Array<StringSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  rateHistoryId?: InputMaybe<Array<StringSearch>>;
  rateId?: InputMaybe<Array<StringSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  scheduledUtc?: InputMaybe<Array<StringSearch>>;
  servicerIataAirlineCode?: InputMaybe<Array<StringSearch>>;
  split?: InputMaybe<Array<IntSearch>>;
  status?: InputMaybe<Array<TripWithFcrInnerJoinStatusEnumSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  trackFlightId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<TripWithFcrInnerJoinTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
};

export enum TripWithFcrInnerJoinStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type TripWithFcrInnerJoinStatusEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithFcrInnerJoinStatusEnum>;
};

export enum TripWithFcrInnerJoinTypeEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type TripWithFcrInnerJoinTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithFcrInnerJoinTypeEnum>;
};

export type TripWithFlagInnerJoin = {
  __typename?: 'TripWithFlagInnerJoin';
  airportCode: Scalars['String']['output'];
  attendants: Scalars['Int']['output'];
  combineId?: Maybe<Scalars['String']['output']>;
  combineType?: Maybe<TripWithFlagInnerJoinCombineTypeEnum>;
  companyId: Scalars['String']['output'];
  completionId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  crewId?: Maybe<Scalars['Int']['output']>;
  curbsideAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  doActualDatetime?: Maybe<Scalars['String']['output']>;
  doAirportCode?: Maybe<Scalars['String']['output']>;
  doLocationId: Scalars['String']['output'];
  driverId?: Maybe<Scalars['String']['output']>;
  etaMinutes?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  flightNumber: Scalars['Int']['output'];
  fromManifest: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isIllegalCombine?: Maybe<Scalars['Int']['output']>;
  kind: TripWithFlagInnerJoinKindEnum;
  lastName?: Maybe<Scalars['String']['output']>;
  latestScheduled?: Maybe<Scalars['String']['output']>;
  latestScheduledUtc?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  loopName: Scalars['String']['output'];
  manifestImportId?: Maybe<Scalars['String']['output']>;
  offset: Scalars['String']['output'];
  payerProviderId: Scalars['String']['output'];
  pilots: Scalars['Int']['output'];
  plannedAt?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['String']['output'];
  puActualDatetime?: Maybe<Scalars['String']['output']>;
  puCompletedDatetime?: Maybe<Scalars['String']['output']>;
  puGpsDatetime?: Maybe<Scalars['String']['output']>;
  puLocationId: Scalars['String']['output'];
  rateHistoryId?: Maybe<Scalars['String']['output']>;
  rateId?: Maybe<Scalars['String']['output']>;
  scheduled: Scalars['String']['output'];
  scheduledUtc: Scalars['String']['output'];
  servicerIataAirlineCode: Scalars['String']['output'];
  split: Scalars['Int']['output'];
  status: TripWithFlagInnerJoinStatusEnum;
  traceId?: Maybe<Scalars['String']['output']>;
  trackFlightId?: Maybe<Scalars['String']['output']>;
  type: TripWithFlagInnerJoinTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vehicleId?: Maybe<Scalars['String']['output']>;
};

export enum TripWithFlagInnerJoinCombineTypeEnum {
  Illegal = 'ILLEGAL',
  IllegalOverCapacity = 'ILLEGAL_OVER_CAPACITY',
  Legal = 'LEGAL',
  LegalOverCapacity = 'LEGAL_OVER_CAPACITY'
}

export type TripWithFlagInnerJoinCombineTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithFlagInnerJoinCombineTypeEnum>;
};

export type TripWithFlagInnerJoinConnection = {
  __typename?: 'TripWithFlagInnerJoinConnection';
  edges?: Maybe<Array<Maybe<TripWithFlagInnerJoinEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TripWithFlagInnerJoinConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<TripWithFlagInnerJoinMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<TripWithFlagInnerJoinSearch>>;
};

export type TripWithFlagInnerJoinEdge = {
  __typename?: 'TripWithFlagInnerJoinEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TripWithFlagInnerJoin>;
};

export enum TripWithFlagInnerJoinKindEnum {
  Arr = 'ARR',
  Dep = 'DEP'
}

export type TripWithFlagInnerJoinKindEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithFlagInnerJoinKindEnum>;
};

export type TripWithFlagInnerJoinMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  attendants?: InputMaybe<Scalars['Boolean']['input']>;
  combineId?: InputMaybe<Scalars['Boolean']['input']>;
  combineType?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  completionId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  crewId?: InputMaybe<Scalars['Boolean']['input']>;
  curbsideAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  doActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  doAirportCode?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  driverId?: InputMaybe<Scalars['Boolean']['input']>;
  etaMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['Boolean']['input']>;
  flightNumber?: InputMaybe<Scalars['Boolean']['input']>;
  fromManifest?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  isIllegalCombine?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['Boolean']['input']>;
  latestScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  latestScheduledUtc?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Boolean']['input']>;
  loopName?: InputMaybe<Scalars['Boolean']['input']>;
  manifestImportId?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  pilots?: InputMaybe<Scalars['Boolean']['input']>;
  plannedAt?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  puActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puCompletedDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puGpsDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  rateHistoryId?: InputMaybe<Scalars['Boolean']['input']>;
  rateId?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledUtc?: InputMaybe<Scalars['Boolean']['input']>;
  servicerIataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  split?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TripWithFlagInnerJoinSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  attendants?: InputMaybe<Array<IntSearch>>;
  combineId?: InputMaybe<Array<StringSearch>>;
  combineType?: InputMaybe<Array<TripWithFlagInnerJoinCombineTypeEnumSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  completionId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  crewId?: InputMaybe<Array<IntSearch>>;
  curbsideAt?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  doActualDatetime?: InputMaybe<Array<StringSearch>>;
  doAirportCode?: InputMaybe<Array<StringSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  driverId?: InputMaybe<Array<StringSearch>>;
  etaMinutes?: InputMaybe<Array<IntSearch>>;
  firstName?: InputMaybe<Array<StringSearch>>;
  flightNumber?: InputMaybe<Array<IntSearch>>;
  fromManifest?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  isIllegalCombine?: InputMaybe<Array<IntSearch>>;
  kind?: InputMaybe<Array<TripWithFlagInnerJoinKindEnumSearch>>;
  lastName?: InputMaybe<Array<StringSearch>>;
  latestScheduled?: InputMaybe<Array<StringSearch>>;
  latestScheduledUtc?: InputMaybe<Array<StringSearch>>;
  legacyId?: InputMaybe<Array<IntSearch>>;
  loopName?: InputMaybe<Array<StringSearch>>;
  manifestImportId?: InputMaybe<Array<StringSearch>>;
  offset?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  pilots?: InputMaybe<Array<IntSearch>>;
  plannedAt?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  puActualDatetime?: InputMaybe<Array<StringSearch>>;
  puCompletedDatetime?: InputMaybe<Array<StringSearch>>;
  puGpsDatetime?: InputMaybe<Array<StringSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  rateHistoryId?: InputMaybe<Array<StringSearch>>;
  rateId?: InputMaybe<Array<StringSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  scheduledUtc?: InputMaybe<Array<StringSearch>>;
  servicerIataAirlineCode?: InputMaybe<Array<StringSearch>>;
  split?: InputMaybe<Array<IntSearch>>;
  status?: InputMaybe<Array<TripWithFlagInnerJoinStatusEnumSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  trackFlightId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<TripWithFlagInnerJoinTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
};

export enum TripWithFlagInnerJoinStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type TripWithFlagInnerJoinStatusEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithFlagInnerJoinStatusEnum>;
};

export enum TripWithFlagInnerJoinTypeEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type TripWithFlagInnerJoinTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithFlagInnerJoinTypeEnum>;
};

export type TripWithRate = {
  __typename?: 'TripWithRate';
  airportCode: Scalars['String']['output'];
  attendants: Scalars['Int']['output'];
  combineId?: Maybe<Scalars['String']['output']>;
  combineType?: Maybe<TripWithRateCombineTypeEnum>;
  companyId: Scalars['String']['output'];
  completionId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  crewId?: Maybe<Scalars['Int']['output']>;
  curbsideAt?: Maybe<Scalars['String']['output']>;
  currency: TripWithRateCurrencyEnum;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  doActualDatetime?: Maybe<Scalars['String']['output']>;
  doAirportCode?: Maybe<Scalars['String']['output']>;
  doLocationId: Scalars['String']['output'];
  driverId?: Maybe<Scalars['String']['output']>;
  etaMinutes?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  flightNumber: Scalars['Int']['output'];
  fromManifest: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isIllegalCombine?: Maybe<Scalars['Int']['output']>;
  kind: TripWithRateKindEnum;
  lastName?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  loopName: Scalars['String']['output'];
  manifestImportId?: Maybe<Scalars['String']['output']>;
  offset: Scalars['String']['output'];
  payerProviderId: Scalars['String']['output'];
  pilots: Scalars['Int']['output'];
  plannedAt?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['String']['output'];
  puActualDatetime?: Maybe<Scalars['String']['output']>;
  puCompletedDatetime?: Maybe<Scalars['String']['output']>;
  puGpsDatetime?: Maybe<Scalars['String']['output']>;
  puLocationId: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
  rateGroupId: Scalars['String']['output'];
  rateHistoryId?: Maybe<Scalars['String']['output']>;
  rateId?: Maybe<Scalars['String']['output']>;
  scheduled: Scalars['String']['output'];
  servicerIataAirlineCode: Scalars['String']['output'];
  split: Scalars['Int']['output'];
  status: TripWithRateStatusEnum;
  traceId?: Maybe<Scalars['String']['output']>;
  trackFlightId?: Maybe<Scalars['String']['output']>;
  type: TripWithRateTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vehicleId?: Maybe<Scalars['String']['output']>;
};

export type TripWithRateChange = {
  __typename?: 'TripWithRateChange';
  newRate?: Maybe<Rate>;
  newRateId?: Maybe<Scalars['String']['output']>;
  oldRate?: Maybe<Rate>;
  oldRateId?: Maybe<Scalars['String']['output']>;
  trip?: Maybe<Trip>;
};

export enum TripWithRateCombineTypeEnum {
  Illegal = 'ILLEGAL',
  IllegalOverCapacity = 'ILLEGAL_OVER_CAPACITY',
  Legal = 'LEGAL',
  LegalOverCapacity = 'LEGAL_OVER_CAPACITY'
}

export type TripWithRateCombineTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithRateCombineTypeEnum>;
};

export type TripWithRateConnection = {
  __typename?: 'TripWithRateConnection';
  edges?: Maybe<Array<Maybe<TripWithRateEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TripWithRateConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<TripWithRateMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<TripWithRateSearch>>;
};

export enum TripWithRateCurrencyEnum {
  Usd = 'USD'
}

export type TripWithRateCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithRateCurrencyEnum>;
};

export type TripWithRateEdge = {
  __typename?: 'TripWithRateEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TripWithRate>;
};

export type TripWithRateForAuditReport = {
  __typename?: 'TripWithRateForAuditReport';
  airportCode: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  currency: TripWithRateForAuditReportCurrencyEnum;
  deletedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payerProviderId: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
  rateId?: Maybe<Scalars['String']['output']>;
  rateType: TripWithRateForAuditReportRateTypeEnum;
  scheduled: Scalars['String']['output'];
  status: TripWithRateForAuditReportStatusEnum;
  type: TripWithRateForAuditReportTypeEnum;
};

export type TripWithRateForAuditReportConnection = {
  __typename?: 'TripWithRateForAuditReportConnection';
  edges?: Maybe<Array<Maybe<TripWithRateForAuditReportEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TripWithRateForAuditReportConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<TripWithRateForAuditReportMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<TripWithRateForAuditReportSearch>>;
};

export enum TripWithRateForAuditReportCurrencyEnum {
  Usd = 'USD'
}

export type TripWithRateForAuditReportCurrencyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithRateForAuditReportCurrencyEnum>;
};

export type TripWithRateForAuditReportEdge = {
  __typename?: 'TripWithRateForAuditReportEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TripWithRateForAuditReport>;
};

export type TripWithRateForAuditReportMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  rate?: InputMaybe<Scalars['Boolean']['input']>;
  rateId?: InputMaybe<Scalars['Boolean']['input']>;
  rateType?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TripWithRateForAuditReportRateTypeEnum {
  Adhoc = 'ADHOC',
  Contract = 'CONTRACT',
  NonContract = 'NON_CONTRACT',
  Override = 'OVERRIDE'
}

export type TripWithRateForAuditReportRateTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithRateForAuditReportRateTypeEnum>;
};

export type TripWithRateForAuditReportSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  currency?: InputMaybe<Array<TripWithRateForAuditReportCurrencyEnumSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  rate?: InputMaybe<Array<FloatSearch>>;
  rateId?: InputMaybe<Array<StringSearch>>;
  rateType?: InputMaybe<Array<TripWithRateForAuditReportRateTypeEnumSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  status?: InputMaybe<Array<TripWithRateForAuditReportStatusEnumSearch>>;
  type?: InputMaybe<Array<TripWithRateForAuditReportTypeEnumSearch>>;
};

export enum TripWithRateForAuditReportStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type TripWithRateForAuditReportStatusEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithRateForAuditReportStatusEnum>;
};

export enum TripWithRateForAuditReportTypeEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type TripWithRateForAuditReportTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithRateForAuditReportTypeEnum>;
};

export enum TripWithRateKindEnum {
  Arr = 'ARR',
  Dep = 'DEP'
}

export type TripWithRateKindEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithRateKindEnum>;
};

export type TripWithRateMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  attendants?: InputMaybe<Scalars['Boolean']['input']>;
  combineId?: InputMaybe<Scalars['Boolean']['input']>;
  combineType?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  completionId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  crewId?: InputMaybe<Scalars['Boolean']['input']>;
  curbsideAt?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  doActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  doAirportCode?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  driverId?: InputMaybe<Scalars['Boolean']['input']>;
  etaMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['Boolean']['input']>;
  flightNumber?: InputMaybe<Scalars['Boolean']['input']>;
  fromManifest?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  isIllegalCombine?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Boolean']['input']>;
  loopName?: InputMaybe<Scalars['Boolean']['input']>;
  manifestImportId?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  pilots?: InputMaybe<Scalars['Boolean']['input']>;
  plannedAt?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  puActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puCompletedDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puGpsDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  rate?: InputMaybe<Scalars['Boolean']['input']>;
  rateGroupId?: InputMaybe<Scalars['Boolean']['input']>;
  rateHistoryId?: InputMaybe<Scalars['Boolean']['input']>;
  rateId?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  servicerIataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  split?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TripWithRateSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  attendants?: InputMaybe<Array<IntSearch>>;
  combineId?: InputMaybe<Array<StringSearch>>;
  combineType?: InputMaybe<Array<TripWithRateCombineTypeEnumSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  completionId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  crewId?: InputMaybe<Array<IntSearch>>;
  curbsideAt?: InputMaybe<Array<StringSearch>>;
  currency?: InputMaybe<Array<TripWithRateCurrencyEnumSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  doActualDatetime?: InputMaybe<Array<StringSearch>>;
  doAirportCode?: InputMaybe<Array<StringSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  driverId?: InputMaybe<Array<StringSearch>>;
  etaMinutes?: InputMaybe<Array<IntSearch>>;
  firstName?: InputMaybe<Array<StringSearch>>;
  flightNumber?: InputMaybe<Array<IntSearch>>;
  fromManifest?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  isIllegalCombine?: InputMaybe<Array<IntSearch>>;
  kind?: InputMaybe<Array<TripWithRateKindEnumSearch>>;
  lastName?: InputMaybe<Array<StringSearch>>;
  legacyId?: InputMaybe<Array<IntSearch>>;
  loopName?: InputMaybe<Array<StringSearch>>;
  manifestImportId?: InputMaybe<Array<StringSearch>>;
  offset?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  pilots?: InputMaybe<Array<IntSearch>>;
  plannedAt?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  puActualDatetime?: InputMaybe<Array<StringSearch>>;
  puCompletedDatetime?: InputMaybe<Array<StringSearch>>;
  puGpsDatetime?: InputMaybe<Array<StringSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  rate?: InputMaybe<Array<FloatSearch>>;
  rateGroupId?: InputMaybe<Array<StringSearch>>;
  rateHistoryId?: InputMaybe<Array<StringSearch>>;
  rateId?: InputMaybe<Array<StringSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  servicerIataAirlineCode?: InputMaybe<Array<StringSearch>>;
  split?: InputMaybe<Array<IntSearch>>;
  status?: InputMaybe<Array<TripWithRateStatusEnumSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  trackFlightId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<TripWithRateTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
};

export enum TripWithRateStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type TripWithRateStatusEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithRateStatusEnum>;
};

export enum TripWithRateTypeEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type TripWithRateTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithRateTypeEnum>;
};

export type TripWithTrackFlight = {
  __typename?: 'TripWithTrackFlight';
  actual?: Maybe<Scalars['String']['output']>;
  airportCode: Scalars['String']['output'];
  arrivalGate?: Maybe<Scalars['String']['output']>;
  arrivalTerminal?: Maybe<Scalars['String']['output']>;
  arrivalTimezone?: Maybe<Scalars['String']['output']>;
  arrivalUtcOffsetHours?: Maybe<Scalars['String']['output']>;
  attendants: Scalars['Int']['output'];
  combineId?: Maybe<Scalars['String']['output']>;
  combineType?: Maybe<TripWithTrackFlightCombineTypeEnum>;
  companyId: Scalars['String']['output'];
  completionId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  crewId?: Maybe<Scalars['Int']['output']>;
  curbsideAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  departureGate?: Maybe<Scalars['String']['output']>;
  departureTerminal?: Maybe<Scalars['String']['output']>;
  departureTimezone?: Maybe<Scalars['String']['output']>;
  departureUtcOffsetHours?: Maybe<Scalars['String']['output']>;
  doActualDatetime?: Maybe<Scalars['String']['output']>;
  doAirportCode?: Maybe<Scalars['String']['output']>;
  doLocationId: Scalars['String']['output'];
  driverId?: Maybe<Scalars['String']['output']>;
  etaMinutes?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  flightNumber: Scalars['Int']['output'];
  fromManifest: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isIllegalCombine?: Maybe<Scalars['Int']['output']>;
  kind: TripWithTrackFlightKindEnum;
  label?: Maybe<TripWithTrackFlightLabelEnum>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastPingTime?: Maybe<Scalars['String']['output']>;
  latestScheduledUtc?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  loopName: Scalars['String']['output'];
  manifestImportId?: Maybe<Scalars['String']['output']>;
  nextPingTime?: Maybe<Scalars['String']['output']>;
  offset: Scalars['String']['output'];
  payerProviderId: Scalars['String']['output'];
  pilots: Scalars['Int']['output'];
  plannedAt?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['String']['output'];
  puActualDatetime?: Maybe<Scalars['String']['output']>;
  puCompletedDatetime?: Maybe<Scalars['String']['output']>;
  puGpsDatetime?: Maybe<Scalars['String']['output']>;
  puLocationId: Scalars['String']['output'];
  rateHistoryId?: Maybe<Scalars['String']['output']>;
  rateId?: Maybe<Scalars['String']['output']>;
  scheduled: Scalars['String']['output'];
  scheduledUtc: Scalars['String']['output'];
  servicerIataAirlineCode: Scalars['String']['output'];
  split: Scalars['Int']['output'];
  status: TripWithTrackFlightStatusEnum;
  traceId?: Maybe<Scalars['String']['output']>;
  trackFlightId?: Maybe<Scalars['String']['output']>;
  trackFlightIdNew: Scalars['String']['output'];
  trackFlightStatus?: Maybe<Scalars['String']['output']>;
  trackFlightType?: Maybe<TripWithTrackFlightTrackFlightTypeEnum>;
  trackerProvider?: Maybe<Scalars['String']['output']>;
  type: TripWithTrackFlightTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vehicleId?: Maybe<Scalars['String']['output']>;
};

export enum TripWithTrackFlightCombineTypeEnum {
  Illegal = 'ILLEGAL',
  IllegalOverCapacity = 'ILLEGAL_OVER_CAPACITY',
  Legal = 'LEGAL',
  LegalOverCapacity = 'LEGAL_OVER_CAPACITY'
}

export type TripWithTrackFlightCombineTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithTrackFlightCombineTypeEnum>;
};

export type TripWithTrackFlightConnection = {
  __typename?: 'TripWithTrackFlightConnection';
  edges?: Maybe<Array<Maybe<TripWithTrackFlightEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TripWithTrackFlightConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<TripWithTrackFlightMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<TripWithTrackFlightSearch>>;
};

export type TripWithTrackFlightEdge = {
  __typename?: 'TripWithTrackFlightEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TripWithTrackFlight>;
};

export enum TripWithTrackFlightKindEnum {
  Arr = 'ARR',
  Dep = 'DEP'
}

export type TripWithTrackFlightKindEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithTrackFlightKindEnum>;
};

export enum TripWithTrackFlightLabelEnum {
  Delayed = 'DELAYED',
  EnRoute = 'EN_ROUTE',
  Gated = 'GATED',
  Landed = 'LANDED',
  Unknown = 'UNKNOWN'
}

export type TripWithTrackFlightLabelEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithTrackFlightLabelEnum>;
};

export type TripWithTrackFlightMaskInput = {
  actual?: InputMaybe<Scalars['Boolean']['input']>;
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  arrivalGate?: InputMaybe<Scalars['Boolean']['input']>;
  arrivalTerminal?: InputMaybe<Scalars['Boolean']['input']>;
  arrivalTimezone?: InputMaybe<Scalars['Boolean']['input']>;
  arrivalUtcOffsetHours?: InputMaybe<Scalars['Boolean']['input']>;
  attendants?: InputMaybe<Scalars['Boolean']['input']>;
  combineId?: InputMaybe<Scalars['Boolean']['input']>;
  combineType?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  completionId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  crewId?: InputMaybe<Scalars['Boolean']['input']>;
  curbsideAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  departureGate?: InputMaybe<Scalars['Boolean']['input']>;
  departureTerminal?: InputMaybe<Scalars['Boolean']['input']>;
  departureTimezone?: InputMaybe<Scalars['Boolean']['input']>;
  departureUtcOffsetHours?: InputMaybe<Scalars['Boolean']['input']>;
  doActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  doAirportCode?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  driverId?: InputMaybe<Scalars['Boolean']['input']>;
  etaMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['Boolean']['input']>;
  flightNumber?: InputMaybe<Scalars['Boolean']['input']>;
  fromManifest?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  isIllegalCombine?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['Boolean']['input']>;
  lastPingTime?: InputMaybe<Scalars['Boolean']['input']>;
  latestScheduledUtc?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Boolean']['input']>;
  loopName?: InputMaybe<Scalars['Boolean']['input']>;
  manifestImportId?: InputMaybe<Scalars['Boolean']['input']>;
  nextPingTime?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  pilots?: InputMaybe<Scalars['Boolean']['input']>;
  plannedAt?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  puActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puCompletedDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puGpsDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  rateHistoryId?: InputMaybe<Scalars['Boolean']['input']>;
  rateId?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledUtc?: InputMaybe<Scalars['Boolean']['input']>;
  servicerIataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  split?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightId?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightIdNew?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightStatus?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightType?: InputMaybe<Scalars['Boolean']['input']>;
  trackerProvider?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TripWithTrackFlightSearch = {
  actual?: InputMaybe<Array<StringSearch>>;
  airportCode?: InputMaybe<Array<StringSearch>>;
  arrivalGate?: InputMaybe<Array<StringSearch>>;
  arrivalTerminal?: InputMaybe<Array<StringSearch>>;
  arrivalTimezone?: InputMaybe<Array<StringSearch>>;
  arrivalUtcOffsetHours?: InputMaybe<Array<StringSearch>>;
  attendants?: InputMaybe<Array<IntSearch>>;
  combineId?: InputMaybe<Array<StringSearch>>;
  combineType?: InputMaybe<Array<TripWithTrackFlightCombineTypeEnumSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  completionId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  crewId?: InputMaybe<Array<IntSearch>>;
  curbsideAt?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  departureGate?: InputMaybe<Array<StringSearch>>;
  departureTerminal?: InputMaybe<Array<StringSearch>>;
  departureTimezone?: InputMaybe<Array<StringSearch>>;
  departureUtcOffsetHours?: InputMaybe<Array<StringSearch>>;
  doActualDatetime?: InputMaybe<Array<StringSearch>>;
  doAirportCode?: InputMaybe<Array<StringSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  driverId?: InputMaybe<Array<StringSearch>>;
  etaMinutes?: InputMaybe<Array<IntSearch>>;
  firstName?: InputMaybe<Array<StringSearch>>;
  flightNumber?: InputMaybe<Array<IntSearch>>;
  fromManifest?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  isIllegalCombine?: InputMaybe<Array<IntSearch>>;
  kind?: InputMaybe<Array<TripWithTrackFlightKindEnumSearch>>;
  label?: InputMaybe<Array<TripWithTrackFlightLabelEnumSearch>>;
  lastName?: InputMaybe<Array<StringSearch>>;
  lastPingTime?: InputMaybe<Array<StringSearch>>;
  latestScheduledUtc?: InputMaybe<Array<StringSearch>>;
  legacyId?: InputMaybe<Array<IntSearch>>;
  loopName?: InputMaybe<Array<StringSearch>>;
  manifestImportId?: InputMaybe<Array<StringSearch>>;
  nextPingTime?: InputMaybe<Array<StringSearch>>;
  offset?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  pilots?: InputMaybe<Array<IntSearch>>;
  plannedAt?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  puActualDatetime?: InputMaybe<Array<StringSearch>>;
  puCompletedDatetime?: InputMaybe<Array<StringSearch>>;
  puGpsDatetime?: InputMaybe<Array<StringSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  rateHistoryId?: InputMaybe<Array<StringSearch>>;
  rateId?: InputMaybe<Array<StringSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  scheduledUtc?: InputMaybe<Array<StringSearch>>;
  servicerIataAirlineCode?: InputMaybe<Array<StringSearch>>;
  split?: InputMaybe<Array<IntSearch>>;
  status?: InputMaybe<Array<TripWithTrackFlightStatusEnumSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  trackFlightId?: InputMaybe<Array<StringSearch>>;
  trackFlightIdNew?: InputMaybe<Array<StringSearch>>;
  trackFlightStatus?: InputMaybe<Array<StringSearch>>;
  trackFlightType?: InputMaybe<Array<TripWithTrackFlightTrackFlightTypeEnumSearch>>;
  trackerProvider?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<TripWithTrackFlightTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
};

export enum TripWithTrackFlightStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type TripWithTrackFlightStatusEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithTrackFlightStatusEnum>;
};

export enum TripWithTrackFlightTrackFlightTypeEnum {
  Domestic = 'DOMESTIC',
  International = 'INTERNATIONAL',
  Unknown = 'UNKNOWN'
}

export type TripWithTrackFlightTrackFlightTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithTrackFlightTrackFlightTypeEnum>;
};

export enum TripWithTrackFlightTypeEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type TripWithTrackFlightTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithTrackFlightTypeEnum>;
};

export type TripWithUtc = {
  __typename?: 'TripWithUtc';
  airportCode: Scalars['String']['output'];
  attendants: Scalars['Int']['output'];
  combineId?: Maybe<Scalars['String']['output']>;
  combineType?: Maybe<TripWithUtcCombineTypeEnum>;
  companyId: Scalars['String']['output'];
  completionId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  crewId?: Maybe<Scalars['Int']['output']>;
  curbsideAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  doActualDatetime?: Maybe<Scalars['String']['output']>;
  doAirportCode?: Maybe<Scalars['String']['output']>;
  doLocationId: Scalars['String']['output'];
  driverId?: Maybe<Scalars['String']['output']>;
  etaMinutes?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  flightNumber: Scalars['Int']['output'];
  fromManifest: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isIllegalCombine?: Maybe<Scalars['Int']['output']>;
  kind: TripWithUtcKindEnum;
  lastName?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  loopName: Scalars['String']['output'];
  manifestImportId?: Maybe<Scalars['String']['output']>;
  offset: Scalars['String']['output'];
  payerProviderId: Scalars['String']['output'];
  pilots: Scalars['Int']['output'];
  plannedAt?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['String']['output'];
  puActualDatetime?: Maybe<Scalars['String']['output']>;
  puCompletedDatetime?: Maybe<Scalars['String']['output']>;
  puGpsDatetime?: Maybe<Scalars['String']['output']>;
  puLocationId: Scalars['String']['output'];
  rateHistoryId?: Maybe<Scalars['String']['output']>;
  rateId?: Maybe<Scalars['String']['output']>;
  scheduled: Scalars['String']['output'];
  scheduledUtc: Scalars['String']['output'];
  servicerIataAirlineCode: Scalars['String']['output'];
  split: Scalars['Int']['output'];
  status: TripWithUtcStatusEnum;
  traceId?: Maybe<Scalars['String']['output']>;
  trackFlightId?: Maybe<Scalars['String']['output']>;
  type: TripWithUtcTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vehicleId?: Maybe<Scalars['String']['output']>;
};

export enum TripWithUtcCombineTypeEnum {
  Illegal = 'ILLEGAL',
  IllegalOverCapacity = 'ILLEGAL_OVER_CAPACITY',
  Legal = 'LEGAL',
  LegalOverCapacity = 'LEGAL_OVER_CAPACITY'
}

export type TripWithUtcCombineTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithUtcCombineTypeEnum>;
};

export type TripWithUtcConnection = {
  __typename?: 'TripWithUtcConnection';
  edges?: Maybe<Array<Maybe<TripWithUtcEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TripWithUtcConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<TripWithUtcMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<TripWithUtcSearch>>;
};

export type TripWithUtcEdge = {
  __typename?: 'TripWithUtcEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TripWithUtc>;
};

export enum TripWithUtcKindEnum {
  Arr = 'ARR',
  Dep = 'DEP'
}

export type TripWithUtcKindEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithUtcKindEnum>;
};

export type TripWithUtcMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  attendants?: InputMaybe<Scalars['Boolean']['input']>;
  combineId?: InputMaybe<Scalars['Boolean']['input']>;
  combineType?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  completionId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  crewId?: InputMaybe<Scalars['Boolean']['input']>;
  curbsideAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  doActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  doAirportCode?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  driverId?: InputMaybe<Scalars['Boolean']['input']>;
  etaMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['Boolean']['input']>;
  flightNumber?: InputMaybe<Scalars['Boolean']['input']>;
  fromManifest?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  isIllegalCombine?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Boolean']['input']>;
  loopName?: InputMaybe<Scalars['Boolean']['input']>;
  manifestImportId?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Boolean']['input']>;
  payerProviderId?: InputMaybe<Scalars['Boolean']['input']>;
  pilots?: InputMaybe<Scalars['Boolean']['input']>;
  plannedAt?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  puActualDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puCompletedDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puGpsDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  rateHistoryId?: InputMaybe<Scalars['Boolean']['input']>;
  rateId?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledUtc?: InputMaybe<Scalars['Boolean']['input']>;
  servicerIataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  split?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  trackFlightId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TripWithUtcSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  attendants?: InputMaybe<Array<IntSearch>>;
  combineId?: InputMaybe<Array<StringSearch>>;
  combineType?: InputMaybe<Array<TripWithUtcCombineTypeEnumSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  completionId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  crewId?: InputMaybe<Array<IntSearch>>;
  curbsideAt?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  doActualDatetime?: InputMaybe<Array<StringSearch>>;
  doAirportCode?: InputMaybe<Array<StringSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  driverId?: InputMaybe<Array<StringSearch>>;
  etaMinutes?: InputMaybe<Array<IntSearch>>;
  firstName?: InputMaybe<Array<StringSearch>>;
  flightNumber?: InputMaybe<Array<IntSearch>>;
  fromManifest?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  isIllegalCombine?: InputMaybe<Array<IntSearch>>;
  kind?: InputMaybe<Array<TripWithUtcKindEnumSearch>>;
  lastName?: InputMaybe<Array<StringSearch>>;
  legacyId?: InputMaybe<Array<IntSearch>>;
  loopName?: InputMaybe<Array<StringSearch>>;
  manifestImportId?: InputMaybe<Array<StringSearch>>;
  offset?: InputMaybe<Array<StringSearch>>;
  payerProviderId?: InputMaybe<Array<StringSearch>>;
  pilots?: InputMaybe<Array<IntSearch>>;
  plannedAt?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  puActualDatetime?: InputMaybe<Array<StringSearch>>;
  puCompletedDatetime?: InputMaybe<Array<StringSearch>>;
  puGpsDatetime?: InputMaybe<Array<StringSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  rateHistoryId?: InputMaybe<Array<StringSearch>>;
  rateId?: InputMaybe<Array<StringSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  scheduledUtc?: InputMaybe<Array<StringSearch>>;
  servicerIataAirlineCode?: InputMaybe<Array<StringSearch>>;
  split?: InputMaybe<Array<IntSearch>>;
  status?: InputMaybe<Array<TripWithUtcStatusEnumSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  trackFlightId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<TripWithUtcTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
};

export enum TripWithUtcStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type TripWithUtcStatusEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithUtcStatusEnum>;
};

export enum TripWithUtcTypeEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type TripWithUtcTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<TripWithUtcTypeEnum>;
};

export enum TypeNameEnum {
  Addon = 'ADDON',
  Manifest = 'MANIFEST',
  Special = 'SPECIAL'
}

export type UndeleteTripBulkInput = {
  tripIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UndeleteTripBulkResponse = {
  __typename?: 'UndeleteTripBulkResponse';
  error?: Maybe<Error>;
  undeleted: Scalars['Int']['output'];
};

export type UniqueTrip = {
  __typename?: 'UniqueTrip';
  companyId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  doLocationId: Scalars['String']['output'];
  flightNumber: Scalars['Int']['output'];
  fromManifest: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  loopName: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  puLocationId: Scalars['String']['output'];
  scheduled: Scalars['String']['output'];
  split: Scalars['Int']['output'];
};

export type UniqueTripConnection = {
  __typename?: 'UniqueTripConnection';
  edges?: Maybe<Array<Maybe<UniqueTripEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UniqueTripConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<UniqueTripMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<UniqueTripSearch>>;
};

export type UniqueTripEdge = {
  __typename?: 'UniqueTripEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<UniqueTrip>;
};

export type UniqueTripMaskInput = {
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  doLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  flightNumber?: InputMaybe<Scalars['Boolean']['input']>;
  fromManifest?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  loopName?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  puLocationId?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
  split?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UniqueTripSearch = {
  companyId?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  doLocationId?: InputMaybe<Array<StringSearch>>;
  flightNumber?: InputMaybe<Array<IntSearch>>;
  fromManifest?: InputMaybe<Array<IntSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  loopName?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  puLocationId?: InputMaybe<Array<StringSearch>>;
  scheduled?: InputMaybe<Array<StringSearch>>;
  split?: InputMaybe<Array<IntSearch>>;
};

export type UpdateActivityBulkInput = {
  updates: Array<UpdateActivityInput>;
};

export type UpdateActivityBulkResponse = {
  __typename?: 'UpdateActivityBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateActivityInput = {
  query: Array<ActivitySearch>;
  value: UpdateActivityValuesInput;
};

export type UpdateActivityObjectBulkInput = {
  updates: Array<UpdateActivityObjectInput>;
};

export type UpdateActivityObjectBulkResponse = {
  __typename?: 'UpdateActivityObjectBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateActivityObjectInput = {
  query: Array<ActivityObjectSearch>;
  value: UpdateActivityObjectValuesInput;
};

export type UpdateActivityObjectValuesInput = {
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
  url?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateActivityValuesInput = {
  field?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formerObjectId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formerValue?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  message?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  objectId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subjectId?: InputMaybe<Array<Scalars['String']['input']>>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<ActivityTypeEnum>>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  via?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateAirportBulkInput = {
  updates: Array<UpdateAirportInput>;
};

export type UpdateAirportBulkResponse = {
  __typename?: 'UpdateAirportBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateAirportGroupBulkInput = {
  updates: Array<UpdateAirportGroupInput>;
};

export type UpdateAirportGroupBulkResponse = {
  __typename?: 'UpdateAirportGroupBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateAirportGroupInput = {
  query: Array<AirportGroupSearch>;
  value: UpdateAirportGroupValuesInput;
};

export type UpdateAirportGroupValuesInput = {
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateAirportHasCompanyBulkInput = {
  updates: Array<UpdateAirportHasCompanyInput>;
};

export type UpdateAirportHasCompanyBulkResponse = {
  __typename?: 'UpdateAirportHasCompanyBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateAirportHasCompanyInput = {
  query: Array<AirportHasCompanySearch>;
  value: UpdateAirportHasCompanyValuesInput;
};

export type UpdateAirportHasCompanyValuesInput = {
  airportCode?: InputMaybe<Array<Scalars['String']['input']>>;
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateAirportHasGroupBulkInput = {
  updates: Array<UpdateAirportHasGroupInput>;
};

export type UpdateAirportHasGroupBulkResponse = {
  __typename?: 'UpdateAirportHasGroupBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateAirportHasGroupInput = {
  query: Array<AirportHasGroupSearch>;
  value: UpdateAirportHasGroupValuesInput;
};

export type UpdateAirportHasGroupValuesInput = {
  airportCode?: InputMaybe<Array<Scalars['String']['input']>>;
  groupId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateAirportHasLocationBulkInput = {
  updates: Array<UpdateAirportHasLocationInput>;
};

export type UpdateAirportHasLocationBulkResponse = {
  __typename?: 'UpdateAirportHasLocationBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateAirportHasLocationInput = {
  query: Array<AirportHasLocationSearch>;
  value: UpdateAirportHasLocationValuesInput;
};

export type UpdateAirportHasLocationValuesInput = {
  airportCode?: InputMaybe<Array<Scalars['String']['input']>>;
  locationId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateAirportHasRateBulkInput = {
  updates: Array<UpdateAirportHasRateInput>;
};

export type UpdateAirportHasRateBulkResponse = {
  __typename?: 'UpdateAirportHasRateBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateAirportHasRateInput = {
  query: Array<AirportHasRateSearch>;
  value: UpdateAirportHasRateValuesInput;
};

export type UpdateAirportHasRateValuesInput = {
  airportCode?: InputMaybe<Array<Scalars['String']['input']>>;
  rate?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type UpdateAirportHasVehicleBulkInput = {
  updates: Array<UpdateAirportHasVehicleInput>;
};

export type UpdateAirportHasVehicleBulkResponse = {
  __typename?: 'UpdateAirportHasVehicleBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateAirportHasVehicleInput = {
  query: Array<AirportHasVehicleSearch>;
  value: UpdateAirportHasVehicleValuesInput;
};

export type UpdateAirportHasVehicleValuesInput = {
  airportCode?: InputMaybe<Array<Scalars['String']['input']>>;
  vehicleId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateAirportInput = {
  query: Array<AirportSearch>;
  value: UpdateAirportValuesInput;
};

export type UpdateAirportValuesInput = {
  cityCode?: InputMaybe<Array<Scalars['String']['input']>>;
  cityName?: InputMaybe<Array<Scalars['String']['input']>>;
  code?: InputMaybe<Array<Scalars['String']['input']>>;
  country?: InputMaybe<Array<Scalars['String']['input']>>;
  countryId?: InputMaybe<Array<Scalars['String']['input']>>;
  elevation?: InputMaybe<Array<Scalars['Int']['input']>>;
  icao?: InputMaybe<Array<Scalars['String']['input']>>;
  location?: InputMaybe<Array<PointInput>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  stateCode?: InputMaybe<Array<Scalars['String']['input']>>;
  timezone?: InputMaybe<Array<Scalars['String']['input']>>;
  url?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateAliasBulkInput = {
  updates: Array<UpdateAliasInput>;
};

export type UpdateAliasBulkResponse = {
  __typename?: 'UpdateAliasBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateAliasInput = {
  query: Array<AliasSearch>;
  value: UpdateAliasValuesInput;
};

export type UpdateAliasValuesInput = {
  locationId?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateAutoCombineRuleBulkInput = {
  updates: Array<UpdateAutoCombineRuleInput>;
};

export type UpdateAutoCombineRuleBulkResponse = {
  __typename?: 'UpdateAutoCombineRuleBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateAutoCombineRuleInput = {
  query: Array<AutoCombineRuleSearch>;
  value: UpdateAutoCombineRuleValuesInput;
};

export type UpdateAutoCombineRuleValuesInput = {
  combineStrategy?: InputMaybe<Array<AutoCombineRuleCombineStrategyEnum>>;
  considerVehicle?: InputMaybe<Array<Scalars['Int']['input']>>;
  contractId?: InputMaybe<Array<Scalars['String']['input']>>;
  kind?: InputMaybe<Array<AutoCombineRuleKindEnum>>;
  maxCombine?: InputMaybe<Array<Scalars['Int']['input']>>;
  pickupWindow?: InputMaybe<Array<Scalars['Int']['input']>>;
  updateScheduled?: InputMaybe<Array<AutoCombineRuleUpdateScheduledEnum>>;
};

export type UpdateBufferTimeBulkInput = {
  updates: Array<UpdateBufferTimeInput>;
};

export type UpdateBufferTimeBulkResponse = {
  __typename?: 'UpdateBufferTimeBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateBufferTimeInput = {
  query: Array<BufferTimeSearch>;
  value: UpdateBufferTimeValuesInput;
};

export type UpdateBufferTimeValuesInput = {
  active?: InputMaybe<Array<Scalars['Int']['input']>>;
  airportCode?: InputMaybe<Array<Scalars['String']['input']>>;
  bufferMinutes?: InputMaybe<Array<Scalars['Int']['input']>>;
  contractId?: InputMaybe<Array<Scalars['String']['input']>>;
  endLocationId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endTime?: InputMaybe<Array<Scalars['String']['input']>>;
  startLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  startTime?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateCancellationRuleBulkInput = {
  updates: Array<UpdateCancellationRuleInput>;
};

export type UpdateCancellationRuleBulkResponse = {
  __typename?: 'UpdateCancellationRuleBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateCancellationRuleInput = {
  query: Array<CancellationRuleSearch>;
  value: UpdateCancellationRuleValuesInput;
};

export type UpdateCancellationRuleValuesInput = {
  airportCode?: InputMaybe<Array<Scalars['String']['input']>>;
  endLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  from?: InputMaybe<Array<Scalars['Int']['input']>>;
  percentage?: InputMaybe<Array<Scalars['String']['input']>>;
  providerId?: InputMaybe<Array<Scalars['String']['input']>>;
  startLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  to?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateCheckinEventBulkInput = {
  updates: Array<UpdateCheckinEventInput>;
};

export type UpdateCheckinEventBulkResponse = {
  __typename?: 'UpdateCheckinEventBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateCheckinEventInput = {
  query: Array<CheckinEventSearch>;
  value: UpdateCheckinEventValuesInput;
};

export type UpdateCheckinEventValuesInput = {
  app?: InputMaybe<Array<Scalars['String']['input']>>;
  datetime?: InputMaybe<Array<Scalars['String']['input']>>;
  ipAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  platform?: InputMaybe<Array<Scalars['String']['input']>>;
  sessionId?: InputMaybe<Array<Scalars['String']['input']>>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
  userAgent?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateCombineRuleBulkInput = {
  updates: Array<UpdateCombineRuleInput>;
};

export type UpdateCombineRuleBulkResponse = {
  __typename?: 'UpdateCombineRuleBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateCombineRuleInput = {
  query: Array<CombineRuleSearch>;
  value: UpdateCombineRuleValuesInput;
};

export type UpdateCombineRuleValuesInput = {
  contractId?: InputMaybe<Array<Scalars['String']['input']>>;
  discount?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pickupWindow?: InputMaybe<Array<Scalars['Int']['input']>>;
  turnaroundThreshold?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateCommunicationBulkInput = {
  updates: Array<UpdateCommunicationInput>;
};

export type UpdateCommunicationBulkResponse = {
  __typename?: 'UpdateCommunicationBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateCommunicationInput = {
  query: Array<CommunicationSearch>;
  value: UpdateCommunicationValuesInput;
};

export type UpdateCommunicationValuesInput = {
  message?: InputMaybe<Array<Scalars['String']['input']>>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateCompanyBulkInput = {
  updates: Array<UpdateCompanyInput>;
};

export type UpdateCompanyBulkResponse = {
  __typename?: 'UpdateCompanyBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateCompanyInput = {
  query: Array<CompanySearch>;
  value: UpdateCompanyValuesInput;
};

export type UpdateCompanyValuesInput = {
  address?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  adminEmail?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cityName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  countryCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dispatchPhone?: InputMaybe<Array<Scalars['String']['input']>>;
  displayName?: InputMaybe<Array<Scalars['String']['input']>>;
  invoiceEmail?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  invoiceFax?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  invoicePhone?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  logo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  settingsId?: InputMaybe<Array<Scalars['String']['input']>>;
  stateCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  website?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zipCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateCompletionBulkInput = {
  updates: Array<UpdateCompletionInput>;
};

export type UpdateCompletionBulkResponse = {
  __typename?: 'UpdateCompletionBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateCompletionInput = {
  query: Array<CompletionSearch>;
  value: UpdateCompletionValuesInput;
};

export type UpdateCompletionTypeBulkInput = {
  updates: Array<UpdateCompletionTypeInput>;
};

export type UpdateCompletionTypeBulkResponse = {
  __typename?: 'UpdateCompletionTypeBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateCompletionTypeInput = {
  query: Array<CompletionTypeSearch>;
  value: UpdateCompletionTypeValuesInput;
};

export type UpdateCompletionTypeValuesInput = {
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateCompletionValuesInput = {
  comment?: InputMaybe<Array<Scalars['String']['input']>>;
  completionTypeId?: InputMaybe<Array<Scalars['String']['input']>>;
  cost?: InputMaybe<Array<Scalars['Float']['input']>>;
  currency?: InputMaybe<Array<CompletionCurrencyEnum>>;
  datetime?: InputMaybe<Array<Scalars['String']['input']>>;
  distanceMeters?: InputMaybe<Array<Scalars['Int']['input']>>;
  efficiency?: InputMaybe<Array<Scalars['Float']['input']>>;
  equivalency?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type UpdateContractBulkInput = {
  updates: Array<UpdateContractInput>;
};

export type UpdateContractBulkResponse = {
  __typename?: 'UpdateContractBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateContractEmailBulkInput = {
  updates: Array<UpdateContractEmailInput>;
};

export type UpdateContractEmailBulkResponse = {
  __typename?: 'UpdateContractEmailBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateContractEmailInput = {
  query: Array<ContractEmailSearch>;
  value: UpdateContractEmailValuesInput;
};

export type UpdateContractEmailValuesInput = {
  contractId?: InputMaybe<Array<Scalars['String']['input']>>;
  email?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<ContractEmailTypeEnum>>;
};

export type UpdateContractInput = {
  query: Array<ContractSearch>;
  value: UpdateContractValuesInput;
};

export type UpdateContractNumberBulkInput = {
  updates: Array<UpdateContractNumberInput>;
};

export type UpdateContractNumberBulkResponse = {
  __typename?: 'UpdateContractNumberBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateContractNumberInput = {
  query: Array<ContractNumberSearch>;
  value: UpdateContractNumberValuesInput;
};

export type UpdateContractNumberValuesInput = {
  contractId?: InputMaybe<Array<Scalars['String']['input']>>;
  email?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<ContractNumberTypeEnum>>;
};

export type UpdateContractValuesInput = {
  active?: InputMaybe<Array<Scalars['Int']['input']>>;
  allowCombines?: InputMaybe<Array<Scalars['Int']['input']>>;
  allowCrewCreateTrip?: InputMaybe<Array<Scalars['Int']['input']>>;
  automateAddons?: InputMaybe<Array<Scalars['Int']['input']>>;
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  enableBufferTime?: InputMaybe<Array<Scalars['Int']['input']>>;
  enableSocialDistancing?: InputMaybe<Array<Scalars['Int']['input']>>;
  invoiceTermId?: InputMaybe<Array<Scalars['String']['input']>>;
  providerId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateCountryRegionBulkInput = {
  updates: Array<UpdateCountryRegionInput>;
};

export type UpdateCountryRegionBulkResponse = {
  __typename?: 'UpdateCountryRegionBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateCountryRegionInput = {
  query: Array<CountryRegionSearch>;
  value: UpdateCountryRegionValuesInput;
};

export type UpdateCountryRegionValuesInput = {
  active?: InputMaybe<Array<Scalars['Int']['input']>>;
  country?: InputMaybe<Array<Scalars['String']['input']>>;
  stateAbbrev?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stateProvince?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateDeviceBulkInput = {
  updates: Array<UpdateDeviceInput>;
};

export type UpdateDeviceBulkResponse = {
  __typename?: 'UpdateDeviceBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateDeviceInput = {
  query: Array<DeviceSearch>;
  value: UpdateDeviceValuesInput;
};

export type UpdateDeviceValuesInput = {
  active?: InputMaybe<Array<Scalars['Int']['input']>>;
  appName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastSeenAt?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  platform?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  token?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateDistanceBulkInput = {
  updates: Array<UpdateDistanceInput>;
};

export type UpdateDistanceBulkResponse = {
  __typename?: 'UpdateDistanceBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateDistanceInput = {
  query: Array<DistanceSearch>;
  value: UpdateDistanceValuesInput;
};

export type UpdateDistanceValuesInput = {
  cachedAt?: InputMaybe<Array<Scalars['String']['input']>>;
  distanceText?: InputMaybe<Array<Scalars['String']['input']>>;
  distanceValue?: InputMaybe<Array<Scalars['Int']['input']>>;
  durationInTrafficText?: InputMaybe<Array<Scalars['String']['input']>>;
  durationInTrafficValue?: InputMaybe<Array<Scalars['Int']['input']>>;
  durationText?: InputMaybe<Array<Scalars['String']['input']>>;
  durationValue?: InputMaybe<Array<Scalars['Int']['input']>>;
  fromLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  toLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateEmailBulkInput = {
  updates: Array<UpdateEmailInput>;
};

export type UpdateEmailBulkResponse = {
  __typename?: 'UpdateEmailBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateEmailInput = {
  query: Array<EmailSearch>;
  value: UpdateEmailValuesInput;
};

export type UpdateEmailValuesInput = {
  address?: InputMaybe<Array<Scalars['String']['input']>>;
  providerId?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<EmailTypeEnum>>;
};

export type UpdateEquivalentTripBulkInput = {
  updates: Array<UpdateEquivalentTripInput>;
};

export type UpdateEquivalentTripBulkResponse = {
  __typename?: 'UpdateEquivalentTripBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateEquivalentTripInput = {
  query: Array<EquivalentTripSearch>;
  value: UpdateEquivalentTripValuesInput;
};

export type UpdateEquivalentTripValuesInput = {
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  count?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  endLocationId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startLocationId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateFcrBulkInput = {
  updates: Array<UpdateFcrInput>;
};

export type UpdateFcrBulkResponse = {
  __typename?: 'UpdateFcrBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateFcrInput = {
  query: Array<FcrSearch>;
  value: UpdateFcrValuesInput;
};

export type UpdateFcrTypeBulkInput = {
  updates: Array<UpdateFcrTypeInput>;
};

export type UpdateFcrTypeBulkResponse = {
  __typename?: 'UpdateFcrTypeBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateFcrTypeInput = {
  query: Array<FcrTypeSearch>;
  value: UpdateFcrTypeValuesInput;
};

export type UpdateFcrTypeValuesInput = {
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateFcrValuesInput = {
  cause?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fcrTypeId?: InputMaybe<Array<Scalars['String']['input']>>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateFlagBulkInput = {
  updates: Array<UpdateFlagInput>;
};

export type UpdateFlagBulkResponse = {
  __typename?: 'UpdateFlagBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateFlagHasTripBulkInput = {
  updates: Array<UpdateFlagHasTripInput>;
};

export type UpdateFlagHasTripBulkResponse = {
  __typename?: 'UpdateFlagHasTripBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateFlagHasTripInput = {
  query: Array<FlagHasTripSearch>;
  value: UpdateFlagHasTripValuesInput;
};

export type UpdateFlagHasTripValuesInput = {
  flagId?: InputMaybe<Array<Scalars['String']['input']>>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateFlagInput = {
  query: Array<FlagSearch>;
  value: UpdateFlagValuesInput;
};

export type UpdateFlagValuesInput = {
  active?: InputMaybe<Array<Scalars['Int']['input']>>;
  color?: InputMaybe<Array<Scalars['String']['input']>>;
  companyId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  global?: InputMaybe<Array<Scalars['Int']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateGroupBulkInput = {
  updates: Array<UpdateGroupInput>;
};

export type UpdateGroupBulkResponse = {
  __typename?: 'UpdateGroupBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateGroupHasPermissionBulkInput = {
  updates: Array<UpdateGroupHasPermissionInput>;
};

export type UpdateGroupHasPermissionBulkResponse = {
  __typename?: 'UpdateGroupHasPermissionBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateGroupHasPermissionInput = {
  query: Array<GroupHasPermissionSearch>;
  value: UpdateGroupHasPermissionValuesInput;
};

export type UpdateGroupHasPermissionValuesInput = {
  groupId?: InputMaybe<Array<Scalars['String']['input']>>;
  permissionId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateGroupHasUserBulkInput = {
  updates: Array<UpdateGroupHasUserInput>;
};

export type UpdateGroupHasUserBulkResponse = {
  __typename?: 'UpdateGroupHasUserBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateGroupHasUserInput = {
  query: Array<GroupHasUserSearch>;
  value: UpdateGroupHasUserValuesInput;
};

export type UpdateGroupHasUserValuesInput = {
  groupId?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateGroupInput = {
  query: Array<GroupSearch>;
  value: UpdateGroupValuesInput;
};

export type UpdateGroupValuesInput = {
  active?: InputMaybe<Array<Scalars['Int']['input']>>;
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<GroupTypeEnum>>;
};

export type UpdateImageBulkInput = {
  updates: Array<UpdateImageInput>;
};

export type UpdateImageBulkResponse = {
  __typename?: 'UpdateImageBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateImageInput = {
  query: Array<ImageSearch>;
  value: UpdateImageValuesInput;
};

export type UpdateImageValuesInput = {
  filename?: InputMaybe<Array<Scalars['String']['input']>>;
  md5?: InputMaybe<Array<Scalars['String']['input']>>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  pickupPointId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateImportBulkInput = {
  updates: Array<UpdateImportInput>;
};

export type UpdateImportBulkResponse = {
  __typename?: 'UpdateImportBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateImportInput = {
  query: Array<ImportSearch>;
  value: UpdateImportValuesInput;
};

export type UpdateImportProviderInput = {
  importId: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type UpdateImportProviderResponse = {
  __typename?: 'UpdateImportProviderResponse';
  error?: Maybe<Error>;
  updated?: Maybe<Scalars['Int']['output']>;
};

export type UpdateImportStatusInput = {
  id: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type UpdateImportValuesInput = {
  airlineIcaoCode?: InputMaybe<Array<Scalars['String']['input']>>;
  archivedAt?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cancelled?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  createTripTolerance?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  created?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  deleted?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  emailId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  excluded?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  fileLocation?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  from?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ingestCompleted?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ingestStarted?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  md5?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noticeDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  previewedAt?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  previousImportId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerId?: InputMaybe<Array<Scalars['String']['input']>>;
  rolledBackAt?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rolledBackBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<ImportStatusEnum>>;
  total?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  type?: InputMaybe<Array<ImportTypeEnum>>;
  unchanged?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  updated?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateImportsStatusInput = {
  imports: Array<UpdateImportStatusInput>;
};

export type UpdateImportsStatusResponse = {
  __typename?: 'UpdateImportsStatusResponse';
  imports: Array<UpdateResponse>;
};

export type UpdateInvoiceTermBulkInput = {
  updates: Array<UpdateInvoiceTermInput>;
};

export type UpdateInvoiceTermBulkResponse = {
  __typename?: 'UpdateInvoiceTermBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateInvoiceTermInput = {
  query: Array<InvoiceTermSearch>;
  value: UpdateInvoiceTermValuesInput;
};

export type UpdateInvoiceTermValuesInput = {
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateIssueBulkInput = {
  updates: Array<UpdateIssueInput>;
};

export type UpdateIssueBulkResponse = {
  __typename?: 'UpdateIssueBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateIssueInput = {
  query: Array<IssueSearch>;
  value: UpdateIssueValuesInput;
};

export type UpdateIssueValuesInput = {
  driverId?: InputMaybe<Array<Scalars['String']['input']>>;
  message?: InputMaybe<Array<Scalars['String']['input']>>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateLicenseBulkInput = {
  updates: Array<UpdateLicenseInput>;
};

export type UpdateLicenseBulkResponse = {
  __typename?: 'UpdateLicenseBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateLicenseInput = {
  query: Array<LicenseSearch>;
  value: UpdateLicenseValuesInput;
};

export type UpdateLicenseTypeBulkInput = {
  updates: Array<UpdateLicenseTypeInput>;
};

export type UpdateLicenseTypeBulkResponse = {
  __typename?: 'UpdateLicenseTypeBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateLicenseTypeInput = {
  query: Array<LicenseTypeSearch>;
  value: UpdateLicenseTypeValuesInput;
};

export type UpdateLicenseTypeValuesInput = {
  displayName?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateLicenseValuesInput = {
  expirationDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  licenseTypeId?: InputMaybe<Array<Scalars['String']['input']>>;
  number?: InputMaybe<Array<Scalars['String']['input']>>;
  stateCode?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateLocationBulkInput = {
  updates: Array<UpdateLocationInput>;
};

export type UpdateLocationBulkResponse = {
  __typename?: 'UpdateLocationBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateLocationByIdInput = {
  airportCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  aliases?: InputMaybe<Array<Scalars['String']['input']>>;
  locationId: Scalars['String']['input'];
  value: UpdateLocationValuesInput;
};

export type UpdateLocationByIdResponse = {
  __typename?: 'UpdateLocationByIdResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type UpdateLocationInput = {
  query: Array<LocationSearch>;
  value: UpdateLocationValuesInput;
};

export type UpdateLocationValuesInput = {
  active?: InputMaybe<Array<Scalars['Int']['input']>>;
  address?: InputMaybe<Array<Scalars['String']['input']>>;
  cityName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  coordinates?: InputMaybe<Array<PointInput>>;
  countryCode?: InputMaybe<Array<Scalars['String']['input']>>;
  legacyId?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryAirportCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  radius?: InputMaybe<Array<Scalars['Int']['input']>>;
  stateCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  traceId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<LocationTypeEnum>>;
  zipCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateLoginEventBulkInput = {
  updates: Array<UpdateLoginEventInput>;
};

export type UpdateLoginEventBulkResponse = {
  __typename?: 'UpdateLoginEventBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateLoginEventInput = {
  query: Array<LoginEventSearch>;
  value: UpdateLoginEventValuesInput;
};

export type UpdateLoginEventValuesInput = {
  cityName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  datetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  flightNumber?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  ipAddress?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userAgent?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateLostAndFoundIssueBulkInput = {
  updates: Array<UpdateLostAndFoundIssueInput>;
};

export type UpdateLostAndFoundIssueBulkResponse = {
  __typename?: 'UpdateLostAndFoundIssueBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateLostAndFoundIssueDocumentBulkInput = {
  updates: Array<UpdateLostAndFoundIssueDocumentInput>;
};

export type UpdateLostAndFoundIssueDocumentBulkResponse = {
  __typename?: 'UpdateLostAndFoundIssueDocumentBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateLostAndFoundIssueDocumentInput = {
  query: Array<LostAndFoundIssueDocumentSearch>;
  value: UpdateLostAndFoundIssueDocumentValuesInput;
};

export type UpdateLostAndFoundIssueDocumentValuesInput = {
  contentType?: InputMaybe<Array<Scalars['String']['input']>>;
  fileLocation?: InputMaybe<Array<Scalars['String']['input']>>;
  fileSize?: InputMaybe<Array<Scalars['Int']['input']>>;
  filename?: InputMaybe<Array<Scalars['String']['input']>>;
  issueId?: InputMaybe<Array<Scalars['String']['input']>>;
  md5?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateLostAndFoundIssueInput = {
  query: Array<LostAndFoundIssueSearch>;
  value: UpdateLostAndFoundIssueValuesInput;
};

export type UpdateLostAndFoundIssueValuesInput = {
  city?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  crewMemberEmail?: InputMaybe<Array<Scalars['String']['input']>>;
  crewMemberName?: InputMaybe<Array<Scalars['String']['input']>>;
  crewMemberPhone?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  dispatcher?: InputMaybe<Array<Scalars['String']['input']>>;
  notes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  outboundDatetime?: InputMaybe<Array<Scalars['String']['input']>>;
  outboundFlightNumber?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  returnStatus?: InputMaybe<Array<InputMaybe<LostAndFoundIssueReturnStatusEnum>>>;
  shippingAddress?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  shippingCost?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  shippingMethod?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stateCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trackingNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateMessageBulkInput = {
  updates: Array<UpdateMessageInput>;
};

export type UpdateMessageBulkResponse = {
  __typename?: 'UpdateMessageBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateMessageInput = {
  query: Array<MessageSearch>;
  value: UpdateMessageValuesInput;
};

export type UpdateMessageValuesInput = {
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  content?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<MessageTypeEnum>>;
};

export type UpdatePasswordInput = {
  confirmPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UpdatePasswordsInput = {
  users: Array<UpdatePasswordInput>;
};

export type UpdatePasswordsResponse = {
  __typename?: 'UpdatePasswordsResponse';
  users: Array<UpdateResponse>;
};

export type UpdatePermissionBulkInput = {
  updates: Array<UpdatePermissionInput>;
};

export type UpdatePermissionBulkResponse = {
  __typename?: 'UpdatePermissionBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdatePermissionHasRoleBulkInput = {
  updates: Array<UpdatePermissionHasRoleInput>;
};

export type UpdatePermissionHasRoleBulkResponse = {
  __typename?: 'UpdatePermissionHasRoleBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdatePermissionHasRoleInput = {
  query: Array<PermissionHasRoleSearch>;
  value: UpdatePermissionHasRoleValuesInput;
};

export type UpdatePermissionHasRoleValuesInput = {
  permissionId?: InputMaybe<Array<Scalars['String']['input']>>;
  roleId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdatePermissionHasUserBulkInput = {
  updates: Array<UpdatePermissionHasUserInput>;
};

export type UpdatePermissionHasUserBulkResponse = {
  __typename?: 'UpdatePermissionHasUserBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdatePermissionHasUserInput = {
  query: Array<PermissionHasUserSearch>;
  value: UpdatePermissionHasUserValuesInput;
};

export type UpdatePermissionHasUserValuesInput = {
  permissionId?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdatePermissionInput = {
  query: Array<PermissionSearch>;
  value: UpdatePermissionValuesInput;
};

export type UpdatePermissionValuesInput = {
  description?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayGroup?: InputMaybe<Array<Scalars['String']['input']>>;
  displayName?: InputMaybe<Array<Scalars['String']['input']>>;
  effect?: InputMaybe<Array<PermissionEffectEnum>>;
  entity?: InputMaybe<Array<Scalars['String']['input']>>;
  resource?: InputMaybe<Array<Scalars['String']['input']>>;
  verb?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdatePickupPointBulkInput = {
  updates: Array<UpdatePickupPointInput>;
};

export type UpdatePickupPointBulkResponse = {
  __typename?: 'UpdatePickupPointBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdatePickupPointInput = {
  query: Array<PickupPointSearch>;
  value: UpdatePickupPointValuesInput;
};

export type UpdatePickupPointValuesInput = {
  coordinates?: InputMaybe<Array<PointInput>>;
  endDatetime?: InputMaybe<Array<Scalars['String']['input']>>;
  instructions?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Array<Scalars['String']['input']>>;
  locationId?: InputMaybe<Array<Scalars['String']['input']>>;
  providerId?: InputMaybe<Array<Scalars['String']['input']>>;
  radius?: InputMaybe<Array<Scalars['Int']['input']>>;
  startDatetime?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdatePotentialTripBulkInput = {
  updates: Array<UpdatePotentialTripInput>;
};

export type UpdatePotentialTripBulkResponse = {
  __typename?: 'UpdatePotentialTripBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdatePotentialTripInput = {
  query: Array<PotentialTripSearch>;
  value: UpdatePotentialTripValuesInput;
};

export type UpdatePotentialTripValuesInput = {
  airportCode?: InputMaybe<Array<Scalars['String']['input']>>;
  attendants?: InputMaybe<Array<Scalars['Int']['input']>>;
  bufferMinutes?: InputMaybe<Array<Scalars['Int']['input']>>;
  combineId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  doLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  flightNumber?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  fromManifest?: InputMaybe<Array<Scalars['Int']['input']>>;
  importId?: InputMaybe<Array<Scalars['String']['input']>>;
  kind?: InputMaybe<Array<PotentialTripKindEnum>>;
  loopName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payerProviderId?: InputMaybe<Array<Scalars['String']['input']>>;
  pilots?: InputMaybe<Array<Scalars['Int']['input']>>;
  puLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  scheduled?: InputMaybe<Array<Scalars['String']['input']>>;
  servicerIataAirlineCode?: InputMaybe<Array<Scalars['String']['input']>>;
  split?: InputMaybe<Array<Scalars['Int']['input']>>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
  typeName?: InputMaybe<Array<InputMaybe<PotentialTripTypeNameEnum>>>;
};

export type UpdateProviderBulkInput = {
  updates: Array<UpdateProviderInput>;
};

export type UpdateProviderBulkResponse = {
  __typename?: 'UpdateProviderBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateProviderInput = {
  query: Array<ProviderSearch>;
  value: UpdateProviderValuesInput;
};

export type UpdateProviderLocationMappingBulkInput = {
  updates: Array<UpdateProviderLocationMappingInput>;
};

export type UpdateProviderLocationMappingBulkResponse = {
  __typename?: 'UpdateProviderLocationMappingBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateProviderLocationMappingInput = {
  query: Array<ProviderLocationMappingSearch>;
  value: UpdateProviderLocationMappingValuesInput;
};

export type UpdateProviderLocationMappingValuesInput = {
  active?: InputMaybe<Array<Scalars['Int']['input']>>;
  contractId?: InputMaybe<Array<Scalars['String']['input']>>;
  doLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  providerId?: InputMaybe<Array<Scalars['String']['input']>>;
  puLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateProviderValuesInput = {
  active?: InputMaybe<Array<Scalars['Int']['input']>>;
  billFrequency?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  billingAddress?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  billingCityCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  billingStateCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  billingZipCode?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  contactPerson?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  faxNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  iataAirlineCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  importCode?: InputMaybe<Array<InputMaybe<ProviderImportCodeEnum>>>;
  invoiceTermId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobilePhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  occPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryAddress?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryCityCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryEmail?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryLogoUrl?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryStateCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primaryZipCode?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  referenceNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  traceId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<ProviderTypeEnum>>;
  website?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdatePunchHistoryBulkInput = {
  updates: Array<UpdatePunchHistoryInput>;
};

export type UpdatePunchHistoryBulkResponse = {
  __typename?: 'UpdatePunchHistoryBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdatePunchHistoryInput = {
  query: Array<PunchHistorySearch>;
  value: UpdatePunchHistoryValuesInput;
};

export type UpdatePunchHistoryValuesInput = {
  approvalEmployee?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  approvalSupervisor?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  approvalSupervisorBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  companyCode?: InputMaybe<Array<Scalars['String']['input']>>;
  date?: InputMaybe<Array<Scalars['String']['input']>>;
  durationOt1?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  durationOt2?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  durationRegular?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  employeeId?: InputMaybe<Array<Scalars['String']['input']>>;
  fileLocation?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName?: InputMaybe<Array<Scalars['String']['input']>>;
  payType?: InputMaybe<Array<Scalars['String']['input']>>;
  punchInTime?: InputMaybe<Array<Scalars['String']['input']>>;
  punchInType?: InputMaybe<Array<PunchHistoryPunchInTypeEnum>>;
  punchOutTime?: InputMaybe<Array<Scalars['String']['input']>>;
  punchOutType?: InputMaybe<Array<PunchHistoryPunchOutTypeEnum>>;
  unpaidDuration?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type UpdatePunchPolicyBulkInput = {
  updates: Array<UpdatePunchPolicyInput>;
};

export type UpdatePunchPolicyBulkResponse = {
  __typename?: 'UpdatePunchPolicyBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdatePunchPolicyInput = {
  query: Array<PunchPolicySearch>;
  value: UpdatePunchPolicyValuesInput;
};

export type UpdatePunchPolicyValuesInput = {
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  endTime?: InputMaybe<Array<Scalars['String']['input']>>;
  eventEndDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  eventStartDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locationId?: InputMaybe<Array<Scalars['String']['input']>>;
  minutesIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  minutesOut?: InputMaybe<Array<Scalars['Int']['input']>>;
  startTime?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateRateBulkInput = {
  updates: Array<UpdateRateInput>;
};

export type UpdateRateBulkResponse = {
  __typename?: 'UpdateRateBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateRateGroupBulkInput = {
  updates: Array<UpdateRateGroupInput>;
};

export type UpdateRateGroupBulkResponse = {
  __typename?: 'UpdateRateGroupBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateRateGroupInput = {
  query: Array<RateGroupSearch>;
  value: UpdateRateGroupValuesInput;
};

export type UpdateRateGroupValuesInput = {
  airportCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  duration?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  payerProviderId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  thatLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  thisLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  tripDistance?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type UpdateRateHistoryBulkInput = {
  updates: Array<UpdateRateHistoryInput>;
};

export type UpdateRateHistoryBulkResponse = {
  __typename?: 'UpdateRateHistoryBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateRateHistoryInput = {
  query: Array<RateHistorySearch>;
  value: UpdateRateHistoryValuesInput;
};

export type UpdateRateHistoryValuesInput = {
  currency?: InputMaybe<Array<RateHistoryCurrencyEnum>>;
  endDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rate?: InputMaybe<Array<Scalars['Float']['input']>>;
  rateGroupId?: InputMaybe<Array<Scalars['String']['input']>>;
  rateItemId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<RateHistoryTypeEnum>>;
};

export type UpdateRateInput = {
  query: Array<RateSearch>;
  value: UpdateRateValuesInput;
};

export type UpdateRateItemBulkInput = {
  updates: Array<UpdateRateItemInput>;
};

export type UpdateRateItemBulkResponse = {
  __typename?: 'UpdateRateItemBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateRateItemInput = {
  query: Array<RateItemSearch>;
  value: UpdateRateItemValuesInput;
};

export type UpdateRateItemValuesInput = {
  currency?: InputMaybe<Array<RateItemCurrencyEnum>>;
  endDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rate?: InputMaybe<Array<Scalars['Float']['input']>>;
  rateGroupId?: InputMaybe<Array<Scalars['String']['input']>>;
  startDatetime?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<RateItemTypeEnum>>;
};

export type UpdateRateOnTripInput = {
  rateHistoryId: Scalars['String']['input'];
  rateId?: InputMaybe<Scalars['String']['input']>;
  tripId: Scalars['String']['input'];
};

export type UpdateRateOnTripResponse = {
  __typename?: 'UpdateRateOnTripResponse';
  error?: Maybe<Error>;
  rateHistoryId?: Maybe<Scalars['String']['output']>;
  rateId?: Maybe<Scalars['String']['output']>;
  tripId?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['Int']['output']>;
};

export type UpdateRateOnTripsInput = {
  input: Array<UpdateRateOnTripInput>;
};

export type UpdateRateOnTripsResponse = {
  __typename?: 'UpdateRateOnTripsResponse';
  output?: Maybe<Array<UpdateRateOnTripResponse>>;
};

export type UpdateRateValuesInput = {
  airportCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  contractEndDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contractId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contractStartDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currency?: InputMaybe<Array<RateCurrencyEnum>>;
  duration?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  endDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  notes?: InputMaybe<Array<Scalars['String']['input']>>;
  payerProviderId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  radius?: InputMaybe<Array<Scalars['Int']['input']>>;
  rate?: InputMaybe<Array<Scalars['Float']['input']>>;
  startDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  thatLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  thisLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  tripDistance?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  type?: InputMaybe<Array<RateTypeEnum>>;
};

export type UpdateRatingBulkInput = {
  updates: Array<UpdateRatingInput>;
};

export type UpdateRatingBulkResponse = {
  __typename?: 'UpdateRatingBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateRatingInput = {
  query: Array<RatingSearch>;
  value: UpdateRatingValuesInput;
};

export type UpdateRatingRemarkBulkInput = {
  updates: Array<UpdateRatingRemarkInput>;
};

export type UpdateRatingRemarkBulkResponse = {
  __typename?: 'UpdateRatingRemarkBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateRatingRemarkInput = {
  query: Array<RatingRemarkSearch>;
  value: UpdateRatingRemarkValuesInput;
};

export type UpdateRatingRemarkValuesInput = {
  ratingId?: InputMaybe<Array<Scalars['String']['input']>>;
  remark?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateRatingTypeBulkInput = {
  updates: Array<UpdateRatingTypeInput>;
};

export type UpdateRatingTypeBulkResponse = {
  __typename?: 'UpdateRatingTypeBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateRatingTypeInput = {
  query: Array<RatingTypeSearch>;
  value: UpdateRatingTypeValuesInput;
};

export type UpdateRatingTypeValuesInput = {
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateRatingValuesInput = {
  comment?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rating?: InputMaybe<Array<Scalars['Int']['input']>>;
  ratingTypeId?: InputMaybe<Array<Scalars['String']['input']>>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateRemarksInput = {
  remarks: Array<RemarkInput>;
};

export type UpdateRemarksResponse = {
  __typename?: 'UpdateRemarksResponse';
  remarks: Array<UpdateResponse>;
};

export type UpdateResponse = {
  __typename?: 'UpdateResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type UpdateRoleBulkInput = {
  updates: Array<UpdateRoleInput>;
};

export type UpdateRoleBulkResponse = {
  __typename?: 'UpdateRoleBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateRoleInput = {
  query: Array<RoleSearch>;
  value: UpdateRoleValuesInput;
};

export type UpdateRoleValuesInput = {
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateSettingsBulkInput = {
  updates: Array<UpdateSettingsInput>;
};

export type UpdateSettingsBulkResponse = {
  __typename?: 'UpdateSettingsBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateSettingsInput = {
  query: Array<SettingsSearch>;
  value: UpdateSettingsValuesInput;
};

export type UpdateSettingsValuesInput = {
  chatReadTime?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  crewChatReadTime?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  messageReadTime?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  outboundLateAlertMinutes?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  passwordExpirationDays?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  vehicleTrackingVendor?: InputMaybe<Array<InputMaybe<SettingsVehicleTrackingVendorEnum>>>;
  vehicleTrackingVendorToken?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateSocialDistanceRuleBulkInput = {
  updates: Array<UpdateSocialDistanceRuleInput>;
};

export type UpdateSocialDistanceRuleBulkResponse = {
  __typename?: 'UpdateSocialDistanceRuleBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateSocialDistanceRuleInput = {
  query: Array<SocialDistanceRuleSearch>;
  value: UpdateSocialDistanceRuleValuesInput;
};

export type UpdateSocialDistanceRuleValuesInput = {
  active?: InputMaybe<Array<Scalars['Int']['input']>>;
  contractId?: InputMaybe<Array<Scalars['String']['input']>>;
  max?: InputMaybe<Array<Scalars['Int']['input']>>;
  type?: InputMaybe<Array<InputMaybe<SocialDistanceRuleTypeEnum>>>;
};

export type UpdateStagedTripBulkInput = {
  updates: Array<UpdateStagedTripInput>;
};

export type UpdateStagedTripBulkResponse = {
  __typename?: 'UpdateStagedTripBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateStagedTripInput = {
  query: Array<StagedTripSearch>;
  value: UpdateStagedTripValuesInput;
};

export type UpdateStagedTripValuesInput = {
  airportCode?: InputMaybe<Array<Scalars['String']['input']>>;
  attendants?: InputMaybe<Array<Scalars['Int']['input']>>;
  bufferMinutes?: InputMaybe<Array<Scalars['Int']['input']>>;
  combineId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  doLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  flightNumber?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  fromManifest?: InputMaybe<Array<Scalars['Int']['input']>>;
  importId?: InputMaybe<Array<Scalars['String']['input']>>;
  ingestAction?: InputMaybe<Array<StagedTripIngestActionEnum>>;
  kind?: InputMaybe<Array<StagedTripKindEnum>>;
  loopName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payerProviderId?: InputMaybe<Array<Scalars['String']['input']>>;
  pilots?: InputMaybe<Array<Scalars['Int']['input']>>;
  potentialTripId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  puLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  scheduled?: InputMaybe<Array<Scalars['String']['input']>>;
  servicerIataAirlineCode?: InputMaybe<Array<Scalars['String']['input']>>;
  split?: InputMaybe<Array<Scalars['Int']['input']>>;
  statusName?: InputMaybe<Array<StagedTripStatusNameEnum>>;
  traceId?: InputMaybe<Array<Scalars['String']['input']>>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
  typeName?: InputMaybe<Array<StagedTripTypeNameEnum>>;
};

export type UpdateStopBulkInput = {
  updates: Array<UpdateStopInput>;
};

export type UpdateStopBulkResponse = {
  __typename?: 'UpdateStopBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateStopInput = {
  query: Array<StopSearch>;
  value: UpdateStopValuesInput;
};

export type UpdateStopValuesInput = {
  actual?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  airportCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  completed?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locationId?: InputMaybe<Array<Scalars['String']['input']>>;
  scheduled?: InputMaybe<Array<Scalars['String']['input']>>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<StopTypeEnum>>;
};

export type UpdateStorageBulkInput = {
  updates: Array<UpdateStorageInput>;
};

export type UpdateStorageBulkResponse = {
  __typename?: 'UpdateStorageBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateStorageInput = {
  query: Array<StorageSearch>;
  value: UpdateStorageValuesInput;
};

export type UpdateStorageValuesInput = {
  key?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateStrategy = {
  __typename?: 'UpdateStrategy';
  active: Scalars['Int']['output'];
  addonStrategy: UpdateStrategyAddonStrategyEnum;
  addonType: UpdateStrategyAddonTypeEnum;
  contractId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  customAddonToleranceMinutes?: Maybe<Scalars['Int']['output']>;
  dailyStrategy: UpdateStrategyDailyStrategyEnum;
  id: Scalars['String']['output'];
  importNameIncludesHotels: Scalars['Int']['output'];
  manifestStrategy: UpdateStrategyManifestStrategyEnum;
  strategy: UpdateStrategyStrategyEnum;
  updateKindFromLocations: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export enum UpdateStrategyAddonStrategyEnum {
  Merge = 'MERGE',
  Replace = 'REPLACE'
}

export type UpdateStrategyAddonStrategyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<UpdateStrategyAddonStrategyEnum>;
};

export enum UpdateStrategyAddonTypeEnum {
  Add = 'ADD',
  Sch = 'SCH',
  Spl = 'SPL'
}

export type UpdateStrategyAddonTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<UpdateStrategyAddonTypeEnum>;
};

export type UpdateStrategyConnection = {
  __typename?: 'UpdateStrategyConnection';
  edges?: Maybe<Array<Maybe<UpdateStrategyEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UpdateStrategyConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<UpdateStrategyMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<UpdateStrategySearch>>;
};

export enum UpdateStrategyDailyStrategyEnum {
  Merge = 'MERGE',
  Replace = 'REPLACE'
}

export type UpdateStrategyDailyStrategyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<UpdateStrategyDailyStrategyEnum>;
};

export type UpdateStrategyEdge = {
  __typename?: 'UpdateStrategyEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<UpdateStrategy>;
};

export enum UpdateStrategyManifestStrategyEnum {
  Merge = 'MERGE',
  Replace = 'REPLACE'
}

export type UpdateStrategyManifestStrategyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<UpdateStrategyManifestStrategyEnum>;
};

export type UpdateStrategyMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  addonStrategy?: InputMaybe<Scalars['Boolean']['input']>;
  addonType?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  customAddonToleranceMinutes?: InputMaybe<Scalars['Boolean']['input']>;
  dailyStrategy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  importNameIncludesHotels?: InputMaybe<Scalars['Boolean']['input']>;
  manifestStrategy?: InputMaybe<Scalars['Boolean']['input']>;
  strategy?: InputMaybe<Scalars['Boolean']['input']>;
  updateKindFromLocations?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateStrategySearch = {
  active?: InputMaybe<Array<IntSearch>>;
  addonStrategy?: InputMaybe<Array<UpdateStrategyAddonStrategyEnumSearch>>;
  addonType?: InputMaybe<Array<UpdateStrategyAddonTypeEnumSearch>>;
  contractId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  customAddonToleranceMinutes?: InputMaybe<Array<IntSearch>>;
  dailyStrategy?: InputMaybe<Array<UpdateStrategyDailyStrategyEnumSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  importNameIncludesHotels?: InputMaybe<Array<IntSearch>>;
  manifestStrategy?: InputMaybe<Array<UpdateStrategyManifestStrategyEnumSearch>>;
  strategy?: InputMaybe<Array<UpdateStrategyStrategyEnumSearch>>;
  updateKindFromLocations?: InputMaybe<Array<IntSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export enum UpdateStrategyStrategyEnum {
  Merge = 'MERGE',
  Replace = 'REPLACE'
}

export type UpdateStrategyStrategyEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<UpdateStrategyStrategyEnum>;
};

export type UpdateTrackFlightBulkInput = {
  updates: Array<UpdateTrackFlightInput>;
};

export type UpdateTrackFlightBulkResponse = {
  __typename?: 'UpdateTrackFlightBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateTrackFlightInput = {
  query: Array<TrackFlightSearch>;
  value: UpdateTrackFlightValuesInput;
};

export type UpdateTrackFlightValuesInput = {
  actual?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  arrivalGate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  arrivalTerminal?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  arrivalTimezone?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  arrivalUtcOffsetHours?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  departureGate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  departureTerminal?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  departureTimezone?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  departureUtcOffsetHours?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label?: InputMaybe<Array<InputMaybe<TrackFlightLabelEnum>>>;
  lastPingTime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nextPingTime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  number?: InputMaybe<Array<Scalars['Int']['input']>>;
  raw?: InputMaybe<Array<Scalars['String']['input']>>;
  scheduled?: InputMaybe<Array<Scalars['String']['input']>>;
  servicerIataAirlineCode?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trackerProvider?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<InputMaybe<TrackFlightTypeEnum>>>;
};

export type UpdateTripBulkInput = {
  updates: Array<UpdateTripInput>;
};

export type UpdateTripBulkResponse = {
  __typename?: 'UpdateTripBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateTripInput = {
  query: Array<TripSearch>;
  value: UpdateTripValuesInput;
};

export type UpdateTripScheduleTriggerBulkInput = {
  updates: Array<UpdateTripScheduleTriggerInput>;
};

export type UpdateTripScheduleTriggerBulkResponse = {
  __typename?: 'UpdateTripScheduleTriggerBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateTripScheduleTriggerInput = {
  query: Array<TripScheduleTriggerSearch>;
  value: UpdateTripScheduleTriggerValuesInput;
};

export type UpdateTripScheduleTriggerValuesInput = {
  communicationCount?: InputMaybe<Array<Scalars['Int']['input']>>;
  doLocationAddress?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  doLocationCityName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  doLocationCoordinates?: InputMaybe<Array<InputMaybe<PointInput>>>;
  doLocationId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  doLocationName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  doLocationPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  doLocationStateCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  doLocationZipCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  driverAvatar?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  driverEmployeeId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  driverFirstName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  driverId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  driverLastName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fcrCount?: InputMaybe<Array<Scalars['Int']['input']>>;
  flagColors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latestScheduled?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latestScheduledUtc?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latestTriggerUsed?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payerProviderDisplayName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payerProviderId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  puLocationAddress?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  puLocationCityName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  puLocationCoordinates?: InputMaybe<Array<InputMaybe<PointInput>>>;
  puLocationId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  puLocationName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  puLocationPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  puLocationStateCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  puLocationZipCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scheduledUtc?: InputMaybe<Array<Scalars['String']['input']>>;
  stopCount?: InputMaybe<Array<Scalars['Int']['input']>>;
  tripId?: InputMaybe<Array<Scalars['String']['input']>>;
  vehicleCapacity?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  vehicleId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleMake?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleModel?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehiclePhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleTrackingId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateTripValuesInput = {
  airportCode?: InputMaybe<Array<Scalars['String']['input']>>;
  attendants?: InputMaybe<Array<Scalars['Int']['input']>>;
  combineId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  combineType?: InputMaybe<Array<InputMaybe<TripCombineTypeEnum>>>;
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  completionId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  crewId?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  curbsideAt?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  doActualDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  doAirportCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  doLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  driverId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  etaMinutes?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  firstName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  flightNumber?: InputMaybe<Array<Scalars['Int']['input']>>;
  fromManifest?: InputMaybe<Array<Scalars['Int']['input']>>;
  isIllegalCombine?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  kind?: InputMaybe<Array<TripKindEnum>>;
  lastName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  legacyId?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  loopName?: InputMaybe<Array<Scalars['String']['input']>>;
  manifestImportId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  offset?: InputMaybe<Array<Scalars['String']['input']>>;
  payerProviderId?: InputMaybe<Array<Scalars['String']['input']>>;
  pilots?: InputMaybe<Array<Scalars['Int']['input']>>;
  plannedAt?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerId?: InputMaybe<Array<Scalars['String']['input']>>;
  puActualDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  puCompletedDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  puGpsDatetime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  puLocationId?: InputMaybe<Array<Scalars['String']['input']>>;
  rateHistoryId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rateId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scheduled?: InputMaybe<Array<Scalars['String']['input']>>;
  servicerIataAirlineCode?: InputMaybe<Array<Scalars['String']['input']>>;
  split?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<Array<TripStatusEnum>>;
  traceId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trackFlightId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<TripTypeEnum>>;
  vehicleId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateUpdateStrategyBulkInput = {
  updates: Array<UpdateUpdateStrategyInput>;
};

export type UpdateUpdateStrategyBulkResponse = {
  __typename?: 'UpdateUpdateStrategyBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateUpdateStrategyInput = {
  query: Array<UpdateStrategySearch>;
  value: UpdateUpdateStrategyValuesInput;
};

export type UpdateUpdateStrategyValuesInput = {
  active?: InputMaybe<Array<Scalars['Int']['input']>>;
  addonStrategy?: InputMaybe<Array<UpdateStrategyAddonStrategyEnum>>;
  addonType?: InputMaybe<Array<UpdateStrategyAddonTypeEnum>>;
  contractId?: InputMaybe<Array<Scalars['String']['input']>>;
  customAddonToleranceMinutes?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  dailyStrategy?: InputMaybe<Array<UpdateStrategyDailyStrategyEnum>>;
  importNameIncludesHotels?: InputMaybe<Array<Scalars['Int']['input']>>;
  manifestStrategy?: InputMaybe<Array<UpdateStrategyManifestStrategyEnum>>;
  strategy?: InputMaybe<Array<UpdateStrategyStrategyEnum>>;
  updateKindFromLocations?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateUserActivationBulkInput = {
  users: Array<UpdateUserActivationInput>;
};

export type UpdateUserActivationBulkResponse = {
  __typename?: 'UpdateUserActivationBulkResponse';
  response: Array<UpdateUserActivationResponse>;
  tripsUpdated: Scalars['Int']['output'];
  usersUpdated: Scalars['Int']['output'];
};

export type UpdateUserActivationInput = {
  action: UserActivationActionEnum;
  userId: Scalars['String']['input'];
};

export type UpdateUserActivationResponse = {
  __typename?: 'UpdateUserActivationResponse';
  active: Scalars['Boolean']['output'];
  userId: Scalars['String']['output'];
};

export type UpdateUserAvailabilityBulkInput = {
  updates: Array<UpdateUserAvailabilityInput>;
};

export type UpdateUserAvailabilityBulkResponse = {
  __typename?: 'UpdateUserAvailabilityBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateUserAvailabilityDriverAppInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  value: UpdateUserAvailabilityValuesInput;
};

export type UpdateUserAvailabilityDriverAppResponse = {
  __typename?: 'UpdateUserAvailabilityDriverAppResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type UpdateUserAvailabilityInput = {
  query: Array<UserAvailabilitySearch>;
  value: UpdateUserAvailabilityValuesInput;
};

export type UpdateUserAvailabilitySeriesDriverAppInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  value: UpdateUserAvailabilityValuesInput;
};

export type UpdateUserAvailabilitySeriesDriverAppResponse = {
  __typename?: 'UpdateUserAvailabilitySeriesDriverAppResponse';
  error?: Maybe<Error>;
  updated: Scalars['Int']['output'];
};

export type UpdateUserAvailabilityValuesInput = {
  approved?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  dayOfWeek?: InputMaybe<Array<Scalars['Int']['input']>>;
  endDate?: InputMaybe<Array<Scalars['String']['input']>>;
  endTime?: InputMaybe<Array<Scalars['String']['input']>>;
  reason?: InputMaybe<Array<Scalars['String']['input']>>;
  repeatUntil?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reviewedBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate?: InputMaybe<Array<Scalars['String']['input']>>;
  startTime?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<UserAvailabilityTypeEnum>>;
  userId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateUserBulkInput = {
  updates: Array<UpdateUserInput>;
};

export type UpdateUserBulkResponse = {
  __typename?: 'UpdateUserBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateUserInput = {
  query: Array<UserSearch>;
  value: UpdateUserValuesInput;
};

export type UpdateUserValuesInput = {
  active?: InputMaybe<Array<Scalars['Int']['input']>>;
  address?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  avatar?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cityName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  countryCode?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  dob?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email?: InputMaybe<Array<Scalars['String']['input']>>;
  employeeId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName?: InputMaybe<Array<Scalars['String']['input']>>;
  longTrip?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  password?: InputMaybe<Array<Scalars['String']['input']>>;
  phone?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  providerId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  resetClientIp?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  resetCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  resetCreatedAtUtc?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleId?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stateCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timezone?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  traceId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  username?: InputMaybe<Array<Scalars['String']['input']>>;
  vehicleId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zipCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateVariableTimeBulkInput = {
  updates: Array<UpdateVariableTimeInput>;
};

export type UpdateVariableTimeBulkResponse = {
  __typename?: 'UpdateVariableTimeBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateVariableTimeInput = {
  query: Array<VariableTimeSearch>;
  value: UpdateVariableTimeValuesInput;
};

export type UpdateVariableTimeValuesInput = {
  airportCode?: InputMaybe<Array<Scalars['String']['input']>>;
  companyId?: InputMaybe<Array<Scalars['String']['input']>>;
  inboundDomestic?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  inboundInternational?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  outbound?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  servicerIataAirlineCode?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateVehicleBulkInput = {
  updates: Array<UpdateVehicleInput>;
};

export type UpdateVehicleBulkResponse = {
  __typename?: 'UpdateVehicleBulkResponse';
  response: Array<UpdateResponse>;
};

export type UpdateVehicleInput = {
  query: Array<VehicleSearch>;
  value: UpdateVehicleValuesInput;
};

export type UpdateVehicleValuesInput = {
  active?: InputMaybe<Array<Scalars['Int']['input']>>;
  airportPermitExpirationDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  capacity?: InputMaybe<Array<Scalars['Int']['input']>>;
  emissionPermitExpirationDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  licensePlate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locationId?: InputMaybe<Array<Scalars['String']['input']>>;
  make?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  model?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  registrationExpirationDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stateRegistered?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tollTag?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tracker?: InputMaybe<Array<VehicleTrackerEnum>>;
  trackingId?: InputMaybe<Array<Scalars['String']['input']>>;
  vin?: InputMaybe<Array<Scalars['String']['input']>>;
  year?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type User = Node & {
  __typename?: 'User';
  active: Scalars['Int']['output'];
  address?: Maybe<Scalars['String']['output']>;
  airportCodes?: Maybe<Array<AirportHasUser>>;
  airports?: Maybe<Array<Scalars['String']['output']>>;
  availability?: Maybe<Array<UserAvailability>>;
  avatar?: Maybe<Scalars['String']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  countryCode?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  employeeId?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<Group>>;
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<LastLogin>;
  lastName: Scalars['String']['output'];
  licenses?: Maybe<Array<License>>;
  longTrip?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  performance?: Maybe<DriverPerformance>;
  phone?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['String']['output']>;
  ratingDetails?: Maybe<RatingDetails>;
  ratings?: Maybe<RatingConnection>;
  resetClientIp?: Maybe<Scalars['String']['output']>;
  resetCode?: Maybe<Scalars['String']['output']>;
  resetCreatedAtUtc?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  roleId: Scalars['String']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  traceId?: Maybe<Scalars['String']['output']>;
  tripDetails?: Maybe<TripDetails>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
  vehicleId?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type UserPerformanceArgs = {
  input?: InputMaybe<DriverPerformanceSearchInput>;
};


export type UserRatingDetailsArgs = {
  input?: InputMaybe<RatingConnectionInput>;
};


export type UserRatingsArgs = {
  input?: InputMaybe<RatingConnectionInput>;
};

export enum UserActivationActionEnum {
  Activate = 'ACTIVATE',
  Deactivate = 'DEACTIVATE'
}

export type UserAvailability = {
  __typename?: 'UserAvailability';
  approved?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  dayOfWeek: Scalars['Int']['output'];
  endDate: Scalars['String']['output'];
  endTime: Scalars['String']['output'];
  id: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  repeatUntil?: Maybe<Scalars['String']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  type: UserAvailabilityTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type UserAvailabilityConnection = {
  __typename?: 'UserAvailabilityConnection';
  edges?: Maybe<Array<Maybe<UserAvailabilityEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserAvailabilityConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<UserAvailabilityMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<UserAvailabilitySearch>>;
};

export type UserAvailabilityEdge = {
  __typename?: 'UserAvailabilityEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<UserAvailability>;
};

export type UserAvailabilityMaskInput = {
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  dayOfWeek?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<Scalars['Boolean']['input']>;
  repeatUntil?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserAvailabilitySearch = {
  approved?: InputMaybe<Array<IntSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  dayOfWeek?: InputMaybe<Array<IntSearch>>;
  endDate?: InputMaybe<Array<StringSearch>>;
  endTime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  reason?: InputMaybe<Array<StringSearch>>;
  repeatUntil?: InputMaybe<Array<StringSearch>>;
  reviewedBy?: InputMaybe<Array<StringSearch>>;
  startDate?: InputMaybe<Array<StringSearch>>;
  startTime?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<UserAvailabilityTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  userId?: InputMaybe<Array<StringSearch>>;
};

export enum UserAvailabilityTypeEnum {
  Off = 'OFF',
  On = 'ON'
}

export type UserAvailabilityTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<UserAvailabilityTypeEnum>;
};

export type UserAvailabilityWithDatetimes = {
  __typename?: 'UserAvailabilityWithDatetimes';
  approved?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  dayOfWeek: Scalars['Int']['output'];
  endDate: Scalars['String']['output'];
  endDatetime: Scalars['String']['output'];
  endTime: Scalars['String']['output'];
  id: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  repeatUntil?: Maybe<Scalars['String']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['String']['output'];
  startDatetime: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  type: UserAvailabilityWithDatetimesTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type UserAvailabilityWithDatetimesConnection = {
  __typename?: 'UserAvailabilityWithDatetimesConnection';
  edges?: Maybe<Array<Maybe<UserAvailabilityWithDatetimesEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserAvailabilityWithDatetimesConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<UserAvailabilityWithDatetimesMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<UserAvailabilityWithDatetimesSearch>>;
};

export type UserAvailabilityWithDatetimesEdge = {
  __typename?: 'UserAvailabilityWithDatetimesEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<UserAvailabilityWithDatetimes>;
};

export type UserAvailabilityWithDatetimesMaskInput = {
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  dayOfWeek?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['Boolean']['input']>;
  endDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<Scalars['Boolean']['input']>;
  repeatUntil?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedBy?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Boolean']['input']>;
  startDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserAvailabilityWithDatetimesSearch = {
  approved?: InputMaybe<Array<IntSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  dayOfWeek?: InputMaybe<Array<IntSearch>>;
  endDate?: InputMaybe<Array<StringSearch>>;
  endDatetime?: InputMaybe<Array<StringSearch>>;
  endTime?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  reason?: InputMaybe<Array<StringSearch>>;
  repeatUntil?: InputMaybe<Array<StringSearch>>;
  reviewedBy?: InputMaybe<Array<StringSearch>>;
  startDate?: InputMaybe<Array<StringSearch>>;
  startDatetime?: InputMaybe<Array<StringSearch>>;
  startTime?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<UserAvailabilityWithDatetimesTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  userId?: InputMaybe<Array<StringSearch>>;
};

export enum UserAvailabilityWithDatetimesTypeEnum {
  Off = 'OFF',
  On = 'ON'
}

export type UserAvailabilityWithDatetimesTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<UserAvailabilityWithDatetimesTypeEnum>;
};

export type UserConfig = {
  __typename?: 'UserConfig';
  homepage?: Maybe<Scalars['String']['output']>;
  menus?: Maybe<UserConfigMenu>;
  permissions?: Maybe<Array<UserConfigPermissions>>;
};

export type UserConfigMenu = {
  __typename?: 'UserConfigMenu';
  navigation?: Maybe<Array<UserConfigNavigation>>;
  shortcuts?: Maybe<Array<UserConfigShortcut>>;
};

export type UserConfigNavigation = {
  __typename?: 'UserConfigNavigation';
  icon: Scalars['String']['output'];
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type UserConfigPermissions = {
  __typename?: 'UserConfigPermissions';
  resource: Scalars['String']['output'];
  sections: Array<UserConfigSection>;
};

export type UserConfigSection = {
  __typename?: 'UserConfigSection';
  columns: Array<UserConfigTableColumns>;
  filters: Array<UserConfigTableFilters>;
  name: Scalars['String']['output'];
  type: UserConfigSectionTypeEnum;
};

export enum UserConfigSectionTypeEnum {
  Table = 'TABLE'
}

export type UserConfigShortcut = {
  __typename?: 'UserConfigShortcut';
  icon: Scalars['String']['output'];
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type UserConfigTableColumns = {
  __typename?: 'UserConfigTableColumns';
  allowView: Scalars['Boolean']['output'];
  name: AirlineTripsTableColumnsEnum;
};

export type UserConfigTableFilters = {
  __typename?: 'UserConfigTableFilters';
  allowView: Scalars['Boolean']['output'];
  name: AirlineTripsTableColumnsEnum;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<UserMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<UserSearch>>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<User>;
};

export type UserInput = {
  active: Scalars['Boolean']['input'];
  address?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  averageRating?: InputMaybe<Scalars['Float']['input']>;
  companyId: Scalars['String']['input'];
  countryCode?: InputMaybe<Scalars['Int']['input']>;
  dob?: InputMaybe<Scalars['String']['input']>;
  driverSinceDate?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  employeeId?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  last: Scalars['String']['input'];
  onTimePercentage?: InputMaybe<Scalars['Float']['input']>;
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  roleId: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
  totalAmountOfTrips?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['Boolean']['input']>;
  cityName?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['Boolean']['input']>;
  longTrip?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  resetClientIp?: InputMaybe<Scalars['Boolean']['input']>;
  resetCode?: InputMaybe<Scalars['Boolean']['input']>;
  resetCreatedAtUtc?: InputMaybe<Scalars['Boolean']['input']>;
  roleId?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Boolean']['input']>;
  stateCode?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  address?: InputMaybe<Array<StringSearch>>;
  avatar?: InputMaybe<Array<StringSearch>>;
  cityName?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  countryCode?: InputMaybe<Array<IntSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  dob?: InputMaybe<Array<StringSearch>>;
  email?: InputMaybe<Array<StringSearch>>;
  employeeId?: InputMaybe<Array<StringSearch>>;
  firstName?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  lastName?: InputMaybe<Array<StringSearch>>;
  longTrip?: InputMaybe<Array<IntSearch>>;
  notes?: InputMaybe<Array<StringSearch>>;
  password?: InputMaybe<Array<StringSearch>>;
  phone?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  resetClientIp?: InputMaybe<Array<StringSearch>>;
  resetCode?: InputMaybe<Array<StringSearch>>;
  resetCreatedAtUtc?: InputMaybe<Array<StringSearch>>;
  roleId?: InputMaybe<Array<StringSearch>>;
  startDate?: InputMaybe<Array<StringSearch>>;
  stateCode?: InputMaybe<Array<StringSearch>>;
  timezone?: InputMaybe<Array<StringSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  username?: InputMaybe<Array<StringSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
  zipCode?: InputMaybe<Array<StringSearch>>;
};

export type UserWithAirportAppendAvailabilityConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  airportCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  availabilityQuery?: InputMaybe<Array<UserAvailabilityWithDatetimesSearch>>;
  endDatetime: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<UserSearch>>;
  sortDate?: InputMaybe<Scalars['String']['input']>;
  startDatetime: Scalars['String']['input'];
};

export type UserWithAirportConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  airports: Array<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<UserSearch>>;
};

export type UserWithAvailabilityAndPermissions = {
  __typename?: 'UserWithAvailabilityAndPermissions';
  active: Scalars['Int']['output'];
  address?: Maybe<Scalars['String']['output']>;
  approved?: Maybe<Scalars['Int']['output']>;
  availabilityEndDate: Scalars['String']['output'];
  availabilityEndDatetime: Scalars['String']['output'];
  availabilityEndTime: Scalars['String']['output'];
  availabilityId: Scalars['String']['output'];
  availabilityStartDate: Scalars['String']['output'];
  availabilityStartDatetime: Scalars['String']['output'];
  availabilityStartTime: Scalars['String']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  countryCode?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  dayOfWeek: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  effect: UserWithAvailabilityAndPermissionsEffectEnum;
  email: Scalars['String']['output'];
  employeeId?: Maybe<Scalars['String']['output']>;
  entity: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  longTrip?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  permissionId: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  repeatUntil?: Maybe<Scalars['String']['output']>;
  resetClientIp?: Maybe<Scalars['String']['output']>;
  resetCode?: Maybe<Scalars['String']['output']>;
  resetCreatedAtUtc?: Maybe<Scalars['String']['output']>;
  resource: Scalars['String']['output'];
  roleId: Scalars['String']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  traceId?: Maybe<Scalars['String']['output']>;
  type: UserWithAvailabilityAndPermissionsTypeEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
  vehicleId?: Maybe<Scalars['String']['output']>;
  verb: Scalars['String']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type UserWithAvailabilityAndPermissionsConnection = {
  __typename?: 'UserWithAvailabilityAndPermissionsConnection';
  edges?: Maybe<Array<Maybe<UserWithAvailabilityAndPermissionsEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserWithAvailabilityAndPermissionsConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<UserWithAvailabilityAndPermissionsMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<UserWithAvailabilityAndPermissionsSearch>>;
};

export type UserWithAvailabilityAndPermissionsEdge = {
  __typename?: 'UserWithAvailabilityAndPermissionsEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<UserWithAvailabilityAndPermissions>;
};

export enum UserWithAvailabilityAndPermissionsEffectEnum {
  Allow = 'ALLOW',
  Deny = 'DENY'
}

export type UserWithAvailabilityAndPermissionsEffectEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<UserWithAvailabilityAndPermissionsEffectEnum>;
};

export type UserWithAvailabilityAndPermissionsGroupByUser = {
  __typename?: 'UserWithAvailabilityAndPermissionsGroupByUser';
  availability?: Maybe<Array<UserAvailability>>;
  permissions?: Maybe<Array<Permission>>;
  user?: Maybe<UserWithAvailabilityAndPermissions>;
};

export type UserWithAvailabilityAndPermissionsGroupByUserConnection = {
  __typename?: 'UserWithAvailabilityAndPermissionsGroupByUserConnection';
  edges?: Maybe<Array<Maybe<UserWithAvailabilityAndPermissionsGroupByUserEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserWithAvailabilityAndPermissionsGroupByUserConnectionInput = {
  endDatetime?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<UserWithAvailabilityAndPermissionsConnectionInput>;
  sortDate?: InputMaybe<Scalars['String']['input']>;
  startDatetime?: InputMaybe<Scalars['String']['input']>;
};

export type UserWithAvailabilityAndPermissionsGroupByUserEdge = {
  __typename?: 'UserWithAvailabilityAndPermissionsGroupByUserEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<UserWithAvailabilityAndPermissionsGroupByUser>;
};

export type UserWithAvailabilityAndPermissionsMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['Boolean']['input']>;
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityEndDate?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityEndDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityEndTime?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityId?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityStartDate?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityStartDatetime?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityStartTime?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['Boolean']['input']>;
  cityName?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  dayOfWeek?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['Boolean']['input']>;
  effect?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Boolean']['input']>;
  entity?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['Boolean']['input']>;
  longTrip?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['Boolean']['input']>;
  permissionId?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  repeatUntil?: InputMaybe<Scalars['Boolean']['input']>;
  resetClientIp?: InputMaybe<Scalars['Boolean']['input']>;
  resetCode?: InputMaybe<Scalars['Boolean']['input']>;
  resetCreatedAtUtc?: InputMaybe<Scalars['Boolean']['input']>;
  resource?: InputMaybe<Scalars['Boolean']['input']>;
  roleId?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Boolean']['input']>;
  stateCode?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['Boolean']['input']>;
  verb?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserWithAvailabilityAndPermissionsSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  address?: InputMaybe<Array<StringSearch>>;
  approved?: InputMaybe<Array<IntSearch>>;
  availabilityEndDate?: InputMaybe<Array<StringSearch>>;
  availabilityEndDatetime?: InputMaybe<Array<StringSearch>>;
  availabilityEndTime?: InputMaybe<Array<StringSearch>>;
  availabilityId?: InputMaybe<Array<StringSearch>>;
  availabilityStartDate?: InputMaybe<Array<StringSearch>>;
  availabilityStartDatetime?: InputMaybe<Array<StringSearch>>;
  availabilityStartTime?: InputMaybe<Array<StringSearch>>;
  avatar?: InputMaybe<Array<StringSearch>>;
  cityName?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  countryCode?: InputMaybe<Array<IntSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  dayOfWeek?: InputMaybe<Array<IntSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  dob?: InputMaybe<Array<StringSearch>>;
  effect?: InputMaybe<Array<UserWithAvailabilityAndPermissionsEffectEnumSearch>>;
  email?: InputMaybe<Array<StringSearch>>;
  employeeId?: InputMaybe<Array<StringSearch>>;
  entity?: InputMaybe<Array<StringSearch>>;
  firstName?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  lastName?: InputMaybe<Array<StringSearch>>;
  longTrip?: InputMaybe<Array<IntSearch>>;
  notes?: InputMaybe<Array<StringSearch>>;
  password?: InputMaybe<Array<StringSearch>>;
  permissionId?: InputMaybe<Array<StringSearch>>;
  phone?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  repeatUntil?: InputMaybe<Array<StringSearch>>;
  resetClientIp?: InputMaybe<Array<StringSearch>>;
  resetCode?: InputMaybe<Array<StringSearch>>;
  resetCreatedAtUtc?: InputMaybe<Array<StringSearch>>;
  resource?: InputMaybe<Array<StringSearch>>;
  roleId?: InputMaybe<Array<StringSearch>>;
  startDate?: InputMaybe<Array<StringSearch>>;
  stateCode?: InputMaybe<Array<StringSearch>>;
  timezone?: InputMaybe<Array<StringSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  type?: InputMaybe<Array<UserWithAvailabilityAndPermissionsTypeEnumSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  username?: InputMaybe<Array<StringSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
  verb?: InputMaybe<Array<StringSearch>>;
  zipCode?: InputMaybe<Array<StringSearch>>;
};

export enum UserWithAvailabilityAndPermissionsTypeEnum {
  Off = 'OFF',
  On = 'ON'
}

export type UserWithAvailabilityAndPermissionsTypeEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<UserWithAvailabilityAndPermissionsTypeEnum>;
};

export type UserWithPermissions = {
  __typename?: 'UserWithPermissions';
  active: Scalars['Int']['output'];
  address?: Maybe<Scalars['String']['output']>;
  availability?: Maybe<Array<UserAvailability>>;
  avatar?: Maybe<Scalars['String']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  countryCode?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  effect: UserWithPermissionsEffectEnum;
  email: Scalars['String']['output'];
  employeeId?: Maybe<Scalars['String']['output']>;
  entity: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  groupId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  longTrip?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  permissionId: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  resetClientIp?: Maybe<Scalars['String']['output']>;
  resetCode?: Maybe<Scalars['String']['output']>;
  resetCreatedAtUtc?: Maybe<Scalars['String']['output']>;
  resource: Scalars['String']['output'];
  roleId: Scalars['String']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  traceId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
  vehicleId?: Maybe<Scalars['String']['output']>;
  verb: Scalars['String']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type UserWithPermissionsConnection = {
  __typename?: 'UserWithPermissionsConnection';
  edges?: Maybe<Array<Maybe<UserWithPermissionsEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserWithPermissionsConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<UserWithPermissionsMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<UserWithPermissionsSearch>>;
};

export type UserWithPermissionsEdge = {
  __typename?: 'UserWithPermissionsEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<UserWithPermissions>;
};

export enum UserWithPermissionsEffectEnum {
  Allow = 'ALLOW',
  Deny = 'DENY'
}

export type UserWithPermissionsEffectEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<UserWithPermissionsEffectEnum>;
};

export type UserWithPermissionsMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['Boolean']['input']>;
  cityName?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  deletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['Boolean']['input']>;
  effect?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['Boolean']['input']>;
  entity?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['Boolean']['input']>;
  groupId?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['Boolean']['input']>;
  longTrip?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['Boolean']['input']>;
  permissionId?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['Boolean']['input']>;
  resetClientIp?: InputMaybe<Scalars['Boolean']['input']>;
  resetCode?: InputMaybe<Scalars['Boolean']['input']>;
  resetCreatedAtUtc?: InputMaybe<Scalars['Boolean']['input']>;
  resource?: InputMaybe<Scalars['Boolean']['input']>;
  roleId?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Boolean']['input']>;
  stateCode?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['Boolean']['input']>;
  traceId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleId?: InputMaybe<Scalars['Boolean']['input']>;
  verb?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserWithPermissionsSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  address?: InputMaybe<Array<StringSearch>>;
  avatar?: InputMaybe<Array<StringSearch>>;
  cityName?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  countryCode?: InputMaybe<Array<IntSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  deletedAt?: InputMaybe<Array<StringSearch>>;
  deletedBy?: InputMaybe<Array<StringSearch>>;
  dob?: InputMaybe<Array<StringSearch>>;
  effect?: InputMaybe<Array<UserWithPermissionsEffectEnumSearch>>;
  email?: InputMaybe<Array<StringSearch>>;
  employeeId?: InputMaybe<Array<StringSearch>>;
  entity?: InputMaybe<Array<StringSearch>>;
  firstName?: InputMaybe<Array<StringSearch>>;
  groupId?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  lastName?: InputMaybe<Array<StringSearch>>;
  longTrip?: InputMaybe<Array<IntSearch>>;
  notes?: InputMaybe<Array<StringSearch>>;
  password?: InputMaybe<Array<StringSearch>>;
  permissionId?: InputMaybe<Array<StringSearch>>;
  phone?: InputMaybe<Array<StringSearch>>;
  providerId?: InputMaybe<Array<StringSearch>>;
  resetClientIp?: InputMaybe<Array<StringSearch>>;
  resetCode?: InputMaybe<Array<StringSearch>>;
  resetCreatedAtUtc?: InputMaybe<Array<StringSearch>>;
  resource?: InputMaybe<Array<StringSearch>>;
  roleId?: InputMaybe<Array<StringSearch>>;
  startDate?: InputMaybe<Array<StringSearch>>;
  stateCode?: InputMaybe<Array<StringSearch>>;
  timezone?: InputMaybe<Array<StringSearch>>;
  traceId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  username?: InputMaybe<Array<StringSearch>>;
  vehicleId?: InputMaybe<Array<StringSearch>>;
  verb?: InputMaybe<Array<StringSearch>>;
  zipCode?: InputMaybe<Array<StringSearch>>;
};

export type VariableTime = {
  __typename?: 'VariableTime';
  airportCode: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inboundDomestic?: Maybe<Scalars['Int']['output']>;
  inboundInternational?: Maybe<Scalars['Int']['output']>;
  outbound?: Maybe<Scalars['Int']['output']>;
  servicerIataAirlineCode: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type VariableTimeConnection = {
  __typename?: 'VariableTimeConnection';
  edges?: Maybe<Array<Maybe<VariableTimeEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VariableTimeConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<VariableTimeMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<VariableTimeSearch>>;
};

export type VariableTimeEdge = {
  __typename?: 'VariableTimeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VariableTime>;
};

export type VariableTimeMaskInput = {
  airportCode?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  inboundDomestic?: InputMaybe<Scalars['Boolean']['input']>;
  inboundInternational?: InputMaybe<Scalars['Boolean']['input']>;
  outbound?: InputMaybe<Scalars['Boolean']['input']>;
  servicerIataAirlineCode?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VariableTimeSearch = {
  airportCode?: InputMaybe<Array<StringSearch>>;
  companyId?: InputMaybe<Array<StringSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  inboundDomestic?: InputMaybe<Array<IntSearch>>;
  inboundInternational?: InputMaybe<Array<IntSearch>>;
  outbound?: InputMaybe<Array<IntSearch>>;
  servicerIataAirlineCode?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  active: Scalars['Int']['output'];
  airportCodes?: Maybe<Array<AirportHasVehicle>>;
  airportPermitExpirationDate?: Maybe<Scalars['String']['output']>;
  capacity: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  emissionPermitExpirationDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  licensePlate?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  locationId: Scalars['String']['output'];
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  registrationExpirationDate?: Maybe<Scalars['String']['output']>;
  stateRegistered?: Maybe<Scalars['String']['output']>;
  tollTag?: Maybe<Scalars['String']['output']>;
  tracker: VehicleTrackerEnum;
  trackingId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vin: Scalars['String']['output'];
  year?: Maybe<Scalars['Int']['output']>;
};

export type VehicleConnection = {
  __typename?: 'VehicleConnection';
  edges?: Maybe<Array<Maybe<VehicleEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VehicleConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  distinctMask?: InputMaybe<VehicleMaskInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Array<VehicleSearch>>;
};

export type VehicleEdge = {
  __typename?: 'VehicleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Vehicle>;
};

export enum VehicleMakeEnum {
  Ford = 'FORD',
  Mercedes = 'MERCEDES',
  Nissan = 'NISSAN'
}

export type VehicleMaskInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  airportPermitExpirationDate?: InputMaybe<Scalars['Boolean']['input']>;
  capacity?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['Boolean']['input']>;
  emissionPermitExpirationDate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  licensePlate?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['Boolean']['input']>;
  make?: InputMaybe<Scalars['Boolean']['input']>;
  model?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  registrationExpirationDate?: InputMaybe<Scalars['Boolean']['input']>;
  stateRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  tollTag?: InputMaybe<Scalars['Boolean']['input']>;
  tracker?: InputMaybe<Scalars['Boolean']['input']>;
  trackingId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  vin?: InputMaybe<Scalars['Boolean']['input']>;
  year?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VehicleModelInput = {
  make?: InputMaybe<VehicleMakeEnum>;
};

export type VehicleModelResponse = {
  __typename?: 'VehicleModelResponse';
  models?: Maybe<Array<AcceptInputOption>>;
};

export type VehicleSearch = {
  active?: InputMaybe<Array<IntSearch>>;
  airportPermitExpirationDate?: InputMaybe<Array<StringSearch>>;
  capacity?: InputMaybe<Array<IntSearch>>;
  createdAt?: InputMaybe<Array<StringSearch>>;
  createdBy?: InputMaybe<Array<StringSearch>>;
  emissionPermitExpirationDate?: InputMaybe<Array<StringSearch>>;
  id?: InputMaybe<Array<StringSearch>>;
  licensePlate?: InputMaybe<Array<StringSearch>>;
  locationId?: InputMaybe<Array<StringSearch>>;
  make?: InputMaybe<Array<StringSearch>>;
  model?: InputMaybe<Array<StringSearch>>;
  phoneNumber?: InputMaybe<Array<StringSearch>>;
  registrationExpirationDate?: InputMaybe<Array<StringSearch>>;
  stateRegistered?: InputMaybe<Array<StringSearch>>;
  tollTag?: InputMaybe<Array<StringSearch>>;
  tracker?: InputMaybe<Array<VehicleTrackerEnumSearch>>;
  trackingId?: InputMaybe<Array<StringSearch>>;
  updatedAt?: InputMaybe<Array<StringSearch>>;
  updatedBy?: InputMaybe<Array<StringSearch>>;
  vin?: InputMaybe<Array<StringSearch>>;
  year?: InputMaybe<Array<IntSearch>>;
};

export enum VehicleTrackerEnum {
  PhoneGps = 'PHONE_GPS',
  VanTracking = 'VAN_TRACKING'
}

export type VehicleTrackerEnumSearch = {
  sort?: InputMaybe<SortOptions>;
  type: SearchTypeEnum;
  values: Array<VehicleTrackerEnum>;
};
