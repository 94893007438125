import { Datetime, changeState } from '@/utils';
import EnumDropdown, { EnumDropdownMultiProps, getItemsFromEnum } from '@/components/EnumDropdown';
import { LostAndFoundHistoryContext, initLostAndFoundHistoryState, useLostAndFoundHistory } from '@/features/LostAndFoundHistory';
import { TODAY, TODAY_EOD } from '@/constants';

import AirlineIataDropdown from '@/components/AirlineDropdown';
import AirportDropdown from '@/components/AirportDropdown';
import { Button } from 'react-bootstrap';
import DateRangePicker from '@/components/DateRangePicker';
import LostAndFoundHistoryFiltersInfo from '@/features/LostAndFoundHistory/LostAndFoundHistoryFilters/LostAndFoundHistoryFiltersInfo';
import QueryFilters from '@/components/QueryFilters';
import { useCallback } from 'react';

enum LostAndFoundStatus {
  REPORTED_LOST = 'REPORTED_LOST',
  UNCLAIMED = 'UNCLAIMED',
  SHIPPED = 'SHIPPED',
  NOT_FOUND = 'NOT_FOUND',
  RETURNED = 'RETURNED',
}
const LostAndFoundStatusDropdown = (props: Omit<EnumDropdownMultiProps, 'enum'>): JSX.Element => (
  <EnumDropdown.Multi {...props} enum={getItemsFromEnum(LostAndFoundStatus)} options={{ locale: { 'Select...': 'Item Status' } }} />
);

const LostAndFoundHistoryFilters = (): JSX.Element => {
  const [{ filters, loading }, setState, { submitFilters }] = useLostAndFoundHistory(({ filters, loading }) => ({ filters, loading }));
  const { startDatetime, endDatetime, airports, airlines, status } = filters;

  const resetFilters = useCallback((): void => {
    setState((current) => ({ ...current, filters: initLostAndFoundHistoryState.filters }));
    submitFilters(initLostAndFoundHistoryState.filters);
  }, [setState]);

  const onChange = changeState<LostAndFoundHistoryContext>(setState, {
    path: 'filters.*',
    handlers: {
      datetime: (value, setState) => {
        const [from, to] = (value?.target?.value?.split(' - ') || [TODAY, TODAY_EOD]).map((date: string): string =>
          new Datetime(date).toString()
        );
        setState((current) => ({
          ...current,
          filters: { ...current.filters, startDatetime: from, endDatetime: to },
        }));
      },
    },
  });

  return (
    <>
      <QueryFilters>
        <QueryFilters.Control>
          <DateRangePicker value={[startDatetime, endDatetime]} onChange={onChange('datetime')} />
        </QueryFilters.Control>
        <QueryFilters.Input>
          <AirportDropdown.Multi value={airports} onChange={onChange('airports')} options={{ locale: { 'Select...': 'Airports' } }} />
        </QueryFilters.Input>
        <QueryFilters.Input>
          <AirlineIataDropdown.Multi value={airlines} onChange={onChange('airlines')} />
        </QueryFilters.Input>
        <QueryFilters.Input>
          <LostAndFoundStatusDropdown value={status} onChange={onChange('status')} />
        </QueryFilters.Input>
        <QueryFilters.Control>
          <Button className="border-white" name="SUBMIT" variant="success" onClick={() => submitFilters()} disabled={loading}>
            {loading && <i className="fa fa-spinner fa-pulse" />}
            {!loading && <span>Go</span>}
          </Button>
          <Button name="RESET" variant="outline-secondary-subtle" onClick={resetFilters} disabled={loading}>
            Reset
          </Button>
        </QueryFilters.Control>
      </QueryFilters>
      <LostAndFoundHistoryFiltersInfo />
    </>
  );
};

export default LostAndFoundHistoryFilters;
