import { Col, Container, Row } from 'react-bootstrap';
import { Dispatch, SetStateAction } from 'react';
import { TODAY, TODAY_EOD } from '@/constants';

import Feature from '@/common/Feature';
import LostAndFoundHistoryFilters from '@/features/LostAndFoundHistory/LostAndFoundHistoryFilters';
import LostAndFoundHistoryTable from '@/features/LostAndFoundHistory/LostAndFoundHistoryTable';
import { SortDirectionEnum } from '@/models/gen/graphql';
import { sleep } from '@/utils';
import { useProvider } from '@/common/Provider';

const LostAndFoundHistory = (): JSX.Element => {
  return (
    <Feature name="LostAndFoundHistory" context={initLostAndFoundHistoryState} methods={lostandfoundHistoryMethodConstructor}>
      <LostAndFoundHistoryFilters />
      <Container>
        <Row>
          <Col>
            <LostAndFoundHistoryTable />
          </Col>
        </Row>
      </Container>
    </Feature>
  );
};

export type LostAndFoundHistoryFilters = {
  startDatetime: string;
  endDatetime: string;
  airports: string[];
  airlines: string[];
  status: string[];
};
export type LostAndFoundHistoryRow = {
  airport: string;
  datetime: string;
  dispatcher: string;
  airline: string;
  flightNumber: string;
  van: string;
  driver: string;
  description: string;
  status: string;
};
export type LostAndFoundHistoryContext = {
  filters: LostAndFoundHistoryFilters;
  reports: Map<string, LostAndFoundHistoryRow>;
  totalReports: number;
  selected: Map<string, LostAndFoundHistoryRow>;
  loading: boolean;
  sortBy: string;
  sortDirection: SortDirectionEnum;
};
export type LostAndFoundHistoryMethods = {
  resetState: (overrides?: Partial<LostAndFoundHistoryContext>) => void;
  submitFilters: (values?: Partial<LostAndFoundHistoryFilters>) => Promise<void>;
};
export const initLostAndFoundHistoryState: LostAndFoundHistoryContext = {
  filters: {
    startDatetime: TODAY,
    endDatetime: TODAY_EOD,
    airports: [],
    airlines: [],
    status: [],
  },
  reports: new Map(),
  totalReports: 0,
  selected: new Map(),
  sortBy: '',
  sortDirection: SortDirectionEnum.Asc,
  loading: false,
};
export const lostandfoundHistoryMethodConstructor = (
  state: LostAndFoundHistoryContext,
  setState: Dispatch<SetStateAction<LostAndFoundHistoryContext>>
): LostAndFoundHistoryMethods => ({
  resetState: (overrides?: Partial<LostAndFoundHistoryContext>): void =>
    setState({ ...initLostAndFoundHistoryState, ...(overrides || {}) }),
  submitFilters: async (values: Partial<LostAndFoundHistoryFilters> = state.filters): Promise<void> => {
    try {
      console.log('Submitting filters:', values);
      setState((prevState) => ({ ...prevState, loading: true }));
      // TODO: Add full query logic once the service has been created.
      await sleep(3000);
    } catch (err) {
      console.error('Error submitting filters:', err);
    } finally {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  },
});
export function useLostAndFoundHistory<T = LostAndFoundHistoryContext>(
  selector?: (state: LostAndFoundHistoryContext) => T
): [T, Dispatch<SetStateAction<LostAndFoundHistoryContext>>, LostAndFoundHistoryMethods] {
  return useProvider<LostAndFoundHistoryContext, T, LostAndFoundHistoryMethods>(selector);
}

export default LostAndFoundHistory;
